import { Form, PasswordField, Tab, TabProvider, Tabs } from '@frontend/mui'
import { Maybe } from '@frontend/type'
import { TabPanel } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  createCustomPasswordInfo,
  isCustomPasswordValid,
} from 'src/components/Password'
import { useMe } from 'src/context/me'
import {
  LoginMutation,
  changePasswordByEmailMutation,
  changePasswordByPhoneNumberMutation,
  useTenantPasswordConfigQuery,
} from 'src/generated/apollo'
import { isMobile, useTenantCode } from 'src/utils/chaos'
import { useOAuth2 } from 'src/utils/oauth2'
import { EmailFields } from '../fields/email'
import { PhoneFields } from '../fields/phone'

type ForgetFormShape = {
  email: string
  phoneNumber: string
  verifyCode: string
  newPassword: string
}

export function Forget(props: { onGotoLogin?: () => void; isLarge?: boolean }) {
  const mobile = isMobile()
  const { onGotoLogin, isLarge } = props
  const { maybeGrantAuthorization } = useOAuth2()
  const { login } = useMe()
  const tenantCode = useTenantCode()
  const methods = useForm<ForgetFormShape>({ mode: 'onBlur' })
  const { data: passwordConfigQuery } = useTenantPasswordConfigQuery({
    skip: !tenantCode,
    variables: { tenantCode: tenantCode! },
  })
  const passwordConfig = Object.assign(
    { length: 8 },
    passwordConfigQuery?.tenantPasswordConfig,
  )
  const { info } = createCustomPasswordInfo(passwordConfig)
  const [tab, setTab] = useState<'phone' | 'email'>('email')
  const passwordField = (
    <PasswordField
      name="newPassword"
      label="新密码"
      required
      autoComplete="off"
      helperText={info}
      validate={(value: string) => {
        if (!isCustomPasswordValid(value || '', passwordConfig)) {
          return info
        }
      }}
    />
  )

  return (
    <Form<ForgetFormShape>
      methods={methods}
      onSubmit={async (values) => {
        let authInfo: Maybe<LoginMutation['login']> = null

        if (tab === 'email') {
          const { data } = await changePasswordByEmailMutation({
            variables: {
              input: {
                email: values.email,
                verifyCode: values.verifyCode,
                newPassword: values.newPassword,
                tenantCode: tenantCode!,
              },
            },
          })
          authInfo = data?.changePasswordByEmail
        } else if (tab === 'phone') {
          const { data } = await changePasswordByPhoneNumberMutation({
            variables: {
              input: {
                phoneNumber: values.phoneNumber,
                verifyCode: values.verifyCode,
                newPassword: values.newPassword,
                tenantCode: tenantCode!,
              },
            },
          })
          authInfo = data?.changePasswordByPhoneNumber
        }

        if (!authInfo) return
        maybeGrantAuthorization(authInfo.token!)
        login(authInfo.token!)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          m: mobile ? 0 : '36px 48px',
          height: mobile ? 488 : 568,

          ...(isLarge
            ? {
                '& .MuiOutlinedInput-input': {
                  height: '32px',
                },
              }
            : {}),
        }}
      >
        <Box display="grid" gap={mobile ? 3 : 6}>
          <Typography variant="h3">重置密码</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            可通过“已认证邮箱/手机号”或直接联系管理员重置密码。
          </Typography>
          <TabProvider value={tab} onChange={setTab}>
            <Tabs value={tab}>
              <Tab label="邮箱验证" value="email" sx={{ flex: 1 }} />
              <Tab label="手机验证" value="phone" sx={{ flex: 1 }} />
            </Tabs>
            <TabPanel value="email">
              <Stack display="grid" gridTemplateRows="72px 72px" rowGap={3}>
                <EmailFields getCaptchaSize={isLarge ? 'large' : undefined} />
                {passwordField}
              </Stack>
            </TabPanel>
            <TabPanel value="phone">
              <Stack display="grid" gridTemplateRows="72px 72px" rowGap={3}>
                <PhoneFields getCaptchaSize={isLarge ? 'large' : undefined} />
                {passwordField}
              </Stack>
            </TabPanel>
          </TabProvider>
        </Box>
        <Box display="grid" gap={1} height={68}>
          <Button type="submit" size={isLarge ? 'large' : undefined}>
            重置密码
          </Button>
          <Button variant="text" onClick={onGotoLogin}>
            返回登录
          </Button>
        </Box>
      </Box>
    </Form>
  )
}
