import { AsyncStatus } from '@frontend/mui'
import { Box, Stack } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { TELETRAAN_WEBSITE_LOGO_URL } from 'src/constants/env'
import { authTokenStorage } from 'src/context/apollo'
import { useThemeOfTenantCodeQuery } from 'src/generated/apollo'
import { useTenantCode } from 'src/utils/chaos'
import { useOAuth2 } from 'src/utils/oauth2'
import { isDingDingScene } from '../dingding/useDingTalk'
import { BackgroundFade } from './BackgroundFade'
import { Info } from './Info'
import { Logo } from './Logo'
import { Welcome } from './Welcome'
import { LoginForm } from './form/index'

export function Login() {
  const authToken = authTokenStorage.get()
  const { maybeGrantAuthorization, isOAuth2LoginScene } = useOAuth2()

  const tenantCode = useTenantCode()

  const { data, loading } = useThemeOfTenantCodeQuery({
    variables: {
      tenantCode: tenantCode!,
    },
    skip: !tenantCode || !!authToken,
  })

  if (isDingDingScene()) {
    return <AsyncStatus loading={true} />
  }
  if (authToken) {
    maybeGrantAuthorization(authToken)
    if (isOAuth2LoginScene) {
      return null
    }
    return <Navigate to="/" replace />
  }

  // TODO theme 查询

  return (
    <AsyncStatus loading={loading}>
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {!data?.themeOfTenantCode?.hideLogo && (
          <Logo src={TELETRAAN_WEBSITE_LOGO_URL} />
        )}
        <BackgroundFade bg={data?.themeOfTenantCode?.loginBackground?.url} />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Stack paddingLeft="56px" width="45%" spacing="16px">
            <Welcome
              greetings={data?.themeOfTenantCode?.greetings || undefined}
            />
          </Stack>
          <Stack
            sx={{
              maxHeight: '100vh',
              width: '55%',
              alignItems: 'center',
              overflow: 'auto',
              p: '32px 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#fff',
                flexDirection: 'column',
                borderRadius: '16px',
                boxSizing: 'border-box',
                alignItems: 'center',
              }}
            >
              <LoginForm />
            </Box>
          </Stack>
        </Box>
        <Info />
      </Box>
    </AsyncStatus>
  )
}
