"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUseContext = void 0;
const react_1 = require("react");
const createUseContextValue_1 = require("./createUseContextValue");
function createUseContext(defaultValue) {
    const context = (0, react_1.createContext)(null);
    const use = (0, createUseContextValue_1.createUseContextValue)(context, defaultValue);
    const Provider = context.Provider;
    return {
        context,
        use,
        Provider,
    };
}
exports.createUseContext = createUseContext;
