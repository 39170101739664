import { Form } from '@frontend/mui'
import { Maybe, UseState } from '@frontend/type'
import { AdUnits, Person } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, Link, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InsertLink from 'src/assets/insert-link.svg'
import EmailImg from 'src/assets/mail-download.svg'
import WxWorkImg from 'src/assets/wxlogo.svg'
import { AltImage } from 'src/components/AltImage'
import { useMe } from 'src/context/me'
import {
  LoginMode,
  LoginMutation,
  loginByPhoneNumberMutation,
  registerByPhoneNumberAllTenantMutation,
  loginByPhoneNumberAllTenantMutation,
  useLoginByEmailMutation,
  useLoginConfigOfTenantCodeQuery,
  useLoginMutation,
} from 'src/generated/apollo'
import { useTenantCode } from 'src/utils/chaos'
import { useOAuth2 } from 'src/utils/oauth2'
import { PanelType } from '.'
import { NoTenantMessage } from '../NoTenantMessage'
import { AccountFields } from '../fields/account'
import { EmailFields } from '../fields/email'
import { PhoneFields } from '../fields/phone'
import { SSOLogin } from './SSOLogin'
import { WxWorkLogin } from './WxWorkLogin'
import { useLocation, useNavigate } from 'react-router-dom'

enum LoginType {
  Account,
  Email,
  Phone,
  WxWork,
  SSO,
}

const loginTitleMap: Record<LoginType, string> = {
  '0': '密码登录',
  '1': '邮箱验证码登录',
  '2': '短信验证码登录',
  '3': '企业微信登录',
  '4': 'SSO认证登录',
}

const loginDescriptionMap: Record<LoginType, string> = {
  '0': '请输入账号/手机号/邮箱+密码登录',
  '1': '请输入邮箱及验证码登录',
  '2': '请输入手机号及验证码登录',
  '3': '请使用企业微信扫码登录',
  '4': '通过Teletraan系统登录，无需账号密码',
}

export interface LoginInput {
  email: string
  account: string
  password: string
  phoneNumber: string
  verifyCode: string
}

export function Login(props: { usePanel: UseState<PanelType> }) {
  const { login } = useMe()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [, setPanel] = props.usePanel
  const [loginMutation] = useLoginMutation()
  const [loginByEmailMutation] = useLoginByEmailMutation()
  const { maybeGrantAuthorization } = useOAuth2()

  const isRegister = pathname.startsWith('/register')

  const [loginType, setLoginType] = useState<LoginType>(LoginType.Account)
  const tenantCode = useTenantCode()
  const { data: loginConfigOfTenantCodeQuery, loading } =
    useLoginConfigOfTenantCodeQuery({
      skip: !tenantCode,
      variables: { tenantCode: tenantCode! },
      context: {
        ignoreError: true,
      },
    })
  const { modes, defaultMode } =
    loginConfigOfTenantCodeQuery?.loginConfigOfTenantCode ?? {}
  const allowedExtraLogin = (mode: LoginMode) =>
    tenantCode ? modes?.includes(mode) : true
  const handleSubmit = useCallback(
    async (values: LoginInput) => {
      let authInfo: Maybe<LoginMutation['login']> = null

      if (!tenantCode) {
        if (isRegister) {
          const result = await registerByPhoneNumberAllTenantMutation({
            variables: {
              input: values,
            },
          })
          authInfo = result.data?.registerByPhoneNumberAllTenant
        } else {
          const result = await loginByPhoneNumberAllTenantMutation({
            variables: {
              input: values,
            },
          })
          authInfo = result.data?.loginByPhoneNumberAllTenant
        }
      } else if (loginType === LoginType.Account) {
        const result = await loginMutation({
          variables: {
            input: { ...values, tenantCode },
          },
        })
        authInfo = result.data?.login
      } else if (loginType === LoginType.Email) {
        const result = await loginByEmailMutation({
          variables: {
            input: { ...values, tenantCode },
          },
        })
        authInfo = result.data?.loginByEmail
      } else if (loginType === LoginType.Phone) {
        const result = await loginByPhoneNumberMutation({
          variables: {
            input: { ...values, tenantCode },
          },
        })
        authInfo = result.data?.loginByPhoneNumber
      }

      if (!authInfo) return
      maybeGrantAuthorization(authInfo.token!)
      login(authInfo.token!)
    },
    [
      login,
      loginByEmailMutation,
      loginMutation,
      loginType,
      maybeGrantAuthorization,
      tenantCode,
      isRegister,
    ],
  )

  useEffect(() => {
    setLoginType(
      !tenantCode
        ? LoginType.Phone
        : defaultMode === 'EMAIL_PASSWORD'
        ? LoginType.Email
        : defaultMode === 'PHONE_VERIFY_CODE'
        ? LoginType.Phone
        : defaultMode === 'WECHAT'
        ? LoginType.WxWork
        : defaultMode === 'SSO'
        ? LoginType.SSO
        : LoginType.Account,
    )
  }, [defaultMode, tenantCode])

  const formMethods = useForm<LoginInput>({
    mode: 'onBlur',
  })

  if (loading) {
    return null
  }

  return (
    <Box
      sx={{
        padding: '48px 48px 32px 48px',
      }}
    >
      {!modes && tenantCode ? (
        <NoTenantMessage />
      ) : (
        <Form onSubmit={handleSubmit} methods={formMethods}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
            }}
          >
            <Box>
              <Typography variant="h2">
                {tenantCode
                  ? loginTitleMap[loginType]
                  : isRegister
                  ? '注册'
                  : '登录'}
              </Typography>
              <Typography
                variant="h5"
                color={(theme) => theme.palette.text.secondary}
              >
                {tenantCode ? (
                  loginDescriptionMap[loginType]
                ) : isRegister ? (
                  <>
                    已有账号？
                    <Link
                      onClick={() => {
                        formMethods.reset()
                        navigate('/login')
                      }}
                      underline="hover"
                      sx={(theme) => ({
                        ...theme.typography.h5,
                      })}
                    >
                      登录现有账号
                    </Link>
                  </>
                ) : (
                  <>
                    还没有账号？
                    <Link
                      onClick={() => {
                        formMethods.reset()
                        navigate('/register')
                      }}
                      underline="hover"
                      sx={(theme) => ({
                        ...theme.typography.h5,
                      })}
                    >
                      立即注册
                    </Link>
                  </>
                )}
              </Typography>
            </Box>
            {loginType === LoginType.WxWork ? (
              <WxWorkLogin />
            ) : loginType === LoginType.SSO ? (
              <SSOLogin />
            ) : (
              <Box
                sx={{
                  height: '256px',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  justifyContent: 'center',
                  '& .MuiOutlinedInput-input': {
                    height: '32px',
                  },
                }}
              >
                {loginType === LoginType.Account ? (
                  <AccountFields
                    forget={
                      tenantCode && (
                        <Link onClick={() => setPanel(PanelType.Forget)}>
                          忘记密码？
                        </Link>
                      )
                    }
                  />
                ) : loginType === LoginType.Email ? (
                  <EmailFields getCaptchaSize="large" />
                ) : loginType === LoginType.Phone ? (
                  <PhoneFields getCaptchaSize="large" />
                ) : null}
              </Box>
            )}
            <Stack
              hidden={!tenantCode}
              sx={{
                gap: '24px',
              }}
            >
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formMethods.formState.isSubmitting}
                sx={{
                  display: (
                    [LoginType.WxWork, LoginType.SSO] as LoginType[]
                  ).some((item) => item === loginType)
                    ? 'none'
                    : undefined,
                }}
              >
                {tenantCode ? '登录' : isRegister ? '注册' : '登录'}
              </LoadingButton>
              <Stack
                sx={{
                  gap: '16px',
                  display: tenantCode ? undefined : 'none',
                }}
              >
                {modes && modes.length > 1 && (
                  <Divider>
                    <Typography
                      sx={(theme) => ({
                        padding: '0 12px',
                        color: theme.palette.text.secondary,
                      })}
                    >
                      其他登录方式
                    </Typography>
                  </Divider>
                )}
                <Stack justifyContent="center" direction="row" spacing={4}>
                  {loginType !== LoginType.Account && (
                    <AltImage
                      onClick={() => setLoginType(LoginType.Account)}
                      icon={<Person />}
                      alt="账号"
                    />
                  )}
                  {allowedExtraLogin('EMAIL_PASSWORD') &&
                    loginType !== LoginType.Email && (
                      <AltImage
                        onClick={() => setLoginType(LoginType.Email)}
                        src={EmailImg}
                        alt="邮箱"
                      />
                    )}
                  {allowedExtraLogin('PHONE_VERIFY_CODE') &&
                    loginType !== LoginType.Phone && (
                      <AltImage
                        onClick={() => setLoginType(LoginType.Phone)}
                        icon={<AdUnits />}
                        alt="手机"
                      />
                    )}
                  {allowedExtraLogin('WECHAT') &&
                    loginType !== LoginType.WxWork && (
                      <AltImage
                        onClick={() => setLoginType(LoginType.WxWork)}
                        src={WxWorkImg}
                        alt="企业微信"
                      />
                    )}
                  {tenantCode &&
                    allowedExtraLogin('SSO') &&
                    loginType !== LoginType.SSO && (
                      <AltImage
                        onClick={() => setLoginType(LoginType.SSO)}
                        src={InsertLink}
                        alt="SSO"
                      />
                    )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Form>
      )}
    </Box>
  )
}
