import { AsyncStatus } from '@frontend/mui'
import { SetState } from '@frontend/type'
import {
  Box,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { useEffect, useState } from 'react'
import AccountImg from 'src/assets/account.svg'
import EmailImg from 'src/assets/email.svg'
import PhoneImg from 'src/assets/phone.svg'
import SSOImg from 'src/assets/sso.svg'
import TELETRAAN_DEFAULT_LOGO from 'src/assets/teletraan_logo.svg'
import { AltImage } from 'src/components/AltImage'
import {
  TELETRAAN_LOGO_MOBILE_PRIMARY_URL,
  VIEWPORT_HEIGHT,
} from 'src/constants/env'
import {
  LoginMode,
  useLoginConfigOfTenantCodeQuery,
} from 'src/generated/apollo'
import { PanelType } from 'src/pages/login/form'
import { Forget } from 'src/pages/login/form/Forget'
import { SSOLogin } from 'src/pages/login/form/SSOLogin'
import { useTenantCode } from 'src/utils/chaos'
import { isDingDingScene } from '../dingding/useDingTalk'
import { LoginForm } from './form'

export enum LoginType {
  Account,
  Email,
  Phone,
  SSO,
}

export const PaperWrapper = styled(Paper)(() => ({
  width: '100%',
  height: 540,
  padding: '32px 24px 20px',
  '&&&': {
    '.MuiFormControl-root': {
      alignItems: 'flex-start',
      '.MuiInputBase-root': {
        width: '100%',
      },
    },
  },
}))

export function MobileLogin() {
  const [panelType, setPanelType] = useState<PanelType>(PanelType.Login)
  if (isDingDingScene()) {
    return <AsyncStatus loading={true} />
  }
  return (
    <Stack
      position="relative"
      sx={{ overflowY: 'auto' }}
      height={VIEWPORT_HEIGHT}
    >
      <Box
        sx={{
          position: 'absolute',
          height: window.innerHeight,
          width: window.innerWidth,
          overflow: 'hidden',
          zIndex: -1,
          background:
            'url(https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/1.jpeg)',
          backgroundSize: 'cover',
        }}
      />
      <Stack padding="0 24px" height="100%" justifyContent="center">
        <PaperWrapper>
          {panelType === PanelType.Login ? (
            <LoginWrapper setPanelType={setPanelType} />
          ) : (
            <Forget onGotoLogin={() => setPanelType(PanelType.Login)} />
          )}
        </PaperWrapper>
      </Stack>
    </Stack>
  )
}

function LoginWrapper(props: { setPanelType: SetState<PanelType> }) {
  const { setPanelType } = props
  const [loginType, setLoginType] = useState<LoginType>(LoginType.Account)
  const tenantCode = useTenantCode()
  const { data: loginConfig, loading: loginConfigLoading } =
    useLoginConfigOfTenantCodeQuery({
      skip: !tenantCode,
      variables: { tenantCode: tenantCode! },
    })
  const { modes, defaultMode } = loginConfig?.loginConfigOfTenantCode ?? {}
  const allowedExtraLogin = (mode: LoginMode) =>
    tenantCode ? modes?.includes(mode) : true

  useEffect(() => {
    setLoginType(
      !tenantCode
        ? LoginType.Phone
        : defaultMode === 'EMAIL_PASSWORD'
        ? LoginType.Email
        : defaultMode === 'SSO'
        ? LoginType.SSO
        : LoginType.Account,
    )
  }, [defaultMode, tenantCode])

  if (loginConfigLoading) {
    return null
  }

  return (
    <Stack direction="column" alignItems="center" width="100%">
      <img
        src={TELETRAAN_LOGO_MOBILE_PRIMARY_URL || TELETRAAN_DEFAULT_LOGO}
        style={{ marginBottom: '24px' }}
        alt="logo"
      />
      <Stack direction="column" width="100%">
        {loginType === LoginType.SSO ? (
          <SSOLogin />
        ) : (
          <LoginForm loginType={loginType}>
            <Box hidden={!tenantCode || loginType !== LoginType.Account}>
              <Link width="70px" onClick={() => setPanelType(PanelType.Forget)}>
                忘记密码？
              </Link>
            </Box>
          </LoginForm>
        )}
        <Box hidden={!tenantCode}>
          {modes && modes.length > 1 && (
            <Divider sx={{ m: '8px 0 16px' }}>
              <Typography
                sx={{ padding: '0 12px', color: 'rgba(52, 60, 73, 0.65);' }}
              >
                其他登录方式
              </Typography>
            </Divider>
          )}
          <Stack justifyContent="center" direction="row" spacing={4}>
            {loginType !== LoginType.Account && (
              <AltImage
                size={36}
                onClick={() => setLoginType(LoginType.Account)}
                src={AccountImg}
                alt="账号"
              />
            )}
            {allowedExtraLogin('EMAIL_PASSWORD') &&
              loginType !== LoginType.Email && (
                <AltImage
                  size={36}
                  onClick={() => setLoginType(LoginType.Email)}
                  src={EmailImg}
                  alt="邮箱"
                />
              )}
            {allowedExtraLogin('PHONE_VERIFY_CODE') &&
              loginType !== LoginType.Phone && (
                <AltImage
                  size={36}
                  onClick={() => setLoginType(LoginType.Phone)}
                  src={PhoneImg}
                  alt="手机"
                />
              )}
            {tenantCode &&
              allowedExtraLogin('SSO') &&
              loginType !== LoginType.SSO && (
                <AltImage
                  size={36}
                  onClick={() => setLoginType(LoginType.SSO)}
                  src={SSOImg}
                  alt="SSO"
                />
              )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
