import { range, sample } from 'lodash-es'
import { PasswordCharacterSet } from 'src/generated/apollo'

type CustomPasswordFieldProps = {
  length: number
  characterSet?: PasswordCharacterSet[]
}

const charset = {
  UPPERCASE: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  LOWERCASE: 'abcdefghijklmnopqrstuvwxyz',
  NUMBER: '0123456789',
  SYMBOLS: '~!@#$%^&*()_+-=,.',
}

export const PasswordCharacterSetMap: Record<PasswordCharacterSet, string> = {
  UPPERCASE: '英文大写',
  LOWERCASE: '英文小写',
  NUMBER: '数字',
  SYMBOLS: '特殊符号',
}

export function createCustomPasswordInfo({
  length,
  characterSet,
}: CustomPasswordFieldProps) {
  const hint = `请输入不少于${length ? `${length}位` : ''}的密码。${
    characterSet?.length
      ? `必须包括${(
          Object.keys(PasswordCharacterSetMap) as PasswordCharacterSet[]
        )
          .filter((set) => characterSet.includes(set))
          .map((item) => PasswordCharacterSetMap[item])
          .join('/')}`
      : ''
  }`

  return {
    info: `${hint}\n特殊符号包括：~ ! @ # $ % ^ & * ( ) _ + - = , .`,
  }
}

export function generateCustomPassword({
  length,
  characterSet,
}: CustomPasswordFieldProps) {
  const requiredPassword = (characterSet || []).map((set) =>
    sample(charset[set]),
  )
  const restPassword = range(0, length - requiredPassword.length).map(() =>
    sample(sample(Object.values(charset))),
  )
  const result = requiredPassword
    .concat(restPassword)
    .sort(() => 0.5 - Math.random())
    .join('')

  return result
}

export function isCustomPasswordValid(
  password: string,
  { length, characterSet }: CustomPasswordFieldProps,
) {
  if (password.length === 0) return false
  if (password.length < length) return false
  if (!/^[\w~!@#$%^&*()_+\-=,.]{1,1000}$/.test(password)) {
    return false
  }
  return (characterSet || [])
    .map((item) => {
      return Array.from(charset[item]).some((char) => password.includes(char))
    })
    .every(Boolean)
}
