import { Box, Link, Typography } from '@mui/material'
import { COPY_RIGHT, ICP_LINK, ICP_NUMBER, SUPPORT } from 'src/constants/env'

export function Info() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#fff',
        opacity: 0.6,
        transform: 'scale(1)',
        position: 'absolute',
        bottom: 0,
        left: '20px',
      }}
    >
      <Box display="flex">
        {COPY_RIGHT && (
          <Typography
            variant="caption"
            sx={{
              display: 'inline-block',
              fontSize: '10px',
              marginRight: '6px',
            }}
          >
            © 版权所有：{COPY_RIGHT}
          </Typography>
        )}
        {SUPPORT && (
          <Typography
            variant="caption"
            sx={{
              display: 'inline-block',
              fontSize: '10px',
            }}
          >
            {`| 技术支持：${SUPPORT}`}
          </Typography>
        )}
      </Box>
      {ICP_NUMBER && (
        <Box display="flex" marginBottom={'18px'}>
          <Link href={ICP_LINK} target="_blank">
            <Typography
              variant="caption"
              sx={{
                fontSize: '10px',
                color: '#fff',
              }}
            >
              备案号：{ICP_NUMBER}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  )
}
