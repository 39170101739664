/**
 * url query string 转化为对象形式
 * @param search URL查询参数 e.g. ?a=1&b=2
 * @returns
 */
export function qsToObj(search: string) {
  const normalizedQuery = search.replace(/^\?+/, '')
  return Object.fromEntries(new URLSearchParams(normalizedQuery).entries())
}

export function objectToQuery(object: object) {
  const str: string[] = []

  Object.entries(object).forEach(([key, value]) => {
    if (object.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
    }
  })

  return str.join('&')
}
