import {
  EntryProvider,
  TCProvider,
  defaultTheme,
  mobileTheme,
} from '@frontend/mui'
import { BrowserRouter } from 'react-router-dom'
import { ROUTE_URL } from './constants/env'
import { ApolloProvider } from './context/apollo'
import { MeProvider } from './context/me'
import { Pages, entries } from './pages'
import { isMobile } from './utils/chaos'
function App() {
  return (
    <BrowserRouter basename={ROUTE_URL}>
      <TCProvider theme={isMobile() ? mobileTheme : defaultTheme}>
        <ApolloProvider>
          <MeProvider>
            <EntryProvider entries={entries}>
              <Pages></Pages>
            </EntryProvider>
          </MeProvider>
        </ApolloProvider>
      </TCProvider>
    </BrowserRouter>
  )
}

export default App
