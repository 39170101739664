import { AsyncStatus, useConfirm } from '@frontend/mui'
import { Check, PriorityHigh } from '@mui/icons-material'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useRecoilState } from 'recoil'
import { authTokenStorage, logout } from 'src/context/apollo'
import { accountExistsQuery } from 'src/generated/apollo'
import { clearCookie } from 'src/utils/chaos'
import { wxWorkState } from './useWxWork'

export function WxWorkBindFailedPage() {
  const authToken = authTokenStorage.get()
  const confirm = useConfirm()
  const [wxWork, setWxWork] = useRecoilState(wxWorkState)
  const [account, setAccount] = useState('')
  const { bindStatus } = wxWork

  // 验证账号后才算绑定成功
  const handleAccount = useCallback(
    async (account: string) => {
      const { data } = await accountExistsQuery({
        variables: {
          account,
        },
      })
      if (data.accountExists) {
        setWxWork((prev) => ({
          ...prev,
          bindStatus: 'success',
          isBindSuccess: true,
        }))
      } else {
        setWxWork((prev) => ({ ...prev, bindStatus: 'invalid' }))
      }
    },
    [setWxWork],
  )

  const bind = useCallback(async () => {
    const bindUserResult = await fetch('/api/v1/wecom/binduser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
    if (bindUserResult.ok) {
      const { account } = await bindUserResult.json()
      setAccount(account)
      handleAccount(account)
    } else {
      if (bindUserResult.status === 400) {
        const confirmed = await confirm({
          title: '用户已被绑定',
        })
        if (confirmed) {
          logout()
        }
      } else if (bindUserResult.status === 401) {
        const confirmed = await confirm({
          title: '绑定失败',
        })
        if (confirmed) {
          logout()
        }
      }
    }
  }, [authToken, confirm, handleAccount])

  const unbind = useCallback(async () => {
    const result = await fetch('/api/v1/wecom/unbinduser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
    if (!result.ok) {
      await confirm({ title: '取消绑定失败' })
    }
    setWxWork({
      isBindSuccess: false,
      isAuthorized: false,
      bindStatus: null,
    })
    clearCookie()
    logout()
  }, [authToken, confirm, setWxWork])

  const onClickButton = useCallback(async () => {
    if (bindStatus === 'success') {
      const confirmed = await confirm({
        title: '确定取消绑定吗？',
        cancelLabel: '返回',
        confirmLabel: '确定取消',
      })
      if (confirmed) {
        unbind()
      }
    } else {
      unbind()
    }
  }, [bindStatus, confirm, unbind])

  useEffectOnce(() => {
    ;(async () => {
      if (bindStatus) return

      const userInfoResult = await fetch('/api/v1/wecom/userinfo')

      if (userInfoResult.ok) {
        userInfoResult.json().then(async (data) => {
          if (!data?.account) {
            // 未绑定进行绑定
            bind()
          } else {
            // 已绑定直接验证账号
            setAccount(data.account)
            handleAccount(data.account)
          }
        })
      } else {
        const confirmed = await confirm({ title: '获取用户信息失败' })
        if (confirmed) {
          clearCookie()
          logout()
        }
      }
    })()
  })

  return (
    <AsyncStatus loading={!bindStatus}>
      <Stack sx={{ height: window.innerHeight }}>
        <Stack
          rowGap="10px"
          alignItems="center"
          justifyContent="center"
          height={700}
        >
          <Grid
            sx={{
              display: 'grid',
              width: '80px',
              height: '80px',
              borderRadius: '100%',
              background: bindStatus === 'success' ? '#30bf78' : '#f4664a',
              placeItems: 'center',
              color: 'white',
              fontSize: '48px',
              marginBottom: '14px',
            }}
          >
            {bindStatus === 'success' ? (
              <Check fontSize="inherit" />
            ) : (
              <PriorityHigh fontSize="inherit" />
            )}
          </Grid>
          <Typography variant="h4">
            {bindStatus === 'success'
              ? '绑定成功'
              : bindStatus === 'invalid'
              ? '账号失效'
              : '绑定失败'}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            whiteSpace="pre-wrap"
          >
            {bindStatus === 'success' &&
              `${account} 您好！您已绑定成功，可接收来自Teletraan的推送消息！`}
            {bindStatus === 'invalid' &&
              '您原绑定的账号已被删除，\n请重新绑定！'}
          </Typography>
        </Stack>
        <Button onClick={() => onClickButton()} variant="text">
          {bindStatus === 'success' ? '取消绑定' : '重新绑定'}
        </Button>
      </Stack>
    </AsyncStatus>
  )
}
