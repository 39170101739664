"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.omitAllVariables = exports.omitVariables = exports.omitField = void 0;
const graphql_1 = require("graphql");
const lodash_1 = require("lodash");
const utils_1 = require("../utils");
function getFieldType(fieldType) {
    if (!(0, graphql_1.isListType)(fieldType) && 'ofType' in fieldType) {
        return getFieldType(fieldType.ofType);
    }
    return fieldType;
}
function omitField(args) {
    const { variables, initialVariables, inputType } = args;
    const fieldType = getFieldType(inputType);
    if (variables === null || variables === undefined) {
        return variables;
    }
    if ((0, graphql_1.isInputObjectType)(fieldType)) {
        return Object.values(fieldType.getFields()).reduce((result, { name, type }) => {
            ;
            result[name] = omitField({
                variables: variables[name],
                initialVariables: initialVariables === null || initialVariables === void 0 ? void 0 : initialVariables[name],
                inputType: type,
            });
            return result;
        }, {});
    }
    if (!(0, lodash_1.isNil)(initialVariables) &&
        (0, graphql_1.isNullableType)(inputType) &&
        (0, lodash_1.isEqual)(variables, initialVariables)) {
        return undefined;
    }
    if ((0, graphql_1.isListType)(fieldType)) {
        return variables.map((variable) => omitField({
            variables: variable,
            inputType: getFieldType(fieldType.ofType),
        }));
    }
    if (fieldType.name === 'String' && typeof variables === 'number') {
        return variables.toString();
    }
    else if (fieldType.name === 'Int' && typeof variables === 'string') {
        return parseInt(variables, 10);
    }
    return variables;
}
exports.omitField = omitField;
function omitVariables(args) {
    const { variables, initialVariables, schema, operationName, operationType } = args;
    const operation = (0, utils_1.getOperation)({
        schema,
        operationType,
        operationName,
    });
    if (variables === null || variables === undefined || !(operation === null || operation === void 0 ? void 0 : operation.args)) {
        return variables;
    }
    return operation.args.reduce((result, { name, type }) => {
        ;
        result[name] = omitField({
            variables: variables[name],
            initialVariables: initialVariables === null || initialVariables === void 0 ? void 0 : initialVariables[name],
            inputType: type,
        });
        return result;
    }, {});
}
exports.omitVariables = omitVariables;
function omitAllVariables(args) {
    const { variables, initialVariables, schema, operationType } = args;
    if (variables === null || variables === undefined) {
        return variables;
    }
    return Object.entries(variables).reduce((result, [key, value]) => {
        ;
        result[key] = omitVariables({
            schema,
            operationType,
            operationName: key,
            variables: value,
            initialVariables,
        });
        return result;
    }, {});
}
exports.omitAllVariables = omitAllVariables;
