import { storage } from '@frontend/helpers'
import { Maybe } from '@frontend/type'
import { useNavigate } from 'react-router-dom'
import { useCookie, useEffectOnce } from 'react-use'
import { atom, useRecoilState } from 'recoil'
import { authTokenStorage } from 'src/context/apollo'
import { clearCookie } from 'src/utils/chaos'

export const homePathUrlStorage = storage<string | undefined>('home_path')
export const wxWorkState = atom<{
  tenantId?: Maybe<string>
  isBindSuccess?: boolean
  isAuthorized?: boolean
  bindStatus?: 'success' | 'invalid' | null
}>({
  key: 'wxWorkState',
  default: {
    isBindSuccess: false,
    isAuthorized: false,
    bindStatus: null,
  },
})

// 判断是否为企业微信环境
export function isWxWorkScene() {
  return /wxwork/i.test(navigator.userAgent)
}

// 企业微信的验证逻辑
export function useWxWork() {
  const navigate = useNavigate()
  const [wecomId] = useCookie('wecom_id')
  const [wxWork, setWxWork] = useRecoilState(wxWorkState)
  const { isAuthorized, isBindSuccess, bindStatus } = wxWork
  const authToken = authTokenStorage.get()

  // 企业微信需要补充信息
  const [profileIsSet] = useCookie('profile_is_set')
  // 企业微信自动登录
  const [accessToken] = useCookie('access_token')
  const [redirect] = useCookie('redirect')
  const [homePath] = useCookie('home_path')

  useEffectOnce(() => {
    if (profileIsSet === '0') {
      navigate('/set_profile', { replace: true })
    }
  })

  useEffectOnce(() => {
    if (isWxWorkScene()) {
      if (accessToken && !authToken) {
        authTokenStorage.set(accessToken)
        if (homePath) homePathUrlStorage.set(homePath)
        if (redirect) {
          window.location.href = `${redirect}`
        } //已经授权后再次进入跳转至相关app
        else {
          window.location.href = `/dashboard`
        }
      }
    }
  })

  useEffectOnce(() => {
    const homePathUrl = homePathUrlStorage.get()
    if (!authToken && homePathUrl) {
      window.location.href = `/${homePathUrl}`
    }
  })

  useEffectOnce(() => {
    if (
      !isWxWorkScene() ||
      isBindSuccess ||
      isAuthorized ||
      authToken ||
      bindStatus ||
      profileIsSet === '0'
    ) {
      return
    }
    // 未授权进行跳转请求授权
    if (!wecomId) {
      window.location.pathname = '/wecom/authorize'
    }

    fetch('/api/v1/wecom/userinfo')
      .then((res) => {
        if (res.status === 401) {
          clearCookie()
          window.location.pathname = '/wecom/authorize'
          throw Error()
        } else {
          return res.json()
        }
      })
      .then(async (data) => {
        if (data?.tenant_id) {
          setWxWork({
            ...wxWorkState,
            isAuthorized: true,
            tenantId: data.tenant_id,
          })
        } else {
          clearCookie()
          window.location.pathname = '/wecom/authorize'
        }
      })
  })
}
