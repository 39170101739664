import { Box, Stack } from '@mui/material'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useWindowSize } from 'react-use'
import { LOGIN_BG_URLS } from 'src/constants/env'
import 'swiper/css'
import 'swiper/css/effect-fade'

const images = LOGIN_BG_URLS?.length
  ? LOGIN_BG_URLS
  : [
      'https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/7.jpeg',
      'https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/8.jpeg',
      'https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/9.jpeg',
      'https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/10.jpeg',
      'https://teletraan-assets.s3.cn-northwest-1.amazonaws.com.cn/team/login_background/11.jpeg',
    ]

export function BackgroundFade({ bg }: { bg?: string }) {
  const { width, height } = useWindowSize()

  const imgs = bg ? [bg] : images

  return (
    <Stack justifyContent="center">
      <Box
        sx={{
          position: 'absolute',
          width: width,
          height: height,
          zIndex: -1,
          left: 0,
          top: 0,
        }}
      >
        <Swiper
          modules={[EffectFade, Autoplay]}
          effect="fade"
          autoplay={{
            delay: 9000,
            disableOnInteraction: false,
          }}
          speed={1600}
        >
          {imgs.map((url) => (
            <SwiperSlide>
              <Stack
                sx={{
                  backgroundImage: `url(${url})`,
                  backgroundSize: 'cover',
                  width: width,
                  height: height,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Stack>
  )
}
