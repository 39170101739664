import { useAlert } from '@frontend/mui'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMe } from 'src/context/me'
import {
  LoginConfigurationKind,
  wecomLoginMutation,
} from 'src/generated/apollo'
import { useTenantCode, useThirdStateCode } from 'src/utils/chaos'
import { qsToObj } from 'src/utils/query'
import { Forget } from './Forget'
import { Login } from './Login'

export enum PanelType {
  Login,
  Forget,
}

export type DispatchLogin = {
  kind: LoginConfigurationKind
  authenticationConfigurationName?: string
  loginUrl?: string
}

export function LoginForm() {
  const alert = useAlert()
  const { login } = useMe()
  const [panelType, setPanelType] = useState<PanelType>(PanelType.Login)
  const { auth_code, state } = qsToObj(window.location.search)
  const [wxWorkLoginLoading, setWxWorkLoginLoading] = useState(false)
  const stateCode = useThirdStateCode()

  // 当前环境是企业微信扫码登录的回调
  useEffect(() => {
    if (auth_code && !wxWorkLoginLoading) {
      ;(async () => {
        if (state !== stateCode) {
          alert.show({ children: '企业微信参数错误', severity: 'error' })
        } else {
          setWxWorkLoginLoading(true)
          const { data } = await wecomLoginMutation({
            variables: { authCode: auth_code },
          })
          if (data?.wecomLogin.userId) {
            login(data.wecomLogin.token)
            window.location.href = '/'
          }
        }
      })()
    }
  }, [alert, auth_code, login, state, stateCode, wxWorkLoginLoading])

  const tenantCode = useTenantCode()

  return (
    <Box sx={{ width: 532, height: tenantCode ? 640 : 588 }}>
      {panelType === PanelType.Login ? (
        <Login usePanel={[panelType, setPanelType]} />
      ) : panelType === PanelType.Forget ? (
        <Forget onGotoLogin={() => setPanelType(PanelType.Login)} isLarge />
      ) : null}
    </Box>
  )
}
