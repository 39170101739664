import { Maybe } from '@frontend/type'
import { Box } from '@mui/material'

export function Logo(props: { src: Maybe<string> }) {
  const { src } = props
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '56px',
        top: '44px',
        cursor: 'pointer',
      }}
      onClick={() => {
        window.location.href = '/'
      }}
    >
      <img src={src || undefined} height="39px" alt="" />
    </Box>
  )
}
