"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOperation = void 0;
function getOperation(args) {
    const { schema, operationType, operationName } = args;
    switch (operationType) {
        case 'query':
            const queryType = schema.getQueryType();
            if (!queryType) {
                return;
            }
            return queryType.getFields()[operationName];
        case 'mutation':
            const mutationType = schema.getMutationType();
            if (!mutationType) {
                return;
            }
            return mutationType.getFields()[operationName];
        case 'subscription':
            const subscriptionType = schema.getSubscriptionType();
            if (!subscriptionType) {
                return;
            }
            return subscriptionType.getFields()[operationName];
        default:
            throw new Error(`unknown operation type: ${operationType}`);
    }
}
exports.getOperation = getOperation;
