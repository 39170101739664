"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShallowCompareCallback = void 0;
const fast_shallow_equal_1 = require("fast-shallow-equal");
const react_1 = require("react");
function useShallowCompareCallback(callback, deps) {
    const ref = (0, react_1.useRef)();
    if (!ref.current ||
        !ref.current.every((item, index) => (0, fast_shallow_equal_1.equal)(item, deps[index]))) {
        ref.current = deps;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (0, react_1.useCallback)(callback, ref.current);
}
exports.useShallowCompareCallback = useShallowCompareCallback;
