import { createUseContext } from '@frontend/helpers'
import { PropsWithChildren } from 'react'
import { MyAppListQuery, useMyAppListQuery } from 'src/generated/apollo'
import { authTokenStorage } from './apollo'

interface MyAppListContextShape {
  appList?: MyAppListQuery['myAppList']['data']
  loading: boolean
}

export const { use: useMyAppList, Provider } =
  createUseContext<MyAppListContextShape>()

export function MyAppListProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const authToken = authTokenStorage.get()
  const path = window.location.pathname
  const { data, loading } = useMyAppListQuery({
    skip: !authToken || /^\/login/.test(path),
  })

  return (
    <Provider
      value={{
        appList: data?.myAppList.data,
        loading,
      }}
    >
      {children}
    </Provider>
  )
}
