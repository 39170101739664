"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWhiteList = exports.isNotNil = exports.getOperation = void 0;
function getOperation(args) {
    const { schema, operationType, operationName } = args;
    switch (operationType) {
        case 'query':
            const queryType = schema.getQueryType();
            if (!queryType) {
                return;
            }
            return queryType.getFields()[operationName];
        case 'mutation':
            const mutationType = schema.getMutationType();
            if (!mutationType) {
                return;
            }
            return mutationType.getFields()[operationName];
        case 'subscription':
            const subscriptionType = schema.getSubscriptionType();
            if (!subscriptionType) {
                return;
            }
            return subscriptionType.getFields()[operationName];
        default:
            return;
    }
}
exports.getOperation = getOperation;
function isNotNil(value) {
    return value !== undefined && value !== null;
}
exports.isNotNil = isNotNil;
function isWhiteList(fieldsFilter) {
    if (fieldsFilter === true) {
        return true;
    }
    if (fieldsFilter === false || fieldsFilter === undefined) {
        return false;
    }
    return Object.values(fieldsFilter).map(isWhiteList).includes(true);
}
exports.isWhiteList = isWhiteList;
