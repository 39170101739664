"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOperationsBody = exports.createOperationBody = void 0;
const utils_1 = require("../utils");
const field_1 = require("./field");
function createOperationBody(args) {
    const { schema, operationType, operationName, fieldsFilter, modelsFilter, operationDict, maxDepth = 5, } = args;
    const operation = (0, utils_1.getOperation)({
        schema,
        operationType,
        operationName,
    });
    if (!operation) {
        return '';
    }
    const argumentsSet = new Set();
    const operationFieldString = (0, field_1.stringifyField)({
        schema,
        field: operation,
        fieldsFilter,
        modelsFilter,
        unionDict: operationDict === null || operationDict === void 0 ? void 0 : operationDict[operation.name],
        maxDepth,
        argumentsSet,
    });
    const operationArgsDefination = argumentsSet.size
        ? Array.from(argumentsSet.values()).join(', ')
        : '';
    return `
    ${operationType} ${operationName}${operationArgsDefination ? `(${operationArgsDefination})` : ''} {
      ${operationFieldString}
    }
  `;
}
exports.createOperationBody = createOperationBody;
function createOperationsBody(args) {
    const { schema, operationType, fieldsFilter, modelsFilter, operationDict, maxDepth = 5, } = args;
    const operationNames = Object.keys(fieldsFilter);
    const operations = operationNames
        .map((operationName) => (0, utils_1.getOperation)({
        schema,
        operationType,
        operationName,
    }))
        .filter(utils_1.isNotNil);
    const operationName = operationNames.join('_');
    const operationsFieldsString = operations
        .map((operation) => (0, field_1.stringifyField)({
        schema,
        field: operation,
        fieldsFilter: fieldsFilter[operation.name],
        modelsFilter,
        unionDict: operationDict === null || operationDict === void 0 ? void 0 : operationDict[operation.name],
        prefix: operation.name,
        maxDepth,
        argumentsSet: new Set(),
    }))
        .join('\n');
    // TODO: add field's arguments
    const operationArgsDefination = operations
        .filter((operation) => operation.args.length)
        .map((operation) => `${operation.args
        .map((arg) => `$${operation.name}_${arg.name}: ${arg.type}`)
        .join(', ')}`)
        .join(', ');
    return `
    ${operationType} ${operationName}${operationArgsDefination ? `(${operationArgsDefination})` : ''} {
        ${operationsFieldsString}
      }
    `;
}
exports.createOperationsBody = createOperationsBody;
