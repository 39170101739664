import { PasswordField, TextField } from '@frontend/mui'
import { ReactNode } from 'react'
import { FakeField } from './fake'

export function AccountFields({ forget }: { forget?: ReactNode }) {
  return (
    <>
      <FakeField />
      <TextField
        name="account"
        label="账号"
        placeholder="请输入账号/手机号/邮箱 "
        required
      />
      <PasswordField
        name="password"
        label="密码"
        required
        helperText={forget}
      />
    </>
  )
}
