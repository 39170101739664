import { storage } from '@frontend/helpers'
import { Form, TextField, useDialog } from '@frontend/mui'
import { Button, Stack } from '@mui/material'
import { useCallback } from 'react'
import { useEffectOnce, useSearchParam } from 'react-use'
import { atom, useRecoilState } from 'recoil'
import { authTokenStorage } from 'src/context/apollo'
import {
  loginConfigOfTenantCodeQuery,
  useAuthByWechatCodeMutation,
} from 'src/generated/apollo'
import { useThirdStateCode } from 'src/utils/chaos'
import { objectToQuery } from 'src/utils/query'
import { tenantStorage } from '..'
import { isWxWorkScene } from '../wxwork/useWxWork'

// 后端在sso登录后会塞入这个数据，目前用于标识绑定微信公众号
export const ssoStorage = storage<boolean | undefined>('LOGIN_BY_SSO')

export const accessTokenStorage = storage<string | undefined>(
  'wxmp_accessToken',
)

const pass = !!authTokenStorage.get() && !ssoStorage.get()

export const wxMPState = atom<{
  isBindSuccess?: boolean
  isAuthorized?: boolean
  bindStatus?: 'success' | 'invalid' | null
}>({
  key: 'wxMPState',
  default: {
    isBindSuccess: pass,
    isAuthorized: pass,
    bindStatus: pass ? 'success' : null,
  },
})

// 判断是否为微信公众号环境
export function isWxMPScene() {
  return !isWxWorkScene() && /micromessenger/i.test(navigator.userAgent)
}

export function useWxMP() {
  const dialog = useDialog()
  const [mutation] = useAuthByWechatCodeMutation()
  const [wxMP, setWxMP] = useRecoilState(wxMPState)
  const { isAuthorized, isBindSuccess, bindStatus } = wxMP
  const authToken = authTokenStorage.get()
  const code = useSearchParam('code')
  const state = useThirdStateCode()
  const getAuthorizeUrl = useCallback(
    () =>
      `https://open.weixin.qq.com/connect/oauth2/authorize?${objectToQuery({
        appid: 'wx7d24095701443e18',
        redirect_uri: window.location.origin + `/login/${tenantStorage.get()}`,
        response_type: 'code',
        scope: 'snsapi_base',
        state,
      })}#wechat_redirect`,
    [state],
  )

  useEffectOnce(() => {
    if (
      !isWxMPScene() ||
      isBindSuccess ||
      isAuthorized ||
      authToken ||
      bindStatus
    ) {
      return
    }

    const authorize = async () => {
      // 未授权进行跳转请求授权
      if (!code) {
        window.location.href = getAuthorizeUrl()
        return
      }

      const { data } = await mutation({
        variables: { code },
      })
      const { token, accessToken } = data?.authByWechatCode ?? {}
      // 一个token都没有代表异常情况
      if (!token && !accessToken) {
        window.location.href = getAuthorizeUrl()
      }
      // 有token，说明后端已经进行绑定
      if (token) {
        authTokenStorage.set(token)
        setWxMP({
          isBindSuccess: true,
          isAuthorized: true,
          bindStatus: 'success',
        })
      }
      // 只有accessToken，需要登录和绑定
      if (accessToken) {
        accessTokenStorage.set(accessToken)
        setWxMP({
          ...wxMP,
          isAuthorized: true,
        })
      }
    }

    if (tenantStorage.get()) {
      authorize()
    } else {
      dialog.show({
        title: '企业标识符',
        hideCloseIcon: true,
        content: (
          <Form
            onSubmit={async (values) => {
              const { data } = await loginConfigOfTenantCodeQuery({
                variables: { tenantCode: values.code },
              })
              if (data.loginConfigOfTenantCode) {
                tenantStorage.set(values.code)
                authorize()
              }
            }}
          >
            <Stack spacing={4}>
              <TextField name="code" label="企业标识符" required />
              <Button fullWidth type="submit">
                确定
              </Button>
            </Stack>
          </Form>
        ),
      })
    }
  })
}
