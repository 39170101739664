"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncState = void 0;
const react_1 = require("react");
const react_use_1 = require("react-use");
const useConstantCallback_1 = require("./useConstantCallback");
function useSyncState(value, onChange, stateFromValueArg, valueFromState) {
    const [innerValue, setInnerValue] = (0, react_1.useState)(() => stateFromValueArg(value));
    const stateFromValue = (0, useConstantCallback_1.useConstantCallback)(stateFromValueArg);
    const setSyncState = (0, useConstantCallback_1.useConstantCallback)((action) => {
        setInnerValue((prev) => {
            const nextInnerValue = typeof action === 'function'
                ? action(prev)
                : action;
            if (nextInnerValue !== prev) {
                const nextValue = valueFromState(nextInnerValue);
                if (nextValue !== undefined) {
                    onChange(nextValue);
                }
            }
            return nextInnerValue;
        });
    });
    (0, react_use_1.useUpdateEffect)(() => {
        setInnerValue(stateFromValue(value));
    }, [stateFromValue, value]);
    return [innerValue, setSyncState];
}
exports.useSyncState = useSyncState;
