import { storage } from '@frontend/helpers'
import { useAlert } from '@frontend/mui'
import { AnyObject } from '@frontend/type'
import * as dd from 'dingtalk-jsapi'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authTokenStorage } from 'src/context/apollo'
import { getAuthTokenByDingdingUserMutation } from 'src/generated/apollo'
// 判断是否为钉钉环境
export function isDingDingScene() {
  return /DingTalk/i.test(navigator.userAgent)
}
export const dingDingStorage = storage<AnyObject>('dingding_info')
export const tenantStorage = storage<string>('tenant_code')
export function useDingTalk() {
  const authToken = authTokenStorage.get()
  const navigate = useNavigate()
  const alert = useAlert()
  const location = useLocation()
  const param = useMemo(() => {
    const res = Object.fromEntries(
      new URLSearchParams(location.search).entries(),
    ) as {
      corpId: string
      appCode: string
      tenantCode: string
    }
    if (res?.corpId && res?.appCode && res?.appCode) {
      localStorage.setItem('dingdingCorpId', res.corpId)
      localStorage.setItem('dingdingAppCode', res.appCode)
      localStorage.setItem('dingdingTenantCode', res.tenantCode)
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    const dingdingCorpId = localStorage.getItem('dingdingCorpId')
    const dingdingAppCode = localStorage.getItem('dingdingAppCode')
    const dingdingTenantCode = localStorage.getItem('dingdingTenantCode')
    if (!authToken && isDingDingScene()) {
      dd.runtime.permission.requestAuthCode({
        corpId: dingdingCorpId ?? param.corpId,
        onSuccess: async function (res: any) {
          // 调用成功时回调
          const { data: token } = await getAuthTokenByDingdingUserMutation({
            variables: {
              input: {
                appCode: dingdingAppCode ?? param.appCode,
                corpId: dingdingCorpId ?? param.corpId,
                tenantCode: dingdingTenantCode ?? param.tenantCode,
                code: res.code,
              },
            },
          })

          if (token?.getAuthTokenByDingdingUser) {
            alert.show({
              children: '登录成功',
            })
            authTokenStorage.set(token?.getAuthTokenByDingdingUser)
          }

          if (dingdingAppCode) {
            navigate(`/subapp/${dingdingAppCode}`)
          }
        },
        onFail: function (err: any) {
          // 调用失败时回调
          alert.show({ severity: 'error', children: JSON.stringify(err) })
        },
      } as any)
    } else {
      // alert.show({ severity: 'error', children: 'authToken!' })
      if (dingdingAppCode) navigate(`/subapp/${dingdingAppCode}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, param])
}
