import { apolloClient } from 'src/context/apollo'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: Record<string, any>
  JSONString: string
  Timestamp: number
  TimestampRange: any
  Void: any
}

export type AcceptThingInspectionInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
}

export type AccountListFilterInput = {
  accounts?: InputMaybe<Array<Scalars['String']>>
  exclude?: InputMaybe<Array<IdInput>>
  includeChildrenOrganizations?: InputMaybe<Scalars['Boolean']>
  isAdmin?: InputMaybe<Scalars['Boolean']>
  isAllowedToLogin?: InputMaybe<Scalars['Boolean']>
  isDirectlyRole?: InputMaybe<Scalars['Boolean']>
  /** staff.job_status */
  jobStatus?: InputMaybe<Array<StaffJobStatus>>
  organizations?: InputMaybe<Array<StringIdInput>>
  positions?: InputMaybe<Array<StringIdInput>>
  /** 用户满足任一角色即可被筛选出 */
  roles?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
}

export type AccountRoleListFilterInput = {
  accounts?: InputMaybe<Array<StringIdInput>>
  filterByTenantApps?: InputMaybe<Scalars['Boolean']>
}

export type AddAccountRolesInput = {
  accountIds: Array<Scalars['String']>
  roleIds: Array<Scalars['String']>
  /** if selectAllAccounts is ture, ignore accountIds */
  selectAllAccounts?: InputMaybe<Scalars['Boolean']>
}

export type AddAppAdminAccounts = {
  accounts: Array<StringIdInput>
  app: StringIdInput
}

export type AddChannelsToMessageTemplatesInput = {
  channelKinds: Array<MessageChannelKind>
  messageTemplates: Array<StringIdInput>
}

export type AddFeaturePackToTenant = {
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  featurePack: StringIdInput
  isAlwaysEffective: Scalars['Boolean']
  tenant: StringIdInput
}

export type AddStaffToDynamicBusinessStaffGroupInput = {
  id: Scalars['String']
  role: Array<StringIdInput>
}

export type AddStaffToStaticBusinessStaffGroupInput = {
  id: Scalars['String']
  staff: Array<StringIdInput>
}

export type AddThingTransferRecordByCodeInput = {
  records: Array<ThingTransferRecordInput>
  thingCode: Scalars['String']
}

export type AddThingTransferRecordInput = {
  records: Array<ThingTransferRecordInput>
  thing: IdInput
}

export type AddressInput = {
  city: StringIdInput
  county: StringIdInput
  province: StringIdInput
}

export type AnnouncementExpiredKind =
  /** 永不失效 */
  | 'NEVER'
  /** 指定失效时间 */
  | 'TIME'

export type AnnouncementPublishKind =
  /** 即时发布 */
  | 'IMMEDIATE'
  /** 定时发布 */
  | 'TIME'

export type ApiFilterInput = {
  /** 搜索关联 api 名称 */
  search?: InputMaybe<Scalars['String']>
}

export type AppAdminAccountsFilterInput = {
  /** 通过myTenantAppList接口获取app列表 */
  apps?: InputMaybe<Array<StringIdInput>>
}

export type AppKind = 'ENTERPRISE' | 'SELF' | 'THIRD_PARTY'

export type AppListFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

export type AppPromotionCategory = 'INFORMATION' | 'PRODUCT'

export type AppPromotionListFilterInput = {
  /** 是否已发布 */
  isPublished?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索：标题 */
  search?: InputMaybe<Scalars['String']>
}

export type AppPromotionListOfMyTenantFilterInput = {
  /** 筛选: id in ids */
  ids?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
}

export type AppResourceUnitInput = {
  appId: Scalars['String']
  capacityRange?: InputMaybe<ResourceCapacityRangeInput>
  decimalDigits: Scalars['Int']
  id: Scalars['String']
  name: Scalars['String']
  unit: Scalars['String']
}

export type AppVersionListFilterInput = {
  appId?: InputMaybe<Scalars['String']>
}

export type AppVersionStatus = 'OFFLINE' | 'ONLINE' | 'PENDING'

export type ApplyForTenantCertificationInput = {
  address?: InputMaybe<Scalars['String']>
  businessLicenseImage: IdInput
  city?: InputMaybe<StringIdInput>
  contact?: InputMaybe<Scalars['String']>
  county?: InputMaybe<StringIdInput>
  email?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<StringIdInput>
  name: Scalars['String']
  province?: InputMaybe<StringIdInput>
  uscc?: InputMaybe<Scalars['String']>
}

export type ApproveFallbackKind =
  /** 回退到指定经办过的节点 */
  | 'BY_WAY'
  /** 回退到上一相邻节点 */
  | 'LAST'
  /** 回退到固定节点 */
  | 'SPECIFY'

export type ApproveProcessActivityTaskInput = {
  id: Scalars['String']
}

export type ApproveSignatureKind =
  /** 会签 */
  | 'AND'
  /** 或签 */
  | 'OR'

export type ApproveStaffConfigInput = {
  /** 业务自定义配置 */
  customStaffConfigs?: InputMaybe<Array<CustomStaffConfigInput>>
  /** 指定组织层级上的组织负责人(以发起人部门的最高层级部门为第一级，向下依次递减) */
  designatedOrganizationLevel: Scalars['Boolean']
  /** 发起人的连续多级上级的组织负责人(以发起人的直属组织为参照) */
  designatedOrganizationUpLevelCount: Scalars['Boolean']
  /** 指定角色 */
  designatedRole: Scalars['Boolean']
  /** 指定人员 */
  designatedStaff: Scalars['Boolean']
  /** 指定组织层级 */
  organizationLevel?: InputMaybe<Scalars['Int']>
  /** 发起人的连续多级上级 */
  organizationUpLevelCount?: InputMaybe<Scalars['Int']>
  /** 角色 */
  roles?: InputMaybe<Array<StringIdInput>>
  /** 人员 */
  staffs?: InputMaybe<Array<StringIdInput>>
}

export type ApproveThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Array<Scalars['Int']>
  opinion?: InputMaybe<Scalars['String']>
}

export type ApproveThingCalibrateInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  isSynchronized?: InputMaybe<Scalars['Boolean']>
  remark?: InputMaybe<Scalars['String']>
}

export type AssignAppPermissionsInput = {
  app: StringIdInput
  permissions: Array<StringIdInput>
}

export type AssignTenantAppsInput = {
  apps: Array<StringIdInput>
  tenant: StringIdInput
}

export type AttachmentFormat =
  | 'CUSTOM'
  | 'JPG'
  | 'MP3'
  | 'MP4'
  | 'NONE'
  | 'TXT'
  | 'ZIP'

export type AuthenticationConfigurationKind = 'OAUTH_2' | 'OPENID_CONNECT_1'

export type AuthenticationMethod =
  /** 'Basic' HTTP Authentication Scheme, see https://datatracker.ietf.org/doc/html/rfc7617 */
  | 'BASIC_HTTP_AUTHENTICATION_SCHEME'
  /** 以FORM格式编码的POST请求 */
  | 'POST_IN_FORM'

export type AuthenticationSourceKind =
  | 'LDAP_3'
  | 'OAUTH_2'
  | 'OPENID_CONNECT_1'
  | 'SAML_2'

export type AuthorizationRuleAndDependenciesOfUserFilterInput = {
  id: Scalars['String']
  permissionTypes?: InputMaybe<Array<PermissionType>>
  userId: Scalars['String']
}

export type AuthorizationRuleFilterInput = {
  /** 授权规则权限所属的 app */
  appIds?: InputMaybe<Array<Scalars['String']>>
  isAllowed?: InputMaybe<Scalars['Boolean']>
  isLeafOnly?: InputMaybe<Scalars['Boolean']>
  permissionTypes?: InputMaybe<Array<PermissionType>>
  /** 是否要求此权限一定要有数据权限范围 */
  requireDataRanges?: InputMaybe<Scalars['Boolean']>
  /** 搜索对应权限的名称 */
  search?: InputMaybe<Scalars['String']>
}

export type AutoPassApproveConfig =
  /** “上一相邻节点”经办人时自动通过 */
  | 'ADJACENT'
  /** “之前任一节点”经办人时自动通过 */
  | 'BY_WAY'

/**
 * aggregation: sum, max, min, count, distinct_count
 * 在填start,end的情形下，granularity必填
 */
export type BiFilterInput = {
  aggregation: Scalars['String']
  end?: InputMaybe<Scalars['Timestamp']>
  granularity?: InputMaybe<Granularity>
  metric: Scalars['String']
  series?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['Timestamp']>
}

export type BacklogFieldDataInput = {
  /** BacklogFieldMeta中的key属性对应 */
  key: Scalars['String']
  value: Scalars['String']
}

export type BacklogInput = {
  data?: InputMaybe<Array<BacklogFieldDataInput>>
  group: StringIdInput
  /** 全局唯一标识符(uuid)，业务应用提供 */
  id: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
  web_url?: InputMaybe<Scalars['String']>
}

export type BacklogStatus = 'FINISHED' | 'PENDING'

/** 二维码，CODE128条形码 */
export type BarCodeType = 'CODE128' | 'QRCODE'

export type BatchUpdateThingAreaCodeInput = {
  areas: Array<UpdateThingAreaCodeInput>
}

export type BatchUpdateThingCategoryCodeInput = {
  categories: Array<UpdateThingCategoryCodeInput>
}

export type BindMyEmailInput = {
  email: Scalars['String']
  verifyCode: Scalars['String']
}

export type BindMyPhoneNumberInput = {
  phoneNumber: Scalars['String']
  verifyCode: Scalars['String']
}

export type BorrowState = 'IS_BORROWED' | 'IS_RETURN' | 'NOT_CONFIRMED'

export type BusinessGroupDynamicRuleInput = {
  kind: BusinessGroupDynamicRuleKind
  organization?: InputMaybe<OrganizationDynamicRuleInput>
  staffJobType?: InputMaybe<StaffJobTypeDynamicRuleInput>
}

export type BusinessGroupDynamicRuleKind = 'ORGANIZATION' | 'STAFF_JOB_TYPE'

export type BusinessStaffGroupDynamicRuleStaffsFilter = {
  businessStaffGroupId: Scalars['String']
  jobType?: InputMaybe<Array<StaffJobType>>
  organizations?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
}

export type BusinessStaffGroupListFilterInput = {
  /** 来源应用 */
  apps?: InputMaybe<Array<StringIdInput>>
  exclude?: InputMaybe<Array<Scalars['String']>>
  /** ids */
  ids?: InputMaybe<Array<Scalars['String']>>
  isDynamic?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索：名称 */
  search?: InputMaybe<Scalars['String']>
}

export type CalendarActivityStatus = 'FINISHED' | 'IN_PROGRESS' | 'PENDING'

export type CalendarCrossCategory =
  | 'CROSS_FIRST'
  | 'CROSS_LAST'
  | 'CROSS_MEDIUM'
  | 'NO_CROSS'

export type CalendarSplitRange = 'DAY' | 'WEEK'

export type CalibrateMethod = 'EXEMPTION' | 'INSIDE' | 'OUTSIDE' | 'UNSET'

export type CalibrateOrganizationListFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

/** 到期，新购，外部借用，租赁，维修，其他 */
export type CalibrateReason =
  | 'BORROWED'
  | 'EXPIRED'
  | 'LEASED'
  | 'NEW'
  | 'OTHER'
  | 'REPAIR'

export type CalibrateRepeatInput = {
  frequency: Scalars['Int']
  period: CalibrateRepeatPeriod
}

export type CalibrateRepeatPeriod = 'MONTH' | 'YEAR'

/**
 * QUALIFIED: 合格
 * UNQUALIFIED: 不合格
 * BY_RESULT: 按结果使用
 * UNSET: NA
 * HALT: 停用
 */
export type CalibrateResult =
  | 'BY_RESULT'
  | 'HALT'
  | 'QUALIFIED'
  | 'UNQUALIFIED'
  | 'UNSET'

export type CalibrateScheduleFilterInput = {
  /** 模糊搜索: 名称 */
  search?: InputMaybe<Scalars['String']>
}

export type CalibrateState = 'CALIBRATING' | 'DONE' | 'UNSET'

export type CertificateState = 'NOT_RETURNED' | 'RETURNED'

export type ChangeMyPasswordByVerifyCodeInput = {
  newPassword: Scalars['String']
  verifyCode: Scalars['String']
}

export type ChangeMyPasswordInput = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export type ChangePasswordByEmailInput = {
  email: Scalars['String']
  newPassword: Scalars['String']
  tenantCode: Scalars['String']
  verifyCode: Scalars['String']
}

export type ChangePasswordByPhoneNumberInput = {
  newPassword: Scalars['String']
  phoneNumber: Scalars['String']
  tenantCode: Scalars['String']
  verifyCode: Scalars['String']
}

export type ChannelStatus = 'AVAILABLE' | 'UNAVAILABLE'

export type ChipFieldColor =
  | 'ERROR'
  | 'INFO'
  | 'STANDARD'
  | 'SUCCESS'
  | 'WARNING'

export type CityFilterInput = {
  province?: InputMaybe<StringIdInput>
}

export type ClientAppPermissionInput = {
  dependencies?: InputMaybe<Array<Scalars['String']>>
  id: Scalars['String']
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['String']>
}

export type CodeColumnConfigurationInput = {
  areaLevel?: InputMaybe<ThingAreaLevel>
  categoryLength?: InputMaybe<Scalars['Int']>
  categoryLevel?: InputMaybe<ThingCategoryLevel>
  columnType: CodeRuleConfigurationColumn
  connector?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  departmentLevel?: InputMaybe<ThingDepartmentLevel>
  format?: InputMaybe<DatetimeFormat>
  length?: InputMaybe<Scalars['Int']>
  needConnector: Scalars['Boolean']
  orderNumber: Scalars['Int']
  relateColumn?: InputMaybe<Array<CodeRuleConfigurationColumn>>
  startNumber?: InputMaybe<Scalars['Int']>
}

export type CodePrefix = 'GZ' | 'LJ' | 'ZL'

/** 固定字段，编码前缀（定制），资产类别，采购时间，流水号，部门编码，区域编码 */
export type CodeRuleConfigurationColumn =
  | 'CODE_PREFIX'
  | 'FIXED_FIELD'
  | 'PURCHASE'
  | 'SERIAL_NUMBER'
  | 'THING_AREA'
  | 'THING_CATEGORY'
  | 'THING_DEPARTMENT'

export type CodeRuleConfigurationRule =
  | 'AUTOGENERATE_CANNOT_MODIFY'
  | 'AUTOGENERATE_CAN_MODIFY'
  | 'BY_HAND'

export type CombaCockpitAddressApListFilterInput = {
  area?: InputMaybe<Array<IntIdInput>>
}

export type CombaCockpitImportOption = 'INSERT_NORMAL' | 'UPSERT_NORMAL'

/** 工作中，维修中，待机，关机 */
export type CombaCockpitStatus = 'OFF' | 'ON' | 'REPAIR' | 'STANDBY'

/**
 * isOffTooMuch: 长闲置
 * isBrokenTooMuch: 长故障
 * hasMac: 资产编码及MAC配置
 */
export type CombaCockpitThingListInput = {
  category?: InputMaybe<Array<IntIdInput>>
  durationMin?: InputMaybe<Scalars['Int']>
  isBrokenTooMuch?: InputMaybe<Scalars['Boolean']>
  isLent?: InputMaybe<Scalars['Boolean']>
  isOffTooMuch?: InputMaybe<Scalars['Boolean']>
  line?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<Array<CombaCockpitStatus>>
  thing?: InputMaybe<Array<IdInput>>
}

export type CombaCockpitThingMacListInput = {
  macSearch?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<Array<IdInput>>
}

export type CombaCockpitThingRangeInput = {
  thing?: InputMaybe<Array<IdInput>>
}

/**
 * `FEE_RATE`: 费率
 * `UNQUALIFIED`: 整机不良品分类
 * `FIRST_PASS_RATE`: 整机直通率
 * `PRODUCTION`: 产品计划达成情况
 * `SMT`: SMT完成情况
 * `REPAIR`: 返修率
 * ``
 */
export type CombaDataType =
  | 'FEE_RATE'
  | 'FIRST_PASS_RATE'
  | 'PRODUCTION'
  | 'REPAIR'
  | 'SMT'
  | 'UNQUALIFIED'

export type CombaDeviceStatus = 'RUNNING' | 'STANDBY'

/**
 * `DAILY`: 日
 * `MONTHLY`: 月
 * ``
 */
export type CombaGranularity = 'DAILY' | 'MONTHLY'

export type CombaLineDailyProductionFilterInput = {
  date?: InputMaybe<Scalars['Timestamp']>
  endDate?: InputMaybe<Scalars['Timestamp']>
  line?: InputMaybe<Array<IdInput>>
  startDate?: InputMaybe<Scalars['Timestamp']>
}

export type CombaLineFilter = {
  line: Array<IdInput>
}

/**
 * 生产设备
 * 互调仪
 * 网络分析仪
 */
export type CombaMesDeviceCategory =
  | 'INTERMODULATOR'
  | 'NETWORK_ANALYZER'
  | 'PRODUCTION'

export type CombaMesEamUsageFilter = {
  department?: InputMaybe<Array<InputMaybe<IdInput>>>
}

export type CombaOrderListFilter = {
  search: Scalars['String']
}

export type CombaOweFilterInput = {
  endDate?: InputMaybe<Scalars['Timestamp']>
  endPlanStartDate?: InputMaybe<Scalars['Timestamp']>
  search?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['Timestamp']>
  startPlanStartDate?: InputMaybe<Scalars['Timestamp']>
}

export type CompanyBiDatasourceFilter = {
  company?: InputMaybe<IdInput>
  search?: InputMaybe<Scalars['String']>
}

export type CompanyFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type CompanyLoginInput = {
  account: Scalars['String']
  companyUID: Scalars['String']
  password: Scalars['String']
}

export type ConditionOperatorKind =
  | 'DIV'
  | 'EQ'
  | 'GE'
  | 'GT'
  | 'LE'
  | 'LT'
  | 'MOD'
  | 'NE'

export type ConfigureAuthenticationSourceLdap3Input = {
  bindDN: Scalars['String']
  bindPassword: Scalars['String']
  companyId: Scalars['Int']
  /** type: std JSONPath */
  emailAttribute?: InputMaybe<Scalars['String']>
  encryptionMethod: Ldap3EncryptionMethod
  host: Scalars['String']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  /** type: std JSONPath */
  nameAttribute?: InputMaybe<Scalars['String']>
  /** type: std JSONPath */
  phoneAttribute?: InputMaybe<Scalars['String']>
  port: Scalars['Int']
  /** type: std JSONPath */
  uidAttribute: Scalars['String']
  userSearchBaseDN: Scalars['String']
  userSearchFilter: Scalars['String']
}

export type ConfigureAuthenticationSourceOAuth2Input = {
  authorizationURL: Scalars['String']
  clientID: Scalars['String']
  clientSecret: Scalars['String']
  companyId: Scalars['Int']
  /** type: std JSONPath */
  emailAttribute?: InputMaybe<Scalars['String']>
  isActive: Scalars['Boolean']
  name: Scalars['String']
  /** type: std JSONPath */
  nameAttribute?: InputMaybe<Scalars['String']>
  /** type: std JSONPath */
  phoneAttribute?: InputMaybe<Scalars['String']>
  scope: Scalars['String']
  tokenURL: Scalars['String']
  /** type: std JSONPath */
  uidAttribute: Scalars['String']
  userInfoURL: Scalars['String']
}

export type ConfigureAuthenticationSourceOpenIdConnect1Input = {
  /** required if configurationMethod is "MANUAL" */
  authorizationURL?: InputMaybe<Scalars['String']>
  clientID: Scalars['String']
  clientSecret: Scalars['String']
  companyId: Scalars['Int']
  configurationMethod: OpenIdConnect1ConfigurationMethod
  /** required if configurationMethod is "DISCOVERY" */
  configurationURL?: InputMaybe<Scalars['String']>
  emailAttribute?: InputMaybe<Scalars['String']>
  isActive: Scalars['Boolean']
  /** required if configurationMethod is "MANUAL" */
  jwksURL?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  nameAttribute?: InputMaybe<Scalars['String']>
  phoneAttribute?: InputMaybe<Scalars['String']>
  scope: Scalars['String']
  /** required if configurationMethod is "MANUAL" */
  tokenURL?: InputMaybe<Scalars['String']>
  /** if uidAttribute not provided, use OIDC 'sub' as alternative */
  uidAttribute: Scalars['String']
}

export type ConfigureAuthenticationSourceSaml2Input = {
  companyId: Scalars['Int']
  isActive: Scalars['Boolean']
  name: Scalars['String']
}

export type ConfirmBorrowByThingInput = {
  borrowAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
  thingBorrow: IntIdInput
}

export type ConfirmBorrowInput = {
  borrowAt: Scalars['Timestamp']
  id: Array<Scalars['Int']>
  remark?: InputMaybe<Scalars['String']>
}

export type ConfirmReturnByThingInput = {
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
  thing: IdInput
}

export type ConfirmReturnInput = {
  id: Array<Scalars['Int']>
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
}

export type ConfirmSparePartClaimInput = {
  id: Scalars['Int']
  /** item: 出库单item的id */
  item?: InputMaybe<Array<IntIdInput>>
}

export type ConfirmThingBorrowInput = {
  /** 资产所属区域 */
  area?: InputMaybe<IntIdInput>
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  opinion?: InputMaybe<Scalars['String']>
  /** 资产存放地点 */
  storageAddr?: InputMaybe<Scalars['String']>
}

export type CopyFeaturePackInput = {
  id: Scalars['String']
  name: Scalars['String']
}

export type CopyRoleInput = {
  authorizationRules: Array<SetAuthorizationRuleInput>
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  role: StringIdInput
}

export type CorrelateInventoryRedundantRecordInput = {
  redundantRecord: Array<IntIdInput>
  ticket: IntIdInput
}

export type CountyFilterInput = {
  city?: InputMaybe<StringIdInput>
}

export type CreateAccountInput = {
  account: Scalars['String']
  isAllowedToLogin?: InputMaybe<Scalars['Boolean']>
  password?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<StringIdInput>>
  staff: StringIdInput
}

export type CreateAppPromotionInput = {
  attachments?: InputMaybe<Array<StringIdInput>>
  category: AppPromotionCategory
  content: Scalars['JSON']
  cover: Scalars['String']
  title: Scalars['String']
}

export type CreateAppVersionInput = {
  appId: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  version: Scalars['String']
}

export type CreateCalibrateOrganizationInput = {
  name: Scalars['String']
}

export type CreateCalibrateScheduleInput = {
  department?: InputMaybe<Array<IdInput>>
  /** 生成时间点 */
  generateClock?: InputMaybe<Scalars['Int']>
  /** 生成日 */
  generateDay?: InputMaybe<Scalars['Int']>
  includeExpired: Scalars['Boolean']
  isAllDepartment?: InputMaybe<Scalars['Boolean']>
  isAllThingCategory?: InputMaybe<Scalars['Boolean']>
  isInsideCalibrate?: InputMaybe<Scalars['Boolean']>
  isOutsideCalibrate?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type CreateCombaCockpitAddressApInput = {
  ap: Scalars['String']
  area: IntIdInput
  line?: InputMaybe<Scalars['String']>
}

export type CreateCombaCockpitThingInput = {
  combaCockpitMac: Scalars['String']
  thing: IdInput
}

export type CreateCompanyBiDatasourceInput = {
  company: IdInput
  datasource: Array<IdInput>
}

export type CreateContactConfigInput = {
  agentId: Scalars['String']
  checkFileId: Scalars['ID']
  corpId: Scalars['String']
  secret: Scalars['String']
}

export type CreateDemoTicketInput = {
  remark: Scalars['String']
}

export type CreateDingdingAppInput = {
  agentId: Scalars['String']
  app: DingdingAppDetailInput
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  corpId: Scalars['String']
  dingdingAppId: Scalars['String']
  ssoSecret: Scalars['String']
}

export type CreateDingdingContactConfigInput = {
  agentId: Scalars['String']
  appId: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  corpId: Scalars['String']
}

export type CreateDynamicBusinessStaffGroupInput = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type CreateEsmDemoInput = {
  attachments?: InputMaybe<Array<MutateEsmDemoFileInput>>
  fieldData?: InputMaybe<Scalars['JSON']>
  nestedDateRange?: InputMaybe<MutateEsmDemoDateRangeInput>
  nestedNumberRange?: InputMaybe<MutateEsmDemoNumberRangeInput>
  no: Scalars['String']
  organization?: InputMaybe<StringIdInput>
  staff?: InputMaybe<StringIdInput>
}

export type CreateEsmFileExportTaskInput = {
  data?: InputMaybe<Scalars['JSON']>
  groupKey: Scalars['String']
  key: Scalars['String']
}

export type CreateEsmFileTaskInput = {
  data?: InputMaybe<Scalars['JSON']>
  groupKey: Scalars['String']
  key: Scalars['String']
}

export type CreateEamFileInput = {
  companyId?: InputMaybe<Scalars['ID']>
  fileName?: InputMaybe<Scalars['String']>
  length?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

export type CreateEamFormByTemplateInput = {
  formTemplateType: FormTemplateType
}

export type CreateEamSparePartCategoryInput = {
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['Int']>
  relatedToThing: Scalars['Boolean']
  role?: InputMaybe<Array<Scalars['String']>>
  roleRule: SparePartRoleRule
}

export type CreateEamSparePartWarehouseInput = {
  name: Scalars['String']
}

export type CreateEamTeamInput = {
  category: EamTeamCategory
  leader: IdInput
  member?: InputMaybe<Array<IdInput>>
  name: Scalars['String']
}

export type CreateEnterpriseAppInput = {
  description?: InputMaybe<Scalars['String']>
  group?: InputMaybe<StringIdInput>
  name: Scalars['String']
}

export type CreateFeaturePackInput = {
  app: StringIdInput
  name: Scalars['String']
  releaseName: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  versionLevel: FeaturePackVersionLevel
}

export type CreateFileInput = {
  length?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

export type CreateImageInput = {
  length?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

export type CreateInspectionMethodInput = {
  constraint: ThingInspectionConstraint
  fieldData?: InputMaybe<Array<InspectionMethodFieldDataInput>>
  material?: InputMaybe<Scalars['String']>
  method?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<InspectionRepeatInput>
  standard?: InputMaybe<Scalars['String']>
  standardCostTime?: InputMaybe<Scalars['Float']>
  target?: InputMaybe<Scalars['String']>
  uploadAttachment: Scalars['Boolean']
}

export type CreateInspectionPointInput = {
  area?: InputMaybe<IntIdInput>
  category: InspectionPointCategory
  code: Scalars['String']
  name: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<IdInput>
}

export type CreateInventoryRedundantRecordByThingInput = {
  area?: InputMaybe<IntIdInput>
  category?: InputMaybe<IntIdInput>
  department?: InputMaybe<IdInput>
  image?: InputMaybe<Array<IdInput>>
  manager?: InputMaybe<StringIdInput>
  modelNum?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
}

export type CreateMaintenanceMethodInput = {
  department?: InputMaybe<Array<StringIdInput>>
  label?: InputMaybe<Array<Scalars['String']>>
  level: MaintenanceLevel
  material?: InputMaybe<Scalars['String']>
  method?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<MaintenanceRepeatInput>
  sparePartItem?: InputMaybe<Array<MaintenanceMethodSparePartItemInput>>
  standard?: InputMaybe<Scalars['String']>
  standardCostTime?: InputMaybe<Scalars['Float']>
  target?: InputMaybe<Scalars['String']>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type CreateMarketFileInput = {
  length?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

export type CreateOAuth2AuthenticationConfigurationInput = {
  /** access_token在token响应中的取值路径（JsonPath），如果为空，默认为"access_token" */
  accessTokenAttributePath: Scalars['String']
  accountAttributePath: Scalars['String']
  authorizationUrl: Scalars['String']
  /** 客户端认证方式 */
  clientAuthenticationMethod: AuthenticationMethod
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  /** 登录时是否更新用户信息 */
  doUpdateLocalUserInfoWhenLogin: Scalars['Boolean']
  emailAttributePath?: InputMaybe<Scalars['String']>
  isActive: Scalars['Boolean']
  name: Scalars['String']
  nameAttributePath?: InputMaybe<Scalars['String']>
  phoneNumberAttributePath?: InputMaybe<Scalars['String']>
  scope: Scalars['String']
  /** 是否支持state参数 */
  supportState: Scalars['Boolean']
  /** token响应格式 */
  tokenResponseFormat: ResponseFormat
  tokenUrl: Scalars['String']
  /** user_info鉴权方式 */
  userInfoAuthenticationMethod: AuthenticationMethod
  /** user_info请求方式 */
  userInfoRequestMethod: UserInfoRequestMethod
  /** user_info响应格式 */
  userInfoResponseFormat: ResponseFormat
  userInfoUrl: Scalars['String']
}

export type CreateOpenIdConnect1AuthenticationConfigurationInput = {
  accountAttributePath: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  configurationUrl?: InputMaybe<Scalars['String']>
  /** 登录时是否更新用户信息 */
  doUpdateLocalUserInfoWhenLogin: Scalars['Boolean']
  emailAttributePath?: InputMaybe<Scalars['String']>
  isActive: Scalars['Boolean']
  kind: AuthenticationConfigurationKind
  name: Scalars['String']
  nameAttributePath?: InputMaybe<Scalars['String']>
  phoneNumberAttributePath?: InputMaybe<Scalars['String']>
  scope: Scalars['String']
}

export type CreateOrganizationInput = {
  code?: InputMaybe<Scalars['String']>
  level?: InputMaybe<Scalars['Int']>
  manager?: InputMaybe<StringIdInput>
  name: Scalars['String']
  parent: StringIdInput
}

export type CreateOriginalInventoryInput = {
  /** 仓库 */
  depository: IntIdInput
  /** 库存数量 */
  inventory: Scalars['Int']
}

export type CreateOutsideCalibrateInput = {
  applyForAt?: InputMaybe<Scalars['Timestamp']>
  calibrateAt: Scalars['Timestamp']
  calibrateOrganization: IdInput
  name: Scalars['String']
  payAt?: InputMaybe<Scalars['Timestamp']>
  payStatus?: InputMaybe<OutsideCalibratePayStatus>
  remark?: InputMaybe<Scalars['String']>
}

export type CreatePlatProductInput = {
  description: Scalars['String']
  durationMonth?: InputMaybe<Scalars['Int']>
  featurePack?: InputMaybe<Array<StringIdInput>>
  isAlwaysEffective?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  selectable: Scalars['Boolean']
  shortName: Scalars['String']
}

export type CreatePlatformAnnouncementConfigInput = {
  /** 指定应用 */
  apps?: InputMaybe<Array<StringIdInput>>
  /** 附件 */
  attachments?: InputMaybe<Array<StringIdInput>>
  /** 内容 */
  content: Scalars['JSON']
  /** 封面 */
  cover: Scalars['String']
  /** 指定失效时间 */
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  /** 失效时间 */
  expiredKind: AnnouncementExpiredKind
  /** 是否是草稿 */
  isDraft: Scalars['Boolean']
  /** 发布时间 */
  publishKind: AnnouncementPublishKind
  /** 可见人员 */
  staffRange: PlatformAnnouncementStaffRange
  /** 指定行业 */
  tenantIndustries?: InputMaybe<Array<StringIdInput>>
  /** 指定平台 */
  tenants?: InputMaybe<Array<StringIdInput>>
  /** 定时发布时间 */
  timePublishAt?: InputMaybe<Scalars['Timestamp']>
  /** 标题 */
  title: Scalars['String']
  /** 可见范围 */
  visibleRange: PlatformAnnouncementVisibleRange
}

export type CreateProcessHistoryLogInput = {
  action: FieldChipDataInput
  businessKey: Scalars['String']
  fields: Array<ProcessHistoryLogFieldInput>
  name: Scalars['String']
}

export type CreateRoleInput = {
  app: StringIdInput
  authorizationRules: Array<SetAuthorizationRuleInput>
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type CreateSelfWecomAppInput = {
  agentId: Scalars['String']
  app: WecomAppInput
  checkFileId: Scalars['ID']
  corpId: Scalars['String']
  secret: Scalars['String']
}

/**
 * reason 申领原因
 * claimAt 申领日期
 * department 申领部门
 * remark 备注
 */
export type CreateSparePartClaimInput = {
  date: Scalars['Timestamp']
  department: IdInput
  item: Array<SparePartClaimItemInput>
  reason: SparePartClaimReason
  remark?: InputMaybe<Scalars['String']>
}

export type CreateSparePartInput = {
  /** 备件附件 */
  attachment?: InputMaybe<Array<IdInput>>
  /** 备件类别 */
  category?: InputMaybe<IntIdInput>
  /** 备件编号 */
  code: Scalars['String']
  /** 经销商 */
  distributor?: InputMaybe<Scalars['String']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  /** 生产厂家 */
  manufacturer?: InputMaybe<Scalars['String']>
  /** 型号 */
  model?: InputMaybe<Scalars['String']>
  /** 备件名称 */
  name: Scalars['String']
  /** 备注 */
  remark?: InputMaybe<Scalars['String']>
  /** 安全库存 */
  safeInventory?: InputMaybe<EamNumberRangeInput>
  /** 规格 */
  specification?: InputMaybe<Scalars['String']>
  unitPrice?: InputMaybe<Scalars['Float']>
}

export type CreateSparePartOutboundInput = {
  claim?: InputMaybe<IntIdInput>
  date: Scalars['Timestamp']
  item: Array<CreateSparePartOutboundItemInput>
  kind: SparePartOutboundKind
  remark?: InputMaybe<Scalars['String']>
  warehouse: IntIdInput
}

export type CreateSparePartOutboundItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type CreateSparePartReceiptInput = {
  date: Scalars['Timestamp']
  item: Array<CreateSparePartReceiptItemInput>
  kind: SparePartReceiptKind
  remark?: InputMaybe<Scalars['String']>
  warehouse: IntIdInput
}

export type CreateSparePartReceiptItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type CreateSparePartTransferInput = {
  date: Scalars['Timestamp']
  export: IntIdInput
  import: IntIdInput
  item: Array<CreateSparePartTransferItemInput>
  remark?: InputMaybe<Scalars['String']>
}

export type CreateSparePartTransferItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type CreateStaffInput = {
  avatar?: InputMaybe<IdInput>
  businessGroups?: InputMaybe<Array<StringIdInput>>
  email?: InputMaybe<Scalars['String']>
  groups?: InputMaybe<Array<StringIdInput>>
  jobNumber: Scalars['String']
  jobStatus?: InputMaybe<StaffJobStatus>
  jobType?: InputMaybe<StaffJobType>
  name: Scalars['String']
  organizations: Array<StringIdInput>
  phoneNumber?: InputMaybe<Scalars['String']>
  positions?: InputMaybe<Array<StringIdInput>>
  remark?: InputMaybe<Scalars['String']>
}

export type CreateStaffPositionInput = {
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  name: Scalars['String']
}

export type CreateStaticBusinessStaffGroupInput = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type CreateTenantAnnouncementConfigInput = {
  /** 附件 */
  attachments?: InputMaybe<Array<StringIdInput>>
  /** 内容 */
  content: Scalars['JSON']
  /** 封面 */
  cover: Scalars['String']
  /** 指定失效时间 */
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  /** 失效时间 */
  expiredKind: AnnouncementExpiredKind
  /** 是否是草稿 */
  isDraft: Scalars['Boolean']
  /** 指定组织 */
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 发布时间 */
  publishKind: AnnouncementPublishKind
  /** 指定角色 */
  roles?: InputMaybe<Array<StringIdInput>>
  /** 指定人员 */
  staffs?: InputMaybe<Array<StringIdInput>>
  /** 定时发布时间 */
  timePublishAt?: InputMaybe<Scalars['Timestamp']>
  /** 标题 */
  title: Scalars['String']
  /** 可见范围 */
  visibleRange: TenantAnnouncementVisibleRange
}

export type CreateTenantInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<StringIdInput>
  code: Scalars['String']
  contact?: InputMaybe<Scalars['String']>
  county?: InputMaybe<StringIdInput>
  email?: InputMaybe<Scalars['String']>
  /** 企业所属行业 */
  industry?: InputMaybe<StringIdInput>
  name: Scalars['String']
  owner?: InputMaybe<TenantOwner>
  phone?: InputMaybe<Scalars['String']>
  province?: InputMaybe<StringIdInput>
  type: TenantType
  /** 统一社会信用代码: Unified Social Credit Code */
  uscc?: InputMaybe<Scalars['String']>
}

export type CreateTenantOwnerInput = {
  account: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  tenant: StringIdInput
}

export type CreateTenantPlaceInput = {
  code?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
}

export type CreateThingAbortInput = {
  abortAt: Scalars['Timestamp']
  attachment?: InputMaybe<Array<IdInput>>
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
  thingDrop?: InputMaybe<IntIdInput>
}

export type CreateThingAdministratorDepartmentInput = {
  administrator: Array<StringIdInput>
  department: IdInput
}

export type CreateThingApplyInput = {
  applicant: IdInput
  applyAt: Scalars['Timestamp']
  applyOfDepartment: IdInput
  attachment?: InputMaybe<Array<IdInput>>
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type CreateThingAreaInput = {
  code?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['Int']>
}

export type CreateThingBarLabelInput = {
  activateBarCode: Scalars['Boolean']
  activateCustomText: Scalars['Boolean']
  activateField: Scalars['Boolean']
  activateLogo: Scalars['Boolean']
  barCodeType?: InputMaybe<BarCodeType>
  boardLayout?: InputMaybe<Scalars['JSON']>
  customTexts?: InputMaybe<Array<EamCustomTextInput>>
  fieldKey?: InputMaybe<Array<Scalars['String']>>
  fontBold?: InputMaybe<Scalars['Boolean']>
  fontSize?: InputMaybe<Scalars['Float']>
  height: Scalars['Int']
  logo?: InputMaybe<IdInput>
  name: Scalars['String']
  previewImage?: InputMaybe<Scalars['String']>
  printObjectValue: EamBarCodePrintObjectValue
  showBarCode?: InputMaybe<Scalars['Boolean']>
  width: Scalars['Int']
}

export type CreateThingCalibrateInput = {
  reason: CalibrateReason
  thing: Array<IdInput>
}

export type CreateThingCalibrateOperatorInput = {
  department: Array<IdInput>
  staff: StringIdInput
}

export type CreateThingCategoryInput = {
  code?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['Int']>
}

export type CreateThingCompleteFileRuleInput = {
  attachmentField: Array<IntIdInput>
  thingCategory: Array<IntIdInput>
}

export type CreateThingDropInput = {
  attachment?: InputMaybe<Array<IdInput>>
  dropAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type CreateThingGroupInput = {
  code?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  parent?: InputMaybe<IdInput>
}

export type CreateThingInput = {
  acceptanceAt?: InputMaybe<Scalars['Timestamp']>
  accountType?: InputMaybe<ThingAccountType>
  accountingDepartment?: InputMaybe<IdInput>
  /** 购置金额（含税） */
  acquisitionCost?: InputMaybe<Scalars['Float']>
  activatedAt?: InputMaybe<Scalars['Timestamp']>
  alertAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseNum?: InputMaybe<Scalars['String']>
  area?: InputMaybe<IntIdInput>
  arrivedAt?: InputMaybe<Scalars['Timestamp']>
  assetNormalizationAt?: InputMaybe<Scalars['Timestamp']>
  attachment?: InputMaybe<Array<IdInput>>
  bookValue?: InputMaybe<Scalars['Float']>
  brand?: InputMaybe<Scalars['String']>
  canBorrowed?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<IntIdInput>
  code: Scalars['String']
  /** 编码前缀（定制） */
  codePrefix?: InputMaybe<CodePrefix>
  companyId?: InputMaybe<Scalars['ID']>
  contractNum?: InputMaybe<Scalars['String']>
  department?: InputMaybe<IdInput>
  depreciationOfYear?: InputMaybe<Scalars['Float']>
  depreciationRate?: InputMaybe<Scalars['Float']>
  depreciationRateOfMonth?: InputMaybe<Scalars['Float']>
  desc?: InputMaybe<Scalars['String']>
  distributor?: InputMaybe<Scalars['String']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  finalValue?: InputMaybe<Scalars['Float']>
  fuselageCode?: InputMaybe<Scalars['String']>
  groupFile?: InputMaybe<Array<GroupFileInput>>
  image?: InputMaybe<Array<IdInput>>
  installedAt?: InputMaybe<Scalars['Timestamp']>
  label?: InputMaybe<Array<IntIdInput>>
  leaseBeginAt?: InputMaybe<Scalars['Timestamp']>
  leaseFinishAt?: InputMaybe<Scalars['Timestamp']>
  leaseNum?: InputMaybe<Scalars['String']>
  machineNumber?: InputMaybe<Scalars['String']>
  maintainer?: InputMaybe<Array<StringIdInput>>
  manager?: InputMaybe<StringIdInput>
  manufacturer?: InputMaybe<Scalars['String']>
  modelNum?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  onState?: InputMaybe<OnState>
  performanceStatus?: InputMaybe<ThingPerformanceStatus>
  poNum?: InputMaybe<Scalars['String']>
  predictResidualRate?: InputMaybe<Scalars['Float']>
  produceAt?: InputMaybe<Scalars['Timestamp']>
  purchasePrice?: InputMaybe<Scalars['Float']>
  purchaseType?: InputMaybe<ThingPurchaseType>
  purchasedAt?: InputMaybe<Scalars['Timestamp']>
  /** rfid */
  rfid?: InputMaybe<Scalars['String']>
  sapThingCode?: InputMaybe<Scalars['String']>
  serialNumber?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
  storageType?: InputMaybe<ThingStorageType>
  thingGroup?: InputMaybe<IdInput>
  thingSubjectCode?: InputMaybe<Scalars['String']>
  transferAt?: InputMaybe<Scalars['Timestamp']>
  usedYear?: InputMaybe<Scalars['Float']>
  /** 保修截止日期 */
  warrantyDeadlineAt?: InputMaybe<Scalars['Timestamp']>
  warrantyInstitutions?: InputMaybe<Scalars['String']>
  warrantyMethod?: InputMaybe<ThingWarrantyMethod>
  /** 保修期限 */
  warrantyPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修临期提醒 */
  warrantyRemindPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修起始日期 */
  warrantyStartAt?: InputMaybe<Scalars['Timestamp']>
  yearsOfUse?: InputMaybe<Scalars['Float']>
}

export type CreateThingInspectionPointInput = {
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type CreateThingInspectionScheduleInput = {
  executeAt: Scalars['Timestamp']
  inspectionMethod?: InputMaybe<Array<IdInput>>
  inspectionPoint?: InputMaybe<Array<IntIdInput>>
  inspectionType: ThingInspectionType
  name: Scalars['String']
  operator: IdInput
  reason?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<Array<Scalars['Timestamp']>>
  splitMethod: ThingInspectionSplitMethod
  team: IntIdInput
}

export type CreateThingInventoryRedundantRecordInput = {
  area?: InputMaybe<IntIdInput>
  category?: InputMaybe<IntIdInput>
  department?: InputMaybe<IdInput>
  image?: InputMaybe<Array<IdInput>>
  manager?: InputMaybe<StringIdInput>
  modelNum?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
  ticket: IntIdInput
}

export type CreateThingInventoryTicketInput = {
  assign?: InputMaybe<StringIdInput>
  canManual?: InputMaybe<Scalars['Boolean']>
  canRfid?: InputMaybe<Scalars['Boolean']>
  constraint?: InputMaybe<Array<ThingInventoryConstraint>>
  name: Scalars['String']
  planAt: Scalars['TimestampRange']
  remark?: InputMaybe<Scalars['String']>
  thingFilter?: InputMaybe<ThingInventoryThingFilterInput>
  userScope: ThingInventoryUserScope
}

export type CreateThingMaintenanceScheduleInput = {
  executeAt: Scalars['Timestamp']
  maintenanceMethod?: InputMaybe<Array<IdInput>>
  name: Scalars['String']
  operator: IdInput
  reason?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<Array<Scalars['Timestamp']>>
  team: IntIdInput
  thing?: InputMaybe<Array<IdInput>>
  type: ThingMaintenanceType
}

export type CreateThingMissingFindInfoInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  department: IdInput
  findAt: Scalars['Timestamp']
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type CreateThingMissingInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  department?: InputMaybe<IdInput>
  manager?: InputMaybe<StringIdInput>
  missAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type CreateThingMoveInput = {
  attachment?: InputMaybe<Array<IdInput>>
  inboundDepartment: IdInput
  moveAt: Scalars['Timestamp']
  outboundDepartment: IdInput
  remark?: InputMaybe<Scalars['String']>
  thingManager: Array<ThingMoveThingManagerInput>
}

export type CreateThingOffLeaseInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  offLeaseAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
  thingDrop?: InputMaybe<IntIdInput>
}

export type CreateThingReceiveInput = {
  attachments?: InputMaybe<Array<IdInput>>
  remark?: InputMaybe<Scalars['String']>
  source: ThingReceiveSource
  thingCategory: IntIdInput
  thingCount: Scalars['Int']
  thingFinalValue?: InputMaybe<Scalars['Float']>
  thingModel?: InputMaybe<Scalars['String']>
  thingName: Scalars['String']
  thingPurchaseOrderNo?: InputMaybe<Scalars['String']>
  thingPurchaseUnitPrice?: InputMaybe<Scalars['Float']>
  thingPurchasedAt?: InputMaybe<Scalars['Timestamp']>
  thingRentEndAt?: InputMaybe<Scalars['Timestamp']>
  thingRentOrderNo?: InputMaybe<Scalars['String']>
  thingRentUnitPrice?: InputMaybe<Scalars['Float']>
  thingSpecification?: InputMaybe<Scalars['String']>
}

export type CreateThingRentBackInput = {
  attachment?: InputMaybe<Array<IdInput>>
  backAt: Scalars['Timestamp']
  department: IdInput
  institution?: InputMaybe<Scalars['String']>
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type CreateThingRentInput = {
  attachment?: InputMaybe<Array<IdInput>>
  department: IdInput
  end?: InputMaybe<Scalars['Timestamp']>
  institution: Scalars['String']
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  start: Scalars['Timestamp']
  thing: IdInput
}

export type CreateThingRepairFaultInput = {
  category?: InputMaybe<Array<IntIdInput>>
  code: Scalars['String']
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['Int']>
}

export type CreateThingRepairKnowhowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  body?: InputMaybe<Scalars['String']>
  fault?: InputMaybe<Array<IntIdInput>>
  keyword?: InputMaybe<Array<Scalars['String']>>
  title: Scalars['String']
}

export type CreateThingRepairNotificationConditionInput = {
  duty?: InputMaybe<Array<ThingRepairDuty>>
  filterRange: ThingRepairNotificationRangeInput
  name: Scalars['String']
  role?: InputMaybe<Array<StringIdInput>>
  staff?: InputMaybe<Array<StringIdInput>>
}

export type CreateThingRepairRuleInput = {
  filterRange?: InputMaybe<Array<InputMaybe<Array<ThingRepairRuleRangeInput>>>>
  leaderOnly?: InputMaybe<Scalars['Boolean']>
  method: ThingRepairDispatch
  name: Scalars['String']
  team?: InputMaybe<IntIdInput>
  teamMember?: InputMaybe<IntIdInput>
}

export type CreateThingReturnInput = {
  attachment?: InputMaybe<Array<IdInput>>
  department: IdInput
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
  thing: Array<IdInput>
}

export type CreateThingSaleInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  saleAt: Scalars['Timestamp']
  saleTo?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
  thingDrop?: InputMaybe<IntIdInput>
}

export type CreateUserInput = {
  account: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  isAccountEnabled: Scalars['Boolean']
  name: Scalars['String']
  organizations: Array<StringIdInput>
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<StringIdInput>>
}

export type CustomStaffConfigInput = {
  /** 调用restful url(POST)的请求数据 */
  data?: InputMaybe<Scalars['JSON']>
  enabled: Scalars['Boolean']
  /** 通过自定义配置获取人员ID列表的restful url(POST) */
  url?: InputMaybe<Scalars['String']>
}

export type DataRangeFieldInput = {
  key: Scalars['String']
  name: Scalars['String']
}

export type DataRangeFieldValueInput = {
  data?: InputMaybe<Scalars['JSON']>
  dataUI: DataValueSourceUiInput
  dataValueSourceId: Scalars['String']
}

export type DataRangeOperatorInput = {
  key: Scalars['String']
  name: Scalars['String']
}

export type DataUiKind = 'SELECT' | 'TREE'

export type DataValueSourceUiInput = {
  kind: DataUiKind
}

export type DatetimeFormat =
  | 'MMDD'
  | 'YY'
  | 'YYMM'
  | 'YYMMDD'
  | 'YYYY'
  | 'YYYYMM'
  | 'YYYYMMDD'

export type DefaultFilterFieldInput = {
  active: Scalars['Boolean']
  dataType: UccDataType
  defaultFilterValue?: InputMaybe<Scalars['String']>
  defaultOperator: UccOperator
  editable: Scalars['Boolean']
  id: Scalars['String']
  isPreset: Scalars['Boolean']
  label: Scalars['String']
  multiple: Scalars['Boolean']
  options?: InputMaybe<Array<Scalars['String']>>
  type: UccFilterUiType
}

export type DeleteDepartmentThingGroupInput = {
  department: Scalars['JSONString']
  thingGroups: Array<IdInput>
}

export type DeleteDingdingAppInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type DeleteEamSparePartCategoryInput = {
  id: Array<Scalars['Int']>
  moveTo: IntIdInput
}

export type DeleteThingCategoryInput = {
  id: Array<Scalars['Int']>
  moveTo: IntIdInput
}

export type DeleteThingLimitedReason =
  | 'THING_BORROW'
  | 'THING_CALIBRATE'
  | 'THING_INSPECTION'
  | 'THING_INVENTORY'
  | 'THING_MAINTENANCE'
  | 'THING_REPAIR'

export type DemoTicketListFilterInput = {
  /** 模糊搜索：工单编号、备注 */
  search?: InputMaybe<Scalars['String']>
}

export type DemoTicketStatus =
  /** 审批中 */
  | 'APPROVAL'
  /** 已取消 */
  | 'CANCELLED'
  /** 已完成 */
  | 'COMPLETED'
  /** 待发起 */
  | 'TO_BE_INITIATED'

export type Density =
  /** 密集 */
  | 'DENSE'
  /** 稀疏 */
  | 'SPARSE'
  /** 标准 */
  | 'STANDARD'

export type DepartmentListFilter = {
  code?: InputMaybe<Array<Scalars['String']>>
  company?: InputMaybe<IdInput>
  currentOnly?: InputMaybe<Scalars['Boolean']>
  ids?: InputMaybe<Array<IdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  isRevoked?: InputMaybe<Scalars['Boolean']>
  /** 搜索名称 */
  search?: InputMaybe<Scalars['String']>
}

export type DepartmentThingAdministratorListFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  staff?: InputMaybe<Array<IdInput>>
}

export type DepartmentThingGroupFilterInput = {
  department?: InputMaybe<Array<IdInput>>
}

export type DepartmentTreeFilter = {
  company?: InputMaybe<IdInput>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
}

export type DeptUserThingGroupInputFilter = {
  departments?: InputMaybe<Array<IdInput>>
  search?: InputMaybe<Scalars['String']>
  user?: InputMaybe<IdInput>
}

export type DesignatedStaffFilterOfStaffGroup = {
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索：姓名，工号 */
  search?: InputMaybe<Scalars['String']>
}

export type DifferenceResult = 'ADDED' | 'REMOVED'

export type DingdingAppDetailInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type EsmAlertSeverity = 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING'

export type EsmDataRangeOption = 'NEXT' | 'NONE' | 'PRE'

export type EsmDateView = 'DAY' | 'MINUTE' | 'MONTH' | 'YEAR'

export type EsmDemoImportMode = 'CREATE' | 'CREATE_OR_UPDATE' | 'UPDATE'

export type EsmFieldAccessConfig =
  | 'EDITABLE'
  | 'HIDDEN'
  | 'IS_PREVIEW'
  | 'READ_ONLY'

export type EsmFieldConfigInput = {
  content?: InputMaybe<Scalars['String']>
  customFormat?: InputMaybe<Array<Scalars['String']>>
  dateRangeOption?: InputMaybe<EsmDataRangeOption>
  defaultValueBoolean?: InputMaybe<Scalars['Boolean']>
  defaultValueString?: InputMaybe<Scalars['String']>
  defaultValueStringArray?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  format?: InputMaybe<AttachmentFormat>
  fractionDigits?: InputMaybe<Scalars['Int']>
  helperText?: InputMaybe<Scalars['String']>
  label?: InputMaybe<Scalars['String']>
  maxCount?: InputMaybe<Scalars['Int']>
  maxLength?: InputMaybe<Scalars['Int']>
  maxSize?: InputMaybe<Scalars['Int']>
  multiline?: InputMaybe<Scalars['Boolean']>
  multiple?: InputMaybe<Scalars['Boolean']>
  numberRange?: InputMaybe<EsmNumberRangeInput>
  options?: InputMaybe<Array<EsmOptionInput>>
  prefix?: InputMaybe<Scalars['String']>
  required?: InputMaybe<Scalars['Boolean']>
  severity?: InputMaybe<EsmAlertSeverity>
  showDescription?: InputMaybe<Scalars['Boolean']>
  suffix?: InputMaybe<Scalars['String']>
  view?: InputMaybe<EsmDateView>
  width: Scalars['Int']
}

export type EsmFieldInput = {
  config: EsmFieldConfigInput
  id: Scalars['String']
  widgetType: EsmWidgetType
}

export type EsmFieldSectionInput = {
  fields: Array<EsmFieldInput>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type EsmFileExportTaskListFilterInput = {
  groupKey: Scalars['String']
  key?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<Array<EsmFileExportTaskStatus>>
}

export type EsmFileExportTaskStatus = 'EXPORTING' | 'FAILED' | 'FINISHED'

export type EsmFileTaskListFilterInput = {
  groupKey: Scalars['String']
  key?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<Array<EsmFileTaskStatus>>
}

export type EsmFileTaskStatus = 'FAILED' | 'FINISHED' | 'PENDING'

export type EsmNumberRangeInput = {
  end?: InputMaybe<Scalars['Float']>
  start?: InputMaybe<Scalars['Float']>
}

export type EsmOptionInput = {
  id: Scalars['String']
  label: Scalars['String']
}

export type EsmScreenFieldInput = {
  accessConfig: EsmFieldAccessConfig
  fieldId: Scalars['String']
}

export type EsmScreenInput = {
  fields: Array<EsmScreenFieldInput>
  id: Scalars['String']
}

export type EsmTextCharacterSet =
  | 'CHINESE'
  | 'LOWERCASE_LETTERS'
  | 'NUMBER'
  /** ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _ ` { | } ~ */
  | 'SPECIAL_SYMBOLS'
  | 'UPPERCASE_LETTERS'

export type EsmWidgetType =
  | 'ACCUMULATOR'
  | 'ALERT'
  | 'ATTACHMENT'
  | 'CASCADER'
  | 'CHECKBOX'
  | 'CYCLE_TIME'
  | 'DATE'
  | 'DATE_RANGE'
  | 'DISTRICT'
  | 'DIVIDER'
  | 'DURATION'
  | 'EMAIL'
  | 'ID_NUMBER'
  | 'IMAGE'
  | 'MONEY'
  | 'MULTI_CHECKBOX'
  | 'NUMBER'
  | 'NUMBER_RANGE'
  | 'ORGANIZE'
  | 'PHONE'
  | 'RADIO'
  | 'REFERENCE'
  | 'ROLE'
  | 'SELECT'
  | 'STAFF'
  | 'SWITCH'
  | 'TEXT'
  | 'TREE'
  | 'URL'

export type EamAttachmentType =
  | 'AUDIO'
  | 'CUSTOM'
  | 'DOC'
  | 'PIC'
  | 'VIDEO'
  | 'ZIP'

export type EamBarCodePrintObjectValue = 'INSPECTION_POINT' | 'THING'

export type EamComponentFieldInput = {
  connector?: InputMaybe<Scalars['String']>
  field: IntIdInput
  needConnector: Scalars['Boolean']
  needSuffix?: InputMaybe<Scalars['Boolean']>
  needUnit?: InputMaybe<Scalars['Boolean']>
}

export type EamCustomTextInput = {
  content: Scalars['String']
  fontBold?: InputMaybe<Scalars['Boolean']>
  fontSize?: InputMaybe<Scalars['Float']>
}

export type EamFieldFilterInput = {
  /** 布尔类型范围值 */
  booleanValues?: InputMaybe<Array<Scalars['Boolean']>>
  /** 日期类型 */
  datetimeValues?: InputMaybe<Scalars['TimestampRange']>
  id: Scalars['ID']
  /** 数值类型 */
  numberValues?: InputMaybe<EamNumberRangeInput>
  /** 字符串类型范围值 */
  stringValues?: InputMaybe<Array<Scalars['String']>>
}

/** 基础信息，管理信息，采购信息吗，财务信息，校准信息，维保信息 */
export type EamFieldGroupType =
  | 'ATTACHMENT'
  | 'CALIBRATE'
  | 'FINANCIAL'
  | 'FOUNDATION'
  | 'MAINTENANCE'
  | 'MANAGEMENT'
  | 'PURCHASE'

export type EamFieldInput = {
  attachmentType?: InputMaybe<EamAttachmentType>
  content?: InputMaybe<Scalars['String']>
  defaultBool?: InputMaybe<Scalars['Boolean']>
  defaultNum?: InputMaybe<Scalars['Float']>
  defaultStr?: InputMaybe<Scalars['String']>
  defaultStrList?: InputMaybe<Array<Scalars['String']>>
  desc?: InputMaybe<Scalars['String']>
  extension?: InputMaybe<Array<Scalars['String']>>
  format?: InputMaybe<Scalars['String']>
  frequency?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<EamFieldGroupType>
  hint?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  isActive?: InputMaybe<Scalars['Boolean']>
  max?: InputMaybe<Scalars['Float']>
  maxCount?: InputMaybe<Scalars['Int']>
  maxRange?: InputMaybe<EamMetaRangeExtremumInput>
  maxSize?: InputMaybe<Scalars['Int']>
  min?: InputMaybe<Scalars['Float']>
  minRange?: InputMaybe<EamMetaRangeExtremumInput>
  multi?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  option?: InputMaybe<Array<Scalars['String']>>
  period?: InputMaybe<EamRepeatPeriod>
  role?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  round?: InputMaybe<Scalars['Int']>
  set?: InputMaybe<Array<EamFieldInput>>
  suffix?: InputMaybe<Scalars['String']>
  type: EamFieldType
  unit?: InputMaybe<Scalars['String']>
  unitAlign?: InputMaybe<EamUnitAlign>
  zeroable?: InputMaybe<Scalars['Boolean']>
}

export type EamFieldInterListFilterInput = {
  category: Array<Scalars['ID']>
  group?: InputMaybe<Array<EamFieldGroupType>>
  type?: InputMaybe<Array<EamFieldType>>
}

export type EamFieldListFilterInput = {
  exclude?: InputMaybe<Array<IntIdInput>>
  form?: InputMaybe<Array<IntIdInput>>
  group?: InputMaybe<Array<EamFieldGroupType>>
  include?: InputMaybe<Array<IntIdInput>>
  isActive?: InputMaybe<Scalars['Boolean']>
  isLock?: InputMaybe<Scalars['Boolean']>
  metaInclude?: InputMaybe<Array<EamFieldMetaInclude>>
  module?: InputMaybe<Array<EamFieldModule>>
  name?: InputMaybe<Scalars['String']>
  roleEnable?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  type?: InputMaybe<Array<EamFieldType>>
}

export type EamFieldMetaInclude = {
  metaID: Scalars['String']
  module: EamFieldModule
}

export type EamFieldModule =
  | 'INVENTORY_RECORDS'
  | 'SPARE_PARTS'
  | 'THINGS'
  | 'THING_RECEIPT'

/**
 * TEXT: 输入框
 * LABEL: 提示文本
 * LABEL_TEXT_SET: 提示+文本
 * NUMBER: 数值输入
 * SELECT_BOX: 下拉选择
 * DATE: 日期选择
 * DATE_SET: 日期范围
 * MULTI_RADIO: 复选框组
 * RADIO: 单选框组
 * RANGE: 数值范围
 * TEXT_SET: 文本+输入框
 * MULTI_TEXT_SET: 输入框组
 * SELECT_TEXT_SET: 下拉框+输入框
 * SELECT_DATE_SET: 下拉框+日期
 * MULTI_RADIO_TEXT_SET: 复选框+输入框
 * MULTI_RADIO_DATE_SET: 复选框+日期
 * CATEGORY: 类别
 * DEPARTMENT: 部门
 * ATTACHMENT: 附件上传
 * BOOLEAN：开关
 * MONEY：金额输入
 * MULTILEVEL_SELECT：树选择
 * CODE：编码输入
 * MEMBER：成员选择
 * IMAGE: 图片
 */
export type EamFieldType =
  | 'ATTACHMENT'
  | 'BOOLEAN'
  | 'CATEGORY'
  | 'CODE'
  | 'DATE'
  | 'DATE_SET'
  | 'DEPARTMENT'
  | 'IMAGE'
  | 'LABEL'
  | 'LABEL_TEXT_SET'
  | 'MEMBER'
  | 'MONEY'
  | 'MULTILEVEL_SELECT'
  | 'MULTI_RADIO'
  | 'MULTI_RADIO_DATE_SET'
  | 'MULTI_RADIO_TEXT_SET'
  | 'NUMBER'
  | 'OTHER'
  | 'PERIOD'
  | 'RADIO'
  | 'RANGE'
  | 'SELECT_BOX'
  | 'SELECT_DATE_SET'
  | 'SELECT_TEXT_SET'
  | 'TEXT'
  | 'TEXT_SET'

export type EamFileListFilterInput = {
  file?: InputMaybe<Array<IdInput>>
}

export type EamFormByThingCategoryFilterInput = {
  thingCategory: Array<IntIdInput>
}

export type EamFormFieldInput = {
  component?: InputMaybe<Array<EamComponentFieldInput>>
  editable?: InputMaybe<Scalars['Boolean']>
  field: IntIdInput
  option?: InputMaybe<Array<EamFormFieldOptionInput>>
  required?: InputMaybe<Scalars['Boolean']>
  width?: InputMaybe<Scalars['Int']>
}

export type EamFormFieldOptionInput = {
  field?: InputMaybe<Array<IntIdInput>>
  option: Scalars['String']
}

export type EamFormListFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  roleEnable?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export type EamFormStructureFilter = {
  id: IntIdInput
  roleEnable?: InputMaybe<Scalars['Boolean']>
}

export type EamImportOption = 'INSERT_NORMAL' | 'UPSERT_NORMAL'

/** 模板异常、导入异常、导入成功、异步 */
export type EamImportResultType =
  | 'ASYNC'
  | 'DATA_INVALID'
  | 'HEADER_INVALID'
  | 'SUCCESS'

/** 行政管理，生产制造，教育培训，医疗服务 */
export type EamIndustryField =
  | 'ADMINISTRATION'
  | 'EDUCATION'
  | 'MANUFACTURING'
  | 'MEDICAL_TREATMENT'

export type EamMetaRangeExtremumInput = {
  default?: InputMaybe<Scalars['Float']>
  hint?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  max?: InputMaybe<Scalars['Float']>
  min?: InputMaybe<Scalars['Float']>
  round?: InputMaybe<Scalars['Int']>
  unit?: InputMaybe<Scalars['String']>
  unitAlign?: InputMaybe<EamUnitAlign>
  zeroable?: InputMaybe<Scalars['Boolean']>
}

export type EamNumberRangeInput = {
  end?: InputMaybe<Scalars['Float']>
  start?: InputMaybe<Scalars['Float']>
}

export type EamReceiveThingInput = {
  thing: CreateThingInput
  thingReceive: IntIdInput
}

export type EamRepeatPeriod = 'DAY' | 'MONTH' | 'YEAR'

export type EamSparePartCategoryListFilterInput = {
  dataRangeDisable?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Array<Scalars['Int']>>
  /** 模糊搜索： 名称 */
  search?: InputMaybe<Scalars['String']>
}

export type EamSparePartWarehouseListFilterInput = {
  manager?: InputMaybe<Array<IdInput>>
  /** 模糊搜索： 名称 */
  search?: InputMaybe<Scalars['String']>
}

export type EamStaffListFilterInput = {
  category?: InputMaybe<Array<EamTeamCategory>>
  staffSearch?: InputMaybe<Scalars['String']>
  team?: InputMaybe<Array<IntIdInput>>
}

export type EamTableFilterFieldInput = {
  active: Scalars['Boolean']
  id: Scalars['ID']
}

export type EamTableFilterKey =
  | 'EAM_THING_TABLE'
  | 'PROCESSED_THING_TABLE'
  | 'THING_RECEIPT_TABLE'

export type EamTeamCategory =
  | 'THING_INSPECTION'
  | 'THING_MAINTENANCE'
  | 'THING_REPAIR'

export type EamTeamListFilterInput = {
  /** 班组类别 */
  category?: InputMaybe<Array<EamTeamCategory>>
  /** 班组长 */
  leader?: InputMaybe<Array<StringIdInput>>
  /** 班组成员 */
  member?: InputMaybe<Array<StringIdInput>>
  /** 班组名称搜索 */
  search?: InputMaybe<Scalars['String']>
  /** 班组人员（班组人员和班组人员筛选条件不可以同时出现） */
  staff?: InputMaybe<Array<StringIdInput>>
}

export type EamTeamMemberListFilterInput = {
  category?: InputMaybe<Array<EamTeamCategory>>
  excludeStaff?: InputMaybe<Array<StringIdInput>>
  staffSearch?: InputMaybe<Scalars['String']>
  team?: InputMaybe<Array<IntIdInput>>
}

export type EamTicketState =
  | 'BORROW'
  | 'CALIBRATE'
  | 'INSPECTION'
  | 'INVENTORY'
  | 'MAINTENANCE'
  | 'REPAIR'

export type EamUnitAlign = 'HIDDEN' | 'LEFT' | 'RIGHT'

export type EamWhisperInput = {
  id: Scalars['ID']
  resourceKey: ResourceKey
}

export type EamWorkflowDefaultReviewOperate =
  | 'ABORT'
  | 'AUTO_APPROVE'
  | 'SPECIFIC'

export type EamWorkflowExecutorPerson =
  | 'OPERATOR'
  | 'SCHEDULE_CREATED_BY'
  | 'TEAM_LEADER'

export type EamWorkflowReferMethod = 'ABSOLUTE' | 'RELATIVE'

export type EamWorkflowReviewer = 'KEEPER' | 'MATCH' | 'OFFICER'

export type EamWorkflowReviewerAbsoluteLevel =
  | 'EIGHT'
  | 'FIVE'
  | 'FOUR'
  | 'NINE'
  | 'ONE'
  | 'SEVEN'
  | 'SIX'
  | 'TEN'
  | 'THREE'
  | 'TWO'

export type EamWorkflowReviewerRelativeLevel =
  | 'EIGHT'
  | 'FIVE'
  | 'FOUR'
  | 'NINE'
  | 'ONE'
  | 'PRESENT'
  | 'SEVEN'
  | 'SIX'
  | 'THREE'
  | 'TWO'

export type EamZipImportResultType = 'ASYNC'

export type EmptyStaffApproveConfigInput = {
  /** 指定经办人自动通过 */
  autoPassStaffs?: InputMaybe<Array<StringIdInput>>
  /** 当审批人为空时 */
  strategy: EmptyStaffApproveStrategy
}

export type EmptyStaffApproveStrategy =
  /** 指定人员审批 */
  | 'DESIGNATED_STAFF'
  /** 自动通过 */
  | 'PASS'
  /** 终止操作 */
  | 'TERMINATE'

export type EvasionDateInput = {
  date: Scalars['TimestampRange']
  name: Scalars['String']
}

export type ExcelFieldOption = {
  label: Scalars['String']
  value: Scalars['String']
}

export type ExcelImportResultType =
  | 'ASYNC'
  | 'DATA_INVALID'
  | 'HEADER_INVALID'
  | 'SUCCESS'

export type ExcelTableFieldConfigInput = {
  /** 中文名称 */
  label: Scalars['String']
  /** 数据取值变量 */
  name: Scalars['String']
  option?: InputMaybe<Array<ExcelFieldOption>>
  /** 是否隐藏列 */
  visible: Scalars['Boolean']
}

export type ExportThingInput = {
  id: Array<Scalars['ID']>
}

export type ExtendThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  delayAt: Scalars['Timestamp']
  id: Array<Scalars['Int']>
  opinion?: InputMaybe<Scalars['String']>
}

export type FailedEsmFileExportTaskInput = {
  id: Scalars['String']
  reason?: InputMaybe<Scalars['String']>
}

export type FailedEsmFileTaskInput = {
  id: Scalars['String']
  reasons?: InputMaybe<Array<Scalars['String']>>
}

export type FallbackProcessInstanceInput = {
  fallbackActivityKey: Scalars['String']
  id: Scalars['String']
  reason: Scalars['String']
}

export type FeaturePackListFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索：name, releaseName */
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<FeaturePackStatus>>
  versionLevels?: InputMaybe<Array<FeaturePackVersionLevel>>
}

export type FeaturePackStatus =
  /** 已停用 */
  | 'DISABLED'
  /** 待发布 */
  | 'PENDING'
  /** 已发布 */
  | 'RELEASED'

export type FeaturePackSubConfigFieldInput = {
  address?: InputMaybe<Array<AddressInput>>
  certificationStatus?: InputMaybe<Array<TenantCertificationStatus>>
  industry?: InputMaybe<Array<StringIdInput>>
  kind: FeaturePackSubConfigKind
  platProduct?: InputMaybe<Array<StringIdInput>>
}

export type FeaturePackSubConfigKind =
  | 'ADDRESS'
  | 'INDUSTRY'
  | 'PLAT_PRODUCT'
  | 'STATUS'

export type FeaturePackSubConfigSubscriptionInput = {
  durationMonth?: InputMaybe<Scalars['Int']>
  featurePack: StringIdInput
  isAlwaysEffective: Scalars['Boolean']
}

export type FeaturePackSubscriptionsOfTenantFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  tenant: StringIdInput
}

export type FeaturePackVersionLevel =
  | 'CERTIFIED_BASIC'
  | 'CUSTOM'
  | 'EDITION_PROFESSIONAL'
  | 'NOT_CERTIFIED_BASIC'
  | 'REFUELING_BAG'
  | 'STANDARD'
  | 'ULTIMATE'

export type FeaturePackVersionType = 'REFUELING_BAG' | 'REGULAR_VERSION'

export type FeaturePacksOfAppsFilterInput = {
  /** 模糊搜索：版本名称/版本发布名称 */
  search?: InputMaybe<Scalars['String']>
  searchAppName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<FeaturePackStatus>>
  versionLevels?: InputMaybe<Array<FeaturePackVersionLevel>>
}

export type FieldChipDataInput = {
  color: ChipFieldColor
  label: Scalars['String']
}

export type FieldDataFormat =
  /** 附件 */
  | 'ATTACHMENT'
  /** 标签 */
  | 'CHIP'
  /** 图片 */
  | 'IMAGE'
  /** 链接 */
  | 'LINK'
  /** 文本 */
  | 'TEXT'

export type FieldLinkDataInput = {
  label: Scalars['String']
  url: Scalars['String']
}

export type FindThingInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  department: IdInput
  id: Scalars['Int']
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
}

export type FinishEsmFileExportTaskInput = {
  fileUrls: Array<Scalars['String']>
  id: Scalars['String']
}

export type FinishEsmFileTaskInput = {
  fileUrls: Array<Scalars['String']>
  id: Scalars['String']
}

export type FontSize =
  /** 大 */
  | 'EXTRA_LARGE'
  /** 较大 */
  | 'LARGE'
  /** 中 */
  | 'MEDIUM'
  /** 小 */
  | 'MINI'
  /** 较小 */
  | 'SMALL'

export type FormTemplateType = 'METER' | 'OFFICIAL' | 'VEHICLE'

export type GenerateCodeInput = {
  areaPathCode?: InputMaybe<Scalars['String']>
  categoryPathCode?: InputMaybe<Scalars['String']>
  codePrefix?: InputMaybe<Scalars['String']>
  departmentCode?: InputMaybe<Scalars['String']>
  purchasedAt?: InputMaybe<Scalars['Timestamp']>
}

export type GetAuthTokenByDingdingUserInput = {
  appCode: Scalars['String']
  code: Scalars['String']
  corpId: Scalars['String']
  tenantCode: Scalars['String']
}

export type GetTenantAccessTokenInput = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type GetTenantAccessTokenOfPlatformInput = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  tenantId: Scalars['String']
}

export type GetTenantIdsOfPlatformAppInput = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

/**
 * `DAILY`: 日
 * `MONTHLY`: 月
 * `ANNUALLY`: 年
 * ``
 */
export type Granularity =
  | 'ANNUALLY'
  | 'DAILY'
  | 'FIVE_MINUTE'
  | 'HOURLY'
  | 'MINUTE'
  | 'MONTHLY'
  | 'WEEKLY'

export type GroupFileInput = {
  file?: InputMaybe<Array<Scalars['String']>>
  groupName: Scalars['String']
}

export type IamImportMode = 'CREATE' | 'CREATE_OR_UPDATE' | 'UPDATE'

export type IdInput = {
  id: Scalars['ID']
}

export type InboxMessageFilterInput = {
  isRead?: InputMaybe<Scalars['Boolean']>
  sourceAppKeys?: InputMaybe<Array<Scalars['String']>>
}

export type InitialPasswordMode =
  /** 自定义 */
  | 'CUSTOM'
  /** 规则生成 */
  | 'RULE'

export type InitialPasswordRule =
  /** 固定密码 */
  | 'FIXED'
  /** 同邮箱 */
  | 'WITH_EMAIL'
  /** 同工号 */
  | 'WITH_JOB_NUMBER'
  /** 同手机号 */
  | 'WITH_PHONE_NUMBER'

export type InspectionMethodFieldDataInput = {
  containMax?: InputMaybe<Scalars['Boolean']>
  containMin?: InputMaybe<Scalars['Boolean']>
  default?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  options?: InputMaybe<Array<Scalars['String']>>
  qualifiedValueMax?: InputMaybe<Scalars['Float']>
  qualifiedValueMin?: InputMaybe<Scalars['Float']>
  required: Scalars['Boolean']
  type: InspectionMethodFieldType
  unit?: InputMaybe<Scalars['String']>
}

export type InspectionMethodFieldType = 'NUMBER' | 'SELECT_BOX' | 'TEXT'

export type InspectionMethodListFilterInput = {
  exclude?: InputMaybe<Array<IntIdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  /** 搜索：项目名称/巡检方法/备注/目的模糊搜索 */
  search?: InputMaybe<Scalars['String']>
}

export type InspectionPointCategory = 'AREA' | 'OTHER' | 'THING'

export type InspectionPointListFilterInput = {
  /** 关联区域 */
  area?: InputMaybe<Array<InputMaybe<IntIdInput>>>
  /** 类型 */
  category?: InputMaybe<Array<InspectionPointCategory>>
  /** 关联资产保管部门 */
  department?: InputMaybe<Array<IdInput>>
  /** 状态 */
  excludeAbnormal?: InputMaybe<Scalars['Boolean']>
  /** 过滤指定巡检点 */
  excludeInspectionPoint?: InputMaybe<Array<IntIdInput>>
  /** 模糊搜索：编码，名称，备注 */
  search?: InputMaybe<Scalars['String']>
  /** 关联资产所属区域 */
  thingArea?: InputMaybe<Array<IntIdInput>>
  /** 关联资产类别 */
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type InspectionPointStatus = 'ABNORMAL' | 'NORMAL'

export type InspectionRepeatInput = {
  /** 重复频率 */
  frequency: Scalars['Int']
  /** 重复周期 */
  period: RepeatPeriod
}

export type InspectionReportPerson =
  | 'DESIGNATED_PERSON'
  | 'SCHEDULE_CREATED_BY'
  | 'TEAM_LEADER'

export type IntIdInput = {
  id: Scalars['Int']
}

export type InventoryThingsFilterInput = {
  /** 资产类别 */
  category?: InputMaybe<Array<IntIdInput>>
  code?: InputMaybe<Array<Scalars['String']>>
  /** 资产保管部门 */
  department?: InputMaybe<Array<IdInput>>
  /** 资产保管人员 */
  manager?: InputMaybe<Array<IdInput>>
  rfid?: InputMaybe<Array<Scalars['String']>>
  /** 资产财务编码 */
  sapThingCode?: InputMaybe<Scalars['String']>
  /** 模糊搜索：资产编码/资产名称/规格/型号 */
  search?: InputMaybe<Scalars['String']>
}

export type IsCalibrateOrganizationInput = {
  name?: InputMaybe<Scalars['String']>
}

export type IsCalibrateScheduleExistsInput = {
  name: Scalars['String']
}

export type IsEamFieldExistsFilterInput = {
  isLock: Scalars['Boolean']
  name: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type IsEamFormExistsFilterInput = {
  name: Scalars['String']
}

export type IsEamSparePartCategoryExistsInput = {
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['Int']>
}

export type IsEamSparePartWarehouseExistsFilter = {
  name: Scalars['String']
}

export type IsExistThingCirculationFilterInput = {
  state?: InputMaybe<Array<BorrowState>>
  thing: IdInput
}

export type IsInspectionMethodExistsInput = {
  name: Scalars['String']
}

export type IsMaintenanceMethodExistsInput = {
  name: Scalars['String']
}

export type IsOutsideCalibrateExistsInput = {
  name: Scalars['String']
}

export type IsSparePartExistsInput = {
  code: Scalars['String']
  companyId?: InputMaybe<Scalars['ID']>
}

export type IsThingAreaCodeExistsInput = {
  /** 编码 */
  code: Scalars['String']
}

export type IsThingAreaExistsInput = {
  name: Scalars['String']
  /** 名称 */
  parentId?: InputMaybe<Scalars['Int']>
}

export type IsThingBarLabelExistsInput = {
  name: Scalars['String']
}

export type IsThingCategoryCodeExistsInput = {
  /** 编码 */
  code: Scalars['String']
}

export type IsThingCategoryExistsInput = {
  name: Scalars['String']
  /** 名称 */
  parentId?: InputMaybe<Scalars['Int']>
}

export type IsThingContainInput = {
  category?: InputMaybe<Array<IntIdInput>>
}

export type IsThingExistsInput = {
  code: Scalars['String']
}

export type IsThingInventoryTicketExistsInput = {
  name: Scalars['String']
}

export type IsThingRepairFaultCodeExistsInput = {
  /** 编码 */
  code: Scalars['String']
}

export type IsThingRepairFaultExistsInput = {
  name: Scalars['String']
  /** 名称 */
  parentId?: InputMaybe<Scalars['Int']>
}

export type IsThingRepairNotificationConditionExistsInput = {
  name: Scalars['String']
}

export type IsThingRepairRuleExistsInput = {
  name: Scalars['String']
}

export type IsThingRfidExistsInput = {
  rfid: Scalars['String']
}

export type IssueSparePartClaimInput = {
  date: Scalars['Timestamp']
  id: Scalars['Int']
  item: Array<CreateSparePartOutboundItemInput>
  remark?: InputMaybe<Scalars['String']>
}

export type JumpKind = 'CURRENT_WINDOW' | 'NEW_WINDOW'

export type KindOfDataValueSource = 'SELECT' | 'TREE'

export type Ldap3EncryptionMethod = 'NONE' | 'SSL' | 'STARTTLS'

export type LogAction =
  | 'CREATE'
  | 'DELETE'
  | 'EXPORT'
  | 'LOGIN'
  | 'LOGOUT'
  | 'READ'
  | 'UPDATE'

export type LogListFilterInput = {
  action?: InputMaybe<Array<LogAction>>
  appId?: InputMaybe<Array<Scalars['String']>>
  end?: InputMaybe<Scalars['Timestamp']>
  /** 用户账号 */
  search?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['Timestamp']>
}

export type LoginByEmailInput = {
  email: Scalars['String']
  tenantCode: Scalars['String']
  verifyCode: Scalars['String']
}

export type LoginByPhoneNumberAllTenantInput = {
  phoneNumber: Scalars['String']
  verifyCode: Scalars['String']
}

export type LoginByPhoneNumberInput = {
  phoneNumber: Scalars['String']
  tenantCode: Scalars['String']
  verifyCode: Scalars['String']
}

export type LoginConfigurationKind = 'OAUTH_2' | 'OPENID_CONNECT_1' | 'SYSTEM'

export type LoginInput = {
  account: Scalars['String']
  password: Scalars['String']
  tenantCode: Scalars['String']
}

export type LoginMode =
  | 'ACCOUNT_PASSWORD'
  | 'DINGDING'
  | 'EMAIL_PASSWORD'
  | 'PHONE_VERIFY_CODE'
  | 'SSO'
  | 'WECHAT'

export type MaintenanceLevel = 'FIRST' | 'REGULAR' | 'SECOND'

export type MaintenanceMethodListFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  exclude?: InputMaybe<Array<IntIdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  label?: InputMaybe<Array<Scalars['String']>>
  level?: InputMaybe<Array<MaintenanceLevel>>
  /** 搜索：保养项目名称，方法，目的 */
  search?: InputMaybe<Scalars['String']>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type MaintenanceMethodSparePartItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type MaintenanceRepeatInput = {
  /** 重复频率 */
  frequency: Scalars['Int']
  /** 重复周期 */
  period: RepeatPeriod
}

export type MaintenanceSparePartItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
  sparePartClaim: IntIdInput
}

export type MenuListFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

export type MenuVisitHistoryListFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

export type MessageChannelKind =
  | 'DINGDING'
  | 'EMAIL'
  | 'FEISHU'
  | 'INBOX'
  | 'SMS'
  | 'WECHAT'
  | 'WECOM'

export type MessageTemplateListInput = {
  apps?: InputMaybe<Array<IdInput>>
  availableChannelKinds?: InputMaybe<Array<Scalars['String']>>
  /** 模糊搜索：消息名称,接受对象 */
  search?: InputMaybe<Scalars['String']>
  target?: InputMaybe<Scalars['String']>
}

export type MetaTemplateListInput = {
  /** 应用筛选 */
  apps?: InputMaybe<Array<IdInput>>
  /** 模板名称关键字模糊搜索 */
  search?: InputMaybe<Scalars['String']>
}

export type MoveThingInput = {
  thingGroup: IdInput
  things: Array<IdInput>
}

export type MutateEsmDemoDateRangeInput = {
  end?: InputMaybe<Scalars['Timestamp']>
  start?: InputMaybe<Scalars['Timestamp']>
}

export type MutateEsmDemoFileInput = {
  id: Scalars['ID']
  length?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  url: Scalars['String']
}

export type MutateEsmDemoNumberRangeInput = {
  end?: InputMaybe<Scalars['Float']>
  start?: InputMaybe<Scalars['Float']>
}

export type MyBacklogGroupFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  groups?: InputMaybe<Array<StringIdInput>>
  status?: InputMaybe<Array<BacklogStatus>>
}

export type MyCalendarTaskFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  endDateRange?: InputMaybe<Scalars['TimestampRange']>
  /** 活动名称 */
  search?: InputMaybe<Scalars['String']>
  splitRange?: InputMaybe<CalendarSplitRange>
  startDateRange?: InputMaybe<Scalars['TimestampRange']>
  status?: InputMaybe<Array<CalendarActivityStatus>>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
}

export type MyDayCalendarTaskFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  dayRange?: InputMaybe<Scalars['TimestampRange']>
  /** 活动名称 */
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<CalendarActivityStatus>>
}

export type MyPlatformAnnouncementListFilterInput = {
  /** 筛选: id in ids */
  ids?: InputMaybe<Array<Scalars['String']>>
  /** 模糊搜索: title ilike search */
  search?: InputMaybe<Scalars['String']>
}

export type MyTenantAnnouncementListFilterInput = {
  /** 筛选: id in ids */
  ids?: InputMaybe<Array<Scalars['String']>>
  /** 模糊搜索: title ilike search */
  search?: InputMaybe<Scalars['String']>
}

export type MyTenantAppListFilterInput = {
  /** 是否排除“管理后台”应用 */
  excludeAdmin?: InputMaybe<Scalars['Boolean']>
  /** 是否包含隐藏应用 */
  includeInvisible?: InputMaybe<Scalars['Boolean']>
  /** app 是否支持水印 */
  isWatermarkSupported?: InputMaybe<Scalars['Boolean']>
  /** 应用种类 */
  kinds?: InputMaybe<Array<AppKind>>
  /** 模糊搜索：名称 */
  search?: InputMaybe<Scalars['String']>
}

export type MyTenantDevelopmentAppListFilterInput = {
  includeNotOnline?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索：名称 */
  search?: InputMaybe<Scalars['String']>
}

export type OldRoleFilterInput = {
  company?: InputMaybe<IdInput>
  id?: InputMaybe<Scalars['ID']>
  permission?: InputMaybe<Array<Scalars['String']>>
  scope?: InputMaybe<Array<Scalars['String']>>
  /** search: name */
  search?: InputMaybe<Scalars['String']>
}

export type OnState =
  | 'AVAILABLE'
  | 'IN_USE'
  | 'PENDING'
  | 'PROCESSED'
  | 'RENT'
  | 'STOCK_IN'

export type OpenIdConnect1ConfigurationMethod = 'DISCOVERY' | 'MANUAL'

export type OperateSparePartClaimInput = {
  id: Scalars['Int']
  operateType: SparePartClaimOperatorType
  opinion?: InputMaybe<Scalars['String']>
}

export type OperateThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  operateType: ThingBorrowOperatorType
  opinion?: InputMaybe<Scalars['String']>
}

export type OperateThingMaintenanceInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  operateType: ThingMaintenanceOperator
  remark?: InputMaybe<Scalars['String']>
}

export type OperateThingRepairInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  operateType: ThingRepairOperatorType
  remark?: InputMaybe<Scalars['String']>
}

export type OrderPlatProductInput = {
  id: Scalars['String']
  index: Scalars['Int']
}

export type OrderTenantPlaceInput = {
  childrenIds: Array<Scalars['String']>
  /** parentId为null表示childrenIds是一级节点 */
  parentId?: InputMaybe<Scalars['String']>
}

export type OrganizationDynamicRuleAction =
  | 'BELONG_TO_EXCLUDE_CHILDREN'
  | 'BELONG_TO_INCLUDE_CHILDREN'
  | 'NOT_BELONGING_TO_EXCLUDE_CHILDREN'
  | 'NOT_BELONGING_TO_INCLUDE_CHILDREN'

export type OrganizationDynamicRuleInput = {
  action: OrganizationDynamicRuleAction
  organizations: Array<StringIdInput>
}

/** isChildrenIncluded 为 true 时, 查询 ids/id（必须传一个）下的子部门, 此时其他查询条件会失效 */
export type OrganizationListFilterInput = {
  id?: InputMaybe<Scalars['String']>
  ids?: InputMaybe<Array<Scalars['String']>>
  /** 默认不返回已撤销部门，includeRevoked=true返回结果会包含已撤销部门 */
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  isChildrenIncluded?: InputMaybe<Scalars['Boolean']>
  /** isRevoked=true仅返回已撤销部门，如果设置了isRevoked，参数includeRevoked=true */
  isRevoked?: InputMaybe<Scalars['Boolean']>
  /** 查询层级，如果企业使用树层级作为部门层级，该查询依然有效 */
  level?: InputMaybe<Scalars['Int']>
  parentId?: InputMaybe<Scalars['String']>
  parentIsRoot?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export type OrganizationRoleItemInput = {
  includeChildren: Scalars['Boolean']
  role: StringIdInput
}

export type OrganizationTreeNodeFilterInput = {
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  selectedNodes?: InputMaybe<Array<StringIdInput>>
}

export type OutsideCalibrateListListFilterInput = {
  calibrateOrganization?: InputMaybe<Array<IdInput>>
  payStatus?: InputMaybe<Array<OutsideCalibratePayStatus>>
  /** 模糊搜索：机构名称 */
  search?: InputMaybe<Scalars['String']>
}

export type OutsideCalibratePayStatus = 'PAID' | 'UNDER_REVIEW'

export type PageListFilterInput = {
  apps?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
}

export type PassInventoryTicketInput = {
  things: Array<IdInput>
  ticket: IntIdInput
}

export type PasswordCharacterSet =
  /** 英文小写 */
  | 'LOWERCASE'
  /** 数字 */
  | 'NUMBER'
  /** 特殊符号 */
  | 'SYMBOLS'
  /** 英文大写 */
  | 'UPPERCASE'

export type PermissionDataRangeInput = {
  /** 数据范围 */
  code: Scalars['String']
  name: Scalars['String']
  rules?: InputMaybe<Array<PermissionDataRangeRuleInput>>
}

export type PermissionDataRangeRuleInput = {
  field: DataRangeFieldInput
  fieldValue: DataRangeFieldValueInput
  operator: DataRangeOperatorInput
}

export type PermissionFilterInput = {
  appKeys?: InputMaybe<Array<Scalars['String']>>
  excludeAppKeys?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['String']>>
  isAllowed?: InputMaybe<Scalars['Boolean']>
  isLeafOnly?: InputMaybe<Scalars['Boolean']>
  /** 是否要求此权限一定要有数据权限范围 */
  requireDataRanges?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  types?: InputMaybe<Array<PermissionType>>
}

export type PermissionType = 'FEATURE' | 'MENU' | 'PAGE'

export type PlatProductFilterInput = {
  isPublished?: InputMaybe<Scalars['Boolean']>
}

export type PlatformAnnouncementConfigListFilterInput = {
  /** 是否是草稿 */
  isDraft?: InputMaybe<Scalars['Boolean']>
  /** 是否失效：转换为ExpiredAtRange */
  isExpired?: InputMaybe<Scalars['Boolean']>
  /** 发布时间范围 */
  publishAtRange?: InputMaybe<Scalars['TimestampRange']>
  /** 公告标题 */
  search?: InputMaybe<Scalars['String']>
}

export type PlatformAnnouncementStaffRange =
  /** 所有人 */
  | 'ALL'
  /** 仅超级管理员 */
  | 'SUPERUSER'

export type PlatformAnnouncementVisibleRange =
  /** 全部平台 */
  | 'ALL'
  /** 指定应用 */
  | 'DESIGNATED_APP'
  /** 指定平台 */
  | 'DESIGNATED_TENANT'
  /** 指定行业 */
  | 'DESIGNATED_TENANT_INDUSTRY'

export type ProcessActivityKind =
  /** 审批 */
  | 'APPROVE'
  /** 抄送 */
  | 'CARBON_COPY'
  /** 条件分支 */
  | 'CONDITION'
  /** 结束 */
  | 'END'
  /** 开始 */
  | 'START'

export type ProcessActivityTaskKind =
  /** 审批 */
  | 'APPROVE'
  /** 抄送 */
  | 'CARBON_COPY'

export type ProcessActivityTaskStatus =
  /** 取消 */
  | 'CANCELED'
  /** 回退 */
  | 'FALLBACK'
  /** 已完成 */
  | 'FINISHED'
  /** 代办 */
  | 'PENDING'
  /** 已拒绝 */
  | 'REJECTED'
  /** 已转派 */
  | 'TRANSFER'

export type ProcessApproveActivityInput = {
  /** 允许“转交”给其他审批人 */
  allowTransfer?: InputMaybe<Scalars['Boolean']>
  /** 审批人配置 */
  approveStaffConfig: ApproveStaffConfigInput
  /** 审批人自动通过配置 */
  autoPassConfig?: InputMaybe<AutoPassApproveConfig>
  /** 当审批人为空时 */
  emptyStaffApproveConfig: EmptyStaffApproveConfigInput
  /** 启动自动通过配置 */
  enableAutoPassConfig?: InputMaybe<Scalars['Boolean']>
  /** “回退”配置 */
  fallbackConfig?: InputMaybe<ProcessApproveFallbackConfigInput>
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点类型 */
  kind: ProcessActivityKind
  /** 节点名称 */
  label: Scalars['String']
  /** 当存在多位审批人时，通过方式 */
  sign: ApproveSignatureKind
}

export type ProcessApproveFallbackConfigInput = {
  /** 允许“回退”操作 */
  allowFallback?: InputMaybe<Scalars['Boolean']>
  /** 回退节点ID */
  fallbackActivityKey?: InputMaybe<Scalars['String']>
  /** 回退方式 */
  fallbackKind?: InputMaybe<ApproveFallbackKind>
}

export type ProcessCarbonCopyActivityInput = {
  /** 审批人配置 */
  approveStaffConfig: ApproveStaffConfigInput
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点类型 */
  kind: ProcessActivityKind
  /** 节点名称 */
  label?: InputMaybe<Scalars['String']>
}

export type ProcessConditionActivityBranchInput = {
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点名称 */
  label: Scalars['String']
  /** 条件规则配置数据，数据业务前端自行定义JSON格式 */
  rule: Scalars['JSON']
}

export type ProcessConditionActivityInput = {
  /** 条件分支 */
  branches: Array<ProcessConditionActivityBranchInput>
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点类型 */
  kind: ProcessActivityKind
}

export type ProcessDefinitionActivityConfigInput = {
  /** 审核节点配置 */
  approveActivities: Array<ProcessApproveActivityInput>
  /** 抄送节点配置 */
  carbonCopyActivities: Array<ProcessCarbonCopyActivityInput>
  /** 条件节点配置 */
  conditionActivities: Array<ProcessConditionActivityInput>
  /** 结束节点配置 */
  endActivity: ProcessEndActivityInput
  /** 开始节点配置 */
  startActivity: ProcessStartActivityInput
}

export type ProcessDefinitionDiagramConfigInput = {
  edges: Array<ProcessDefinitionDiagramEdgeInput>
  nodes: Array<ProcessDefinitionDiagramNodeInput>
}

export type ProcessDefinitionDiagramEdgeInput = {
  data?: InputMaybe<Scalars['JSON']>
  id: Scalars['String']
  labels?: InputMaybe<Scalars['JSON']>
  shape: Scalars['String']
  source: ProcessDiagramEdgePortInput
  target: ProcessDiagramEdgePortInput
}

export type ProcessDefinitionDiagramNodeInput = {
  data?: InputMaybe<Scalars['JSON']>
  id: Scalars['String']
  ports: Scalars['JSON']
  shape: Scalars['String']
}

export type ProcessDiagramEdgePortInput = {
  cell: Scalars['String']
  port: Scalars['String']
}

export type ProcessEndActivityInput = {
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点类型 */
  kind: ProcessActivityKind
  /** 节点名称 */
  label: Scalars['String']
}

export type ProcessHistoryLogFieldInput = {
  attachmentValue?: InputMaybe<Array<StringIdInput>>
  chipValue?: InputMaybe<Array<FieldChipDataInput>>
  format: FieldDataFormat
  imageValue?: InputMaybe<Array<StringIdInput>>
  key: Scalars['String']
  linkValue?: InputMaybe<Array<FieldLinkDataInput>>
  textValue?: InputMaybe<Array<Scalars['String']>>
}

export type ProcessHistoryLogFilterInput = {
  businessKey: Scalars['String']
  createdAtRange?: InputMaybe<Scalars['TimestampRange']>
}

export type ProcessStartActivityInput = {
  /** 在流程定义中的唯一标识符 */
  key: Scalars['String']
  /** 节点类型 */
  kind: ProcessActivityKind
  /** 节点名称 */
  label: Scalars['String']
}

export type ProcessedFilterType = 'ALL' | 'PROCESSED' | 'UNPROCESSED'

export type PushScheduleType =
  /** 定时推送 */
  | 'CRON'
  /** 立即推送 */
  | 'IMMEDIATELY'

export type ReassignThingInventoryRecordInput = {
  id: Array<Scalars['Int']>
  staff: Array<StringIdInput>
}

export type RecordInventoryByThingInput = {
  image?: InputMaybe<Array<IdInput>>
  isBroadcast?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Array<ThingInventoryLabel>>
  remark?: InputMaybe<Scalars['String']>
  state: ThingInventoryState
  thing: IdInput
}

export type RecordThingInspectionInput = {
  /** 附件 */
  attachment?: InputMaybe<Array<IdInput>>
  /** 指标内容 */
  fieldData?: InputMaybe<Scalars['JSON']>
  /** 具体的process_item_id */
  id: Scalars['Int']
  image?: InputMaybe<Array<IdInput>>
  /** 巡检情况 */
  remark?: InputMaybe<Scalars['String']>
  /** 巡检结果 */
  result?: InputMaybe<ThingInspectionResult>
}

export type RecordThingMaintenanceInput = {
  /** 附件 */
  attachment?: InputMaybe<Array<IdInput>>
  /** 具体的process_item_id */
  id: Scalars['Int']
  /** 保养情况说明 */
  remark?: InputMaybe<Scalars['String']>
  sparePartItem?: InputMaybe<Array<MaintenanceSparePartItemInput>>
}

export type RegisterByPhoneNumberAllTenantInput = {
  phoneNumber: Scalars['String']
  verifyCode: Scalars['String']
}

export type RejectTenantCertificationInput = {
  id: Scalars['String']
  reason: Scalars['String']
}

export type RejectThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Array<Scalars['Int']>
  opinion: Scalars['String']
}

export type RejectThingCalibrateInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
}

export type RejectThingInspectionInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
}

export type RelateBusinessStaffGroupToRoleInput = {
  businessStaffGroup: Array<StringIdInput>
  roleId: Scalars['String']
}

export type RelateRoleToBusinessStaffGroup = {
  id: Scalars['String']
  role: Array<StringIdInput>
}

export type RelateRoleToDynamicBusinessStaffGroup = {
  id: Scalars['String']
  role: Array<StringIdInput>
}

export type RemindProcessInstanceInput = {
  id: Scalars['String']
}

export type RemoveAccountRolesInput = {
  accountIds: Array<Scalars['String']>
  roleIds: Array<Scalars['String']>
  selectAllAccounts?: InputMaybe<Scalars['Boolean']>
}

export type RemoveAppAdminAccounts = {
  accounts: Array<StringIdInput>
  app: StringIdInput
}

export type RemoveBusinessStaffGroupFromRoleInput = {
  businessStaffGroup: Array<StringIdInput>
  roleId: Scalars['String']
}

export type RemoveChannelsToMessageTemplatesInput = {
  channelKinds: Array<MessageChannelKind>
  messageTemplates: Array<StringIdInput>
}

export type RemoveRoleAccountsInput = {
  accountIds: Array<Scalars['String']>
  id: Scalars['String']
}

export type RemoveRoleAppsInput = {
  appIds: Array<Scalars['String']>
  id: Scalars['String']
}

export type RemoveStaffFromStaticBusinessStaffGroupInput = {
  id: Scalars['String']
  staff: StringIdInput
}

export type RemoveThingAdministratorDepartmentInput = {
  administrator: Array<StringIdInput>
  department: IdInput
}

export type RepairSparePartItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
  sparePartClaim: IntIdInput
}

/**
 * DAY: 每日
 * WEEK: 每周
 * MONTH: 每月
 * YEAR: 每年
 */
export type RepeatPeriod = 'DAY' | 'MONTH' | 'WEEK' | 'YEAR'

export type ReplaceFeaturePackSubscriptionInput = {
  sourceFeaturePack: StringIdInput
  targetFeaturePack: StringIdInput
  tenants: Array<StringIdInput>
}

export type ReplaceThingBorrowInput = {
  id: Scalars['Int']
  thing: IdInput
}

export type ReplaceThingFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  id: Scalars['ID']
  /** 搜索: 资产编码，资产名称，型号 */
  search?: InputMaybe<Scalars['String']>
}

export type ReportThingInspectionInput = {
  id: Scalars['Int']
}

export type ReportThingMaintenanceInput = {
  id: Scalars['Int']
}

export type ReportThingRepairInput = {
  /** 附件 */
  attachment?: InputMaybe<Array<IdInput>>
  fault?: InputMaybe<Array<IntIdInput>>
  fee?: InputMaybe<Scalars['Float']>
  /** 维修工时 */
  hours?: InputMaybe<Scalars['Float']>
  id: Scalars['Int']
  outcome?: InputMaybe<ThingRepairReportOutcome>
  /** 维修单位 */
  outsource?: InputMaybe<Scalars['String']>
  /** 说明 */
  remark?: InputMaybe<Scalars['String']>
  /** 验收人员 */
  sequentialID?: InputMaybe<Scalars['String']>
  /** 维修使用备件 */
  sparePartItem?: InputMaybe<Array<RepairSparePartItemInput>>
  /** 维修方式 */
  type: ThingRepairType
}

export type ResourceCapacityRangeInput = {
  end?: InputMaybe<Scalars['Float']>
  start?: InputMaybe<Scalars['Float']>
}

export type ResourceKey = 'THING_REPAIR_KNOWHOW'

export type ResourceUnitConfigInput = {
  capacity?: InputMaybe<Scalars['Float']>
  enabled: Scalars['Boolean']
  isInfinite: Scalars['Boolean']
  resourceUnit: StringIdInput
}

export type ResourceUnitsFilterInput = {
  app?: InputMaybe<StringIdInput>
  /** 模糊搜索：name */
  search?: InputMaybe<Scalars['String']>
}

export type ResponseFormat = 'FORM' | 'JSON'

export type RestartThingAbortInput = {
  abortAt: Scalars['Timestamp']
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type RestartThingApplyInput = {
  applicant: IdInput
  applyAt: Scalars['Timestamp']
  applyOfDepartment: IdInput
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type RestartThingMissingFindInfoInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  department: IdInput
  findAt: Scalars['Timestamp']
  id: Scalars['Int']
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type RestartThingMissingInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  department?: InputMaybe<IdInput>
  id: Scalars['Int']
  manager?: InputMaybe<StringIdInput>
  missAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type RestartThingMoveInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  inboundDepartment: IdInput
  moveAt: Scalars['Timestamp']
  outboundDepartment: IdInput
  remark?: InputMaybe<Scalars['String']>
  thingManager: Array<ThingMoveThingManagerInput>
}

export type RestartThingOffLeaseInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  id: Scalars['Int']
  offLeaseAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type RestartThingRentBackInput = {
  attachment?: InputMaybe<Array<IdInput>>
  backAt: Scalars['Timestamp']
  department: IdInput
  id: Scalars['Int']
  institution?: InputMaybe<Scalars['String']>
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  thing: IdInput
}

export type RestartThingRentInput = {
  attachment?: InputMaybe<Array<IdInput>>
  department: IdInput
  end?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  institution: Scalars['String']
  manager: StringIdInput
  remark?: InputMaybe<Scalars['String']>
  start: Scalars['Timestamp']
  thing: IdInput
}

export type RestartThingReturnInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
  thing: Array<IdInput>
}

export type RestartThingSaleInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  id: Scalars['Int']
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  saleAt: Scalars['Timestamp']
  saleTo?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type ReturnSparePartClaimInput = {
  /** 申领单id */
  id: Scalars['Int']
  item: Array<ReturnSparePartClaimItemInput>
}

export type ReturnSparePartClaimItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
  warehouse: IntIdInput
}

export type ReturnState = 'NOT_RETURNED' | 'RETURNED'

export type RoleFilterInput = {
  /** app key 取交集 */
  apps?: InputMaybe<Array<StringIdInput>>
  /** 是否超管、应用管理员影响数据权限 */
  enableDataRange?: InputMaybe<Scalars['Boolean']>
  /** 是否根据组合app过滤 */
  filterByTenantApps?: InputMaybe<Scalars['Boolean']>
  /** 预设角色 */
  isPreset?: InputMaybe<Scalars['Boolean']>
  /** 搜索角色名称 */
  search?: InputMaybe<Scalars['String']>
}

export type RulePointConfigInput = {
  rulePoint: StringIdInput
  value: Scalars['String']
}

export type RulePointsFilterInput = {
  app?: InputMaybe<StringIdInput>
}

export type SaveThingCalibrateInput = {
  calibrateAt?: InputMaybe<Scalars['Timestamp']>
  calibrateLabel?: InputMaybe<Array<IdInput>>
  calibrateMethod: CalibrateMethod
  calibrateOrganization?: InputMaybe<IdInput>
  calibrateReport?: InputMaybe<Array<IdInput>>
  calibrateResult?: InputMaybe<CalibrateResult>
  certificateReturnAt?: InputMaybe<Scalars['Timestamp']>
  explain?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  returnAt?: InputMaybe<Scalars['Timestamp']>
  sendAt?: InputMaybe<Scalars['Timestamp']>
}

export type SetAppAdminAccounts = {
  accounts: Array<StringIdInput>
  app: StringIdInput
}

export type SetAuthorizationRuleInput = {
  dataRange?: InputMaybe<PermissionDataRangeInput>
  id?: InputMaybe<Scalars['String']>
  isAllowed?: InputMaybe<Scalars['Boolean']>
  permission?: InputMaybe<StringIdInput>
}

export type SetAuthorizationRulesToRoleInput = {
  authorizationRules: Array<SetAuthorizationRuleInput>
  role: StringIdInput
}

export type SetAuthorizationRulesToUserInput = {
  authorizationRules: Array<SetAuthorizationRuleInput>
  isAllowed: Scalars['Boolean']
  user: StringIdInput
}

export type SetBusinessStaffGroupDynamicRuleInput = {
  businessStaffGroupId: Scalars['String']
  rule: Array<Array<BusinessGroupDynamicRuleInput>>
}

export type SetBusinessStaffGroupRoleConfigInput = {
  businessStaffGroup: StringIdInput
  roles: Array<StringIdInput>
}

export type SetChannelsToMessageTemplatesInput = {
  channelKinds: Array<MessageChannelKind>
  messageTemplates: Array<StringIdInput>
}

export type SetClientAppPermissionsInput = {
  appId: Scalars['String']
  permissions: Array<ClientAppPermissionInput>
}

export type SetCodeRuleConfigurationInput = {
  configuration?: InputMaybe<Array<CodeColumnConfigurationInput>>
  rule: CodeRuleConfigurationRule
}

export type SetCombaCockpitThingCategoryInput = {
  category_i: IntIdInput
  category_ii: IntIdInput
}

export type SetCombaCockpitThingRangeInput = {
  thing: Array<IntIdInput>
}

export type SetDepartmentThingGroup = {
  department: IdInput
  thingGroup: Array<IdInput>
}

/** 将一个设备组关联给 N 个部门 */
export type SetDepartmentThingGroupInput = {
  departments?: InputMaybe<Array<IdInput>>
  thingGroup: IdInput
}

export type SetEsmSchemeInput = {
  id: Scalars['String']
  name: Scalars['String']
  screens: Array<EsmScreenInput>
  sections: Array<EsmFieldSectionInput>
}

export type SetEamFormStructureInput = {
  id?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  zone?: InputMaybe<Array<InputMaybe<Array<InputMaybe<EamFormFieldInput>>>>>
}

export type SetEamReceiptFormStructureInput = {
  id?: InputMaybe<Scalars['Int']>
  zone?: InputMaybe<Array<InputMaybe<Array<InputMaybe<EamFormFieldInput>>>>>
}

export type SetEamSparePartWarehouseCategoryInput = {
  category?: InputMaybe<Array<IntIdInput>>
  rule: WarehouseCategoryRule
  warehouse: IntIdInput
}

export type SetEamSparePartWarehouseManager = {
  manager?: InputMaybe<Array<IdInput>>
  warehouse?: InputMaybe<IntIdInput>
}

export type SetEvasionConfigInput = {
  date?: InputMaybe<Array<EvasionDateInput>>
  dayOfWeek?: InputMaybe<Array<Scalars['Int']>>
}

export type SetFeaturePackInput = {
  featurePack: StringIdInput
  permissions?: InputMaybe<Array<StringIdInput>>
  resourceUnitConfigs?: InputMaybe<Array<ResourceUnitConfigInput>>
  rulePointConfigs?: InputMaybe<Array<RulePointConfigInput>>
}

export type SetFeaturePackSubConfigInput = {
  fields: Array<FeaturePackSubConfigFieldInput>
  subscriptions: Array<FeaturePackSubConfigSubscriptionInput>
}

export type SetFormThingCategoryInput = {
  eamForm: IntIdInput
  option?: InputMaybe<SetOption>
  thingCategory: Array<IntIdInput>
}

export type SetLoginConfigToMyTenantInput = {
  defaultMode: LoginMode
  modes: Array<LoginMode>
}

export type SetLoginModesToTenantInput = {
  modes: Array<LoginMode>
  tenant: StringIdInput
}

export type SetMyTenantLoginBackgrounds = {
  image: Array<IdInput>
}

export type SetMyTenantPasswordConfigInput = {
  /** 密码策略规则 */
  characterSet: Array<PasswordCharacterSet>
  /** 自定义初始密码 */
  customInitialPassword?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 初始密码 */
  initialPasswordMode: InitialPasswordMode
  /** 初始密码规则 */
  initialPasswordRule?: InputMaybe<InitialPasswordRule>
  /** 密码长度 */
  length: Scalars['Int']
}

export type SetMyTenantStaffInfoConfig = {
  /** 邮箱必填 */
  emailRequired: Scalars['Boolean']
  id: Scalars['String']
  /** 手机号码必填 */
  phoneNumberRequired: Scalars['Boolean']
}

export type SetOption = 'ADD' | 'REPLACE'

export type SetOrganizationLevelConfigInput = {
  tenantId: Scalars['String']
  useTreeLevel: Scalars['Boolean']
}

export type SetOrganizationRoleConfigInput = {
  organization: StringIdInput
  roleItems: Array<OrganizationRoleItemInput>
}

export type SetOrganizationUsersInput = {
  organizationId: Scalars['String']
  staffIds: Array<Scalars['String']>
}

export type SetOrganizationsLevelInput = {
  id: Scalars['String']
  level?: InputMaybe<Scalars['Int']>
}

export type SetOutsideCalibrateThingCalibrateInput = {
  outsideCalibrate: IdInput
  thingCalibrate: Array<IdInput>
}

export type SetPermissionToFeaturePackInput = {
  featurePack: StringIdInput
  permissions: Array<StringIdInput>
}

export type SetPermissionToTenantInput = {
  permissions: Array<StringIdInput>
  tenant: StringIdInput
}

export type SetProcessDefinitionInput = {
  /** 节点图关系配置 */
  diagramConfig: ProcessDefinitionDiagramConfigInput
  /** 流程定义唯一标识 */
  key: Scalars['String']
  /** 流程定义名称 */
  name: Scalars['String']
  /** 节点定义配置 */
  nodeConfig: ProcessDefinitionActivityConfigInput
  /** 流程定义负责人 */
  principal?: InputMaybe<IdInput>
}

export type SetRoleAccountInput = {
  accountIds: Array<Scalars['String']>
  roleId: Scalars['String']
}

/**
 * 为一个部门关联 N 个设备组
 * departmentTree: 部门树
 * department: 当前被选中的部门
 */
export type SetSingleDepartmentThingGroups = {
  department: IdInput
  departmentTree: Scalars['JSONString']
  thingGroups: Array<IdInput>
}

/** 将多个设备组关联给 1个用户 */
export type SetSingleUserThingGroupsInput = {
  thingGroups: Array<IdInput>
  user: IdInput
}

export type SetSparePartStockConfigurationInput = {
  role?: InputMaybe<Array<StringIdInput>>
  scope: SparePartStockScope
}

export type SetSparePartThingInput = {
  sparePart: IntIdInput
  thing: Array<IdInput>
}

export type SetSparePartWorkflowConfigurationInput = {
  underConfirm: SparePartSwitchInput
  underIssue: SparePartSwitchInput
  underReview: SparePartReviewInput
}

export type SetSsoConfigurationInput = {
  appId: Scalars['String']
  callbackUrls: Array<Scalars['String']>
  protocol: SsoProtocol
  scopes: Array<SsoScope>
}

export type SetStaffPositionRoleConfigInput = {
  position: StringIdInput
  roles: Array<StringIdInput>
}

export type SetStaffToDynamicBusinessStaffGroupBlackAndWhiteInput = {
  blackStaffs: Array<StringIdInput>
  businessStaffGroupId: Scalars['String']
  whiteStaffs: Array<StringIdInput>
}

export type SetSubThingInput = {
  subThing: Array<IdInput>
  thing: IdInput
}

export type SetTableColumnSettingInput = {
  columns: Array<TableColumnInput>
  key: Scalars['String']
}

export type SetThemeNavigationBarInput = {
  /** 欢迎词 */
  greetings?: InputMaybe<Scalars['String']>
  hideLogo?: InputMaybe<Scalars['Boolean']>
  loginBackground?: InputMaybe<IdInput>
  /** 企业 logo */
  logo?: InputMaybe<IdInput>
  /** 平台名称 */
  platformName?: InputMaybe<Scalars['String']>
}

export type SetThemeWaterMarkInput = {
  /** 水印所有内容 */
  content: Array<WatermarkContent>
  /** 水印密度 */
  density: Density
  /** 水印方向 */
  direction: WatermarkDirection
  /** 水印字体大小 */
  fontSize: FontSize
  /** 水印范围 */
  range: WatermarkRangeInput
  /** 水印被选择的内容 */
  selectedContent: Array<WatermarkContent>
}

export type SetThingAbortWorkflowConfigurationPrincipalInput = {
  principal: StringIdInput
}

export type SetThingAdministratorDepartmentInput = {
  administrator?: InputMaybe<Array<StringIdInput>>
  department: IdInput
}

export type SetThingApplyWorkflowConfigurationPrincipalInput = {
  principal: IdInput
}

export type SetThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  /** 申请人 */
  borrower: IdInput
  departmentOfApplicant: IdInput
  expected?: InputMaybe<Scalars['TimestampRange']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  reason?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type SetThingBorrowOtherConfigurationInput = {
  attachmentHint?: InputMaybe<Scalars['String']>
  locationRequired?: InputMaybe<Scalars['Boolean']>
}

export type SetThingBorrowRangeConfigurationInput = {
  exclude?: InputMaybe<Array<IdInput>>
  filterRange?: InputMaybe<Array<Array<ThingBorrowRangeInput>>>
  include?: InputMaybe<Array<IdInput>>
  /** 配置范围条件 */
  option: ThingBorrowRangeOption
}

export type SetThingBorrowWorkflowConfigurationInput = {
  borrowEvaluation: ThingBorrowEvaluationInput
  extendUnderReviewByApplyFor: ThingBorrowReviewInput
  extendUnderReviewByBorrowed: ThingBorrowReviewInput
  lostConfirmation: ThingBorrowLostConfirmationInput
  pending: ThingBorrowPendingInput
  returnEvaluation: ThingBorrowReturnEvaluationInput
  underReviewByApplyFor: ThingBorrowReviewInput
  underReviewByBorrowed: ThingBorrowReviewInput
}

export type SetThingCalibrateInput = {
  byThingRepair?: InputMaybe<Scalars['Boolean']>
}

export type SetThingCalibrateRangeConfigurationInput = {
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type SetThingCalibrateWorkflowConfigurationInput = {
  issue?: InputMaybe<ThingCalibrateIssueInput>
  pending: ThingCalibratePendingInput
  underReview: ThingCalibrateReviewInput
}

export type SetThingDepartmentInput = {
  department: Array<IdInput>
}

export type SetThingFoundWorkflowConfigurationPrincipalInput = {
  principal: StringIdInput
}

export type SetThingInspectionOtherConfigurationInput = {
  allowInterTeam?: InputMaybe<Scalars['Boolean']>
}

export type SetThingInspectionWorkflowConfigurationInput = {
  /** 关闭验收，需要设置`acceptance: null` */
  inspectingAcceptance: ThingInspectionAcceptanceInput
  inspectingExecute: ThingInspectionInspectingExecuteInput
  pending: ThingInspectionPendingInput
}

export type SetThingInventoryRecordInput = {
  id: Scalars['Int']
  image?: InputMaybe<Array<IdInput>>
  isBroadcast?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Array<ThingInventoryLabel>>
  remark?: InputMaybe<Scalars['String']>
  state: ThingInventoryState
}

export type SetThingMaintenanceOtherConfigurationInput = {
  allowInterTeam?: InputMaybe<Scalars['Boolean']>
}

export type SetThingMaintenanceWorkflowConfigurationInput = {
  maintainingExecute: ThingMaintenanceExecuteInput
  maintainingUnderReview: ThingMaintenanceReviewInput
  pending: ThingMaintenancePendingInput
}

export type SetThingMissingWorkflowConfigurationPrincipalInput = {
  principal: StringIdInput
}

export type SetThingMoveWorkflowConfigurationPrincipalInput = {
  principal: IdInput
}

export type SetThingOffLeaseWorkflowConfigurationPrincipalInput = {
  principal: StringIdInput
}

export type SetThingRentBackWorkflowConfigurationPrincipalInput = {
  principal: IdInput
}

export type SetThingRentWorkflowConfigurationPrincipalInput = {
  principal: IdInput
}

export type SetThingRepairInput = {
  /** 附件 */
  attachment?: InputMaybe<Array<IdInput>>
  /** 故障描述 */
  description: Scalars['String']
  /** 要求完成时间 */
  estimatedAt?: InputMaybe<Scalars['Timestamp']>
  /** 故障发现时间 */
  foundAt: Scalars['Timestamp']
  id?: InputMaybe<Scalars['Int']>
  /** 故障影响 */
  influence?: InputMaybe<Scalars['String']>
  /** 执行人员 */
  operator?: InputMaybe<IdInput>
  /** 执行班组 */
  team?: InputMaybe<IntIdInput>
  /** 维修资产 */
  thing: IdInput
  /** 关联工单 */
  thingCalibrate?: InputMaybe<IntIdInput>
  thingInspection?: InputMaybe<IntIdInput>
  thingInventory?: InputMaybe<IntIdInput>
  thingInventoryTrack?: InputMaybe<IntIdInput>
  thingMaintenance?: InputMaybe<IntIdInput>
}

export type SetThingRepairRuleDefaultInput = {
  defaultDispatch: ThingRepairDispatchDefault
  teamMember?: InputMaybe<IntIdInput>
}

export type SetThingRepairWorkflowConfigurationInput = {
  pending: ThingRepairPendingInput
  repairAssessment: ThingRepairAssessmentInput
  repairCheck: ThingRepairCheckInput
  repairExecute: ThingRepairExecuteInput
  repairUnderReview: ThingRepairUnderReviewInput
}

export type SetThingReturnDepartmentManagerInput = {
  department: IdInput
  id: Scalars['Int']
  manager: IdInput
}

export type SetThingReturnWorkflowConfigurationPrincipalInput = {
  principal: IdInput
}

export type SetThingSaleWorkflowConfigurationPrincipalInput = {
  principal: StringIdInput
}

export type SetThingSparePartInput = {
  sparePart: Array<IntIdInput>
  thing: IdInput
}

export type SetUccStackDataInput = {
  company?: InputMaybe<IdInput>
  data?: InputMaybe<Scalars['JSON']>
  key: Scalars['String']
}

/** 将一个设备组关联给 N 个用户 */
export type SetUserThingGroupInput = {
  thingGroup: IdInput
  users: Array<IdInput>
}

export type SignUpCompanyTenantInput = {
  city?: InputMaybe<StringIdInput>
  /** 企业名称 */
  code: Scalars['String']
  /** 企业联系人 */
  contact: Scalars['String']
  county?: InputMaybe<StringIdInput>
  /** 企业所属行业 */
  industry?: InputMaybe<StringIdInput>
  /** 企业名称 */
  name: Scalars['String']
  /** 手机号码 */
  phone: Scalars['String']
  province?: InputMaybe<StringIdInput>
  /** 验证码 */
  verifyCode: Scalars['String']
}

export type SparePartClaimItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
  warehouse: IntIdInput
}

export type SparePartClaimListFilterInput = {
  createdBy?: InputMaybe<Array<IdInput>>
  department?: InputMaybe<Array<IdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  reason?: InputMaybe<Array<SparePartClaimReason>>
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<SparePartClaimStatus>>
  /** 关联工单 */
  thingMaintenance?: InputMaybe<Array<IntIdInput>>
  thingRepair?: InputMaybe<Array<IntIdInput>>
  ticketView?: InputMaybe<TicketView>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
  /** 备件可用数量>0 */
  usable?: InputMaybe<Scalars['Boolean']>
  warehouse?: InputMaybe<Array<IntIdInput>>
}

/** 备件申领：提交，撤销，打回，通过，发放，取消，确认, 记录, 转派, 退还 */
export type SparePartClaimOperatorType =
  | 'APPROVE'
  | 'CANCEL'
  | 'CONFIRM'
  | 'ISSUE'
  | 'RECORD'
  | 'REJECT'
  | 'SEND_BACK'
  | 'SUBMIT'
  | 'TURN_TO'
  | 'WITHDRAW'

/**
 * MAINTENANCE 保养
 * REPAIR 维修
 * INSPECTION 巡检
 * UPGRADE 升级
 * OTHER 其他
 */
export type SparePartClaimReason =
  | 'INSPECTION'
  | 'MAINTENANCE'
  | 'OTHER'
  | 'REPAIR'
  | 'UPGRADE'

export type SparePartClaimReportByApplicantFilter = {
  applicant?: InputMaybe<Array<StringIdInput>>
  department?: InputMaybe<Array<StringIdInput>>
  queryAt: Scalars['TimestampRange']
}

export type SparePartClaimReportFilter = {
  queryAt: Scalars['TimestampRange']
  sparePartCategory?: InputMaybe<Array<IntIdInput>>
}

export type SparePartClaimReturnRecordListFilterInput = {
  id?: InputMaybe<Scalars['Int']>
}

/** 申领、审核、出库、确认、申领完成、申领撤销 */
export type SparePartClaimState =
  | 'COMPLETE'
  | 'ISSUING'
  | 'PENDING'
  | 'UNDER_CONFIRM'
  | 'UNDER_REVIEW'
  | 'WITHDRAWED'

/** 备件申领状态：待提交，待审核，待发放，发放中，待确认，已完成，已撤销 */
export type SparePartClaimStatus =
  | 'COMPLETED'
  | 'ISSUING'
  | 'PENDING'
  | 'UNDER_CONFIRM'
  | 'UNDER_ISSUE'
  | 'UNDER_REVIEW'
  | 'WITHDRAWED'

export type SparePartDefaultReviewOperate = 'AUTO_APPROVE' | 'SPECIFIC'

export type SparePartFilterInput = {
  /** 备件类别 */
  category?: InputMaybe<Array<IntIdInput>>
  exclude?: InputMaybe<Array<IdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  /** 仅超出安全范围的备件，null不做筛选 */
  onlyUnsafeInventory?: InputMaybe<Scalars['Boolean']>
  /** 搜索: 备件编号、备件名称、型号、规格 */
  search?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<Array<Scalars['ID']>>
  /** 仓库 */
  warehouse?: InputMaybe<Array<IntIdInput>>
}

export type SparePartOfThingFilterInput = {
  /** 备件类别 */
  category?: InputMaybe<Array<IntIdInput>>
  /** 搜索: 备件编号、备件名称、型号、规格 */
  search?: InputMaybe<Scalars['String']>
}

export type SparePartOutboundFilterInput = {
  claim?: InputMaybe<Array<IntIdInput>>
  createdBy?: InputMaybe<Array<IdInput>>
  dataRangeLimit?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Array<Scalars['Int']>>
  kind?: InputMaybe<Array<SparePartOutboundKind>>
  /** 搜索项：出库单编号、备件编码、备件名称、申领人、经办人 */
  search?: InputMaybe<Scalars['String']>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
  warehouse?: InputMaybe<Array<IntIdInput>>
}

export type SparePartOutboundItemFilterInput = {
  sparePartOutbound: IntIdInput
}

export type SparePartOutboundKind = 'CLAIM' | 'OTHER' | 'TRANSFER'

export type SparePartOutboundSummaryFilterInput = {
  thingMaintenance?: InputMaybe<Array<IntIdInput>>
  thingRepair?: InputMaybe<Array<IntIdInput>>
}

export type SparePartOutboundWriteoffFilterInput = {
  outbound: IntIdInput
}

export type SparePartOutboundWriteoffItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type SparePartReceiptFilterInput = {
  createdBy?: InputMaybe<Array<IdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  kind?: InputMaybe<Array<SparePartReceiptKind>>
  /** 搜索项：入库单编号、备件编码、备件名称、经办人 */
  search?: InputMaybe<Scalars['String']>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
  warehouse?: InputMaybe<Array<IntIdInput>>
}

export type SparePartReceiptItemFilterInput = {
  receipt: IntIdInput
  /** 备件编码、备件名称 */
  search?: InputMaybe<Scalars['String']>
}

export type SparePartReceiptKind = 'OTHER' | 'PURCHASE' | 'RETURN' | 'TRANSFER'

export type SparePartReceiptWriteoffFilterInput = {
  receipt: IntIdInput
}

export type SparePartReceiptWriteoffItemInput = {
  quantity: Scalars['Int']
  sparePart: IntIdInput
}

export type SparePartReportFilter = {
  sparePartCategory?: InputMaybe<Array<IntIdInput>>
}

export type SparePartReviewInput = {
  absoluteLevel?: InputMaybe<EamWorkflowReviewerAbsoluteLevel>
  assignee?: InputMaybe<Array<IdInput>>
  defaultReviewOperate?: InputMaybe<SparePartDefaultReviewOperate>
  enabled: Scalars['Boolean']
  method?: InputMaybe<EamWorkflowReferMethod>
  relativeLevel?: InputMaybe<EamWorkflowReviewerRelativeLevel>
}

export type SparePartRoleRule = 'ALL' | 'ASSIGN' | 'FOLLOW'

/** date: 出入库时间 */
export type SparePartStockRecordFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  date?: InputMaybe<Scalars['TimestampRange']>
  sparePart?: InputMaybe<Array<IntIdInput>>
  type?: InputMaybe<Array<SparePartStockRecordType>>
  warehouse?: InputMaybe<Array<IntIdInput>>
}

export type SparePartStockRecordType = 'OUTBOUND' | 'RECEIPT'

export type SparePartStockScope = 'ALL' | 'SPECIFIC'

export type SparePartSwitchInput = {
  enabled: Scalars['Boolean']
}

export type SparePartThingFilterInput = {
  excludeThing?: InputMaybe<Array<IdInput>>
  /** 配合qualifiedParent使用 */
  includeSubThing?: InputMaybe<Array<IdInput>>
  qualifiedParent: IdInput
  /** 搜索: 设备名称、设备编号、规格、型号 */
  search?: InputMaybe<Scalars['String']>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type SparePartTransferFilterInput = {
  createdBy?: InputMaybe<Array<IdInput>>
  export?: InputMaybe<Array<IntIdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  import?: InputMaybe<Array<IntIdInput>>
  /** 搜索项：调拨单编号、备件编码、备件名称、经办人 */
  search?: InputMaybe<Scalars['String']>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
}

export type SparePartTransferItemFilterInput = {
  transfer: IntIdInput
}

export type SparePartUsageRecordFilterInput = {
  claim?: InputMaybe<IdInput>
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  thing?: InputMaybe<IdInput>
}

export type SsoProtocol = 'OAUTH2' | 'OIDC'

export type SsoScope = 'EMAIL' | 'OPENID' | 'PHONE' | 'PROFILE'

export type StaffDynamicRuleAction = 'BELONG_TO' | 'NOT_BELONGING_TO'

export type StaffFilterOfStaffGroup = {
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索：姓名，工号 */
  search?: InputMaybe<Scalars['String']>
}

export type StaffGroupFilter = {
  apps?: InputMaybe<Array<StringIdInput>>
  isDesignatedStaffsEnabled?: InputMaybe<Scalars['Boolean']>
  isEnabled?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索：名称 */
  search?: InputMaybe<Scalars['String']>
}

export type StaffJobStatus = 'ON_THE_JOB' | 'RESIGNED'

export type StaffJobType =
  | 'EXTERNAL'
  | 'FORMAL'
  | 'INFORMAL'
  | 'PUBLIC'
  | 'ROBOT'

export type StaffJobTypeDynamicRuleInput = {
  action: StaffDynamicRuleAction
  jobTypes: Array<StaffJobType>
}

export type StaffListFilterInput = {
  /** 按业务成员组筛选 */
  businessStaffGroups?: InputMaybe<Array<StringIdInput>>
  /** 需要排除的 staff id */
  exclude?: InputMaybe<Array<IdInput>>
  /** 排除 account.is_admin is true 的 staff */
  excludeAdmin?: InputMaybe<Scalars['Boolean']>
  /** 排除所谓这些app的管理员 */
  excludeAppAdmin?: InputMaybe<Array<StringIdInput>>
  /** 排除已在当前成员组的人 */
  excludeBusinessStaffGroups?: InputMaybe<Array<StringIdInput>>
  /** 排除成员组中的指定成员，input 成员组 */
  excludeDesignatedStaffsOfStaffGroups?: InputMaybe<Array<StringIdInput>>
  /** 有账号的 staff */
  hasAccount?: InputMaybe<Scalars['Boolean']>
  ids?: InputMaybe<Array<Scalars['String']>>
  includeChildrenOrganizations?: InputMaybe<Scalars['Boolean']>
  isAllowedToLogin?: InputMaybe<Scalars['Boolean']>
  jobStatus?: InputMaybe<Array<StaffJobStatus>>
  jobType?: InputMaybe<Array<StaffJobType>>
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 按岗位筛选 */
  positions?: InputMaybe<Array<StringIdInput>>
  resignedAt?: InputMaybe<Scalars['TimestampRange']>
  /** 角色 */
  role?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  staffGroup?: InputMaybe<Array<StringIdInput>>
}

export type StaffPositionListFilterInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  /** 模糊查询: 岗位名称 */
  search?: InputMaybe<Scalars['String']>
  systemPresetCode?: InputMaybe<Scalars['String']>
}

export type StaffPositionRoleConfigInput = {
  position: Scalars['String']
}

export type StarAppInput = {
  app: StringIdInput
}

export type StartThingAbortInput = {
  abortAt: Scalars['Timestamp']
  attachment?: InputMaybe<Array<IdInput>>
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type StartThingApplyInput = {
  applicant: IdInput
  applyAt: Scalars['Timestamp']
  applyOfDepartment: IdInput
  attachment?: InputMaybe<Array<IdInput>>
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type StartThingOffLeaseInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  offLeaseAt: Scalars['Timestamp']
  remark?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type StartThingReturnInput = {
  attachment?: InputMaybe<Array<IdInput>>
  remark?: InputMaybe<Scalars['String']>
  returnAt: Scalars['Timestamp']
  thing: Array<IdInput>
}

export type StartThingSaleInput = {
  attachment?: InputMaybe<Array<IntIdInput>>
  reason: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  saleAt: Scalars['Timestamp']
  saleTo?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type StringIdInput = {
  id: Scalars['String']
}

export type SubThingListFilterInput = {
  thing?: InputMaybe<Array<IdInput>>
}

/** `search`: 用户账号/资源名称 */
export type SystemLogFilterInput = {
  action?: InputMaybe<Array<Scalars['String']>>
  company?: InputMaybe<IdInput>
  end: Scalars['Timestamp']
  search?: InputMaybe<Scalars['String']>
  start: Scalars['Timestamp']
}

export type SystemTaskListFilterInput = {
  /** 标题模糊搜索 */
  search?: InputMaybe<Scalars['String']>
}

export type SystemTaskStatus = 'FAILED' | 'FINISHED' | 'PENDING'

export type TableColumnInput = {
  disabled?: InputMaybe<Scalars['Boolean']>
  fixed?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  unchecked?: InputMaybe<Scalars['Boolean']>
  width?: InputMaybe<Scalars['Float']>
}

export type TableFilterFieldInput = {
  active: Scalars['Boolean']
  id: Scalars['String']
}

export type TenantAnnouncementConfigListFilterInput = {
  /** 是否是草稿 */
  isDraft?: InputMaybe<Scalars['Boolean']>
  /** 是否失效：转换为ExpiredAtRange */
  isExpired?: InputMaybe<Scalars['Boolean']>
  /** 发布时间范围 */
  publishAtRange?: InputMaybe<Scalars['TimestampRange']>
  /** 公告标题 */
  search?: InputMaybe<Scalars['String']>
}

export type TenantAnnouncementVisibleRange =
  /** 所有人 */
  | 'ALL'
  /** 指定组织 */
  | 'DESIGNATED_ORGANIZATION'
  /** 指定角色 */
  | 'DESIGNATED_ROLE'
  /** 指定人员 */
  | 'DESIGNATED_STAFF'

export type TenantAppListFilterInput = {
  /** 模糊搜索：名称 */
  search?: InputMaybe<Scalars['String']>
  /** 租户 */
  tenant: StringIdInput
}

export type TenantCertificationStatus =
  | 'CERTIFIED'
  | 'PENDING'
  | 'REJECTED'
  | 'UNCERTIFIED'

export type TenantFilterInput = {
  certificationStatus?: InputMaybe<Array<TenantCertificationStatus>>
  cityIds?: InputMaybe<Array<Scalars['String']>>
  countyIds?: InputMaybe<Array<Scalars['String']>>
  excludeIds?: InputMaybe<Array<Scalars['String']>>
  featurePacks?: InputMaybe<Array<StringIdInput>>
  ids?: InputMaybe<Array<Scalars['String']>>
  industryIds?: InputMaybe<Array<Scalars['String']>>
  provinceIds?: InputMaybe<Array<Scalars['String']>>
  /** 搜索企业名称 */
  search?: InputMaybe<Scalars['String']>
}

export type TenantIndustryTreeNodeFilterInput = {
  /** 搜索行业名称 */
  search?: InputMaybe<Scalars['String']>
}

export type TenantOwner = {
  account: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  password: Scalars['String']
  phone_number?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
}

export type TenantPlaceListFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  /** 模糊查询: 场所名称 */
  search?: InputMaybe<Scalars['String']>
}

export type TenantStatus =
  /** 禁用 */
  | 'DISABLED'
  /** 正常 */
  | 'ENABLED'

export type TenantType =
  /** 公司 */
  | 'COMPANY'
  /** 平台 */
  | 'PLATFORM'

export type TerminateProcessInstanceInput = {
  id: Scalars['String']
  reason: Scalars['String']
}

export type ThingAbortListFilterInput = {
  abortAt?: InputMaybe<Scalars['TimestampRange']>
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  /** 模糊搜索退库单编号 */
  search?: InputMaybe<Scalars['String']>
  ticketView?: InputMaybe<TicketView>
}

export type ThingAbortWorkflowConfigurationInput = {
  review: ThingAbortWorkflowReviewConfigurationInput
}

export type ThingAbortWorkflowReviewConfigurationInput = {
  enable: Scalars['Boolean']
}

/** 固定资产、低值易耗品 */
export type ThingAccountType = 'FIXED' | 'LOW_VALUE_CONSUMABLES'

export type ThingAdministratorListFilterInput = {
  department: IdInput
}

export type ThingApplyListFilterInput = {
  applicant?: InputMaybe<Array<IdInput>>
  applyAt?: InputMaybe<Scalars['TimestampRange']>
  applyOfDepartment?: InputMaybe<Array<IdInput>>
  applyType?: InputMaybe<Array<ThingApplyType>>
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  onDuty?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索领用单编号 */
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingApplyState>>
  status?: InputMaybe<Array<ThingApplyStatus>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingApplyState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingApplyStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingApplyType = 'APPLY' | 'ASSIGN'

export type ThingApplyWorkflowConfigurationInput = {
  review: ThingApplyWorkflowReviewConfigurationInput
}

export type ThingApplyWorkflowReviewConfigurationInput = {
  conf?: InputMaybe<IdInput>
  enable?: InputMaybe<Scalars['Boolean']>
}

export type ThingAreaLevel = 'CURRENT' | 'FIRST' | 'SECOND'

export type ThingAreaListFilterInput = {
  depth?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Array<Scalars['Int']>>
  /** 模糊搜索： 名称 */
  search?: InputMaybe<Scalars['String']>
}

export type ThingBarLabelListFilterInput = {
  /** 类型筛选 */
  printObjectValue?: InputMaybe<EamBarCodePrintObjectValue>
}

export type ThingBorrowEvaluationInput = {
  assignee?: InputMaybe<IdInput>
  defaultReviewOperate?: InputMaybe<EamWorkflowDefaultReviewOperate>
  enabled: Scalars['Boolean']
  rule?: InputMaybe<Array<ThingBorrowEvaluationRuleInput>>
}

export type ThingBorrowEvaluationRuleInput = {
  assignee: Array<IdInput>
  department: Array<IdInput>
  name: Scalars['String']
  thingCategory: Array<IntIdInput>
}

export type ThingBorrowListFilterInput = {
  applicant?: InputMaybe<Array<IdInput>>
  borrowEndAt?: InputMaybe<Scalars['Timestamp']>
  /** 借用开始时间筛选 */
  borrowStartAt?: InputMaybe<Scalars['Timestamp']>
  borrower?: InputMaybe<Array<IdInput>>
  /** 申请部门和人进行筛选 */
  departmentOfApplicant?: InputMaybe<Array<IdInput>>
  departmentOfManager?: InputMaybe<Array<IdInput>>
  endAt?: InputMaybe<Scalars['Timestamp']>
  id?: InputMaybe<Array<Scalars['Int']>>
  operator?: InputMaybe<Array<IdInput>>
  queryTimeRange?: InputMaybe<Scalars['TimestampRange']>
  returnEndAt?: InputMaybe<Scalars['Timestamp']>
  /** 借用结束时间筛选 */
  returnStartAt?: InputMaybe<Scalars['Timestamp']>
  /** 借用单编号 */
  search?: InputMaybe<Scalars['String']>
  /** 创建时间筛选 */
  startAt?: InputMaybe<Scalars['Timestamp']>
  state?: InputMaybe<Array<ThingBorrowState>>
  status?: InputMaybe<Array<ThingBorrowStatus>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingBorrowLostConfirmationInput = {
  enabled: Scalars['Boolean']
}

/**
 * 资产借用：提交，通过，打回，撤销，鉴定，更换，合格，不合格，确认借用，发起归还，确认归还，报失，确认遗失，找回
 * 转派
 */
export type ThingBorrowOperatorType =
  | 'APPROVE'
  | 'BORROW_CONFIRMATION'
  | 'EVALUATION'
  | 'EXTEND'
  | 'FOUND'
  | 'LOST_CONFIRMATION'
  | 'LOST_REPORT'
  | 'QUALIFIED'
  | 'REJECT'
  | 'REPLACE'
  | 'RETURN_CONFIRMATION'
  | 'RETURN_REPORT'
  | 'SUBMIT'
  | 'TURN_TO'
  | 'UNQUALIFIED'
  | 'WITHDRAW'

export type ThingBorrowPendingInput = {
  /** 允许代他人发起借用 */
  canAgency: Scalars['Boolean']
  /** 允许多人对单个资产发起借用 */
  isMultiple: Scalars['Boolean']
}

export type ThingBorrowRangeInput = {
  accountType?: InputMaybe<Array<ThingAccountType>>
  accountingDepartment?: InputMaybe<Array<IdInput>>
  area?: InputMaybe<Array<IntIdInput>>
  /** 布尔类型范围值 */
  booleanValues?: InputMaybe<Array<Scalars['Boolean']>>
  calibrateMethod?: InputMaybe<Array<CalibrateMethod>>
  calibrateResult?: InputMaybe<Array<CalibrateResult>>
  canBorrowed?: InputMaybe<Array<Scalars['Boolean']>>
  canCalibrate?: InputMaybe<Array<Scalars['Boolean']>>
  category?: InputMaybe<Array<IntIdInput>>
  codePrefix?: InputMaybe<Array<CodePrefix>>
  department?: InputMaybe<Array<IdInput>>
  /** 选择字段 */
  field: IntIdInput
  isCalibrationExpired?: InputMaybe<Array<Scalars['Boolean']>>
  isCompleteFile?: InputMaybe<Array<Scalars['Boolean']>>
  isLent?: InputMaybe<Array<Scalars['Boolean']>>
  manager?: InputMaybe<Array<StringIdInput>>
  onState?: InputMaybe<Array<OnState>>
  /** 包含/不包含 */
  operate: ThingBorrowRangeOperate
  performanceStatus?: InputMaybe<Array<ThingPerformanceStatus>>
  /** 字符串类型范围值 */
  stringValues?: InputMaybe<Array<Scalars['String']>>
}

export type ThingBorrowRangeOperate = 'IN' | 'NOT_IN'

export type ThingBorrowRangeOption = 'ALL' | 'SPECIFIED'

export type ThingBorrowReportByDepartmentFilter = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  queryAt: Scalars['TimestampRange']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingBorrowReportFilter = {
  department?: InputMaybe<Array<StringIdInput>>
  queryAt: Scalars['TimestampRange']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingBorrowReturnEvaluationInput = {
  assignee?: InputMaybe<IdInput>
  defaultReviewOperate?: InputMaybe<EamWorkflowDefaultReviewOperate>
  enabled: Scalars['Boolean']
  rule?: InputMaybe<Array<ThingBorrowEvaluationRuleInput>>
  /** 使用借用鉴定分派规则 */
  useBorrowEvaluation?: InputMaybe<Scalars['Boolean']>
}

export type ThingBorrowReviewInput = {
  absoluteLevel?: InputMaybe<EamWorkflowReviewerAbsoluteLevel>
  assignee?: InputMaybe<Array<IdInput>>
  defaultReviewOperate?: InputMaybe<EamWorkflowDefaultReviewOperate>
  enabled: Scalars['Boolean']
  method?: InputMaybe<EamWorkflowReferMethod>
  /** 处理人包含上一节点经办人时自动通过 */
  quickApprove?: InputMaybe<Scalars['Boolean']>
  relativeLevel?: InputMaybe<EamWorkflowReviewerRelativeLevel>
  reviewer?: InputMaybe<EamWorkflowReviewer>
}

/**
 * 资产借用节点
 * PENDING: 借用开始
 * UNDER_REVIEW_BY_APPLY_FOR: 申请部门审核
 * UNDER_REVIEW_BY_BORROWED: 借出部门审核
 * UNDER_REVIEW_BY_KEEPER: 保管人审核
 * BORROW_EVALUATION: 借用鉴定
 * BORROW_CONFIRMATION: 借用确认
 * LENT: 借用中
 * RETURN_EVALUATION: 归还鉴定
 * RETURN_CONFIRMATION: 归还确认
 * LOST_CONFIRMATION: 遗失确认
 * FINISHED_WITHDRAWED: 借用结束（撤销）
 * FINISHED_RETURNED: 借用结束（已归还）
 * FINISHED_LOST: 借用结束（遗失）
 * EXTEND_UNDER_REVIEW_BY_APPLY_FOR: 申请部门延期审核
 * EXTEND_UNDER_REVIEW_BY_BORROWED: 借出部门延期审核
 * EXTEND_UNDER_REVIEW_BY_KEEPER: 保管人延期审核
 */
export type ThingBorrowState =
  | 'BORROW_CONFIRMATION'
  | 'BORROW_EVALUATION'
  | 'EXTEND_UNDER_REVIEW_BY_APPLY_FOR'
  | 'EXTEND_UNDER_REVIEW_BY_BORROWED'
  | 'EXTEND_UNDER_REVIEW_BY_KEEPER'
  | 'FINISHED_LOST'
  | 'FINISHED_RETURNED'
  | 'FINISHED_WITHDRAWED'
  | 'LENT'
  | 'LOST_CONFIRMATION'
  | 'PENDING'
  | 'RETURN_CONFIRMATION'
  | 'RETURN_EVALUATION'
  | 'UNDER_REVIEW_BY_APPLY_FOR'
  | 'UNDER_REVIEW_BY_BORROWED'
  | 'UNDER_REVIEW_BY_KEEPER'

/** 资产借用状态：待提交，审核中，借用中，已归还，撤销，遗失 */
export type ThingBorrowStatus =
  | 'LENT'
  | 'LOST'
  | 'PENDING'
  | 'RETURNED'
  | 'UNDER_REVIEW'
  | 'WITHDRAWED'

/** 资产借用：提交，通过，打回， */
export type ThingBorrowTrigger =
  | 'APPROVE'
  | 'REJECT'
  | 'SAVE'
  | 'SUBMIT'
  | 'WITHDRAW'

export type ThingCalibrateIssueInput = {
  assignee?: InputMaybe<Array<IdInput>>
}

export type ThingCalibrateListFilterInput = {
  calibrate?: InputMaybe<Scalars['TimestampRange']>
  calibrateOrganization?: InputMaybe<Array<IdInput>>
  /** 模糊搜索校准编码 */
  code?: InputMaybe<Scalars['String']>
  deadline?: InputMaybe<Scalars['TimestampRange']>
  department?: InputMaybe<Array<IdInput>>
  excludeThingCalibrate?: InputMaybe<Array<IntIdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  manager?: InputMaybe<Array<StringIdInput>>
  method?: InputMaybe<Array<CalibrateMethod>>
  operator?: InputMaybe<Array<IdInput>>
  reason?: InputMaybe<Array<CalibrateReason>>
  result?: InputMaybe<Array<CalibrateResult>>
  /** 模糊搜索：资产编码、名称、规格型号、校准编码 */
  search?: InputMaybe<Scalars['String']>
  sendAt?: InputMaybe<Scalars['TimestampRange']>
  status?: InputMaybe<Array<ThingCalibrateStatus>>
  thing?: InputMaybe<Array<IdInput>>
  ticketView?: InputMaybe<TicketView>
  /** 未关联其他外交对账单 */
  withoutOutsideCalibrate?: InputMaybe<Scalars['Boolean']>
}

export type ThingCalibrateListOfThingFilterInput = {
  calibrate?: InputMaybe<Scalars['TimestampRange']>
  /** 模糊搜索校准编码 */
  code?: InputMaybe<Scalars['String']>
  method?: InputMaybe<Array<CalibrateMethod>>
  reason?: InputMaybe<Array<CalibrateReason>>
  result?: InputMaybe<Array<CalibrateResult>>
  status?: InputMaybe<Array<ThingCalibrateStatus>>
}

export type ThingCalibrateOperatorListFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  staff?: InputMaybe<Array<StringIdInput>>
}

/** 资产校验：发起，提交，记录，通过，打回，转派，报修，撤销 */
export type ThingCalibrateOperatorType =
  | 'APPROVE'
  | 'ISSUE'
  | 'RECORD'
  | 'REJECT'
  | 'REPAIR'
  | 'SUBMIT'
  | 'TURNTO'
  | 'TURN_TO_PRINCIPAL'
  | 'WITHDRAW'

export type ThingCalibratePendingInput = {
  assignee?: InputMaybe<Array<IdInput>>
}

export type ThingCalibrateRelateResourceFilterInput = {
  status?: InputMaybe<Array<ThingCalibrateStatus>>
}

export type ThingCalibrateReportByDepartmentFilter = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  queryAt: Scalars['TimestampRange']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingCalibrateReportFilter = {
  department?: InputMaybe<Array<StringIdInput>>
  queryAt: Scalars['TimestampRange']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingCalibrateReviewInput = {
  absoluteLevel?: InputMaybe<EamWorkflowReviewerAbsoluteLevel>
  assignee?: InputMaybe<Array<IdInput>>
  defaultReviewOperate?: InputMaybe<EamWorkflowDefaultReviewOperate>
  match?: InputMaybe<Array<ThingCalibrateReviewMatchInput>>
  method?: InputMaybe<EamWorkflowReferMethod>
  relativeLevel?: InputMaybe<EamWorkflowReviewerRelativeLevel>
  reviewer?: InputMaybe<EamWorkflowReviewer>
}

export type ThingCalibrateReviewMatchInput = {
  department: Array<IdInput>
  staff: StringIdInput
}

/** 资产校验节点：工单生成, 校准执行，校准验收，校准结束，校准撤销 */
export type ThingCalibrateState =
  | 'CALIBRATE_BEGIN'
  | 'CALIBRATE_FINISHED'
  | 'CALIBRATE_ISSUE'
  | 'CALIBRATE_UNDER_REVIEW'
  | 'CALIBRATE_WITHDRAWED'

/** 资产校验状态：待校准，审核中，已完结，已撤销 */
export type ThingCalibrateStatus =
  | 'FINISHED'
  | 'PENDING'
  | 'TO_ISSUE'
  | 'UNDER_REVIEW'
  | 'WITHDRAWED'

export type ThingCategoryLevel = 'CURRENT' | 'FIRST' | 'SECOND'

export type ThingCategoryListFilterInput = {
  code?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Array<Scalars['Int']>>
  /** 是否是校准类别 */
  isCalibration?: InputMaybe<Scalars['Boolean']>
  /** 是否已经关联资产齐套规则 */
  isRelatedThingCompleteFileRule?: InputMaybe<Scalars['Boolean']>
  /** 是否已经关联资产表单 */
  isRelatedThingUcc?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索： 名称 */
  search?: InputMaybe<Scalars['String']>
  /** 资产表单key(id)remove */
  uccKey?: InputMaybe<Scalars['String']>
}

export type ThingChangeSummaryByDepartmentFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  queryAt: Scalars['TimestampRange']
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingChangeSummaryFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  queryAt: Scalars['TimestampRange']
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingCirculationListFilterInput = {
  applicant?: InputMaybe<Array<IdInput>>
  department?: InputMaybe<Array<IdInput>>
  excludeThing?: InputMaybe<Array<IdInput>>
  isLent?: InputMaybe<Scalars['Boolean']>
  onlyApplyForDepartment?: InputMaybe<Scalars['Boolean']>
  onlyManager?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<BorrowState>>
  thing?: InputMaybe<Array<IdInput>>
}

export type ThingCompleteFileRuleListFilterInput = {
  /** 资产类别名称模糊搜索 */
  search?: InputMaybe<Scalars['String']>
}

export type ThingDepartmentLevel = 'CURRENT' | 'FIRST' | 'SECOND'

export type ThingDepreciationSummaryByThingDepartmentFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  queryAt: Scalars['TimestampRange']
}

export type ThingDropListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  dropAt?: InputMaybe<Scalars['TimestampRange']>
  search?: InputMaybe<Scalars['String']>
}

export type ThingDropRecordListFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  drop?: InputMaybe<Array<IntIdInput>>
  onState?: InputMaybe<Array<OnState>>
  /** 模糊搜索：资产编码/资产名称/规格/型号 */
  search?: InputMaybe<Scalars['String']>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingFilterInput = {
  /** 财务类型 */
  accountType?: InputMaybe<Array<ThingAccountType>>
  /** 财务归属部门 */
  accountingDepartmentId?: InputMaybe<Array<IdInput>>
  /** 购置金额 */
  acquisitionCost?: InputMaybe<EamNumberRangeInput>
  /** 预警到期日期 */
  alertAt?: InputMaybe<Scalars['TimestampRange']>
  /** 资本化日期 */
  assetNormalizationAt?: InputMaybe<Scalars['TimestampRange']>
  /** 账面净值 */
  bookValue?: InputMaybe<EamNumberRangeInput>
  /** 校准编码 */
  calibrateCode?: InputMaybe<Scalars['String']>
  calibrateMethod?: InputMaybe<Array<CalibrateMethod>>
  calibrateResult?: InputMaybe<Array<CalibrateResult>>
  /** 资产编号名称规格型号校准编号 */
  calibrateSearch?: InputMaybe<Scalars['String']>
  calibrateState?: InputMaybe<Array<CalibrateState>>
  canBorrowed?: InputMaybe<Scalars['Boolean']>
  canCalibrate?: InputMaybe<Scalars['Boolean']>
  /** 精准匹配资产编号 */
  code?: InputMaybe<Array<Scalars['String']>>
  /** 编码前缀（定制） */
  codePrefix?: InputMaybe<Array<CodePrefix>>
  /** 搜索: 设备编号 */
  codeSearch?: InputMaybe<Scalars['String']>
  currentOnly?: InputMaybe<Scalars['Boolean']>
  /** 是否涉及数据权限 */
  dataRangeLimit?: InputMaybe<Scalars['Boolean']>
  department?: InputMaybe<Array<IdInput>>
  departmentOrder?: InputMaybe<Array<InputMaybe<IdInput>>>
  /** 排除已有进行中领用单的资产 */
  excludeHasThingApply?: InputMaybe<Scalars['Boolean']>
  /** 排除已有进行中转移单的资产 */
  excludeHasThingMove?: InputMaybe<Scalars['Boolean']>
  /** 排除已有进行中出租单的资产 */
  excludeHasThingRent?: InputMaybe<Scalars['Boolean']>
  /** 排除已有进行中回收单的资产 */
  excludeHasThingRentBack?: InputMaybe<Scalars['Boolean']>
  /** 排除已有进行中退库单的资产 */
  excludeHasThingReturn?: InputMaybe<Scalars['Boolean']>
  excludeThing?: InputMaybe<Array<IdInput>>
  /** 自定义字段筛选 */
  fieldData?: InputMaybe<Array<EamFieldFilterInput>>
  /** 资产原值 */
  finalValue?: InputMaybe<EamNumberRangeInput>
  form?: InputMaybe<Array<IntIdInput>>
  hasDepartment?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Array<Scalars['ID']>>
  /** @deprecated: Use `id` */
  ids?: InputMaybe<Array<Scalars['ID']>>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
  /** 配合qualifiedParent使用 */
  includeSubThing?: InputMaybe<Array<IdInput>>
  isCalibrationExpired?: InputMaybe<Scalars['Boolean']>
  isLent?: InputMaybe<Scalars['Boolean']>
  /** 仅查看保管部门为失效组织的 */
  isRevoked?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Array<IntIdInput>>
  /** 最近一次校准日期 */
  lastCalibrateAt?: InputMaybe<Scalars['TimestampRange']>
  /** 租赁结束日期 */
  leaseFinishAt?: InputMaybe<Scalars['TimestampRange']>
  /** 机台号 */
  machineNumber?: InputMaybe<Scalars['String']>
  manager?: InputMaybe<Array<IdInput>>
  managerOrder?: InputMaybe<Array<IdInput>>
  nextCalibrateAt?: InputMaybe<Scalars['TimestampRange']>
  /** 设备使用状态 */
  onState?: InputMaybe<Array<OnState>>
  /** @deprecated: Use `thingGroup` */
  organization?: InputMaybe<Array<InputMaybe<IdInput>>>
  /** 性能状态 */
  performanceStatus?: InputMaybe<Array<ThingPerformanceStatus>>
  /** 处置类型 */
  processedType?: InputMaybe<ProcessedFilterType>
  /** 采购日期 */
  purchasedAt?: InputMaybe<Scalars['TimestampRange']>
  qualifiedParent?: InputMaybe<IdInput>
  /** rfid */
  rfid?: InputMaybe<Array<Scalars['String']>>
  /** 资产财务编码 */
  sapThingCode?: InputMaybe<Scalars['String']>
  /** 搜索: 设备名称、设备编号、规格、型号 */
  search?: InputMaybe<Scalars['String']>
  /** 规格型号 */
  specification?: InputMaybe<Array<Scalars['String']>>
  /** 存放地点 */
  storageAddr?: InputMaybe<Array<Scalars['String']>>
  /** 存放地点搜索 */
  storageAddrSearch?: InputMaybe<Scalars['String']>
  thingArea?: InputMaybe<Array<IntIdInput>>
  /** 借用工单 */
  thingBorrow?: InputMaybe<Array<IntIdInput>>
  /** 校准工单 */
  thingCalibrate?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
  thingGroup?: InputMaybe<Array<InputMaybe<IdInput>>>
  /** 巡检工单 */
  thingInspection?: InputMaybe<Array<IntIdInput>>
  /** 盘点工单 */
  thingInventory?: InputMaybe<Array<IntIdInput>>
  /** 保养工单 */
  thingMaintenance?: InputMaybe<Array<IntIdInput>>
  /** 资产登记单 */
  thingReceive?: InputMaybe<Array<IntIdInput>>
  /** 借用工单 */
  thingRepair?: InputMaybe<Array<IntIdInput>>
  /** 关联工单状态 */
  ticketState?: InputMaybe<Array<EamTicketState>>
  /** 自定义表单key */
  uccKey?: InputMaybe<Scalars['String']>
  /** 保修截至日期 */
  warrantyDeadlineAt?: InputMaybe<Scalars['TimestampRange']>
}

export type ThingFoundWorkflowConfigurationInput = {
  review: ThingFoundWorkflowReviewConfigurationInput
}

export type ThingFoundWorkflowReviewConfigurationInput = {
  enable: Scalars['Boolean']
}

export type ThingFunctionDepartmentFilterInput = {
  /** 用户名称/直属组织模糊查询 */
  defaultCalibrateUserSearch?: InputMaybe<Scalars['String']>
  /** 组织名称模糊查询 */
  search?: InputMaybe<Scalars['String']>
}

export type ThingGroupAlterOperation = 'ADD' | 'REMOVE'

export type ThingGroupDeptFilter = {
  currentOnly?: InputMaybe<Scalars['Boolean']>
  departments?: InputMaybe<Array<IdInput>>
  thingGroup: IdInput
}

export type ThingGroupFilter = {
  code?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
}

/**
 * PERSONAL： 个人设备组
 * DEPARTMENT： 部门设备组
 */
export type ThingGroupType = 'DEPARTMENT' | 'PERSONAL'

export type ThingGroupUserFilter = {
  currentOnly?: InputMaybe<Scalars['Boolean']>
  thingGroup: IdInput
  users?: InputMaybe<Array<IdInput>>
}

/**
 * STATUS: 资产流转记录
 * TICKET: 关联工单记录
 * RELATION: 关系变更记录
 */
export type ThingHistoryCategory = 'RELATION' | 'STATUS' | 'TICKET'

export type ThingHistoryListFilterInput = {
  category?: InputMaybe<Array<ThingHistoryCategory>>
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  operation?: InputMaybe<Array<ThingHistoryOperation>>
  thing: IdInput
}

/**
 * RECEIPT: 登记(入库)
 * VALIDATE: 验收
 * INSERT: 新增资产
 * APPLY: 领用
 * RETURN: 退库
 * MOVE: 转移
 * RENT: 出租
 * BACK: 回收
 * DROP: 清理
 * CANCEL_DROP: 取消清理
 * ABORT: 报废
 * OFF_LEASE: 退租
 * MISSING: 遗失
 * FOUND: 找回
 * SALE: 变卖
 * BORROW: 借用
 * BORROW_RETURN: 归还
 * MAINTENANCE: 保养
 * INSPECTION: 巡检
 * REPAIR: 维修
 * INVENTORY: 盘点
 * CALIBRATE: 校准
 * SUB_THING: 主从变更
 * SPARE_PART: 备件变更
 */
export type ThingHistoryOperation =
  | 'ABORT'
  | 'APPLY'
  | 'BACK'
  | 'BORROW'
  | 'BORROW_RETURN'
  | 'CALIBRATE'
  | 'CANCEL_DROP'
  | 'DROP'
  | 'FOUND'
  | 'INSERT'
  | 'INSPECTION'
  | 'INVENTORY'
  | 'MAINTENANCE'
  | 'MISSING'
  | 'MOVE'
  | 'OFF_LEASE'
  | 'RECEIPT'
  | 'RENT'
  | 'REPAIR'
  | 'RETURN'
  | 'SALE'
  | 'SPARE_PART'
  | 'SUB_THING'
  | 'VALIDATE'

export type ThingInspectionAcceptanceExecutorInput = {
  review?: InputMaybe<ThingInspectionReviewerInput>
  turnto?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingInspectionAcceptanceInput = {
  enabled: Scalars['Boolean']
  executor?: InputMaybe<ThingInspectionAcceptanceExecutorInput>
}

/**
 * NONE: 无需上传图片
 * UPLOAD_IMAGE: 上传照片
 * UPLOAD_IMAGE_MOBILE: 仅拍照上传图片
 */
export type ThingInspectionConstraint =
  | 'NONE'
  | 'UPLOAD_IMAGE'
  | 'UPLOAD_IMAGE_MOBILE'

export type ThingInspectionInspectingExecuteExecutorInput = {
  turnto?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingInspectionInspectingExecuteInput = {
  executor: ThingInspectionInspectingExecuteExecutorInput
}

export type ThingInspectionListFilterInput = {
  executeAt?: InputMaybe<Scalars['TimestampRange']>
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  id?: InputMaybe<Array<Scalars['Int']>>
  inspectionPoint?: InputMaybe<Array<IntIdInput>>
  inspectionType?: InputMaybe<Array<ThingInspectionType>>
  onDuty?: InputMaybe<Array<IdInput>>
  onlyMe?: InputMaybe<Scalars['Boolean']>
  operator?: InputMaybe<Array<IdInput>>
  schedule?: InputMaybe<Array<IntIdInput>>
  /** 工单编号、计划名称 */
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  state?: InputMaybe<Array<ThingInspectionState>>
  status?: InputMaybe<Array<ThingInspectionStatus>>
  team?: InputMaybe<Array<IntIdInput>>
  thing?: InputMaybe<Array<IdInput>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingInspectionListOfThingFilterInput = {
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  /** 工单编号、计划名称 */
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<ThingInspectionStatus>>
}

/** 巡检：提前执行，变更，报工，记录，转派，撤销,报修,通过，打回 */
export type ThingInspectionOperatorType =
  | 'ACCEPT'
  | 'EDIT'
  | 'RECORD'
  | 'REJECT'
  | 'REPAIR'
  | 'REPORT'
  | 'START'
  | 'TURNTO'
  | 'TURN_TO_PRINCIPAL'
  | 'WITHDRAW'

export type ThingInspectionPendingExecutorInput = {
  edit?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  start?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingInspectionPendingInput = {
  executor: ThingInspectionPendingExecutorInput
}

export type ThingInspectionRelateResourceFilterInput = {
  status?: InputMaybe<Array<ThingInspectionStatus>>
}

export type ThingInspectionResult =
  | 'CANNOT_EXECUTE'
  | 'QUALIFIED'
  | 'UNQUALIFIED'

export type ThingInspectionReviewerInput = {
  assign?: InputMaybe<StringIdInput>
  reviewPerson: ThingInspectionReviewerPerson
}

export type ThingInspectionReviewerPerson =
  | 'ASSIGN'
  | 'SCHEDULE_CREATED_BY'
  | 'TEAM_LEADER'

export type ThingInspectionScheduleFilterInput = {
  /** 模糊搜索：计划名称，说明, 目的 */
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  state?: InputMaybe<Array<ThingInspectionScheduleState>>
}

/** 未开始、进行中、已结束 */
export type ThingInspectionScheduleState =
  | 'FINISHED'
  | 'IN_PROGRESS'
  | 'PENDING'

export type ThingInspectionSplitMethod = 'BY_INSPECTION_POINT' | 'NONE'

/** 巡检：工单生成，巡检执行，巡检验收，巡检结束（完结），巡检结束（撤销） */
export type ThingInspectionState =
  | 'FINISHED_DONE'
  | 'FINISHED_WITHDRAWED'
  | 'INSPECTING_ACCEPTANCE'
  | 'INSPECTING_EXECUTE'
  | 'PENDING'

/** 巡检：待巡检，巡检中，已完结，撤销 */
export type ThingInspectionStatus =
  | 'FINISHED'
  | 'INSPECTING'
  | 'PENDING'
  | 'WITHDRAWED'

export type ThingInspectionType =
  | 'KEY_POINT_INSPECTION'
  | 'NORMAL_INSPECTION'
  | 'OTHER'
  | 'POINT_INSPECTION'

export type ThingInspectionWithdrawReason = 'OTHER' | 'SHUTDOWN'

/** UPLOAD_IMAGE: 上传照片 */
export type ThingInventoryConstraint = 'ONLY_PHOTO' | 'UPLOAD_IMAGE'

/**
 * INCONSISTENT: 信息不一致
 * RELABEL: 重贴标签
 * REPAIR: 维修
 * ABANDONED: 报废
 * MAINTENANCE: 保养
 * OTHER: 其他
 */
export type ThingInventoryLabel =
  | 'ABANDONED'
  | 'INCONSISTENT'
  | 'MAINTENANCE'
  | 'OTHER'
  | 'RELABEL'
  | 'REPAIR'

/**
 * SCAN: 扫码盘点
 * MANUAL: 手动盘点
 * RFID: rfid盘点
 */
export type ThingInventoryMethod = 'MANUAL' | 'RFID' | 'SCAN'

export type ThingInventoryOfflineDataInput = {
  records: Array<SetThingInventoryRecordInput>
  redundantRecords: Array<CreateThingInventoryRedundantRecordInput>
  ticket: IntIdInput
}

export type ThingInventoryRecordListFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  label?: InputMaybe<Array<ThingInventoryLabel>>
  /** 资产财务编码 */
  sapThingCode?: InputMaybe<Scalars['String']>
  /** 模糊搜索：资产编码，资产名称，资产规格型号 */
  search?: InputMaybe<Scalars['String']>
  /** 可盘人员 */
  staff?: InputMaybe<Array<StringIdInput>>
  thing?: InputMaybe<Array<IdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
  thingInventoryState?: InputMaybe<Array<ThingInventoryState>>
  /** 资产保管人员 */
  thingManager?: InputMaybe<Array<StringIdInput>>
  ticket?: InputMaybe<Array<IntIdInput>>
  ticketState?: InputMaybe<Array<ThingInventoryTicketState>>
}

export type ThingInventoryRedundantRecordListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  isRelated?: InputMaybe<Scalars['Boolean']>
  /** 模糊搜索名称 */
  search?: InputMaybe<Scalars['String']>
  ticket?: InputMaybe<Array<IntIdInput>>
}

/**
 * FINISHED: 已盘
 * UN_FINISHED: 未盘
 * LACK: 盘亏
 */
export type ThingInventoryState = 'FINISHED' | 'LACK' | 'UN_FINISHED'

export type ThingInventoryThingFilterInput = {
  /** 购置金额（含税） */
  acquisitionCost?: InputMaybe<EamNumberRangeInput>
  /** 账面净值 */
  bookValue?: InputMaybe<EamNumberRangeInput>
  /** 资产保管部门 */
  department?: InputMaybe<Array<IdInput>>
  /** 资产原值 */
  finalValue?: InputMaybe<EamNumberRangeInput>
  /** 资产保管人员 */
  manager?: InputMaybe<Array<StringIdInput>>
  /** 使用状态 */
  onState?: InputMaybe<Array<OnState>>
  /** 采购日期 */
  purchasedAt?: InputMaybe<Scalars['TimestampRange']>
  /** 资产所属区域 */
  thingArea?: InputMaybe<Array<IntIdInput>>
  /** 资产类别 */
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingInventoryTicketListFilterInput = {
  createdBy?: InputMaybe<Array<IdInput>>
  id?: InputMaybe<Array<Scalars['Int']>>
  methods?: InputMaybe<Array<ThingInventoryMethod>>
  onlyMe?: InputMaybe<Scalars['Boolean']>
  /** 负责人 */
  principal?: InputMaybe<Array<IdInput>>
  /** 模糊搜索：工单名称 */
  search?: InputMaybe<Scalars['String']>
  /** 工单状态 */
  state?: InputMaybe<Array<ThingInventoryTicketState>>
  /** 应盘资产 */
  thing?: InputMaybe<Array<IdInput>>
  timeRange?: InputMaybe<Scalars['TimestampRange']>
}

/**
 * #PENDING: 未开始
 * IN_PROGRESS: 进行中
 * WITHDRAWED: 已撤销
 * FINISHED: 已完成
 */
export type ThingInventoryTicketState =
  | 'FINISHED'
  | 'IN_PROGRESS'
  | 'WITHDRAWED'

/**
 * REPAIR: 报修
 * FOUND: 找回
 */
export type ThingInventoryTrackProcessMethod = 'FOUND' | 'REPAIR'

export type ThingInventoryTrackRecordListFilterInput = {
  department?: InputMaybe<Array<IdInput>>
  label?: InputMaybe<Array<ThingInventoryLabel>>
  /** 模糊搜索：资产编码，资产名称，资产规格型号 */
  search?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<Array<IdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
  thingInventoryState?: InputMaybe<Array<ThingInventoryState>>
  ticket?: InputMaybe<Array<IntIdInput>>
  ticketState?: InputMaybe<Array<ThingInventoryTicketState>>
}

export type ThingInventoryTrackRedundantRecordListFilterInput = {
  ticket?: InputMaybe<Array<IntIdInput>>
}

/**
 * MANAGER: 资产管理员
 * MANAGER_AND_MAINTAINER: 资产管理员+资产保管员
 */
export type ThingInventoryUserScope =
  | 'ASSIGN'
  | 'MANAGER'
  | 'MANAGER_AND_MAINTAINER'

export type ThingMaintenanceExecuteExecutorInput = {
  turnto?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingMaintenanceExecuteInput = {
  executor: ThingMaintenanceExecuteExecutorInput
}

export type ThingMaintenanceFilterInput = {
  executeAt?: InputMaybe<Scalars['TimestampRange']>
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  id?: InputMaybe<Array<Scalars['Int']>>
  onDuty?: InputMaybe<Array<IdInput>>
  onlyMe?: InputMaybe<Scalars['Boolean']>
  operator?: InputMaybe<Array<IdInput>>
  schedule?: InputMaybe<Array<IntIdInput>>
  /** 模糊搜索：工单编号，计划名称，资产名称、编码，项目名称 */
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<Array<ThingMaintenanceStatus>>
  team?: InputMaybe<Array<IntIdInput>>
  thing?: InputMaybe<Array<IdInput>>
  ticketView?: InputMaybe<TicketView>
  type?: InputMaybe<Array<ThingMaintenanceType>>
}

export type ThingMaintenanceOfThingFilterInput = {
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<ThingMaintenanceStatus>>
}

/** 保养：提前执行，变更，报工，记录，申领备件，保修，转派，打回，通过，撤销， 转移负责人 */
export type ThingMaintenanceOperator =
  | 'APPLY_SPARE_PART_CLAIM'
  | 'APPROVE'
  | 'EDIT'
  | 'RECORD'
  | 'REJECT'
  | 'REPAIR'
  | 'REPORT'
  | 'START'
  | 'TURNTO'
  | 'TURN_TO_PRINCIPAL'
  | 'WITHDRAW'

export type ThingMaintenancePendingExecutorInput = {
  edit?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  start?: InputMaybe<Array<EamWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingMaintenancePendingInput = {
  executor: ThingMaintenancePendingExecutorInput
}

export type ThingMaintenanceRelateResourceFilterInput = {
  status?: InputMaybe<Array<ThingMaintenanceStatus>>
}

export type ThingMaintenanceReviewExecutorInput = {
  turnto?: InputMaybe<Array<EamWorkflowExecutorPerson>>
}

export type ThingMaintenanceReviewInput = {
  enabled: Scalars['Boolean']
  executor?: InputMaybe<ThingMaintenanceReviewExecutorInput>
}

export type ThingMaintenanceScheduleFilterInput = {
  /** 模糊搜索：计划名称，保养事由，目的 */
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  state?: InputMaybe<Array<ThingMaintenanceScheduleState>>
}

/** 未开始、进行中、已结束 */
export type ThingMaintenanceScheduleState =
  | 'FINISHED'
  | 'IN_PROGRESS'
  | 'PENDING'

/** 保养节点：工单生成，保养执行，保养验收，保养结束（完结），保养结束（撤销） */
export type ThingMaintenanceState =
  | 'FINISHED_DONE'
  | 'FINISHED_WITHDRAWED'
  | 'MAINTAINING_EXECUTE'
  | 'MAINTAINING_UNDER_REVIEW'
  | 'PENDING'

/** 保养状态：待保养，保养中，已完结，撤销 */
export type ThingMaintenanceStatus =
  | 'FINISHED'
  | 'MAINTAINING'
  | 'PENDING'
  | 'WITHDRAWED'

export type ThingMaintenanceType = 'BY_MAINTENANCE_METHOD' | 'BY_THING'

export type ThingMissingFindInfoListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  findAt?: InputMaybe<Scalars['TimestampRange']>
  onDuty?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingMissingState>>
  status?: InputMaybe<Array<ThingMissingFindInfoStatus>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingMissingFindInfoState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingMissingFindInfoStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingMissingListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  missAt?: InputMaybe<Scalars['TimestampRange']>
  onDuty?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索遗失单编号、资产编码、名称 */
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingMissingState>>
  status?: InputMaybe<Array<ThingMissingStatus>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingMissingState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingMissingStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingMissingWorkflowConfigurationInput = {
  review: ThingMissingWorkflowReviewConfigurationInput
}

export type ThingMissingWorkflowReviewConfigurationInput = {
  enable: Scalars['Boolean']
}

export type ThingMoveListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  moveAt?: InputMaybe<Scalars['TimestampRange']>
  onDuty?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingMoveState>>
  status?: InputMaybe<Array<ThingMoveStatus>>
  ticketView?: InputMaybe<TicketView>
}

/** 发起转移、审核、完成转移、取消转移 */
export type ThingMoveState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

/** 待发起、审核中、已完成、已取消 */
export type ThingMoveStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingMoveThingManagerInput = {
  manager: StringIdInput
  /** 存放地点 */
  storageAddr?: InputMaybe<Scalars['String']>
  thing: Array<IdInput>
}

export type ThingMoveWorkflowConfigurationInput = {
  review: ThingMoveWorkflowReviewConfigurationInput
}

export type ThingMoveWorkflowReviewConfigurationInput = {
  conf?: InputMaybe<IdInput>
  enable?: InputMaybe<Scalars['Boolean']>
}

export type ThingOffLeaseListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  offLeaseAt?: InputMaybe<Scalars['TimestampRange']>
  /** 模糊搜索变卖单编号、原因、机构 */
  search?: InputMaybe<Scalars['String']>
  ticketView?: InputMaybe<TicketView>
}

export type ThingOffLeaseWorkflowConfigurationInput = {
  review: ThingOffLeaseWorkflowReviewConfigurationInput
}

export type ThingOffLeaseWorkflowReviewConfigurationInput = {
  enable: Scalars['Boolean']
}

/** 良好，故障 */
export type ThingPerformanceStatus = 'ERROR' | 'GOOD'

export type ThingPriceSummaryByDepartmentFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  manager?: InputMaybe<Array<StringIdInput>>
  onState?: InputMaybe<Array<OnState>>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingPriceSummaryFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  manager?: InputMaybe<Array<StringIdInput>>
  onState?: InputMaybe<Array<OnState>>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

/** 外购，租赁，自制 */
export type ThingPurchaseType = 'LEASED' | 'MAKE_BY_SELF' | 'OUTSOURCING'

export type ThingReceiveListFilterInput = {
  /** 创建时间 */
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  /** 创建人 */
  createdBy?: InputMaybe<Array<IdInput>>
  /** 模糊搜索：登记单编号、资产名称、规格、型号 */
  search?: InputMaybe<Scalars['String']>
  /** 资产来源 */
  source?: InputMaybe<Array<ThingReceiveSource>>
  /** 资产登记状态 */
  status?: InputMaybe<Array<ThingReceiveStatus>>
}

/**
 * 采购: PURCHASE
 * 租赁: LEASE
 * 自制: SELF_MADE
 * 接受捐赠: DONATIONS_ACCEPTED
 * 政府补贴: GOVERNMENT_SUBSIDIES
 * 其他: OTHER
 */
export type ThingReceiveSource =
  | 'DONATIONS_ACCEPTED'
  | 'GOVERNMENT_SUBSIDIES'
  | 'LEASE'
  | 'OTHER'
  | 'PURCHASE'
  | 'SELF_MADE'

/**
 * 未登记: NOT_REGISTERED
 * 登记中: REGISTERING
 * 已登记: REGISTERED
 * 已撤销: CANCELED
 */
export type ThingReceiveStatus =
  | 'CANCELED'
  | 'NOT_REGISTERED'
  | 'REGISTERED'
  | 'REGISTERING'

export type ThingRentBackListFilterInput = {
  backAt?: InputMaybe<Scalars['TimestampRange']>
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  onDuty?: InputMaybe<Array<StringIdInput>>
  /** 模糊搜索编码 */
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingRentBackState>>
  status?: InputMaybe<Array<ThingRentBackStatus>>
  ticketView?: InputMaybe<TicketView>
}

/** 发起回收、审核、完成回收、取消回收 */
export type ThingRentBackState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

/** 待发起、审核中、已完成、已取消 */
export type ThingRentBackStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingRentBackWorkflowConfigurationInput = {
  review: ThingRentBackWorkflowReviewConfigurationInput
}

export type ThingRentBackWorkflowReviewConfigurationInput = {
  conf?: InputMaybe<IdInput>
  enable?: InputMaybe<Scalars['Boolean']>
}

export type ThingRentListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  end?: InputMaybe<Scalars['TimestampRange']>
  onDuty?: InputMaybe<Array<StringIdInput>>
  search?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['TimestampRange']>
  state?: InputMaybe<Array<ThingRentState>>
  status?: InputMaybe<Array<ThingRentStatus>>
  ticketView?: InputMaybe<TicketView>
}

/** 发起出租、审核、完成出租、取消出租 */
export type ThingRentState =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

/** 待发起、审核中、已完成、已取消 */
export type ThingRentStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingRentWorkflowConfigurationInput = {
  review: ThingRentWorkflowReviewConfigurationInput
}

export type ThingRentWorkflowReviewConfigurationInput = {
  conf?: InputMaybe<IdInput>
  enable?: InputMaybe<Scalars['Boolean']>
}

export type ThingRepairAssessmentExecutorInput = {
  turnTo?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
}

export type ThingRepairAssessmentInput = {
  executor: ThingRepairAssessmentExecutorInput
}

export type ThingRepairCheckExecutorInput = {
  turnTo?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
}

export type ThingRepairCheckInput = {
  enabled: Scalars['Boolean']
  executor?: InputMaybe<ThingRepairCheckExecutorInput>
}

export type ThingRepairDispatch = 'STAFF' | 'TEAM'

export type ThingRepairDispatchDefault = 'FORBID' | 'STAFF' | 'TEAM'

/**
 * 指定EAM业务人员
 * DEPARTMENT:维修资产保管部门直属人员
 * DEPARTMENT_OFFICER:维修资产保管部门组织负责人
 * DEPARTMENT_ONE_LEVEL_UP:维修资产保管部门向上1级直属人员
 * DEPARTMENT_TWO_LEVEL_UP:维修资产保管部门向上2级直属人员
 */
export type ThingRepairDuty =
  | 'DEPARTMENT'
  | 'DEPARTMENT_OFFICER'
  | 'DEPARTMENT_ONE_LEVEL_UP'
  | 'DEPARTMENT_TWO_LEVEL_UP'

export type ThingRepairExecuteExecutorInput = {
  turnTo?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
  withdraw?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
}

export type ThingRepairExecuteInput = {
  executor: ThingRepairExecuteExecutorInput
}

export type ThingRepairFaultListFilterInput = {
  /** 模糊搜索： 名称 */
  search?: InputMaybe<Scalars['String']>
  /** 资产类别 */
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingRepairFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<IdInput>>
  exclude?: InputMaybe<Array<IntIdInput>>
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  id?: InputMaybe<Array<Scalars['Int']>>
  onDuty?: InputMaybe<Array<IdInput>>
  operator?: InputMaybe<Array<IdInput>>
  principal?: InputMaybe<Array<IdInput>>
  /** 搜索项：编号 */
  search?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Array<Scalars['String']>>
  status?: InputMaybe<Array<ThingRepairStatus>>
  team?: InputMaybe<Array<IntIdInput>>
  thing?: InputMaybe<Array<IdInput>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingRepairKnowhowListFilterInput = {
  author?: InputMaybe<Array<StringIdInput>>
  fault?: InputMaybe<Array<IntIdInput>>
  search?: InputMaybe<Scalars['String']>
}

export type ThingRepairNotificationRangeInput = {
  accountType?: InputMaybe<Array<ThingAccountType>>
  accountingDepartment?: InputMaybe<Array<IdInput>>
  area?: InputMaybe<Array<IntIdInput>>
  calibrateMethod?: InputMaybe<Array<CalibrateMethod>>
  calibrateResult?: InputMaybe<Array<CalibrateResult>>
  canBorrowed?: InputMaybe<Array<Scalars['Boolean']>>
  canCalibrate?: InputMaybe<Array<Scalars['Boolean']>>
  category?: InputMaybe<Array<IntIdInput>>
  codePrefix?: InputMaybe<Array<CodePrefix>>
  department?: InputMaybe<Array<IdInput>>
  isCalibrationExpired?: InputMaybe<Array<Scalars['Boolean']>>
  isLent?: InputMaybe<Array<Scalars['Boolean']>>
  key: Scalars['String']
  manager?: InputMaybe<Array<StringIdInput>>
  option?: InputMaybe<Array<Scalars['String']>>
  performanceStatus?: InputMaybe<Array<ThingPerformanceStatus>>
}

export type ThingRepairOfThingFilterInput = {
  finishedAt?: InputMaybe<Scalars['TimestampRange']>
  /** 搜索项：编号 */
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<ThingRepairStatus>>
}

/** 维修操作：报修，受理，报工，申领备件，转派，打回，通过，撤销，发起校准工单 */
export type ThingRepairOperatorType =
  | 'ACCEPT'
  | 'APPLY_FOR_SPARE_PART'
  | 'APPROVE'
  | 'CALIBRATE'
  | 'REJECT'
  | 'REPAIR'
  | 'REPORT'
  | 'TURN_TO'
  | 'TURN_TO_PRINCIPAL'
  | 'WITHDRAW'

export type ThingRepairPendingExecutorInput = {
  withdraw?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
}

export type ThingRepairPendingInput = {
  executor: ThingRepairPendingExecutorInput
}

export type ThingRepairRelateResourceFilterInput = {
  status?: InputMaybe<Array<ThingRepairStatus>>
}

export type ThingRepairRelatedOrderType =
  | 'THING_CALIBRATE'
  | 'THING_INSPECTION'
  | 'THING_INVENTORY'
  | 'THING_MAINTENANCE'

export type ThingRepairReportOutcome = 'FAILED' | 'SUCCESS'

export type ThingRepairReviewExecutorInput = {
  assign?: InputMaybe<StringIdInput>
  reviewPerson: ThingRepairReviewerPerson
}

export type ThingRepairReviewerPerson =
  | 'ASSIGNEE'
  | 'CREATED_BY'
  | 'TEAM_LEADER'

export type ThingRepairRuleOperate = 'IN' | 'NOT_IN'

export type ThingRepairRuleRangeInput = {
  category?: InputMaybe<Array<IntIdInput>>
  department?: InputMaybe<Array<IdInput>>
  key: Scalars['String']
  /** 包含/不包含 */
  operate: ThingRepairRuleOperate
}

/** 维修节点：工单生成，维修评估，维修执行，维修审核，维修验收，维修结束（完结），维修结束（撤销） */
export type ThingRepairState =
  | 'FINISHED_DONE'
  | 'FINISHED_WITHDRAWED'
  | 'PENDING'
  | 'REPAIR_ASSESSMENT'
  | 'REPAIR_CHECK'
  | 'REPAIR_EXECUTE'
  | 'REPAIR_UNDER_REVIEW'

/** 维修状态：待提交，待维修，维修中，已完结，已撤销 */
export type ThingRepairStatus =
  | 'FINISHED'
  | 'PENDING'
  | 'REPAIRING'
  | 'WAITING_REPAIR'
  | 'WITHDRAWED'

/** 维修方式：内部自修，委外维修 */
export type ThingRepairType = 'INSIDE' | 'OUTSIDE'

export type ThingRepairUnderReviewExecutorInput = {
  review?: InputMaybe<ThingRepairReviewExecutorInput>
  turnTo?: InputMaybe<Array<ThingRepairWorkflowExecutorPerson>>
}

export type ThingRepairUnderReviewInput = {
  enabled: Scalars['Boolean']
  executor?: InputMaybe<ThingRepairUnderReviewExecutorInput>
}

export type ThingRepairWithdrawReason = 'MISREPORT' | 'OTHER'

export type ThingRepairWorkflowExecutorPerson =
  | 'CREATED_BY'
  | 'OPERATOR'
  | 'TEAM_LEADER'

export type ThingReturnListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  createdBy?: InputMaybe<Array<StringIdInput>>
  onDuty?: InputMaybe<Array<StringIdInput>>
  returnAt?: InputMaybe<Scalars['TimestampRange']>
  returnType?: InputMaybe<Array<ThingReturnType>>
  /** 模糊搜索退库单编号 */
  search?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Array<ThingReturnState>>
  status?: InputMaybe<Array<ThingReturnStatus>>
  ticketView?: InputMaybe<TicketView>
}

export type ThingReturnState =
  | 'CANCELED'
  | 'DISTRIBUTE'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingReturnStatus =
  | 'CANCELED'
  | 'FINISHED'
  | 'PENDING'
  | 'UNDER_REVIEW'

export type ThingReturnType = 'RETRIEVE' | 'RETURN'

export type ThingReturnWorkflowConfigurationInput = {
  department: ThingReturnWorkflowReviewDepartmentConfigurationInput
  review: ThingReturnWorkflowReviewConfigurationInput
}

export type ThingReturnWorkflowReviewConfigurationInput = {
  conf?: InputMaybe<IdInput>
  enable?: InputMaybe<Scalars['Boolean']>
}

export type ThingReturnWorkflowReviewDepartmentConfigurationInput = {
  assignee: Array<IdInput>
}

export type ThingSaleListFilterInput = {
  createdAt?: InputMaybe<Scalars['TimestampRange']>
  saleAt?: InputMaybe<Scalars['TimestampRange']>
  /** 模糊搜索变卖单编号、原因、机构 */
  search?: InputMaybe<Scalars['String']>
  ticketView?: InputMaybe<TicketView>
}

export type ThingSaleWorkflowConfigurationInput = {
  review: ThingSaleWorkflowReviewConfigurationInput
}

export type ThingSaleWorkflowReviewConfigurationInput = {
  enable: Scalars['Boolean']
}

export type ThingScanOperation =
  | 'BORROW'
  | 'CALIBRATE'
  | 'REPAIR'
  | 'THING_DETAIL'

/** 个人资产保管，公共资产保管 */
export type ThingStorageType = 'PERSONAL' | 'PUBLIC'

export type ThingTransferRecordInput = {
  applyForAt: Scalars['Timestamp']
  distributor?: InputMaybe<Scalars['String']>
  fieldData?: InputMaybe<Scalars['JSON']>
  groupFile?: InputMaybe<Array<GroupFileInput>>
  modulePrice?: InputMaybe<Scalars['Float']>
  processId: Scalars['String']
  residualValue?: InputMaybe<Scalars['Float']>
  sapCodeAfterTransfer?: InputMaybe<Scalars['String']>
  sapCodeBeforeTransfer?: InputMaybe<Scalars['String']>
  transferInDepartment?: InputMaybe<IdInput>
  transferInDistributor?: InputMaybe<Scalars['String']>
  transferMouldAt?: InputMaybe<Scalars['Timestamp']>
  transferOutDepartment?: InputMaybe<IdInput>
  transferOutDistributor?: InputMaybe<Scalars['String']>
  transferType: ThingTransferType
}

export type ThingTransferRecordListFilterInput = {
  applyForAt?: InputMaybe<Scalars['TimestampRange']>
  thing?: InputMaybe<Array<IdInput>>
  transferType?: InputMaybe<Array<ThingTransferType>>
}

/**
 * TRANSFER: 转移/调拨
 * ABANDONED: 报废
 * BORROW: 借用
 * RETURN: 归还
 * FOUND: 归还
 * LOST: 遗失
 * TRANSFER_MOULD: 移模
 * MODIFY_MOULD: 改模
 */
export type ThingTransferType =
  | 'ABANDONED'
  | 'BORROW'
  | 'FOUND'
  | 'LOST'
  | 'MODIFY_MOULD'
  | 'RETURN'
  | 'TRANSFER'
  | 'TRANSFER_MOULD'

export type ThingUsageSummaryFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  manager?: InputMaybe<Array<StringIdInput>>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type ThingUsageSummaryThingDepartmentFilterInput = {
  department?: InputMaybe<Array<StringIdInput>>
  includeRevoked?: InputMaybe<Scalars['Boolean']>
  manager?: InputMaybe<Array<StringIdInput>>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

/** 原厂维保、指定维保、维修维保、无维保 */
export type ThingWarrantyMethod =
  | 'NO'
  | 'ORIGINAL_FACTORY'
  | 'REPAIR'
  | 'SPECIFIED'

export type ThirdPartyServiceKind = 'DINGDING' | 'FEISHU' | 'WECOM'

export type TicketView = 'RELATED' | 'SPONSOR' | 'TODO'

export type TransferOrganizationInput = {
  allChecked?: InputMaybe<Scalars['Boolean']>
  originOrganizationId: Scalars['String']
  staffIds: Array<Scalars['String']>
  targetOrganizationId: Scalars['String']
}

export type TransferProcessActivityTaskInput = {
  id: Scalars['String']
  reason: Scalars['String']
  transfereeId: Scalars['String']
}

export type TurnToInventoryByThingInput = {
  thing: Array<IdInput>
  turnTo: Array<StringIdInput>
}

export type TurnToPrincipalCalibrateScheduleInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingCalibrateInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingInspectionInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingInspectionScheduleInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingInventoryInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingMaintenanceInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingMaintenanceScheduleInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToPrincipalThingRepairInput = {
  id: Scalars['Int']
  turnTo: IdInput
}

export type TurnToSparePartClaimInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  msg?: InputMaybe<Scalars['String']>
  turnTo: IdInput
}

export type TurnToThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  turnTo: IdInput
}

export type TurnToThingCalibrateInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  operator: IdInput
  remark?: InputMaybe<Scalars['String']>
}

export type TurnToThingInspectionInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  team?: InputMaybe<IntIdInput>
  turnTo: IdInput
}

export type TurnToThingInventoryRecordInput = {
  id: Array<Scalars['Int']>
  staff: Array<StringIdInput>
}

export type TurnToThingMaintenanceInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  team?: InputMaybe<IntIdInput>
  turnTo: IdInput
}

export type TurnToThingRepairInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
  team?: InputMaybe<IntIdInput>
  turnTo: IdInput
}

export type UccDataType =
  | 'BOOL'
  | 'DATE'
  | 'DATETIME'
  | 'FLOAT'
  | 'INT'
  | 'STRING'
  | 'TIME'

export type UccFilterUiType =
  | 'CASCADER'
  | 'CHECKBOX'
  | 'DATETIME_RANGE'
  | 'DATE_RANGE'
  | 'NUMBER_RANGE'
  | 'SELECT'
  | 'TEXT'
  | 'TREE'

export type UccOperator =
  | 'BETWEEN'
  | 'EQ'
  | 'GT'
  | 'GTE'
  | 'IEQ'
  | 'ILIKE'
  | 'IN'
  | 'INEQ'
  | 'IS_NULL'
  | 'LIKE'
  | 'LT'
  | 'LTE'
  | 'NEQ'
  | 'NOT_BETWEEN'
  | 'NOT_IN'
  | 'NOT_OVERLAP'
  | 'OVERLAP'

export type UnAssignAppPermissionsInput = {
  app: StringIdInput
  permissions: Array<StringIdInput>
}

export type UnAssignTenantAppsInput = {
  apps: Array<StringIdInput>
  tenant: StringIdInput
}

export type UnStarAppInput = {
  app: StringIdInput
}

/** 未读消息提醒 */
export type UnreadMessageStyle = 'DOT' | 'NUMBER'

export type UnreadMessageStyleConfigInput = {
  backLog: UnreadMessageStyle
  inboxMessage: UnreadMessageStyle
  systemTask: UnreadMessageStyle
}

export type UpdateAccountInput = {
  id: Scalars['String']
  isAllowedToLogin?: InputMaybe<Scalars['Boolean']>
  roles?: InputMaybe<Array<StringIdInput>>
}

export type UpdateAppPromotionInput = {
  attachments?: InputMaybe<Array<StringIdInput>>
  category?: InputMaybe<AppPromotionCategory>
  content?: InputMaybe<Scalars['JSON']>
  cover?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  title?: InputMaybe<Scalars['String']>
}

export type UpdateAppVersionInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  version?: InputMaybe<Scalars['String']>
}

export type UpdateAuthorizationRuleInput = {
  dataRange?: InputMaybe<PermissionDataRangeInput>
  id: Scalars['String']
  isAllowed?: InputMaybe<Scalars['Boolean']>
}

export type UpdateAuthorizationRulesOfUserInput = {
  authorizationRules: Array<UpdateAuthorizationRuleInput>
  user: StringIdInput
}

export type UpdateBusinessStaffGroupInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateCalibrateOrganizationInput = {
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateCalibrateScheduleInput = {
  department?: InputMaybe<Array<IdInput>>
  /** 生成时间点 */
  generateClock?: InputMaybe<Scalars['Int']>
  /** 生成日 */
  generateDay?: InputMaybe<Scalars['Int']>
  id: Scalars['Int']
  includeExpired?: InputMaybe<Scalars['Boolean']>
  isAllDepartment?: InputMaybe<Scalars['Boolean']>
  isAllThingCategory?: InputMaybe<Scalars['Boolean']>
  isInsideCalibrate?: InputMaybe<Scalars['Boolean']>
  isOutsideCalibrate?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type UpdateCombaCockpitAddressApInput = {
  ap?: InputMaybe<Scalars['String']>
  area?: InputMaybe<IntIdInput>
  id: Scalars['Int']
  line?: InputMaybe<Scalars['String']>
}

export type UpdateCombaCockpitThingInput = {
  combaCockpitMac: Scalars['String']
  thing: IdInput
}

export type UpdateContactConfigInput = {
  agentId?: InputMaybe<Scalars['String']>
  checkFileId?: InputMaybe<Scalars['ID']>
  corpId?: InputMaybe<Scalars['String']>
  secret?: InputMaybe<Scalars['String']>
}

/** 更改部门下的一个设备组为另一个 */
export type UpdateDepartmentThingGroupInput = {
  department: Scalars['JSONString']
  newThingGroup: IdInput
  oldThingGroup: IdInput
}

export type UpdateDingdingAppInput = {
  agentId?: InputMaybe<Scalars['String']>
  app?: InputMaybe<DingdingAppDetailInput>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  corpId?: InputMaybe<Scalars['String']>
  dingdingAppId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  ssoSecret?: InputMaybe<Scalars['String']>
}

export type UpdateDingdingContactConfigInput = {
  agentId: Scalars['String']
  appId?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  corpId?: InputMaybe<Scalars['String']>
}

export type UpdateDynamicBusinessStaffGroupInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateEsmDemoInput = {
  attachments?: InputMaybe<Array<MutateEsmDemoFileInput>>
  fieldData?: InputMaybe<Scalars['JSON']>
  id: Scalars['String']
  nestedDateRange?: InputMaybe<MutateEsmDemoDateRangeInput>
  nestedNumberRange?: InputMaybe<MutateEsmDemoNumberRangeInput>
  organization?: InputMaybe<StringIdInput>
  staff?: InputMaybe<StringIdInput>
}

export type UpdateEamFieldInput = {
  attachmentType?: InputMaybe<EamAttachmentType>
  content?: InputMaybe<Scalars['String']>
  defaultBool?: InputMaybe<Scalars['Boolean']>
  defaultNum?: InputMaybe<Scalars['Float']>
  defaultStr?: InputMaybe<Scalars['String']>
  defaultStrList?: InputMaybe<Array<Scalars['String']>>
  desc?: InputMaybe<Scalars['String']>
  extension?: InputMaybe<Array<Scalars['String']>>
  format?: InputMaybe<Scalars['String']>
  frequency?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<EamFieldGroupType>
  hint?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  isActive?: InputMaybe<Scalars['Boolean']>
  max?: InputMaybe<Scalars['Float']>
  maxCount?: InputMaybe<Scalars['Int']>
  maxRange?: InputMaybe<EamMetaRangeExtremumInput>
  maxSize?: InputMaybe<Scalars['Int']>
  metaID?: InputMaybe<Scalars['ID']>
  min?: InputMaybe<Scalars['Float']>
  minRange?: InputMaybe<EamMetaRangeExtremumInput>
  multi?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  option?: InputMaybe<Array<Scalars['String']>>
  period?: InputMaybe<EamRepeatPeriod>
  role?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  round?: InputMaybe<Scalars['Int']>
  set?: InputMaybe<Array<EamFieldInput>>
  suffix?: InputMaybe<Scalars['String']>
  type?: InputMaybe<EamFieldType>
  unit?: InputMaybe<Scalars['String']>
  unitAlign?: InputMaybe<EamUnitAlign>
  zeroable?: InputMaybe<Scalars['Boolean']>
}

export type UpdateEamSparePartCategoryInput = {
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
  relatedToThing?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<Array<Scalars['String']>>
  roleRule?: InputMaybe<SparePartRoleRule>
}

export type UpdateEamSparePartWarehouseInput = {
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateEamTeamInput = {
  category?: InputMaybe<EamTeamCategory>
  id: Scalars['Int']
  leader?: InputMaybe<IdInput>
  member?: InputMaybe<Array<IdInput>>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateEnterpriseAppInput = {
  avatar?: InputMaybe<IdInput>
  description?: InputMaybe<Scalars['String']>
  group?: InputMaybe<StringIdInput>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  redirectUrl?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type UpdateFeaturePackInput = {
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  releaseName?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  versionLevel?: InputMaybe<FeaturePackVersionLevel>
}

export type UpdateFeaturePackSubscriptionInput = {
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['String']
  isAlwaysEffective?: InputMaybe<Scalars['Boolean']>
  isTenantVisible?: InputMaybe<Scalars['Boolean']>
}

export type UpdateInspectionMethodInput = {
  constraint?: InputMaybe<ThingInspectionConstraint>
  fieldData?: InputMaybe<Array<InspectionMethodFieldDataInput>>
  id: Scalars['Int']
  material?: InputMaybe<Scalars['String']>
  method?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<InspectionRepeatInput>
  standard?: InputMaybe<Scalars['String']>
  standardCostTime?: InputMaybe<Scalars['Float']>
  target?: InputMaybe<Scalars['String']>
  uploadAttachment?: InputMaybe<Scalars['Boolean']>
}

export type UpdateInspectionPointInput = {
  area?: InputMaybe<IntIdInput>
  category?: InputMaybe<InspectionPointCategory>
  code?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<IdInput>
}

export type UpdateLatestThingDataInput = {
  isLent: Scalars['Boolean']
  line?: InputMaybe<Scalars['String']>
  status: CombaCockpitStatus
  thing: IdInput
}

export type UpdateMaintenanceMethodInput = {
  department?: InputMaybe<Array<StringIdInput>>
  id: Scalars['Int']
  label?: InputMaybe<Array<Scalars['String']>>
  level?: InputMaybe<MaintenanceLevel>
  material?: InputMaybe<Scalars['String']>
  method?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<MaintenanceRepeatInput>
  sparePartItem?: InputMaybe<Array<MaintenanceMethodSparePartItemInput>>
  standard?: InputMaybe<Scalars['String']>
  standardCostTime?: InputMaybe<Scalars['Float']>
  target?: InputMaybe<Scalars['String']>
  thingArea?: InputMaybe<Array<IntIdInput>>
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type UpdateMeInput = {
  avatar?: InputMaybe<IdInput>
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  unreadMessageStyleConfig?: InputMaybe<UnreadMessageStyleConfigInput>
}

export type UpdateMyBacklogInput = {
  id: Scalars['String']
  /** 稍后处理 */
  isLater?: InputMaybe<Scalars['Boolean']>
}

export type UpdateMyTenantInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<StringIdInput>
  code?: InputMaybe<Scalars['String']>
  contact?: InputMaybe<Scalars['String']>
  county?: InputMaybe<StringIdInput>
  email?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<StringIdInput>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  platProducts?: InputMaybe<Array<StringIdInput>>
  province?: InputMaybe<StringIdInput>
  shortName?: InputMaybe<Scalars['String']>
  uscc?: InputMaybe<Scalars['String']>
}

export type UpdateOAuth2AuthenticationConfigurationInput = {
  /** access_token在token响应中的取值路径（JsonPath），如果为空，默认为"access_token" */
  accessTokenAttributePath?: InputMaybe<Scalars['String']>
  accountAttributePath?: InputMaybe<Scalars['String']>
  authorizationUrl?: InputMaybe<Scalars['String']>
  /** 客户端认证方式 */
  clientAuthenticationMethod?: InputMaybe<AuthenticationMethod>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  /** 登录时是否更新用户信息 */
  doUpdateLocalUserInfoWhenLogin?: InputMaybe<Scalars['Boolean']>
  emailAttributePath?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  isActive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  nameAttributePath?: InputMaybe<Scalars['String']>
  phoneNumberAttributePath?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  /** 是否支持state参数 */
  supportState?: InputMaybe<Scalars['Boolean']>
  /** token响应格式 */
  tokenResponseFormat?: InputMaybe<ResponseFormat>
  tokenUrl?: InputMaybe<Scalars['String']>
  /** user_info鉴权方式 */
  userInfoAuthenticationMethod?: InputMaybe<AuthenticationMethod>
  /** user_info请求方式 */
  userInfoRequestMethod?: InputMaybe<UserInfoRequestMethod>
  /** user_info响应格式 */
  userInfoResponseFormat?: InputMaybe<ResponseFormat>
  userInfoUrl?: InputMaybe<Scalars['String']>
}

export type UpdateOpenIdConnect1AuthenticationConfigurationInput = {
  accountAttributePath?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  configurationUrl?: InputMaybe<Scalars['String']>
  /** 登录时是否更新用户信息 */
  doUpdateLocalUserInfoWhenLogin?: InputMaybe<Scalars['Boolean']>
  emailAttributePath?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  isActive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  nameAttributePath?: InputMaybe<Scalars['String']>
  phoneNumberAttributePath?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
}

export type UpdateOrganizationInput = {
  code?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  level?: InputMaybe<Scalars['Int']>
  manager?: InputMaybe<StringIdInput>
  name?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<StringIdInput>
}

export type UpdateOutsideCalibrateInput = {
  applyForAt?: InputMaybe<Scalars['Timestamp']>
  calibrateAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
  payAt?: InputMaybe<Scalars['Timestamp']>
  payStatus?: InputMaybe<OutsideCalibratePayStatus>
  remark?: InputMaybe<Scalars['String']>
}

export type UpdatePlatProductInput = {
  description?: InputMaybe<Scalars['String']>
  durationMonth?: InputMaybe<Scalars['Int']>
  featurePack?: InputMaybe<Array<StringIdInput>>
  id: Scalars['String']
  isAlwaysEffective?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  selectable?: InputMaybe<Scalars['Boolean']>
  shortName?: InputMaybe<Scalars['String']>
}

export type UpdatePlatformAnnouncementConfigInput = {
  /** 指定应用 */
  apps?: InputMaybe<Array<StringIdInput>>
  /** 附件 */
  attachments?: InputMaybe<Array<StringIdInput>>
  /** 内容 */
  content?: InputMaybe<Scalars['JSON']>
  /** 封面 */
  cover?: InputMaybe<Scalars['String']>
  /** 指定失效时间 */
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  /** 失效时间 */
  expiredKind?: InputMaybe<AnnouncementExpiredKind>
  id: Scalars['String']
  /** 是否是草稿 */
  isDraft?: InputMaybe<Scalars['Boolean']>
  /** 发布时间 */
  publishKind?: InputMaybe<AnnouncementPublishKind>
  /** 可见人员 */
  staffRange?: InputMaybe<PlatformAnnouncementStaffRange>
  /** 指定行业 */
  tenantIndustries?: InputMaybe<Array<StringIdInput>>
  /** 指定平台 */
  tenants?: InputMaybe<Array<StringIdInput>>
  /** 定时发布时间 */
  timePublishAt?: InputMaybe<Scalars['Timestamp']>
  /** 标题 */
  title?: InputMaybe<Scalars['String']>
  /** 可见范围 */
  visibleRange?: InputMaybe<PlatformAnnouncementVisibleRange>
}

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateSelfWecomAppInput = {
  agentId?: InputMaybe<Scalars['String']>
  checkFileId?: InputMaybe<Scalars['ID']>
  corpId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  secret?: InputMaybe<Scalars['String']>
}

export type UpdateSparePartClaimInput = {
  date?: InputMaybe<Scalars['Timestamp']>
  department?: InputMaybe<IdInput>
  id: Scalars['Int']
  item?: InputMaybe<Array<SparePartClaimItemInput>>
  reason?: InputMaybe<SparePartClaimReason>
  remark?: InputMaybe<Scalars['String']>
}

export type UpdateSparePartInput = {
  /** 备件附件 */
  attachment?: InputMaybe<Array<IdInput>>
  /** 备件类别 */
  category?: InputMaybe<IntIdInput>
  /** 经销商 */
  distributor?: InputMaybe<Scalars['String']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  id: Scalars['Int']
  /** 生产厂家 */
  manufacturer?: InputMaybe<Scalars['String']>
  /** 型号 */
  model?: InputMaybe<Scalars['String']>
  /** 备件名称 */
  name?: InputMaybe<Scalars['String']>
  /** 备注 */
  remark?: InputMaybe<Scalars['String']>
  /** 安全库存 */
  safeInventory?: InputMaybe<EamNumberRangeInput>
  /** 规格 */
  specification?: InputMaybe<Scalars['String']>
  unitPrice?: InputMaybe<Scalars['Float']>
}

export type UpdateStaffInput = {
  avatar?: InputMaybe<IdInput>
  businessGroups?: InputMaybe<Array<StringIdInput>>
  email?: InputMaybe<Scalars['String']>
  groups?: InputMaybe<Array<StringIdInput>>
  id: Scalars['String']
  jobNumber?: InputMaybe<Scalars['String']>
  jobStatus?: InputMaybe<StaffJobStatus>
  jobType?: InputMaybe<StaffJobType>
  name?: InputMaybe<Scalars['String']>
  organizations?: InputMaybe<Array<StringIdInput>>
  phoneNumber?: InputMaybe<Scalars['String']>
  positions?: InputMaybe<Array<StringIdInput>>
  remark?: InputMaybe<Scalars['String']>
}

export type UpdateStaffPositionInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateTenantAnnouncementConfigInput = {
  /** 附件 */
  attachments?: InputMaybe<Array<StringIdInput>>
  /** 内容 */
  content?: InputMaybe<Scalars['JSON']>
  /** 封面 */
  cover?: InputMaybe<Scalars['String']>
  /** 指定失效时间 */
  expiredAt?: InputMaybe<Scalars['Timestamp']>
  /** 失效时间 */
  expiredKind?: InputMaybe<AnnouncementExpiredKind>
  id: Scalars['String']
  /** 是否是草稿 */
  isDraft?: InputMaybe<Scalars['Boolean']>
  /** 指定组织 */
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 发布时间 */
  publishKind?: InputMaybe<AnnouncementPublishKind>
  /** 指定角色 */
  roles?: InputMaybe<Array<StringIdInput>>
  /** 指定人员 */
  staffs?: InputMaybe<Array<StringIdInput>>
  /** 定时发布时间 */
  timePublishAt?: InputMaybe<Scalars['Timestamp']>
  /** 标题 */
  title?: InputMaybe<Scalars['String']>
  /** 可见范围 */
  visibleRange?: InputMaybe<TenantAnnouncementVisibleRange>
}

export type UpdateTenantInput = {
  address?: InputMaybe<Scalars['String']>
  businessLicenseImage?: InputMaybe<IdInput>
  city?: InputMaybe<StringIdInput>
  code?: InputMaybe<Scalars['String']>
  contact?: InputMaybe<Scalars['String']>
  county?: InputMaybe<StringIdInput>
  email?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  /** 企业所属行业 */
  industry?: InputMaybe<StringIdInput>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  province?: InputMaybe<StringIdInput>
  /** 统一社会信用代码: Unified Social Credit Code */
  uscc?: InputMaybe<Scalars['String']>
}

export type UpdateTenantPlaceInput = {
  code?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
}

export type UpdateThingAreaCodeInput = {
  code: Scalars['String']
  id: Scalars['Int']
}

export type UpdateThingAreaInput = {
  code?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateThingBarLabelInput = {
  activateBarCode?: InputMaybe<Scalars['Boolean']>
  activateCustomText?: InputMaybe<Scalars['Boolean']>
  activateField?: InputMaybe<Scalars['Boolean']>
  activateLogo?: InputMaybe<Scalars['Boolean']>
  barCodeType?: InputMaybe<BarCodeType>
  boardLayout?: InputMaybe<Scalars['JSON']>
  customTexts?: InputMaybe<Array<EamCustomTextInput>>
  fieldKey?: InputMaybe<Array<Scalars['String']>>
  fontBold?: InputMaybe<Scalars['Boolean']>
  fontSize?: InputMaybe<Scalars['Float']>
  height?: InputMaybe<Scalars['Int']>
  id: Scalars['Int']
  logo?: InputMaybe<IdInput>
  name?: InputMaybe<Scalars['String']>
  previewImage?: InputMaybe<Scalars['String']>
  printObjectValue?: InputMaybe<EamBarCodePrintObjectValue>
  showBarCode?: InputMaybe<Scalars['Boolean']>
  width?: InputMaybe<Scalars['Int']>
}

export type UpdateThingBorrowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  departmentOfApplicant?: InputMaybe<IdInput>
  expected?: InputMaybe<Scalars['TimestampRange']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  id: Scalars['Int']
  reason?: InputMaybe<Scalars['String']>
  thing?: InputMaybe<IdInput>
}

export type UpdateThingByCodeInput = {
  acceptanceAt?: InputMaybe<Scalars['Timestamp']>
  accountType?: InputMaybe<ThingAccountType>
  accountingDepartment?: InputMaybe<IdInput>
  activatedAt?: InputMaybe<Scalars['Timestamp']>
  alertAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseNum?: InputMaybe<Scalars['String']>
  area?: InputMaybe<IntIdInput>
  arrivedAt?: InputMaybe<Scalars['Timestamp']>
  assetNormalizationAt?: InputMaybe<Scalars['Timestamp']>
  attachment?: InputMaybe<Array<IdInput>>
  bookValue?: InputMaybe<Scalars['Float']>
  brand?: InputMaybe<Scalars['String']>
  /** 校准配置 */
  calibrateCode?: InputMaybe<Scalars['String']>
  calibrateMethod?: InputMaybe<CalibrateMethod>
  calibrateRepeat?: InputMaybe<CalibrateRepeatInput>
  calibrateResult?: InputMaybe<CalibrateResult>
  canBorrowed?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<IntIdInput>
  code: Scalars['String']
  contractNum?: InputMaybe<Scalars['String']>
  department?: InputMaybe<IdInput>
  depreciationOfYear?: InputMaybe<Scalars['Int']>
  depreciationRate?: InputMaybe<Scalars['Float']>
  depreciationRateOfMonth?: InputMaybe<Scalars['Float']>
  desc?: InputMaybe<Scalars['String']>
  distributor?: InputMaybe<Scalars['String']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  finalValue?: InputMaybe<Scalars['Float']>
  fuselageCode?: InputMaybe<Scalars['String']>
  groupFile?: InputMaybe<Array<GroupFileInput>>
  image?: InputMaybe<Array<IdInput>>
  installedAt?: InputMaybe<Scalars['Timestamp']>
  label?: InputMaybe<Array<IntIdInput>>
  lastCalibrateAt?: InputMaybe<Scalars['Timestamp']>
  leaseBeginAt?: InputMaybe<Scalars['Timestamp']>
  leaseFinishAt?: InputMaybe<Scalars['Timestamp']>
  leaseNum?: InputMaybe<Scalars['String']>
  machineNumber?: InputMaybe<Scalars['String']>
  maintainer?: InputMaybe<Array<StringIdInput>>
  manager?: InputMaybe<StringIdInput>
  manufacturer?: InputMaybe<Scalars['String']>
  modelNum?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  onState?: InputMaybe<OnState>
  performanceStatus?: InputMaybe<ThingPerformanceStatus>
  poNum?: InputMaybe<Scalars['String']>
  predictResidualRate?: InputMaybe<Scalars['Float']>
  produceAt?: InputMaybe<Scalars['Timestamp']>
  purchasePrice?: InputMaybe<Scalars['Float']>
  purchaseType?: InputMaybe<ThingPurchaseType>
  purchasedAt?: InputMaybe<Scalars['Timestamp']>
  sapThingCode?: InputMaybe<Scalars['String']>
  serialNumber?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
  storageType?: InputMaybe<ThingStorageType>
  thingGroup?: InputMaybe<IdInput>
  thingSubjectCode?: InputMaybe<Scalars['String']>
  transferAt?: InputMaybe<Scalars['Timestamp']>
  usedYear?: InputMaybe<Scalars['Float']>
  /** 保修截止日期 */
  warrantyDeadlineAt?: InputMaybe<Scalars['Timestamp']>
  warrantyInstitutions?: InputMaybe<Scalars['String']>
  warrantyMethod?: InputMaybe<ThingWarrantyMethod>
  /** 保修期限 */
  warrantyPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修临期提醒 */
  warrantyRemindPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修起始日期 */
  warrantyStartAt?: InputMaybe<Scalars['Timestamp']>
  yearsOfUse?: InputMaybe<Scalars['Float']>
}

export type UpdateThingCalibrateOperatorInput = {
  department: Array<IdInput>
  staff: StringIdInput
}

export type UpdateThingCategoryCodeInput = {
  code: Scalars['String']
  id: Scalars['Int']
}

export type UpdateThingCategoryInput = {
  code?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateThingCompleteFileRuleInput = {
  attachmentField: Array<IntIdInput>
  id: Scalars['Int']
  thingCategory?: InputMaybe<Array<IntIdInput>>
}

export type UpdateThingFunctionDepartmentsInput = {
  defaultCalibrateUser?: InputMaybe<IdInput>
  id: Array<Scalars['Int']>
}

export type UpdateThingGroupInput = {
  code?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<IdInput>
}

export type UpdateThingInput = {
  acceptanceAt?: InputMaybe<Scalars['Timestamp']>
  accountType?: InputMaybe<ThingAccountType>
  accountingDepartment?: InputMaybe<IdInput>
  acquisitionCost?: InputMaybe<Scalars['Float']>
  activatedAt?: InputMaybe<Scalars['Timestamp']>
  alertAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseAt?: InputMaybe<Scalars['Timestamp']>
  applyForPurchaseNum?: InputMaybe<Scalars['String']>
  area?: InputMaybe<IntIdInput>
  arrivedAt?: InputMaybe<Scalars['Timestamp']>
  assetNormalizationAt?: InputMaybe<Scalars['Timestamp']>
  attachment?: InputMaybe<Array<IdInput>>
  bookValue?: InputMaybe<Scalars['Float']>
  brand?: InputMaybe<Scalars['String']>
  /** 校准配置 */
  calibrateCode?: InputMaybe<Scalars['String']>
  calibrateMethod?: InputMaybe<CalibrateMethod>
  calibrateRepeat?: InputMaybe<CalibrateRepeatInput>
  canBorrowed?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<IntIdInput>
  /** 编码前缀（定制） */
  codePrefix?: InputMaybe<CodePrefix>
  contractNum?: InputMaybe<Scalars['String']>
  department?: InputMaybe<IdInput>
  depreciationOfYear?: InputMaybe<Scalars['Float']>
  depreciationRate?: InputMaybe<Scalars['Float']>
  depreciationRateOfMonth?: InputMaybe<Scalars['Float']>
  desc?: InputMaybe<Scalars['String']>
  distributor?: InputMaybe<Scalars['String']>
  /** 自定义表单 */
  fieldData?: InputMaybe<Scalars['JSON']>
  finalValue?: InputMaybe<Scalars['Float']>
  fuselageCode?: InputMaybe<Scalars['String']>
  groupFile?: InputMaybe<Array<GroupFileInput>>
  id: Scalars['ID']
  image?: InputMaybe<Array<IdInput>>
  installedAt?: InputMaybe<Scalars['Timestamp']>
  label?: InputMaybe<Array<IntIdInput>>
  leaseBeginAt?: InputMaybe<Scalars['Timestamp']>
  leaseFinishAt?: InputMaybe<Scalars['Timestamp']>
  leaseNum?: InputMaybe<Scalars['String']>
  machineNumber?: InputMaybe<Scalars['String']>
  maintainer?: InputMaybe<Array<StringIdInput>>
  manager?: InputMaybe<StringIdInput>
  manufacturer?: InputMaybe<Scalars['String']>
  modelNum?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  onState?: InputMaybe<OnState>
  performanceStatus?: InputMaybe<ThingPerformanceStatus>
  poNum?: InputMaybe<Scalars['String']>
  predictResidualRate?: InputMaybe<Scalars['Float']>
  produceAt?: InputMaybe<Scalars['Timestamp']>
  purchasePrice?: InputMaybe<Scalars['Float']>
  purchaseType?: InputMaybe<ThingPurchaseType>
  purchasedAt?: InputMaybe<Scalars['Timestamp']>
  /** rfid */
  rfid?: InputMaybe<Scalars['String']>
  sapThingCode?: InputMaybe<Scalars['String']>
  serialNumber?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
  storageType?: InputMaybe<ThingStorageType>
  thingGroup?: InputMaybe<IdInput>
  thingSubjectCode?: InputMaybe<Scalars['String']>
  transferAt?: InputMaybe<Scalars['Timestamp']>
  usedYear?: InputMaybe<Scalars['Float']>
  /** 保修截止日期 */
  warrantyDeadlineAt?: InputMaybe<Scalars['Timestamp']>
  warrantyInstitutions?: InputMaybe<Scalars['String']>
  warrantyMethod?: InputMaybe<ThingWarrantyMethod>
  /** 保修期限 */
  warrantyPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修临期提醒 */
  warrantyRemindPeriod?: InputMaybe<WarrantyRepeatInput>
  /** 保修起始日期 */
  warrantyStartAt?: InputMaybe<Scalars['Timestamp']>
  yearsOfUse?: InputMaybe<Scalars['Float']>
}

export type UpdateThingInspectionInput = {
  attachment?: InputMaybe<Array<IdInput>>
  executeAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  inspectionMethod?: InputMaybe<Array<IntIdInput>>
  inspectionPoint?: InputMaybe<Array<IntIdInput>>
  operator: IdInput
  remark?: InputMaybe<Scalars['String']>
  team: IntIdInput
}

export type UpdateThingInspectionScheduleInput = {
  executeAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  inspectionMethod?: InputMaybe<Array<IdInput>>
  inspectionPoint?: InputMaybe<Array<IntIdInput>>
  inspectionType?: InputMaybe<ThingInspectionType>
  operator?: InputMaybe<IdInput>
  reason?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<Array<Scalars['Timestamp']>>
  splitMethod?: InputMaybe<ThingInspectionSplitMethod>
  team?: InputMaybe<IntIdInput>
}

export type UpdateThingInventoryRedundantRecordInput = {
  area?: InputMaybe<IntIdInput>
  category?: InputMaybe<IntIdInput>
  department?: InputMaybe<IdInput>
  id: Scalars['Int']
  image?: InputMaybe<Array<IdInput>>
  manager?: InputMaybe<StringIdInput>
  modelNum?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  specification?: InputMaybe<Scalars['String']>
  storageAddr?: InputMaybe<Scalars['String']>
}

export type UpdateThingLocationInput = {
  id?: InputMaybe<Scalars['ID']>
  thingArea?: InputMaybe<IntIdInput>
}

export type UpdateThingMaintenanceInput = {
  attachment?: InputMaybe<Array<IdInput>>
  executeAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  maintenanceMethod?: InputMaybe<Array<IntIdInput>>
  operator: IdInput
  remark?: InputMaybe<Scalars['String']>
  team: IntIdInput
  thing?: InputMaybe<Array<IdInput>>
}

export type UpdateThingMaintenanceScheduleInput = {
  executeAt?: InputMaybe<Scalars['Timestamp']>
  id: Scalars['Int']
  maintenanceMethod?: InputMaybe<Array<IdInput>>
  operator?: InputMaybe<IdInput>
  reason?: InputMaybe<Scalars['String']>
  repeat?: InputMaybe<Array<Scalars['Timestamp']>>
  team?: InputMaybe<IntIdInput>
  thing?: InputMaybe<Array<IdInput>>
  type?: InputMaybe<ThingMaintenanceType>
}

export type UpdateThingRepairFaultInput = {
  category?: InputMaybe<Array<IntIdInput>>
  code?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateThingRepairKnowhowInput = {
  attachment?: InputMaybe<Array<IdInput>>
  body?: InputMaybe<Scalars['String']>
  fault?: InputMaybe<Array<IntIdInput>>
  id: Scalars['Int']
  keyword?: InputMaybe<Array<Scalars['String']>>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateThingRepairNotificationConditionInput = {
  duty?: InputMaybe<Array<ThingRepairDuty>>
  filterRange?: InputMaybe<ThingRepairNotificationRangeInput>
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Array<StringIdInput>>
  staff?: InputMaybe<Array<StringIdInput>>
}

export type UpdateThingRepairRuleInput = {
  filterRange?: InputMaybe<Array<InputMaybe<Array<ThingRepairRuleRangeInput>>>>
  id: Scalars['Int']
  leaderOnly?: InputMaybe<Scalars['Boolean']>
  method?: InputMaybe<ThingRepairDispatch>
  name?: InputMaybe<Scalars['String']>
  team?: InputMaybe<IntIdInput>
  teamMember?: InputMaybe<IntIdInput>
}

export type UpdateThingsInput = {
  calibrateMethod?: InputMaybe<CalibrateMethod>
  calibrateRepeat?: InputMaybe<CalibrateRepeatInput>
  id: Array<Scalars['ID']>
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  isAccountEnabled?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  organizations?: InputMaybe<Array<StringIdInput>>
  phoneNumber?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<StringIdInput>>
}

/** 更改用户下的一个设备组为另一个 */
export type UpdateUserThingGroupInput = {
  newThingGroup: IdInput
  oldThingGroup: IdInput
}

export type UploadInventoryRecordByInventoryTicketInput = {
  records: Array<SetThingInventoryRecordInput>
  redundantRecords: Array<CreateThingInventoryRedundantRecordInput>
  ticket: IntIdInput
}

export type UseSparePartClaimInput = {
  id: Scalars['Int']
  item?: InputMaybe<Array<UseSparePartClaimItemInput>>
}

export type UseSparePartClaimItemInput = {
  remark?: InputMaybe<Scalars['String']>
  sparePart: IntIdInput
  usedThing?: InputMaybe<Array<UsedSparePartClaimThingInput>>
}

export type UsedSparePartClaimThingInput = {
  quantity: Scalars['Int']
  thing?: InputMaybe<IdInput>
}

export type UserFilterInput = {
  accounts?: InputMaybe<Array<Scalars['String']>>
  includeChildrenOrganizations?: InputMaybe<Scalars['Boolean']>
  includeDisabledUsers?: InputMaybe<Scalars['Boolean']>
  isAccountEnabled?: InputMaybe<Scalars['Boolean']>
  isAdmin?: InputMaybe<Scalars['Boolean']>
  organizations?: InputMaybe<Array<StringIdInput>>
  /** 用户满足任一角色即可被筛选出 */
  roles?: InputMaybe<Array<StringIdInput>>
  /** search account / name / phoneNumber */
  search?: InputMaybe<Scalars['String']>
  /** 指定字段进行搜索 */
  searchBy?: InputMaybe<Array<Scalars['String']>>
}

export type UserInfoRequestMethod = 'GET' | 'POST'

export type UserListFilter = {
  company?: InputMaybe<IntIdInput>
  currentOnly?: InputMaybe<Scalars['Boolean']>
  department?: InputMaybe<Array<IntIdInput>>
  ids?: InputMaybe<Array<StringIdInput>>
  isActive?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<Array<IntIdInput>>
  search?: InputMaybe<Scalars['String']>
  searchName?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Array<UserType>>
  uid?: InputMaybe<Array<Scalars['String']>>
}

export type UserOrigin = 'ADDED' | 'REGISTED'

export type UserType = 'COMPANY_ADMIN' | 'COMPANY_NORMAL'

/**
 * INCONSISTENT: 盘点记录和云端不一致
 * STAFF_NOT_INCLUDE: 盘点记录对应的云端可盘人员不再包含当前账号
 * EXPIRED: 部分盘盈记录字段已失效
 */
export type ValidateThingInventoryOfflineDataResult =
  | 'EXPIRED'
  | 'INCONSISTENT'
  | 'STAFF_NOT_INCLUDE'

export type WarehouseCategoryRule = 'ALL' | 'ASSIGN'

export type WarrantyRepeatInput = {
  frequency?: InputMaybe<Scalars['Int']>
  period: EamRepeatPeriod
}

/** 水印内容 */
export type WatermarkContent =
  /** 账号 */
  | 'ACCOUNT'
  /** 当前日期 */
  | 'CURRENT_DATE'
  /** 工号 */
  | 'JOB_NUMBER'
  /** 手机号后四位 */
  | 'LAST_FOUR_PHONE_NUMBER'
  /** 姓名 */
  | 'NAME'
  /** 平台名称 */
  | 'PLATFORM_NAME'
  /** 企业名称 */
  | 'TENANT_NAME'

export type WatermarkDirection =
  /** 水平 */
  | 'HORIZONTAL'
  /** 倾斜 */
  | 'ROTATE'

export type WatermarkRangeInput = {
  appCodes?: InputMaybe<Array<Scalars['String']>>
  scope: WatermarkScope
}

export type WatermarkScope =
  /** 全部应用生效 */
  | 'ALL'
  /** 指定应用生效 */
  | 'APPS'
  /** 都不生效 */
  | 'NONE'

export type WecomAppInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type WecomAppLoginInput = {
  account: Scalars['String']
  password: Scalars['String']
  tenantId: Scalars['String']
}

export type WithdrawThingAbortInput = {
  id: Scalars['Int']
}

export type WithdrawThingApplyInput = {
  id: Scalars['Int']
}

export type WithdrawThingCalibrateInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  remark?: InputMaybe<Scalars['String']>
}

export type WithdrawThingInspectionInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  reason?: InputMaybe<ThingInspectionWithdrawReason>
  remark?: InputMaybe<Scalars['String']>
}

export type WithdrawThingInventoryTicketInput = {
  id: Scalars['Int']
  remark: Scalars['String']
}

export type WithdrawThingMissingFindInfoInput = {
  id: Scalars['Int']
}

export type WithdrawThingMissingInput = {
  id: Scalars['Int']
}

export type WithdrawThingMoveInput = {
  id: Scalars['Int']
}

export type WithdrawThingOffLeaseInput = {
  id: Scalars['Int']
}

export type WithdrawThingRentBackInput = {
  id: Scalars['Int']
}

export type WithdrawThingRentInput = {
  id: Scalars['Int']
}

export type WithdrawThingRepairInput = {
  attachment?: InputMaybe<Array<IdInput>>
  id: Scalars['Int']
  reason?: InputMaybe<ThingRepairWithdrawReason>
  remark?: InputMaybe<Scalars['String']>
}

export type WithdrawThingReturnInput = {
  id: Scalars['Int']
}

export type WithdrawThingSaleInput = {
  id: Scalars['Int']
}

export type WorkbenchCardsFilterInput = {
  /** 所属应用 */
  apps: Array<StringIdInput>
  /** 模糊搜索：name */
  search?: InputMaybe<Scalars['String']>
}

export type WriteoffSparePartOutboundInput = {
  id: Scalars['Int']
  item: Array<SparePartOutboundWriteoffItemInput>
  reason?: InputMaybe<Scalars['String']>
}

export type WriteoffSparePartReceiptInput = {
  id: Scalars['Int']
  item: Array<SparePartReceiptWriteoffItemInput>
  reason?: InputMaybe<Scalars['String']>
}

export type CountryFilter = {
  full?: InputMaybe<Scalars['Boolean']>
}

export type UccStackDataFilter = {
  company?: InputMaybe<IdInput>
  key: Scalars['String']
}

export type SendIdentityVerifyCodeToEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SendIdentityVerifyCodeToEmailMutation = {
  __typename?: 'Mutation'
  sendIdentityVerifyCodeToEmail: boolean
}

export type IsEmailVerifiedQueryVariables = Exact<{
  email: Scalars['String']
}>

export type IsEmailVerifiedQuery = {
  __typename?: 'Query'
  isEmailVerified: boolean
}

export type IsEmailExistsQueryVariables = Exact<{
  email: Scalars['String']
}>

export type IsEmailExistsQuery = {
  __typename?: 'Query'
  isEmailExists: boolean
}

export type LoginByEmailMutationVariables = Exact<{
  input: LoginByEmailInput
}>

export type LoginByEmailMutation = {
  __typename?: 'Mutation'
  loginByEmail: { __typename?: 'AuthInfo'; token: string; userId: string }
}

export type ChangePasswordByEmailMutationVariables = Exact<{
  input: ChangePasswordByEmailInput
}>

export type ChangePasswordByEmailMutation = {
  __typename?: 'Mutation'
  changePasswordByEmail: {
    __typename?: 'AuthInfo'
    token: string
    userId: string
  }
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: { __typename?: 'AuthInfo'; token: string; userId: string }
}

export type LoginConfigurationQueryVariables = Exact<{
  tenantCode?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type LoginConfigurationQuery = {
  __typename?: 'Query'
  loginConfiguration:
    | {
        __typename?: 'OAuth2LoginConfiguration'
        authenticationConfigurationName: string
        kind: LoginConfigurationKind
        loginUrl: string
      }
    | {
        __typename?: 'OpenIDConnect1LoginConfiguration'
        authenticationConfigurationName: string
        kind: LoginConfigurationKind
        loginUrl: string
      }
    | { __typename?: 'SystemLoginConfiguration'; kind: LoginConfigurationKind }
}

export type AccountExistsQueryVariables = Exact<{
  account: Scalars['String']
}>

export type AccountExistsQuery = {
  __typename?: 'Query'
  accountExists: boolean
}

export type SendIdentityVerifyCodeToPhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars['String']
}>

export type SendIdentityVerifyCodeToPhoneNumberMutation = {
  __typename?: 'Mutation'
  sendIdentityVerifyCodeToPhoneNumber: boolean
}

export type WecomLoginMutationVariables = Exact<{
  authCode: Scalars['String']
}>

export type WecomLoginMutation = {
  __typename?: 'Mutation'
  wecomLogin: { __typename?: 'AuthInfo'; token: string; userId: string }
}

export type LoginConfigOfTenantCodeQueryVariables = Exact<{
  tenantCode: Scalars['String']
}>

export type LoginConfigOfTenantCodeQuery = {
  __typename?: 'Query'
  loginConfigOfTenantCode: {
    __typename?: 'LoginConfig'
    defaultMode: LoginMode
    modes: Array<LoginMode>
  }
}

export type WecomAppLoginMutationVariables = Exact<{
  input: WecomAppLoginInput
}>

export type WecomAppLoginMutation = {
  __typename?: 'Mutation'
  wecomAppLogin: { __typename?: 'AuthInfo'; token: string; userId: string }
}

export type AuthByWechatCodeMutationVariables = Exact<{
  code: Scalars['String']
}>

export type AuthByWechatCodeMutation = {
  __typename?: 'Mutation'
  authByWechatCode: {
    __typename?: 'WechatAuthInfo'
    accessToken?: string | null
    token?: string | null
    userId?: string | null
  }
}

export type BindWechatStaffMutationVariables = Exact<{
  accessToken: Scalars['String']
}>

export type BindWechatStaffMutation = {
  __typename?: 'Mutation'
  bindWechatStaff: boolean
}

export type UnbindWechatStaffMutationVariables = Exact<{ [key: string]: never }>

export type UnbindWechatStaffMutation = {
  __typename?: 'Mutation'
  unbindWechatStaff: boolean
}

export type RegisterByPhoneNumberAllTenantMutationVariables = Exact<{
  input: RegisterByPhoneNumberAllTenantInput
}>

export type RegisterByPhoneNumberAllTenantMutation = {
  __typename?: 'Mutation'
  registerByPhoneNumberAllTenant: {
    __typename?: 'AuthInfo'
    token: string
    userId: string
  }
}

export type LoginByPhoneNumberAllTenantMutationVariables = Exact<{
  input: LoginByPhoneNumberAllTenantInput
}>

export type LoginByPhoneNumberAllTenantMutation = {
  __typename?: 'Mutation'
  loginByPhoneNumberAllTenant: {
    __typename?: 'AuthInfo'
    token: string
    userId: string
  }
}

export type ChangePasswordByPhoneNumberMutationVariables = Exact<{
  input: ChangePasswordByPhoneNumberInput
}>

export type ChangePasswordByPhoneNumberMutation = {
  __typename?: 'Mutation'
  changePasswordByPhoneNumber: {
    __typename?: 'AuthInfo'
    token: string
    userId: string
  }
}

export type ThemeOfTenantCodeQueryVariables = Exact<{
  tenantCode: Scalars['String']
}>

export type ThemeOfTenantCodeQuery = {
  __typename?: 'Query'
  themeOfTenantCode?: {
    __typename?: 'Theme'
    id: string
    hideLogo?: boolean | null
    greetings?: string | null
    loginBackground?: { __typename?: 'Image'; url: string } | null
  } | null
}

export type MyAppListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type MyAppListQuery = {
  __typename?: 'Query'
  myAppList: {
    __typename?: 'AppList'
    data: Array<{
      __typename?: 'App'
      code: string
      kind: AppKind
      jumpKind: JumpKind
      url?: string | null
    }>
  }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    account: string
    phoneNumber?: string | null
    name: string
    jobNumber: string
    tenant: {
      __typename?: 'Tenant'
      isCertificationResultNotified: boolean
      name: string
      code: string
    }
  }
}

export type ThemeOfMyTenantQueryVariables = Exact<{ [key: string]: never }>

export type ThemeOfMyTenantQuery = {
  __typename?: 'Query'
  themeOfMyTenant: {
    __typename?: 'Theme'
    selectedContent: Array<WatermarkContent>
    density: Density
    direction: WatermarkDirection
    fontSize: FontSize
    platformName?: string | null
    range: {
      __typename?: 'WatermarkRange'
      appCodes?: Array<string> | null
      scope: WatermarkScope
    }
  }
}

export type MyTenantAppListQueryVariables = Exact<{
  filter?: InputMaybe<MyTenantAppListFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type MyTenantAppListQuery = {
  __typename?: 'Query'
  myTenantAppList: {
    __typename?: 'AppList'
    data: Array<{ __typename?: 'App'; code: string }>
  }
}

export type TenantPasswordConfigQueryVariables = Exact<{
  tenantCode: Scalars['String']
}>

export type TenantPasswordConfigQuery = {
  __typename?: 'Query'
  tenantPasswordConfig: {
    __typename?: 'TenantPasswordConfig'
    characterSet: Array<PasswordCharacterSet>
    customInitialPassword?: string | null
    id: string
    initialPasswordMode: InitialPasswordMode
    initialPasswordRule?: InitialPasswordRule | null
    length: number
  }
}

export type TenantOfVerifiedEmailQueryVariables = Exact<{
  email: Scalars['String']
}>

export type TenantOfVerifiedEmailQuery = {
  __typename?: 'Query'
  tenantOfVerifiedEmail?: { __typename?: 'Tenant'; code: string } | null
}

export type AppByClientIdQueryVariables = Exact<{
  clientId: Scalars['String']
}>

export type AppByClientIdQuery = {
  __typename?: 'Query'
  appByClientId?: {
    __typename?: 'ClientApp'
    clientId: string
    code: string
    id: string
    name: string
  } | null
}

export type IsPhoneNumberVerifiedQueryVariables = Exact<{
  phoneNumber: Scalars['String']
}>

export type IsPhoneNumberVerifiedQuery = {
  __typename?: 'Query'
  isPhoneNumberVerified: boolean
}

export type LoginByPhoneNumberMutationVariables = Exact<{
  input: LoginByPhoneNumberInput
}>

export type LoginByPhoneNumberMutation = {
  __typename?: 'Mutation'
  loginByPhoneNumber: { __typename?: 'AuthInfo'; token: string; userId: string }
}

export type GetAuthTokenByDingdingUserMutationVariables = Exact<{
  input: GetAuthTokenByDingdingUserInput
}>

export type GetAuthTokenByDingdingUserMutation = {
  __typename?: 'Mutation'
  getAuthTokenByDingdingUser: string
}

export const SendIdentityVerifyCodeToEmailDocument = gql`
  mutation sendIdentityVerifyCodeToEmail($email: String!) {
    sendIdentityVerifyCodeToEmail(email: $email)
  }
`
export function useSendIdentityVerifyCodeToEmailMutation(
  options: Apollo.MutationHookOptions<
    SendIdentityVerifyCodeToEmailMutation,
    SendIdentityVerifyCodeToEmailMutationVariables
  > & {
    initialVariables?: SendIdentityVerifyCodeToEmailMutationVariables
  } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      SendIdentityVerifyCodeToEmailMutation,
      SendIdentityVerifyCodeToEmailMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<SendIdentityVerifyCodeToEmailMutation>>,
  Apollo.MutationResult<SendIdentityVerifyCodeToEmailMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    SendIdentityVerifyCodeToEmailMutation,
    SendIdentityVerifyCodeToEmailMutationVariables
  >(SendIdentityVerifyCodeToEmailDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type SendIdentityVerifyCodeToEmailMutationHookResult = ReturnType<
  typeof useSendIdentityVerifyCodeToEmailMutation
>
export async function sendIdentityVerifyCodeToEmailMutation(
  options: Omit<
    Apollo.MutationOptions<
      SendIdentityVerifyCodeToEmailMutation,
      SendIdentityVerifyCodeToEmailMutationVariables
    >,
    'mutation'
  > & { initialVariables?: SendIdentityVerifyCodeToEmailMutationVariables },
): Promise<Apollo.FetchResult<SendIdentityVerifyCodeToEmailMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    SendIdentityVerifyCodeToEmailMutation,
    SendIdentityVerifyCodeToEmailMutationVariables
  >({
    mutation: SendIdentityVerifyCodeToEmailDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const IsEmailVerifiedDocument = gql`
  query isEmailVerified($email: String!) {
    isEmailVerified(email: $email)
  }
`
export function useIsEmailVerifiedQuery(
  options: Apollo.QueryHookOptions<
    IsEmailVerifiedQuery,
    IsEmailVerifiedQueryVariables
  >,
): Apollo.QueryResult<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables> {
  return Apollo.useQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
    IsEmailVerifiedDocument,
    options,
  )
}
export function useIsEmailVerifiedLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    IsEmailVerifiedQuery,
    IsEmailVerifiedQueryVariables
  >,
): [
  (options: Apollo.QueryLazyOptions<IsEmailVerifiedQueryVariables>) => void,
  Apollo.LazyQueryResult<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>,
] {
  return Apollo.useLazyQuery<
    IsEmailVerifiedQuery,
    IsEmailVerifiedQueryVariables
  >(IsEmailVerifiedDocument, options)
}
export type IsEmailVerifiedQueryHookResult = ReturnType<
  typeof useIsEmailVerifiedQuery
>
export type IsEmailVerifiedLazyQueryHookResult = ReturnType<
  typeof useIsEmailVerifiedLazyQuery
>
export async function isEmailVerifiedQuery(
  options: Omit<
    Apollo.QueryOptions<IsEmailVerifiedQueryVariables, IsEmailVerifiedQuery>,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<IsEmailVerifiedQuery>> {
  return await apolloClient.query<
    IsEmailVerifiedQuery,
    IsEmailVerifiedQueryVariables
  >({
    query: IsEmailVerifiedDocument,
    ...options,
  })
}
export const IsEmailExistsDocument = gql`
  query isEmailExists($email: String!) {
    isEmailExists(email: $email)
  }
`
export function useIsEmailExistsQuery(
  options: Apollo.QueryHookOptions<
    IsEmailExistsQuery,
    IsEmailExistsQueryVariables
  >,
): Apollo.QueryResult<IsEmailExistsQuery, IsEmailExistsQueryVariables> {
  return Apollo.useQuery<IsEmailExistsQuery, IsEmailExistsQueryVariables>(
    IsEmailExistsDocument,
    options,
  )
}
export function useIsEmailExistsLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    IsEmailExistsQuery,
    IsEmailExistsQueryVariables
  >,
): [
  (options: Apollo.QueryLazyOptions<IsEmailExistsQueryVariables>) => void,
  Apollo.LazyQueryResult<IsEmailExistsQuery, IsEmailExistsQueryVariables>,
] {
  return Apollo.useLazyQuery<IsEmailExistsQuery, IsEmailExistsQueryVariables>(
    IsEmailExistsDocument,
    options,
  )
}
export type IsEmailExistsQueryHookResult = ReturnType<
  typeof useIsEmailExistsQuery
>
export type IsEmailExistsLazyQueryHookResult = ReturnType<
  typeof useIsEmailExistsLazyQuery
>
export async function isEmailExistsQuery(
  options: Omit<
    Apollo.QueryOptions<IsEmailExistsQueryVariables, IsEmailExistsQuery>,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<IsEmailExistsQuery>> {
  return await apolloClient.query<
    IsEmailExistsQuery,
    IsEmailExistsQueryVariables
  >({
    query: IsEmailExistsDocument,
    ...options,
  })
}
export const LoginByEmailDocument = gql`
  mutation loginByEmail($input: LoginByEmailInput!) {
    loginByEmail(input: $input) {
      token
      userId
    }
  }
`
export function useLoginByEmailMutation(
  options: Apollo.MutationHookOptions<
    LoginByEmailMutation,
    LoginByEmailMutationVariables
  > & { initialVariables?: LoginByEmailMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      LoginByEmailMutation,
      LoginByEmailMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<LoginByEmailMutation>>,
  Apollo.MutationResult<LoginByEmailMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    LoginByEmailMutation,
    LoginByEmailMutationVariables
  >(LoginByEmailDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type LoginByEmailMutationHookResult = ReturnType<
  typeof useLoginByEmailMutation
>
export async function loginByEmailMutation(
  options: Omit<
    Apollo.MutationOptions<LoginByEmailMutation, LoginByEmailMutationVariables>,
    'mutation'
  > & { initialVariables?: LoginByEmailMutationVariables },
): Promise<Apollo.FetchResult<LoginByEmailMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    LoginByEmailMutation,
    LoginByEmailMutationVariables
  >({
    mutation: LoginByEmailDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const ChangePasswordByEmailDocument = gql`
  mutation changePasswordByEmail($input: ChangePasswordByEmailInput!) {
    changePasswordByEmail(input: $input) {
      token
      userId
    }
  }
`
export function useChangePasswordByEmailMutation(
  options: Apollo.MutationHookOptions<
    ChangePasswordByEmailMutation,
    ChangePasswordByEmailMutationVariables
  > & { initialVariables?: ChangePasswordByEmailMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      ChangePasswordByEmailMutation,
      ChangePasswordByEmailMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<ChangePasswordByEmailMutation>>,
  Apollo.MutationResult<ChangePasswordByEmailMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    ChangePasswordByEmailMutation,
    ChangePasswordByEmailMutationVariables
  >(ChangePasswordByEmailDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type ChangePasswordByEmailMutationHookResult = ReturnType<
  typeof useChangePasswordByEmailMutation
>
export async function changePasswordByEmailMutation(
  options: Omit<
    Apollo.MutationOptions<
      ChangePasswordByEmailMutation,
      ChangePasswordByEmailMutationVariables
    >,
    'mutation'
  > & { initialVariables?: ChangePasswordByEmailMutationVariables },
): Promise<Apollo.FetchResult<ChangePasswordByEmailMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    ChangePasswordByEmailMutation,
    ChangePasswordByEmailMutationVariables
  >({
    mutation: ChangePasswordByEmailDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      userId
    }
  }
`
export function useLoginMutation(
  options: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables> & {
    initialVariables?: LoginMutationVariables
  } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      LoginMutation,
      LoginMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<LoginMutation>>,
  Apollo.MutationResult<LoginMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    {
      context: {
        initialVariables,
      },
      ...restOptions,
    },
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export async function loginMutation(
  options: Omit<
    Apollo.MutationOptions<LoginMutation, LoginMutationVariables>,
    'mutation'
  > & { initialVariables?: LoginMutationVariables },
): Promise<Apollo.FetchResult<LoginMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<LoginMutation, LoginMutationVariables>({
    mutation: LoginDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const LoginConfigurationDocument = gql`
  query loginConfiguration($tenantCode: String, $tenantId: String) {
    loginConfiguration(tenantCode: $tenantCode, tenantId: $tenantId) {
      kind
      ... on OAuth2LoginConfiguration {
        authenticationConfigurationName
        kind
        loginUrl
      }
      ... on OpenIDConnect1LoginConfiguration {
        authenticationConfigurationName
        kind
        loginUrl
      }
      ... on SystemLoginConfiguration {
        kind
      }
    }
  }
`
export function useLoginConfigurationQuery(
  options: Apollo.QueryHookOptions<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  > = {},
): Apollo.QueryResult<
  LoginConfigurationQuery,
  LoginConfigurationQueryVariables
> {
  return Apollo.useQuery<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  >(LoginConfigurationDocument, options)
}
export function useLoginConfigurationLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  >,
): [
  (options?: Apollo.QueryLazyOptions<LoginConfigurationQueryVariables>) => void,
  Apollo.LazyQueryResult<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  >(LoginConfigurationDocument, options)
}
export type LoginConfigurationQueryHookResult = ReturnType<
  typeof useLoginConfigurationQuery
>
export type LoginConfigurationLazyQueryHookResult = ReturnType<
  typeof useLoginConfigurationLazyQuery
>
export async function loginConfigurationQuery(
  options: Omit<
    Apollo.QueryOptions<
      LoginConfigurationQueryVariables,
      LoginConfigurationQuery
    >,
    'query'
  > = {},
): Promise<Apollo.ApolloQueryResult<LoginConfigurationQuery>> {
  return await apolloClient.query<
    LoginConfigurationQuery,
    LoginConfigurationQueryVariables
  >({
    query: LoginConfigurationDocument,
    ...options,
  })
}
export const AccountExistsDocument = gql`
  query accountExists($account: String!) {
    accountExists(account: $account)
  }
`
export function useAccountExistsQuery(
  options: Apollo.QueryHookOptions<
    AccountExistsQuery,
    AccountExistsQueryVariables
  >,
): Apollo.QueryResult<AccountExistsQuery, AccountExistsQueryVariables> {
  return Apollo.useQuery<AccountExistsQuery, AccountExistsQueryVariables>(
    AccountExistsDocument,
    options,
  )
}
export function useAccountExistsLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    AccountExistsQuery,
    AccountExistsQueryVariables
  >,
): [
  (options: Apollo.QueryLazyOptions<AccountExistsQueryVariables>) => void,
  Apollo.LazyQueryResult<AccountExistsQuery, AccountExistsQueryVariables>,
] {
  return Apollo.useLazyQuery<AccountExistsQuery, AccountExistsQueryVariables>(
    AccountExistsDocument,
    options,
  )
}
export type AccountExistsQueryHookResult = ReturnType<
  typeof useAccountExistsQuery
>
export type AccountExistsLazyQueryHookResult = ReturnType<
  typeof useAccountExistsLazyQuery
>
export async function accountExistsQuery(
  options: Omit<
    Apollo.QueryOptions<AccountExistsQueryVariables, AccountExistsQuery>,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<AccountExistsQuery>> {
  return await apolloClient.query<
    AccountExistsQuery,
    AccountExistsQueryVariables
  >({
    query: AccountExistsDocument,
    ...options,
  })
}
export const SendIdentityVerifyCodeToPhoneNumberDocument = gql`
  mutation sendIdentityVerifyCodeToPhoneNumber($phoneNumber: String!) {
    sendIdentityVerifyCodeToPhoneNumber(phoneNumber: $phoneNumber)
  }
`
export function useSendIdentityVerifyCodeToPhoneNumberMutation(
  options: Apollo.MutationHookOptions<
    SendIdentityVerifyCodeToPhoneNumberMutation,
    SendIdentityVerifyCodeToPhoneNumberMutationVariables
  > & {
    initialVariables?: SendIdentityVerifyCodeToPhoneNumberMutationVariables
  } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      SendIdentityVerifyCodeToPhoneNumberMutation,
      SendIdentityVerifyCodeToPhoneNumberMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<SendIdentityVerifyCodeToPhoneNumberMutation>>,
  Apollo.MutationResult<SendIdentityVerifyCodeToPhoneNumberMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    SendIdentityVerifyCodeToPhoneNumberMutation,
    SendIdentityVerifyCodeToPhoneNumberMutationVariables
  >(SendIdentityVerifyCodeToPhoneNumberDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type SendIdentityVerifyCodeToPhoneNumberMutationHookResult = ReturnType<
  typeof useSendIdentityVerifyCodeToPhoneNumberMutation
>
export async function sendIdentityVerifyCodeToPhoneNumberMutation(
  options: Omit<
    Apollo.MutationOptions<
      SendIdentityVerifyCodeToPhoneNumberMutation,
      SendIdentityVerifyCodeToPhoneNumberMutationVariables
    >,
    'mutation'
  > & {
    initialVariables?: SendIdentityVerifyCodeToPhoneNumberMutationVariables
  },
): Promise<Apollo.FetchResult<SendIdentityVerifyCodeToPhoneNumberMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    SendIdentityVerifyCodeToPhoneNumberMutation,
    SendIdentityVerifyCodeToPhoneNumberMutationVariables
  >({
    mutation: SendIdentityVerifyCodeToPhoneNumberDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const WecomLoginDocument = gql`
  mutation wecomLogin($authCode: String!) {
    wecomLogin(authCode: $authCode) {
      token
      userId
    }
  }
`
export function useWecomLoginMutation(
  options: Apollo.MutationHookOptions<
    WecomLoginMutation,
    WecomLoginMutationVariables
  > & { initialVariables?: WecomLoginMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      WecomLoginMutation,
      WecomLoginMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<WecomLoginMutation>>,
  Apollo.MutationResult<WecomLoginMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<WecomLoginMutation, WecomLoginMutationVariables>(
    WecomLoginDocument,
    {
      context: {
        initialVariables,
      },
      ...restOptions,
    },
  )
}
export type WecomLoginMutationHookResult = ReturnType<
  typeof useWecomLoginMutation
>
export async function wecomLoginMutation(
  options: Omit<
    Apollo.MutationOptions<WecomLoginMutation, WecomLoginMutationVariables>,
    'mutation'
  > & { initialVariables?: WecomLoginMutationVariables },
): Promise<Apollo.FetchResult<WecomLoginMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    WecomLoginMutation,
    WecomLoginMutationVariables
  >({
    mutation: WecomLoginDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const LoginConfigOfTenantCodeDocument = gql`
  query loginConfigOfTenantCode($tenantCode: String!) {
    loginConfigOfTenantCode(tenantCode: $tenantCode) {
      defaultMode
      modes
    }
  }
`
export function useLoginConfigOfTenantCodeQuery(
  options: Apollo.QueryHookOptions<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >,
): Apollo.QueryResult<
  LoginConfigOfTenantCodeQuery,
  LoginConfigOfTenantCodeQueryVariables
> {
  return Apollo.useQuery<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >(LoginConfigOfTenantCodeDocument, options)
}
export function useLoginConfigOfTenantCodeLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >,
): [
  (
    options: Apollo.QueryLazyOptions<LoginConfigOfTenantCodeQueryVariables>,
  ) => void,
  Apollo.LazyQueryResult<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >(LoginConfigOfTenantCodeDocument, options)
}
export type LoginConfigOfTenantCodeQueryHookResult = ReturnType<
  typeof useLoginConfigOfTenantCodeQuery
>
export type LoginConfigOfTenantCodeLazyQueryHookResult = ReturnType<
  typeof useLoginConfigOfTenantCodeLazyQuery
>
export async function loginConfigOfTenantCodeQuery(
  options: Omit<
    Apollo.QueryOptions<
      LoginConfigOfTenantCodeQueryVariables,
      LoginConfigOfTenantCodeQuery
    >,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<LoginConfigOfTenantCodeQuery>> {
  return await apolloClient.query<
    LoginConfigOfTenantCodeQuery,
    LoginConfigOfTenantCodeQueryVariables
  >({
    query: LoginConfigOfTenantCodeDocument,
    ...options,
  })
}
export const WecomAppLoginDocument = gql`
  mutation wecomAppLogin($input: WecomAppLoginInput!) {
    wecomAppLogin(input: $input) {
      token
      userId
    }
  }
`
export function useWecomAppLoginMutation(
  options: Apollo.MutationHookOptions<
    WecomAppLoginMutation,
    WecomAppLoginMutationVariables
  > & { initialVariables?: WecomAppLoginMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      WecomAppLoginMutation,
      WecomAppLoginMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<WecomAppLoginMutation>>,
  Apollo.MutationResult<WecomAppLoginMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    WecomAppLoginMutation,
    WecomAppLoginMutationVariables
  >(WecomAppLoginDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type WecomAppLoginMutationHookResult = ReturnType<
  typeof useWecomAppLoginMutation
>
export async function wecomAppLoginMutation(
  options: Omit<
    Apollo.MutationOptions<
      WecomAppLoginMutation,
      WecomAppLoginMutationVariables
    >,
    'mutation'
  > & { initialVariables?: WecomAppLoginMutationVariables },
): Promise<Apollo.FetchResult<WecomAppLoginMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    WecomAppLoginMutation,
    WecomAppLoginMutationVariables
  >({
    mutation: WecomAppLoginDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const AuthByWechatCodeDocument = gql`
  mutation authByWechatCode($code: String!) {
    authByWechatCode(code: $code) {
      accessToken
      token
      userId
    }
  }
`
export function useAuthByWechatCodeMutation(
  options: Apollo.MutationHookOptions<
    AuthByWechatCodeMutation,
    AuthByWechatCodeMutationVariables
  > & { initialVariables?: AuthByWechatCodeMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      AuthByWechatCodeMutation,
      AuthByWechatCodeMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<AuthByWechatCodeMutation>>,
  Apollo.MutationResult<AuthByWechatCodeMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    AuthByWechatCodeMutation,
    AuthByWechatCodeMutationVariables
  >(AuthByWechatCodeDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type AuthByWechatCodeMutationHookResult = ReturnType<
  typeof useAuthByWechatCodeMutation
>
export async function authByWechatCodeMutation(
  options: Omit<
    Apollo.MutationOptions<
      AuthByWechatCodeMutation,
      AuthByWechatCodeMutationVariables
    >,
    'mutation'
  > & { initialVariables?: AuthByWechatCodeMutationVariables },
): Promise<Apollo.FetchResult<AuthByWechatCodeMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    AuthByWechatCodeMutation,
    AuthByWechatCodeMutationVariables
  >({
    mutation: AuthByWechatCodeDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const BindWechatStaffDocument = gql`
  mutation bindWechatStaff($accessToken: String!) {
    bindWechatStaff(accessToken: $accessToken)
  }
`
export function useBindWechatStaffMutation(
  options: Apollo.MutationHookOptions<
    BindWechatStaffMutation,
    BindWechatStaffMutationVariables
  > & { initialVariables?: BindWechatStaffMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      BindWechatStaffMutation,
      BindWechatStaffMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<BindWechatStaffMutation>>,
  Apollo.MutationResult<BindWechatStaffMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    BindWechatStaffMutation,
    BindWechatStaffMutationVariables
  >(BindWechatStaffDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type BindWechatStaffMutationHookResult = ReturnType<
  typeof useBindWechatStaffMutation
>
export async function bindWechatStaffMutation(
  options: Omit<
    Apollo.MutationOptions<
      BindWechatStaffMutation,
      BindWechatStaffMutationVariables
    >,
    'mutation'
  > & { initialVariables?: BindWechatStaffMutationVariables },
): Promise<Apollo.FetchResult<BindWechatStaffMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    BindWechatStaffMutation,
    BindWechatStaffMutationVariables
  >({
    mutation: BindWechatStaffDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const UnbindWechatStaffDocument = gql`
  mutation unbindWechatStaff {
    unbindWechatStaff
  }
`
export function useUnbindWechatStaffMutation(
  options: Apollo.MutationHookOptions<
    UnbindWechatStaffMutation,
    UnbindWechatStaffMutationVariables
  > & { initialVariables?: UnbindWechatStaffMutationVariables } = {},
): [
  (
    options?: Apollo.MutationFunctionOptions<
      UnbindWechatStaffMutation,
      UnbindWechatStaffMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<UnbindWechatStaffMutation>>,
  Apollo.MutationResult<UnbindWechatStaffMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    UnbindWechatStaffMutation,
    UnbindWechatStaffMutationVariables
  >(UnbindWechatStaffDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type UnbindWechatStaffMutationHookResult = ReturnType<
  typeof useUnbindWechatStaffMutation
>
export async function unbindWechatStaffMutation(
  options: Omit<
    Apollo.MutationOptions<
      UnbindWechatStaffMutation,
      UnbindWechatStaffMutationVariables
    >,
    'mutation'
  > & { initialVariables?: UnbindWechatStaffMutationVariables } = {},
): Promise<Apollo.FetchResult<UnbindWechatStaffMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    UnbindWechatStaffMutation,
    UnbindWechatStaffMutationVariables
  >({
    mutation: UnbindWechatStaffDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const RegisterByPhoneNumberAllTenantDocument = gql`
  mutation registerByPhoneNumberAllTenant(
    $input: RegisterByPhoneNumberAllTenantInput!
  ) {
    registerByPhoneNumberAllTenant(input: $input) {
      token
      userId
    }
  }
`
export function useRegisterByPhoneNumberAllTenantMutation(
  options: Apollo.MutationHookOptions<
    RegisterByPhoneNumberAllTenantMutation,
    RegisterByPhoneNumberAllTenantMutationVariables
  > & {
    initialVariables?: RegisterByPhoneNumberAllTenantMutationVariables
  } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      RegisterByPhoneNumberAllTenantMutation,
      RegisterByPhoneNumberAllTenantMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<RegisterByPhoneNumberAllTenantMutation>>,
  Apollo.MutationResult<RegisterByPhoneNumberAllTenantMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    RegisterByPhoneNumberAllTenantMutation,
    RegisterByPhoneNumberAllTenantMutationVariables
  >(RegisterByPhoneNumberAllTenantDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type RegisterByPhoneNumberAllTenantMutationHookResult = ReturnType<
  typeof useRegisterByPhoneNumberAllTenantMutation
>
export async function registerByPhoneNumberAllTenantMutation(
  options: Omit<
    Apollo.MutationOptions<
      RegisterByPhoneNumberAllTenantMutation,
      RegisterByPhoneNumberAllTenantMutationVariables
    >,
    'mutation'
  > & { initialVariables?: RegisterByPhoneNumberAllTenantMutationVariables },
): Promise<Apollo.FetchResult<RegisterByPhoneNumberAllTenantMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    RegisterByPhoneNumberAllTenantMutation,
    RegisterByPhoneNumberAllTenantMutationVariables
  >({
    mutation: RegisterByPhoneNumberAllTenantDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const LoginByPhoneNumberAllTenantDocument = gql`
  mutation loginByPhoneNumberAllTenant(
    $input: LoginByPhoneNumberAllTenantInput!
  ) {
    loginByPhoneNumberAllTenant(input: $input) {
      token
      userId
    }
  }
`
export function useLoginByPhoneNumberAllTenantMutation(
  options: Apollo.MutationHookOptions<
    LoginByPhoneNumberAllTenantMutation,
    LoginByPhoneNumberAllTenantMutationVariables
  > & { initialVariables?: LoginByPhoneNumberAllTenantMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      LoginByPhoneNumberAllTenantMutation,
      LoginByPhoneNumberAllTenantMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<LoginByPhoneNumberAllTenantMutation>>,
  Apollo.MutationResult<LoginByPhoneNumberAllTenantMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    LoginByPhoneNumberAllTenantMutation,
    LoginByPhoneNumberAllTenantMutationVariables
  >(LoginByPhoneNumberAllTenantDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type LoginByPhoneNumberAllTenantMutationHookResult = ReturnType<
  typeof useLoginByPhoneNumberAllTenantMutation
>
export async function loginByPhoneNumberAllTenantMutation(
  options: Omit<
    Apollo.MutationOptions<
      LoginByPhoneNumberAllTenantMutation,
      LoginByPhoneNumberAllTenantMutationVariables
    >,
    'mutation'
  > & { initialVariables?: LoginByPhoneNumberAllTenantMutationVariables },
): Promise<Apollo.FetchResult<LoginByPhoneNumberAllTenantMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    LoginByPhoneNumberAllTenantMutation,
    LoginByPhoneNumberAllTenantMutationVariables
  >({
    mutation: LoginByPhoneNumberAllTenantDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const ChangePasswordByPhoneNumberDocument = gql`
  mutation changePasswordByPhoneNumber(
    $input: ChangePasswordByPhoneNumberInput!
  ) {
    changePasswordByPhoneNumber(input: $input) {
      token
      userId
    }
  }
`
export function useChangePasswordByPhoneNumberMutation(
  options: Apollo.MutationHookOptions<
    ChangePasswordByPhoneNumberMutation,
    ChangePasswordByPhoneNumberMutationVariables
  > & { initialVariables?: ChangePasswordByPhoneNumberMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      ChangePasswordByPhoneNumberMutation,
      ChangePasswordByPhoneNumberMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<ChangePasswordByPhoneNumberMutation>>,
  Apollo.MutationResult<ChangePasswordByPhoneNumberMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    ChangePasswordByPhoneNumberMutation,
    ChangePasswordByPhoneNumberMutationVariables
  >(ChangePasswordByPhoneNumberDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type ChangePasswordByPhoneNumberMutationHookResult = ReturnType<
  typeof useChangePasswordByPhoneNumberMutation
>
export async function changePasswordByPhoneNumberMutation(
  options: Omit<
    Apollo.MutationOptions<
      ChangePasswordByPhoneNumberMutation,
      ChangePasswordByPhoneNumberMutationVariables
    >,
    'mutation'
  > & { initialVariables?: ChangePasswordByPhoneNumberMutationVariables },
): Promise<Apollo.FetchResult<ChangePasswordByPhoneNumberMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    ChangePasswordByPhoneNumberMutation,
    ChangePasswordByPhoneNumberMutationVariables
  >({
    mutation: ChangePasswordByPhoneNumberDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const ThemeOfTenantCodeDocument = gql`
  query themeOfTenantCode($tenantCode: String!) {
    themeOfTenantCode(tenantCode: $tenantCode) {
      id
      hideLogo
      loginBackground {
        url
      }
      greetings
    }
  }
`
export function useThemeOfTenantCodeQuery(
  options: Apollo.QueryHookOptions<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >,
): Apollo.QueryResult<ThemeOfTenantCodeQuery, ThemeOfTenantCodeQueryVariables> {
  return Apollo.useQuery<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >(ThemeOfTenantCodeDocument, options)
}
export function useThemeOfTenantCodeLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >,
): [
  (options: Apollo.QueryLazyOptions<ThemeOfTenantCodeQueryVariables>) => void,
  Apollo.LazyQueryResult<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >(ThemeOfTenantCodeDocument, options)
}
export type ThemeOfTenantCodeQueryHookResult = ReturnType<
  typeof useThemeOfTenantCodeQuery
>
export type ThemeOfTenantCodeLazyQueryHookResult = ReturnType<
  typeof useThemeOfTenantCodeLazyQuery
>
export async function themeOfTenantCodeQuery(
  options: Omit<
    Apollo.QueryOptions<
      ThemeOfTenantCodeQueryVariables,
      ThemeOfTenantCodeQuery
    >,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<ThemeOfTenantCodeQuery>> {
  return await apolloClient.query<
    ThemeOfTenantCodeQuery,
    ThemeOfTenantCodeQueryVariables
  >({
    query: ThemeOfTenantCodeDocument,
    ...options,
  })
}
export const MyAppListDocument = gql`
  query myAppList($limit: Int, $offset: Int) {
    myAppList(limit: $limit, offset: $offset) {
      data {
        code
        kind
        jumpKind
        url
      }
    }
  }
`
export function useMyAppListQuery(
  options: Apollo.QueryHookOptions<
    MyAppListQuery,
    MyAppListQueryVariables
  > = {},
): Apollo.QueryResult<MyAppListQuery, MyAppListQueryVariables> {
  return Apollo.useQuery<MyAppListQuery, MyAppListQueryVariables>(
    MyAppListDocument,
    options,
  )
}
export function useMyAppListLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    MyAppListQuery,
    MyAppListQueryVariables
  >,
): [
  (options?: Apollo.QueryLazyOptions<MyAppListQueryVariables>) => void,
  Apollo.LazyQueryResult<MyAppListQuery, MyAppListQueryVariables>,
] {
  return Apollo.useLazyQuery<MyAppListQuery, MyAppListQueryVariables>(
    MyAppListDocument,
    options,
  )
}
export type MyAppListQueryHookResult = ReturnType<typeof useMyAppListQuery>
export type MyAppListLazyQueryHookResult = ReturnType<
  typeof useMyAppListLazyQuery
>
export async function myAppListQuery(
  options: Omit<
    Apollo.QueryOptions<MyAppListQueryVariables, MyAppListQuery>,
    'query'
  > = {},
): Promise<Apollo.ApolloQueryResult<MyAppListQuery>> {
  return await apolloClient.query<MyAppListQuery, MyAppListQueryVariables>({
    query: MyAppListDocument,
    ...options,
  })
}
export const MeDocument = gql`
  query me {
    me {
      tenant {
        isCertificationResultNotified
      }
      account
      phoneNumber
      name
      jobNumber
      tenant {
        name
        code
      }
    }
  }
`
export function useMeQuery(
  options: Apollo.QueryHookOptions<MeQuery, MeQueryVariables> = {},
): Apollo.QueryResult<MeQuery, MeQueryVariables> {
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  options?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
): [
  (options?: Apollo.QueryLazyOptions<MeQueryVariables>) => void,
  Apollo.LazyQueryResult<MeQuery, MeQueryVariables>,
] {
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export async function meQuery(
  options: Omit<Apollo.QueryOptions<MeQueryVariables, MeQuery>, 'query'> = {},
): Promise<Apollo.ApolloQueryResult<MeQuery>> {
  return await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
    ...options,
  })
}
export const ThemeOfMyTenantDocument = gql`
  query themeOfMyTenant {
    themeOfMyTenant {
      selectedContent
      density
      direction
      fontSize
      platformName
      range {
        appCodes
        scope
      }
    }
  }
`
export function useThemeOfMyTenantQuery(
  options: Apollo.QueryHookOptions<
    ThemeOfMyTenantQuery,
    ThemeOfMyTenantQueryVariables
  > = {},
): Apollo.QueryResult<ThemeOfMyTenantQuery, ThemeOfMyTenantQueryVariables> {
  return Apollo.useQuery<ThemeOfMyTenantQuery, ThemeOfMyTenantQueryVariables>(
    ThemeOfMyTenantDocument,
    options,
  )
}
export function useThemeOfMyTenantLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    ThemeOfMyTenantQuery,
    ThemeOfMyTenantQueryVariables
  >,
): [
  (options?: Apollo.QueryLazyOptions<ThemeOfMyTenantQueryVariables>) => void,
  Apollo.LazyQueryResult<ThemeOfMyTenantQuery, ThemeOfMyTenantQueryVariables>,
] {
  return Apollo.useLazyQuery<
    ThemeOfMyTenantQuery,
    ThemeOfMyTenantQueryVariables
  >(ThemeOfMyTenantDocument, options)
}
export type ThemeOfMyTenantQueryHookResult = ReturnType<
  typeof useThemeOfMyTenantQuery
>
export type ThemeOfMyTenantLazyQueryHookResult = ReturnType<
  typeof useThemeOfMyTenantLazyQuery
>
export async function themeOfMyTenantQuery(
  options: Omit<
    Apollo.QueryOptions<ThemeOfMyTenantQueryVariables, ThemeOfMyTenantQuery>,
    'query'
  > = {},
): Promise<Apollo.ApolloQueryResult<ThemeOfMyTenantQuery>> {
  return await apolloClient.query<
    ThemeOfMyTenantQuery,
    ThemeOfMyTenantQueryVariables
  >({
    query: ThemeOfMyTenantDocument,
    ...options,
  })
}
export const MyTenantAppListDocument = gql`
  query myTenantAppList(
    $filter: MyTenantAppListFilterInput
    $limit: Int
    $offset: Int
    $orderBy: [String!]
  ) {
    myTenantAppList(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      data {
        code
      }
    }
  }
`
export function useMyTenantAppListQuery(
  options: Apollo.QueryHookOptions<
    MyTenantAppListQuery,
    MyTenantAppListQueryVariables
  > = {},
): Apollo.QueryResult<MyTenantAppListQuery, MyTenantAppListQueryVariables> {
  return Apollo.useQuery<MyTenantAppListQuery, MyTenantAppListQueryVariables>(
    MyTenantAppListDocument,
    options,
  )
}
export function useMyTenantAppListLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    MyTenantAppListQuery,
    MyTenantAppListQueryVariables
  >,
): [
  (options?: Apollo.QueryLazyOptions<MyTenantAppListQueryVariables>) => void,
  Apollo.LazyQueryResult<MyTenantAppListQuery, MyTenantAppListQueryVariables>,
] {
  return Apollo.useLazyQuery<
    MyTenantAppListQuery,
    MyTenantAppListQueryVariables
  >(MyTenantAppListDocument, options)
}
export type MyTenantAppListQueryHookResult = ReturnType<
  typeof useMyTenantAppListQuery
>
export type MyTenantAppListLazyQueryHookResult = ReturnType<
  typeof useMyTenantAppListLazyQuery
>
export async function myTenantAppListQuery(
  options: Omit<
    Apollo.QueryOptions<MyTenantAppListQueryVariables, MyTenantAppListQuery>,
    'query'
  > = {},
): Promise<Apollo.ApolloQueryResult<MyTenantAppListQuery>> {
  return await apolloClient.query<
    MyTenantAppListQuery,
    MyTenantAppListQueryVariables
  >({
    query: MyTenantAppListDocument,
    ...options,
  })
}
export const TenantPasswordConfigDocument = gql`
  query tenantPasswordConfig($tenantCode: String!) {
    tenantPasswordConfig(tenantCode: $tenantCode) {
      characterSet
      customInitialPassword
      id
      initialPasswordMode
      initialPasswordRule
      length
    }
  }
`
export function useTenantPasswordConfigQuery(
  options: Apollo.QueryHookOptions<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >,
): Apollo.QueryResult<
  TenantPasswordConfigQuery,
  TenantPasswordConfigQueryVariables
> {
  return Apollo.useQuery<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >(TenantPasswordConfigDocument, options)
}
export function useTenantPasswordConfigLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >,
): [
  (
    options: Apollo.QueryLazyOptions<TenantPasswordConfigQueryVariables>,
  ) => void,
  Apollo.LazyQueryResult<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >(TenantPasswordConfigDocument, options)
}
export type TenantPasswordConfigQueryHookResult = ReturnType<
  typeof useTenantPasswordConfigQuery
>
export type TenantPasswordConfigLazyQueryHookResult = ReturnType<
  typeof useTenantPasswordConfigLazyQuery
>
export async function tenantPasswordConfigQuery(
  options: Omit<
    Apollo.QueryOptions<
      TenantPasswordConfigQueryVariables,
      TenantPasswordConfigQuery
    >,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<TenantPasswordConfigQuery>> {
  return await apolloClient.query<
    TenantPasswordConfigQuery,
    TenantPasswordConfigQueryVariables
  >({
    query: TenantPasswordConfigDocument,
    ...options,
  })
}
export const TenantOfVerifiedEmailDocument = gql`
  query tenantOfVerifiedEmail($email: String!) {
    tenantOfVerifiedEmail(email: $email) {
      code
    }
  }
`
export function useTenantOfVerifiedEmailQuery(
  options: Apollo.QueryHookOptions<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >,
): Apollo.QueryResult<
  TenantOfVerifiedEmailQuery,
  TenantOfVerifiedEmailQueryVariables
> {
  return Apollo.useQuery<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >(TenantOfVerifiedEmailDocument, options)
}
export function useTenantOfVerifiedEmailLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >,
): [
  (
    options: Apollo.QueryLazyOptions<TenantOfVerifiedEmailQueryVariables>,
  ) => void,
  Apollo.LazyQueryResult<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >(TenantOfVerifiedEmailDocument, options)
}
export type TenantOfVerifiedEmailQueryHookResult = ReturnType<
  typeof useTenantOfVerifiedEmailQuery
>
export type TenantOfVerifiedEmailLazyQueryHookResult = ReturnType<
  typeof useTenantOfVerifiedEmailLazyQuery
>
export async function tenantOfVerifiedEmailQuery(
  options: Omit<
    Apollo.QueryOptions<
      TenantOfVerifiedEmailQueryVariables,
      TenantOfVerifiedEmailQuery
    >,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<TenantOfVerifiedEmailQuery>> {
  return await apolloClient.query<
    TenantOfVerifiedEmailQuery,
    TenantOfVerifiedEmailQueryVariables
  >({
    query: TenantOfVerifiedEmailDocument,
    ...options,
  })
}
export const AppByClientIdDocument = gql`
  query appByClientId($clientId: String!) {
    appByClientId(clientId: $clientId) {
      clientId
      code
      id
      name
    }
  }
`
export function useAppByClientIdQuery(
  options: Apollo.QueryHookOptions<
    AppByClientIdQuery,
    AppByClientIdQueryVariables
  >,
): Apollo.QueryResult<AppByClientIdQuery, AppByClientIdQueryVariables> {
  return Apollo.useQuery<AppByClientIdQuery, AppByClientIdQueryVariables>(
    AppByClientIdDocument,
    options,
  )
}
export function useAppByClientIdLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    AppByClientIdQuery,
    AppByClientIdQueryVariables
  >,
): [
  (options: Apollo.QueryLazyOptions<AppByClientIdQueryVariables>) => void,
  Apollo.LazyQueryResult<AppByClientIdQuery, AppByClientIdQueryVariables>,
] {
  return Apollo.useLazyQuery<AppByClientIdQuery, AppByClientIdQueryVariables>(
    AppByClientIdDocument,
    options,
  )
}
export type AppByClientIdQueryHookResult = ReturnType<
  typeof useAppByClientIdQuery
>
export type AppByClientIdLazyQueryHookResult = ReturnType<
  typeof useAppByClientIdLazyQuery
>
export async function appByClientIdQuery(
  options: Omit<
    Apollo.QueryOptions<AppByClientIdQueryVariables, AppByClientIdQuery>,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<AppByClientIdQuery>> {
  return await apolloClient.query<
    AppByClientIdQuery,
    AppByClientIdQueryVariables
  >({
    query: AppByClientIdDocument,
    ...options,
  })
}
export const IsPhoneNumberVerifiedDocument = gql`
  query isPhoneNumberVerified($phoneNumber: String!) {
    isPhoneNumberVerified(phoneNumber: $phoneNumber)
  }
`
export function useIsPhoneNumberVerifiedQuery(
  options: Apollo.QueryHookOptions<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >,
): Apollo.QueryResult<
  IsPhoneNumberVerifiedQuery,
  IsPhoneNumberVerifiedQueryVariables
> {
  return Apollo.useQuery<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >(IsPhoneNumberVerifiedDocument, options)
}
export function useIsPhoneNumberVerifiedLazyQuery(
  options?: Apollo.LazyQueryHookOptions<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >,
): [
  (
    options: Apollo.QueryLazyOptions<IsPhoneNumberVerifiedQueryVariables>,
  ) => void,
  Apollo.LazyQueryResult<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >,
] {
  return Apollo.useLazyQuery<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >(IsPhoneNumberVerifiedDocument, options)
}
export type IsPhoneNumberVerifiedQueryHookResult = ReturnType<
  typeof useIsPhoneNumberVerifiedQuery
>
export type IsPhoneNumberVerifiedLazyQueryHookResult = ReturnType<
  typeof useIsPhoneNumberVerifiedLazyQuery
>
export async function isPhoneNumberVerifiedQuery(
  options: Omit<
    Apollo.QueryOptions<
      IsPhoneNumberVerifiedQueryVariables,
      IsPhoneNumberVerifiedQuery
    >,
    'query'
  >,
): Promise<Apollo.ApolloQueryResult<IsPhoneNumberVerifiedQuery>> {
  return await apolloClient.query<
    IsPhoneNumberVerifiedQuery,
    IsPhoneNumberVerifiedQueryVariables
  >({
    query: IsPhoneNumberVerifiedDocument,
    ...options,
  })
}
export const LoginByPhoneNumberDocument = gql`
  mutation loginByPhoneNumber($input: LoginByPhoneNumberInput!) {
    loginByPhoneNumber(input: $input) {
      token
      userId
    }
  }
`
export function useLoginByPhoneNumberMutation(
  options: Apollo.MutationHookOptions<
    LoginByPhoneNumberMutation,
    LoginByPhoneNumberMutationVariables
  > & { initialVariables?: LoginByPhoneNumberMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      LoginByPhoneNumberMutation,
      LoginByPhoneNumberMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<LoginByPhoneNumberMutation>>,
  Apollo.MutationResult<LoginByPhoneNumberMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    LoginByPhoneNumberMutation,
    LoginByPhoneNumberMutationVariables
  >(LoginByPhoneNumberDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type LoginByPhoneNumberMutationHookResult = ReturnType<
  typeof useLoginByPhoneNumberMutation
>
export async function loginByPhoneNumberMutation(
  options: Omit<
    Apollo.MutationOptions<
      LoginByPhoneNumberMutation,
      LoginByPhoneNumberMutationVariables
    >,
    'mutation'
  > & { initialVariables?: LoginByPhoneNumberMutationVariables },
): Promise<Apollo.FetchResult<LoginByPhoneNumberMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    LoginByPhoneNumberMutation,
    LoginByPhoneNumberMutationVariables
  >({
    mutation: LoginByPhoneNumberDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export const GetAuthTokenByDingdingUserDocument = gql`
  mutation getAuthTokenByDingdingUser(
    $input: GetAuthTokenByDingdingUserInput!
  ) {
    getAuthTokenByDingdingUser(input: $input)
  }
`
export function useGetAuthTokenByDingdingUserMutation(
  options: Apollo.MutationHookOptions<
    GetAuthTokenByDingdingUserMutation,
    GetAuthTokenByDingdingUserMutationVariables
  > & { initialVariables?: GetAuthTokenByDingdingUserMutationVariables } = {},
): [
  (
    options: Apollo.MutationFunctionOptions<
      GetAuthTokenByDingdingUserMutation,
      GetAuthTokenByDingdingUserMutationVariables
    >,
  ) => Promise<Apollo.FetchResult<GetAuthTokenByDingdingUserMutation>>,
  Apollo.MutationResult<GetAuthTokenByDingdingUserMutation>,
] {
  const { initialVariables, ...restOptions } = options

  return Apollo.useMutation<
    GetAuthTokenByDingdingUserMutation,
    GetAuthTokenByDingdingUserMutationVariables
  >(GetAuthTokenByDingdingUserDocument, {
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type GetAuthTokenByDingdingUserMutationHookResult = ReturnType<
  typeof useGetAuthTokenByDingdingUserMutation
>
export async function getAuthTokenByDingdingUserMutation(
  options: Omit<
    Apollo.MutationOptions<
      GetAuthTokenByDingdingUserMutation,
      GetAuthTokenByDingdingUserMutationVariables
    >,
    'mutation'
  > & { initialVariables?: GetAuthTokenByDingdingUserMutationVariables },
): Promise<Apollo.FetchResult<GetAuthTokenByDingdingUserMutation>> {
  const { initialVariables, ...restOptions } = options

  return await apolloClient.mutate<
    GetAuthTokenByDingdingUserMutation,
    GetAuthTokenByDingdingUserMutationVariables
  >({
    mutation: GetAuthTokenByDingdingUserDocument,
    context: {
      initialVariables,
    },
    ...restOptions,
  })
}
export type Upload = File
