"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compute = void 0;
function isComputeTo(computable) {
    return typeof computable === 'function';
}
function compute(computable, from) {
    return isComputeTo(computable) ? computable(from) : computable;
}
exports.compute = compute;
