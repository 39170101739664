import { Stack, Typography, useTheme } from '@mui/material'
import { isNumber, range } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'
import { authTokenStorage } from 'src/context/apollo'
import { useMe } from 'src/context/me'
import {
  Density,
  useMyTenantAppListQuery,
  useThemeOfMyTenantQuery,
} from 'src/generated/apollo'

const ctx = document.createElement('canvas').getContext('2d')!
const fontFamily = 'PingFangSC-Medium,Roboto,Microsoft YaHei,sans-serif'

function getTextWidth(text: string, fontSize: number | string = 12) {
  ctx.font = `${isNumber(fontSize) ? `${fontSize}px` : fontSize} ${fontFamily}`
  return ctx.measureText(String(text)).width
}

function useGap(props: { fontSize: number; arrangement: Density }) {
  const { fontSize, arrangement } = props

  if (fontSize < 20) {
    return arrangement === 'SPARSE'
      ? 180
      : arrangement === 'STANDARD'
      ? 120
      : 60
  } else if (fontSize < 28) {
    return arrangement === 'SPARSE'
      ? 240
      : arrangement === 'STANDARD'
      ? 160
      : 80
  } else {
    return arrangement === 'SPARSE'
      ? 300
      : arrangement === 'STANDARD'
      ? 200
      : 100
  }
}

function Watermark(props: {
  variant: 'box' | 'page'
  arrangement: Density
  content: string[]
  fontSize: number
  rotate?: number
}) {
  const { variant, rotate = 0, content, fontSize, arrangement } = props
  const group = useMemo(() => {
    return content.reduce<string[]>((prev, cur, index) => {
      if (index % 2 === 0) {
        prev.push(cur)
      } else {
        prev[prev.length - 1] += ` ${cur}`
      }
      return prev
    }, [])
  }, [content])
  const lineHeight =
    fontSize === 12
      ? 16
      : fontSize === 16
      ? 24
      : fontSize === 20
      ? 28
      : fontSize === 24
      ? 32
      : fontSize === 28
      ? 40
      : fontSize
  const contentHeight = group.length * lineHeight + (group.length - 1) * 4
  const contentWidth =
    Math.max(...group.map((text) => getTextWidth(text, fontSize))) || 1
  const realHeight =
    Math.abs(Math.sin(Math.PI * (rotate / 180))) * contentWidth +
    Math.abs(Math.cos(Math.PI * (rotate / 180))) * contentHeight
  const ref = useRef<HTMLDivElement>(null)
  const [containerSize, setContainerSize] = useState<{
    row: number
    column: number
  }>({ row: 0, column: 0 })
  const gap = useGap({ fontSize, arrangement })
  const theme = useTheme()

  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setContainerSize({
        row: Math.ceil(height / realHeight) + 1,
        column: Math.ceil(width / contentWidth) + 1,
      })
    }
  }, [contentWidth, realHeight])

  return (
    <Stack
      ref={ref}
      style={{
        position: variant === 'box' ? 'absolute' : 'fixed',
        left: -contentWidth,
        top: 0,
        right: 0,
        bottom: 0,
        // 高于常规弹窗层级；小于菜单栏弹窗层级
        zIndex: 1350,
        pointerEvents: 'none',
        opacity: 0.15,
      }}
    >
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap,
        }}
      >
        {range(0, containerSize.row).map((rowIndex, index) => (
          <Stack
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingLeft:
                rowIndex % 2 === 0 ? 0 : `${(contentWidth + gap) / 2}px`,
              gap,
            }}
          >
            {range(0, containerSize.column).map((_, index) => (
              <Stack
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  transform: `rotate(${rotate ?? 0}deg)`,
                  height: realHeight,
                  gap: 4,
                }}
              >
                {group.map((text, index) => (
                  <Typography
                    key={index}
                    style={{
                      width: contentWidth,
                      color: '#343C4966',
                      whiteSpace: 'nowrap',
                      fontFamily: `${theme.typography.fontFamily}`,
                      lineHeight: `${lineHeight}px`,
                      fontSize: `${fontSize}px`,
                    }}
                  >
                    {text}
                  </Typography>
                ))}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export function useWaterMark() {
  const { me, refetch: refetchMe } = useMe()
  const authToken = authTokenStorage.get()
  const { pathname } = useLocation()
  const { data, refetch: refetchTheme } = useThemeOfMyTenantQuery({
    skip: !authToken,
  })
  const { data: apps } = useMyTenantAppListQuery({
    skip: !authToken,
    variables: {
      filter: {
        isWatermarkSupported: true,
        includeInvisible: true,
      },
    },
  })
  const theme = data?.themeOfMyTenant
  const drawWatermark = useCallback(() => {
    let container = window.document.querySelector('.tc-watermark')
    const appCode =
      pathname?.startsWith('/dashboard') || pathname === '/'
        ? 'workbench'
        : pathname?.startsWith('/profile') || pathname?.startsWith('/app')
        ? 'navigate'
        : pathname?.match(/subapp\/.+\/?/)?.[0].split('/')?.[1]
    const hasWatermark =
      (theme?.range.scope === 'ALL' &&
        apps?.myTenantAppList.data.some(({ code }) => code === appCode)) ||
      (theme?.range.scope === 'APPS' &&
        theme.range.appCodes?.includes(appCode ?? ''))

    if (!container) {
      container = window.document.createElement('div')
      container.className = 'tc-watermark'
      window.document.body.appendChild(container)
    }

    if (hasWatermark) {
      ReactDOM.render(
        <Watermark
          variant="page"
          arrangement={theme?.density ?? 'STANDARD'}
          content={
            theme?.selectedContent.map((key) => {
              const str =
                key === 'ACCOUNT'
                  ? me?.account
                  : key === 'CURRENT_DATE'
                  ? `${new Date().getFullYear()}-${
                      new Date().getMonth() + 1
                    }-${new Date().getDate()}`
                  : key === 'JOB_NUMBER'
                  ? me?.jobNumber
                  : key === 'LAST_FOUR_PHONE_NUMBER'
                  ? me?.phoneNumber?.slice(-4) ?? '-'
                  : key === 'NAME'
                  ? me?.name
                  : key === 'PLATFORM_NAME'
                  ? theme.platformName ?? me?.tenant.name
                  : key === 'TENANT_NAME'
                  ? me?.tenant.name
                  : ''
              return str ?? ''
            }) ?? []
          }
          rotate={theme?.direction === 'ROTATE' ? -30 : 0}
          fontSize={
            theme?.fontSize === 'MINI'
              ? 12
              : theme?.fontSize === 'SMALL'
              ? 16
              : theme?.fontSize === 'MEDIUM'
              ? 20
              : theme?.fontSize === 'LARGE'
              ? 24
              : 28
          }
        />,
        container,
      )
    } else {
      container?.remove()
    }

    return () => container?.remove()
  }, [
    apps?.myTenantAppList.data,
    me?.account,
    me?.jobNumber,
    me?.name,
    me?.phoneNumber,
    me?.tenant.name,
    theme?.density,
    theme?.direction,
    theme?.fontSize,
    theme?.platformName,
    theme?.range.appCodes,
    theme?.range.scope,
    theme?.selectedContent,
    pathname,
  ])

  useEffect(() => {
    return drawWatermark()
  }, [drawWatermark])

  return {
    refetch: () => {
      refetchMe()
      refetchTheme()
    },
  }
}
