"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecoilValueAsync = void 0;
const react_1 = require("react");
const react_use_1 = require("react-use");
const recoil_1 = require("recoil");
function useRecoilValueAsync(recoilValue) {
    const loadable = (0, recoil_1.useRecoilValueLoadable)(recoilValue);
    const prevLoadable = (0, react_use_1.usePrevious)(loadable);
    const data = (0, react_1.useMemo)(() => {
        if (loadable.state !== 'hasValue') {
            if ((prevLoadable === null || prevLoadable === void 0 ? void 0 : prevLoadable.state) === 'hasValue') {
                return prevLoadable.contents;
            }
            return null;
        }
        return loadable.contents;
    }, [loadable.contents, loadable.state, prevLoadable]);
    return data;
}
exports.useRecoilValueAsync = useRecoilValueAsync;
