import { Box, Typography } from '@mui/material'
import { isNil } from 'lodash-es'
import { LOGIN_WELCOME_DESC } from 'src/constants/env'
import welcomeSvg from 'src/assets/welcome.svg'

export function Welcome({ greetings }: { greetings?: string }) {
  return (
    <>
      <img src={welcomeSvg} width="100%" alt="" />
      <Typography
        variant="h1"
        sx={{
          display: 'block',
          height: '25px',
          color: '#fff',
          fontSize: '20px',
          lineHeight: '25px',
        }}
      >
        {greetings
          ? greetings
          : isNil(LOGIN_WELCOME_DESC)
          ? '工业互联网 ｜ 助力企业数字化转型'
          : LOGIN_WELCOME_DESC}
      </Typography>
    </>
  )
}
