import { Button, Divider, Stack, Typography } from '@mui/material'
import {
  LoginConfigurationKind,
  useLoginConfigurationQuery,
} from 'src/generated/apollo'
import { isMobile, useTenantCode } from 'src/utils/chaos'

type DispatchLogin = {
  kind: LoginConfigurationKind
  authenticationConfigurationName?: string
  loginUrl?: string
}

export function SSOLogin() {
  const mobile = isMobile()
  const tenantCode = useTenantCode()
  const { data, refetch } = useLoginConfigurationQuery({
    variables: { tenantCode },
    skip: !tenantCode,
  })

  return (
    <Stack sx={{ opacity: data?.loginConfiguration ? 1 : 0 }}>
      <Stack justifyContent="center" height={mobile ? 284 : 280}>
        <Stack
          sx={{
            width: '100%',
            p: mobile ? 0 : '48px 36px',
            borderRadius: '8px',
            alignItems: 'center',
            boxShadow: mobile
              ? undefined
              : ' 0px 1px 10px rgba(26, 59, 164, 0.06), 0px 4px 5px rgba(26, 59, 164, 0.08);',
          }}
        >
          <Typography variant="h3">{`通过${
            ((data?.loginConfiguration ?? {}) as DispatchLogin)
              .authenticationConfigurationName
          }系统登录`}</Typography>
          <Divider
            sx={{
              width: '286px',
              margin: '8px',
            }}
          />
          <Typography variant="subtitle2" color="text.secondary">
            快捷登录，无需账号密码
          </Typography>
        </Stack>
      </Stack>
      <Button
        fullWidth
        size={mobile ? 'medium' : 'large'}
        onClick={async () => {
          const { data } = await refetch()
          window.open(
            (data.loginConfiguration as DispatchLogin)?.loginUrl,
            '_self',
          )
        }}
      >
        一键登录
      </Button>
    </Stack>
  )
}
