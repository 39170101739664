"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.array2TreeNodes = void 0;
const lodash_1 = require("lodash");
function recursion(item, data, depth, maxDepth) {
    const children = data
        .filter((subItem) => {
        return subItem.parentId === item.id;
    })
        .map((subItem) => recursion(subItem, data, depth + 1, maxDepth));
    return {
        label: item.name,
        value: item,
        nodeId: item.id,
        children: depth === maxDepth || children.length === 0 ? undefined : children,
    };
}
function array2TreeNodes(data, maxDepth) {
    return data === null || data === void 0 ? void 0 : data.filter((item) => {
        return (0, lodash_1.isNil)(item.parentId);
    }).map((item) => recursion(item, data, 1, maxDepth));
}
exports.array2TreeNodes = array2TreeNodes;
