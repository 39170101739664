"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noopNull = exports.noopAsync = exports.noop = void 0;
const tslib_1 = require("tslib");
function noop() { }
exports.noop = noop;
function noopAsync() {
    return tslib_1.__awaiter(this, void 0, void 0, function* () { });
}
exports.noopAsync = noopAsync;
function noopNull() {
    return null;
}
exports.noopNull = noopNull;
