// 入口地址: /auth/oauth2.0/authorize?client_id=CLIENT_ID&redirect_uri=REDIRECT_URI&response_type=code&state=STATE

import { useAlert } from '@frontend/mui'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LaptopSvg from 'src/assets/laptop.svg'
import { authTokenStorage } from 'src/context/apollo'
import { useMe } from 'src/context/me'
import { AppByClientIdQuery, useAppByClientIdQuery } from 'src/generated/apollo'

export type Oauth2Param = {
  client_id: string
  redirect_uri: string
  response_type: string
  state: string
}

export type App = NonNullable<AppByClientIdQuery['appByClientId']>

export function Oauth2() {
  const navigate = useNavigate()
  const alert = useAlert()

  const location = useLocation()
  const param = useMemo(
    () =>
      Object.fromEntries(
        new URLSearchParams(location.search).entries(),
      ) as Partial<Oauth2Param>,
    [location.search],
  )

  const isErrorParam = useMemo(
    () =>
      !param.client_id ||
      !param.redirect_uri ||
      !param.response_type ||
      !param.state,
    [param.client_id, param.redirect_uri, param.response_type, param.state],
  )
  const appByClientIdResult = useAppByClientIdQuery({
    variables: {
      clientId: param.client_id!,
    },
    skip: !param.client_id,
    onCompleted: (data) => {
      if (!data.appByClientId) {
        alert.show({
          severity: 'error',
          children: '未找到应用',
        })
        navigate('/')
      }
    },
  })
  const app = useMemo(
    () => appByClientIdResult.data?.appByClientId,
    [appByClientIdResult.data?.appByClientId],
  )

  useEffect(() => {
    if (isErrorParam) {
      alert.show({
        severity: 'error',
        children: '参数错误',
      })
      navigate('/')
    }
  }, [alert, isErrorParam, navigate])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!isErrorParam && app && (
        <Oauth2Content param={param as Oauth2Param} app={app} />
      )}
    </Box>
  )
}

export function Oauth2Content({
  param,
  app,
}: {
  param: Oauth2Param
  app: App
}) {
  const { me } = useMe()
  const navigate = useNavigate()
  const alert = useAlert()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '52px',
        paddingX: '90px',
      }}
    >
      <Box>
        <img src={LaptopSvg} alt="" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '36px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">登录账号授权</Typography>
          <Box
            sx={{
              padding: '12px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <Typography variant="body2">{`同意将账号 ${
              me?.name || '-'
            } 的以下信息授权给 ${app.name}`}</Typography>
            <Box>
              <Typography variant="body2">•&nbsp;登录状态</Typography>
              <Typography variant="body2">
                •&nbsp;个人信息：姓名、工号、账号
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Button
            color="secondary"
            onClick={() => {
              navigate('/')
            }}
          >
            拒绝授权
          </Button>
          <Button
            onClick={async () => {
              const authToken = authTokenStorage.get()
              const result = await fetch(
                `/auth/sso/oauth?client_id=${param.client_id}&redirect_uri=${param.redirect_uri}&response_type=${param.response_type}&state=${param.state}`,
                {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              if (result.ok) {
                alert.show({
                  severity: 'success',
                  children: '授权成功',
                })
                const data: {
                  redirect_uri: string
                } = await result.json()
                window.location.href = data.redirect_uri
              }
            }}
          >
            同意授权
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
