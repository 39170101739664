import { Avatar, Box, Typography } from '@mui/material'
import ErrorTenantCodeSvg from 'src/assets/error_tenant_code.svg'

export function NoTenantMessage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      <Box>
        <Typography variant="h2">当前租户信息不存在</Typography>
        <Typography
          variant="h5"
          color={(theme) => theme.palette.text.secondary}
        >
          请重新输入企业登录地址
        </Typography>
      </Box>

      <Avatar
        src={ErrorTenantCodeSvg}
        sx={{
          width: '436px',
          height: '436px',
        }}
      />
    </Box>
  )
}
