"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.omitAllVariablesByDocument = exports.omitVariablesByDocument = void 0;
const omitVariables_1 = require("./omitVariables");
function getType(type) {
    if ('type' in type) {
        return getType(type.type);
    }
    return type;
}
function omitOperationVariables(schema, variables, variableDefinitions) {
    return variableDefinitions.reduce((result, { variable: { name: { value: name }, }, type, }) => {
        ;
        result[name] = (0, omitVariables_1.omitField)({
            variables: variables[name],
            inputType: schema.getType(getType(type).name.value),
        });
        return result;
    }, {});
}
function omitVariablesByDocument(args) {
    const { variables, schema, document } = args;
    if (variables === null || variables === undefined) {
        return variables;
    }
    return omitOperationVariables(schema, variables, document.definitions[0].variableDefinitions);
}
exports.omitVariablesByDocument = omitVariablesByDocument;
function omitAllVariablesByDocument(args) {
    const { variables, schema, document } = args;
    if (variables === null || variables === undefined) {
        return variables;
    }
    return document.definitions.reduce((result, { name, variableDefinitions }) => {
        ;
        result[name.value] = omitOperationVariables(schema, variables[name.value], variableDefinitions);
        return result;
    }, {});
}
exports.omitAllVariablesByDocument = omitAllVariablesByDocument;
