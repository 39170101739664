"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConstantCallback = void 0;
const react_1 = require("react");
function useConstantCallback(fn) {
    const ref = (0, react_1.useRef)(fn);
    (0, react_1.useLayoutEffect)(() => {
        ref.current = fn;
    });
    return (0, react_1.useCallback)((...args) => ref.current.apply(void 0, args), []);
}
exports.useConstantCallback = useConstantCallback;
