"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringifyField = void 0;
const change_case_1 = require("change-case");
const graphql_1 = require("graphql");
const lodash_1 = require("lodash");
const utils_1 = require("../utils");
function getFieldType(fieldType) {
    if ('ofType' in fieldType) {
        return getFieldType(fieldType.ofType);
    }
    return fieldType;
}
function mergeFilter(modelFilter, fieldFilter) {
    if (typeof fieldFilter === 'object') {
        if (typeof modelFilter === 'object') {
            return (0, lodash_1.merge)({}, modelFilter, fieldFilter);
        }
        return fieldFilter;
    }
    if (fieldFilter === false) {
        return false;
    }
    if (typeof modelFilter === 'object') {
        return modelFilter;
    }
    return fieldFilter;
}
function stringifySubFields(args) {
    const { schema, field, fieldsFilter, modelsFilter, unionDict, maxDepth = 5, argumentsSet, } = args;
    const finalFilter = mergeFilter(modelsFilter === null || modelsFilter === void 0 ? void 0 : modelsFilter[field.name], fieldsFilter);
    return (Object.values(field.getFields())
        .map((subField) => {
        var _a, _b;
        return stringifyField({
            schema,
            field: subField,
            fieldsFilter: typeof finalFilter === 'object'
                ? (0, utils_1.isWhiteList)(finalFilter)
                    ? (_a = finalFilter[subField.name]) !== null && _a !== void 0 ? _a : false
                    : (_b = finalFilter[subField.name]) !== null && _b !== void 0 ? _b : true
                : finalFilter,
            modelsFilter,
            unionDict,
            maxDepth: maxDepth - 1,
            argumentsSet,
        });
    })
        .filter(Boolean)
        .join(' ') || '__typename');
}
function stringifyField(args) {
    const { schema, field, fieldsFilter, modelsFilter, unionDict, prefix, maxDepth = 5, argumentsSet, } = args;
    const operationArgs = field.args.map((arg) => ({
        name: arg.name,
        type: arg.type,
        alias: `${prefix ? prefix + '_' : ''}${arg.name}${argumentsSet.size ? (0, change_case_1.pascalCase)(field.name) + argumentsSet.size : ''}`,
    }));
    const operationArgsReference = operationArgs.length
        ? `(${operationArgs
            .map((item) => `${item.name}: $${item.alias}`)
            .join(', ')})`
        : '';
    const fieldName = `${field.name}${operationArgsReference}`;
    const fieldType = getFieldType(field.type);
    if (maxDepth <= 0 || fieldsFilter === false) {
        return '';
    }
    if ((0, graphql_1.isEnumType)(fieldType) || (0, graphql_1.isScalarType)(fieldType) || maxDepth > 1) {
        operationArgs.forEach((item) => {
            argumentsSet.add(`$${item.alias}: ${item.type}`);
        });
    }
    if ((0, graphql_1.isEnumType)(fieldType) || (0, graphql_1.isScalarType)(fieldType)) {
        return fieldName;
    }
    if (maxDepth <= 1) {
        return '';
    }
    if ((0, graphql_1.isObjectType)(fieldType)) {
        return `${fieldName} { ${stringifySubFields({
            schema,
            field: fieldType,
            fieldsFilter,
            modelsFilter,
            unionDict,
            maxDepth,
            argumentsSet,
        })} }`;
    }
    if ((0, graphql_1.isInterfaceType)(fieldType)) {
        const interfaceFields = stringifySubFields({
            schema,
            field: fieldType,
            fieldsFilter,
            modelsFilter,
            unionDict,
            maxDepth,
            argumentsSet,
        });
        const possibleTypes = (unionDict === null || unionDict === void 0 ? void 0 : unionDict[fieldType.name].map((possibleTypeName) => schema.getType(possibleTypeName))) || schema.getPossibleTypes(fieldType);
        const possibleTypesFields = possibleTypes
            .map((subField) => {
            return `... on ${subField.name} { ${stringifySubFields({
                schema,
                field: subField,
                fieldsFilter,
                modelsFilter,
                unionDict,
                maxDepth,
                argumentsSet,
            })} }`;
        })
            .join('\n');
        return `${fieldName} { ${interfaceFields} ${possibleTypesFields} }`;
    }
    if ((0, graphql_1.isUnionType)(fieldType)) {
        const possibleTypesFields = fieldType
            .getTypes()
            .map((subField) => {
            return `... on ${subField.name} { ${stringifySubFields({
                schema,
                field: subField,
                fieldsFilter,
                modelsFilter,
                unionDict,
                maxDepth,
                argumentsSet,
            })} }`;
        })
            .join('\n');
        return `${fieldName} { ${possibleTypesFields} }`;
    }
    return '';
}
exports.stringifyField = stringifyField;
