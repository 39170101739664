import { storage } from '@frontend/helpers'
import { APPBAR_HEIGHT, Entry, Routes } from '@frontend/mui'
import { AnyObject } from '@frontend/type'
import { Box, Stack } from '@mui/material'
import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useCookie } from 'react-use'
import { useRecoilValue } from 'recoil'
import { TENANT_CODE, TITLE, VIEWPORT_HEIGHT } from 'src/constants/env'
import { authTokenStorage } from 'src/context/apollo'
import { MyAppListProvider } from 'src/context/appList'
import { useMe } from 'src/context/me'
import { isMobile } from 'src/utils/chaos'
import { qsToObj } from 'src/utils/query'
import { useDingTalk } from './dingding/useDingTalk'
import { Login } from './login'
import { MicroApp } from './microApp'
import { MobileLogin } from './mobile'
import { Oauth2 } from './oauth2'
import { WxMPBindFailedPage } from './wxmp/AccountBind'
import { isWxMPScene, useWxMP, wxMPState } from './wxmp/useWxMP'
import { WxWorkBindFailedPage } from './wxwork/AccountBind'
import { AddProfilePage } from './wxwork/profile'
import { isWxWorkScene, useWxWork, wxWorkState } from './wxwork/useWxWork'

export const tenantStorage = storage<string>('tenant_code')
export const dingDingStorage = storage<AnyObject>('dingding_info')

export const redirectUrlStorage = storage<string | undefined>('redirect_url')

export const entries: Entry[] = [
  {
    path: '',
    exact: true,
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: 'login',
    element: isMobile() ? <MobileLogin /> : <Login />,
  },
  {
    path: 'login/:tenantCode',
    element: isMobile() ? <MobileLogin /> : <Login />,
  },
  {
    path: 'register',
    element: isMobile() ? <MobileLogin /> : <Login />,
  },
]

export function Pages() {
  const { pathname } = useLocation()
  const authToken = authTokenStorage.get()
  const redirectUrl = redirectUrlStorage.get()
  const workState = useRecoilValue(wxWorkState)
  const mpState = useRecoilValue(wxMPState)
  const navigate = useNavigate()
  const isLogin =
    pathname.startsWith('/login') || pathname.startsWith('/register')
  const [accessToken] = useCookie('access_token')
  const [redirect] = useCookie('redirect')
  useEffect(() => {
    if (!pathname?.startsWith('/subapp')) {
      document.title = TITLE
    }
  }, [pathname])

  // 企业微信
  useWxWork()
  // 微信公众号
  useWxMP()
  //钉钉免登
  useDingTalk()
  //

  // 企业微信补充信息页面
  if (pathname.startsWith('/set_profile')) {
    return <AddProfilePage />
  }

  // 已登录, 自建应用授权页
  if (authToken && pathname.startsWith('/auth/oauth2.0/authorize')) {
    return <Oauth2 />
  }
  // 已登录如未绑定跳转至企业微信绑定界面
  if (
    (isWxWorkScene() && authToken && !workState.isBindSuccess) ||
    workState.bindStatus === 'invalid'
  ) {
    return <WxWorkBindFailedPage />
  }
  // 已登录如未绑定跳转至微信公众号绑定界面
  if (
    (isWxMPScene() && authToken && !mpState.isBindSuccess) ||
    mpState.bindStatus === 'invalid'
  ) {
    return <WxMPBindFailedPage />
  }

  if (accessToken && !authToken) {
    return null
  }
  //如果已经登录过拿到了redirect责直接跳过去
  // if (redirect) {
  //   navigate(`/${redirect}`)
  // }

  // 没登录引导登录，登录后跳转到原来的页面
  if (authToken && redirectUrl) {
    redirectUrlStorage.remove()
    window.location.href = redirectUrl
    return null
  } else if (!authToken && !isLogin) {
    redirectUrlStorage.set(window.location.href)
    const searches = qsToObj(window.location.search)
    if (searches.tenant_code) {
      tenantStorage.set(searches.tenant_code)
    }
    const tenantCode = tenantStorage.get()
    return (
      <Navigate
        to={!TENANT_CODE && tenantCode ? `/login/${tenantCode}` : '/login'}
        replace
      />
    )
  }

  return (
    <Stack direction="row">
      <MyAppListProvider>
        {isMobile() ? <MobilePage /> : <WebPages />}
      </MyAppListProvider>
    </Stack>
  )
}

function WebPages() {
  const { pathname } = useLocation()
  const origin = window.location.origin
  const { authToken } = useMe()
  const isLogin =
    pathname.startsWith('/login') || pathname.startsWith('/register')

  return (
    <>
      {authToken && (
        <Box sx={{ pointerEvents: 'all' }}>
          <micro-app
            name="navigate"
            url={`${origin}/app/navigate`}
            keep-alive
          />
        </Box>
      )}
      <Box
        paddingTop={!isLogin ? `${APPBAR_HEIGHT}px` : 'initial'}
        width="100%"
        height="100vh"
        boxSizing="border-box"
        overflow="auto"
      >
        <Routes></Routes>
        <MicroApp />
      </Box>
    </>
  )
}

function MobilePage() {
  const { pathname } = useLocation()
  const isSubApp = pathname.includes('/subapp')
  const isLogin =
    pathname?.startsWith('/login') || pathname.startsWith('/register')
  const { authToken } = useMe()

  return (
    <Box
      sx={{
        width: '100%',
        height: VIEWPORT_HEIGHT,
        overflowY: 'auto',
        boxSizing: 'border-box',
        paddingBottom: isSubApp || isLogin ? undefined : '50px',
      }}
    >
      {!isSubApp && authToken && (
        <Box sx={{ pointerEvents: 'all' }}>
          <micro-app
            name="navigate"
            url={`${origin}/app/navigate`}
            keep-alive
          />
        </Box>
      )}
      <Routes />
      <MicroApp />
    </Box>
  )
}
