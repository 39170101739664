"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dictToArr = exports.dictToOptions = void 0;
/**
 *
 * Example:
 *
 * type X ='DAILY' |'MONTHLY'
 * const dicts:Dict<X> = {
 *  DAILY: '日',
 *  MONTHLY: '月'
 * }
 * const options = dictToOptions<X>(dicts)
 *
 * [{
 * key:'DAILY',
 * value:'DAILY',
 * label:'日'
 * },
 * {
 * key:'MONTHLY',
 * value:'MONTHLY',
 * label:'月'
 * }]
 *
 * */
function dictToOptions(dict, isNumber) {
    return Object.entries(dict).map(([key, value]) => ({
        key,
        label: value,
        value: isNumber ? Number(key) : key,
    }));
}
exports.dictToOptions = dictToOptions;
function dictToArr(dict, isNumber) {
    return Object.entries(dict).map(([key]) => isNumber ? Number(key) : key);
}
exports.dictToArr = dictToArr;
