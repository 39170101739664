"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefetchTrigger = exports.useRefetchSubscribe = exports.refetchFlagState = void 0;
const react_use_1 = require("react-use");
const recoil_1 = require("recoil");
const noop_1 = require("../chaos/noop");
const useConstantCallback_1 = require("./useConstantCallback");
exports.refetchFlagState = (0, recoil_1.atomFamily)({
    key: 'refetchFlagState',
    default: 0,
});
function useRefetchSubscribe(key, refetch) {
    const refetchFlag = (0, recoil_1.useRecoilValue)((0, exports.refetchFlagState)(key));
    const refetchCallback = (0, useConstantCallback_1.useConstantCallback)(refetch || noop_1.noop);
    (0, react_use_1.useUpdateEffect)(() => {
        refetchCallback();
    }, [refetchCallback, refetchFlag]);
}
exports.useRefetchSubscribe = useRefetchSubscribe;
function useRefetchTrigger(key) {
    const setRefetchFlag = (0, recoil_1.useSetRecoilState)((0, exports.refetchFlagState)(key));
    return () => {
        setRefetchFlag((prev) => prev + 1);
    };
}
exports.useRefetchTrigger = useRefetchTrigger;
