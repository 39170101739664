"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storage = void 0;
function storage(key) {
    return {
        get() {
            try {
                const rawData = localStorage.getItem(key);
                if (rawData === null) {
                    return undefined;
                }
                return JSON.parse(rawData);
            }
            catch (err) {
                return undefined;
            }
        },
        set(data) {
            return localStorage.setItem(key, JSON.stringify(data));
        },
        remove() {
            return localStorage.removeItem(key);
        },
    };
}
exports.storage = storage;
