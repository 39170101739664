import { createUseContext } from '@frontend/helpers'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MeQuery, useMeQuery } from 'src/generated/apollo'
import { tenantStorage } from 'src/pages'
import { useOAuth2 } from 'src/utils/oauth2'
import { authTokenStorage } from './apollo'

interface MeContextShape {
  authToken: string
  login(authToken: string): void
  refetch: () => void
  me?: MeQuery['me']
}

export const {
  context: MeContext,
  use: useMe,
  Provider,
} = createUseContext<MeContextShape>()

export function MeProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const navigate = useNavigate()
  const { isOAuth2LoginScene } = useOAuth2()
  const [authToken, setAuthToken] = useState<string>(
    authTokenStorage.get() || '',
  )
  const { data, refetch } = useMeQuery({
    skip: !authToken,
  })
  const login = useCallback<MeContextShape['login']>(
    (authToken) => {
      const isRegistry = window.location.pathname.startsWith('/register')
      if (!isRegistry) {
        tenantStorage.set(
          window.location.pathname.replace(new RegExp('/login/*'), ''),
        )
      }
      authTokenStorage.set(authToken)
      setAuthToken(authToken)
      if (!isOAuth2LoginScene) {
        navigate('/dashboard')
      }
    },
    [isOAuth2LoginScene, navigate],
  )

  return (
    <Provider
      value={{
        authToken,
        login,
        me: data?.me,
        refetch,
      }}
    >
      {children}
    </Provider>
  )
}
