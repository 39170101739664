import { Box, Stack } from '@mui/material'
import { WXWORK_APP_ID } from 'src/constants/env'
import { useThirdStateCode } from 'src/utils/chaos'

export function WxWorkLogin() {
  const stateCode = useThirdStateCode()
  const redirect_uri = window.location.origin + '/login'
  const src = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=${WXWORK_APP_ID}&redirect_uri=${redirect_uri}&state=${stateCode}&usertype=member`

  return (
    <Stack alignItems="center" spacing={8}>
      <Stack
        justifyContent="center"
        alignItems="center"
        width={320}
        height={320}
        border="1px solid #E3E5EF"
        borderRadius="8px"
      >
        <Box width={272} height={272} position="relative" overflow="hidden">
          <iframe
            title="qrcode"
            src={src}
            width={432}
            height={480}
            scrolling="no"
            style={{
              borderWidth: 0,
              position: 'absolute',
              left: -80,
              top: -50,
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
