"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalState = exports.useRecoilStateWithDefault = exports.useStateFamily = exports.StateFamilyProvider = exports.StateFamilyContext = void 0;
const react_1 = require("react");
const recoil_1 = require("recoil");
const compute_1 = require("../compute");
const createUseContext_1 = require("./context/createUseContext");
_a = (0, createUseContext_1.createUseContext)(null), exports.StateFamilyContext = _a.context, exports.StateFamilyProvider = _a.Provider, exports.useStateFamily = _a.use;
function useRecoilStateWithDefault(recoilState, initialState) {
    const [rawState, setRawState] = (0, recoil_1.useRecoilState)(recoilState);
    const state = (0, react_1.useMemo)(() => {
        if (rawState === undefined && initialState !== undefined) {
            return initialState;
        }
        return rawState;
    }, [initialState, rawState]);
    const setState = (0, react_1.useCallback)((stateAction) => {
        setRawState((prev) => (0, compute_1.compute)(stateAction, prev === undefined && initialState !== undefined
            ? initialState
            : prev));
    }, [initialState, setRawState]);
    return [state, setState];
}
exports.useRecoilStateWithDefault = useRecoilStateWithDefault;
function useGlobalState(key, initialState) {
    const stateFamily = (0, exports.useStateFamily)();
    return stateFamily
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useRecoilStateWithDefault(stateFamily(key), initialState)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
            (0, react_1.useState)(initialState);
}
exports.useGlobalState = useGlobalState;
