import { Button, ButtonProps } from '@mui/material'
import { RefObject, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useBoolean, useInterval } from 'react-use'

export type GetCaptchaProps = {
  onClickGetCaptcha?: (() => void) | (() => Promise<void>)
  disabled?: boolean
  size?: ButtonProps['size']
  focusRef?: RefObject<HTMLInputElement | null>
}

export function GetCaptcha(props: GetCaptchaProps) {
  const { onClickGetCaptcha, disabled, size, focusRef } = props
  const [isRunning, toggleIsRunning] = useBoolean(false)
  const [countDown, setCountDown] = useState(60)
  const { pathname } = useLocation()

  const isLogin =
    pathname.startsWith('/login') || pathname.startsWith('/register')

  useInterval(
    () => {
      setCountDown((state) => state - 1)
    },
    isRunning ? 1000 : null,
  )

  useEffect(() => {
    if (countDown <= 0) {
      toggleIsRunning(false)
      setCountDown(60)
    }
  }, [countDown, toggleIsRunning])

  useEffect(() => {
    if (isLogin) {
      toggleIsRunning(false)
      setCountDown(60)
    }
  }, [isLogin, pathname, toggleIsRunning])

  return isRunning ? (
    <Button
      variant="outlined"
      disabled
      sx={{
        p: 0,
        width: size === 'large' ? '170px' : '120px',
        textTransform: 'none',
      }}
      size={size}
    >
      重新获取({countDown}s)
    </Button>
  ) : (
    <Button
      variant="outlined"
      disabled={disabled}
      sx={{
        width: size === 'large' ? '170px' : '120px',
        height: size === 'large' ? '44px' : undefined,
      }}
      onClick={async () => {
        try {
          await onClickGetCaptcha?.()
          toggleIsRunning(true)

          if (focusRef?.current) {
            focusRef.current.focus()
          }
        } catch {}
      }}
      size={size}
    >
      获取验证码
    </Button>
  )
}
