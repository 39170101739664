"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diffVariables = exports.diffVariable = void 0;
const graphql_1 = require("graphql");
const lodash_1 = require("lodash");
const getOperation_1 = require("./getOperation");
function getFieldType(fieldType) {
    if (!(0, graphql_1.isListType)(fieldType) && 'ofType' in fieldType) {
        return getFieldType(fieldType.ofType);
    }
    return fieldType;
}
function diffVariable(args) {
    const { inputType, variables, initialVariables } = args;
    const fieldType = getFieldType(inputType);
    if ((0, lodash_1.isNil)(initialVariables) || (0, lodash_1.isNil)(variables)) {
        return variables;
    }
    if ((0, graphql_1.isInputObjectType)(fieldType)) {
        return Object.values(fieldType.getFields()).reduce((result, { name, type }) => (0, lodash_1.extend)(result, {
            [name]: diffVariable({
                inputType: type,
                variables: (0, lodash_1.get)(variables, name),
                initialVariables: (0, lodash_1.get)(initialVariables, name),
            }),
        }), {});
    }
    if ((0, graphql_1.isNonNullType)(inputType) || !(0, lodash_1.isEqual)(variables, initialVariables)) {
        return variables;
    }
    return undefined;
}
exports.diffVariable = diffVariable;
function diffVariables(args) {
    const { schema, operationType, operationName, variables, initialVariables } = args;
    const operation = (0, getOperation_1.getOperation)({
        schema,
        operationType,
        operationName,
    });
    if ((0, lodash_1.isNil)(variables) || (0, lodash_1.isNil)(initialVariables) || !(operation === null || operation === void 0 ? void 0 : operation.args)) {
        return variables;
    }
    const result = operation.args.reduce((result, { name, type }) => {
        return (0, lodash_1.extend)(result, {
            [name]: diffVariable({
                inputType: type,
                variables: (0, lodash_1.get)(variables, name),
                initialVariables: (0, lodash_1.get)(initialVariables, name),
            }),
        });
    }, {});
    return Object.keys(result).length === 0 ? null : result;
}
exports.diffVariables = diffVariables;
