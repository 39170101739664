"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateIO = void 0;
const react_1 = require("react");
/**
 * elegant state management:
 *
 * Example:
 *   const io = useStateIO<number>(0)
 *   <NumberInput {...io} />
 * */
function useStateIO(initialValue) {
    const [value, onChange] = (0, react_1.useState)(initialValue);
    return { value, onChange };
}
exports.useStateIO = useStateIO;
