"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContextValue = void 0;
const react_1 = require("react");
function useContextValue(context, defaultValue) {
    const contextValue = (0, react_1.useContext)(context);
    if (contextValue === null) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`${context.displayName || 'Context'} not found`);
    }
    return contextValue;
}
exports.useContextValue = useContextValue;
