import { sampleSize } from 'lodash-es'
import { NavigateFunction, useParams } from 'react-router-dom'
import { TENANT_CODE, WEBSITE_DOMAIN_NAME } from 'src/constants/env'
import { MyAppListQuery } from 'src/generated/apollo'

export const handleJump = (
  app: Partial<MyAppListQuery['myAppList']['data'][number]>,
  navigate: NavigateFunction,
) => {
  const protocol = window.location.protocol
  const host = window.location.host
  const isCurrentWindow = app.jumpKind === 'CURRENT_WINDOW'
  const isSelf = app.kind === 'SELF'
  if (app.url) {
    /** 如果URL以(http｜https):// 开头 则直接跳该url */
    if (app.url.match(/^http(s?):\/\/.*/)) {
      window.open(app.url, isCurrentWindow ? '_self' : '_blank')
    } else {
      if (isSelf && isCurrentWindow) {
        navigate(app.url)
      } else {
        window.open(
          `${protocol}//${host}${app.url}`,
          isCurrentWindow ? '_self' : '_blank',
        )
      }
    }
  } else {
    if (app.kind === 'SELF' && isCurrentWindow) {
      navigate(`/subapp/${app.code}`)
    } else {
      window.open(
        `${protocol}//${host}/subapp/${app.code}`,
        isCurrentWindow ? '_self' : '_blank',
      )
    }
  }
}

export function isMobile() {
  const { userAgent } = navigator
  const agents = ['Android', 'iPhone', 'Windows Phone', 'iPod']
  const isMobile = agents.find((agent) => userAgent.includes(agent))
  return Boolean(isMobile)
}

export function isFirefox() {
  return navigator.userAgent.indexOf('Firefox') >= 0
}

export function resolveWebSocketURL(raw: string): string {
  const url = new URL(raw, window.location.href)

  url.protocol =
    url.protocol === 'https:' || url.protocol === 'wss:' ? 'wss:' : 'ws:'

  return url.toString()
}

export const clearCookie = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    // 获取cookie的所有属性
    const cookieAttributes = document.cookie.replace(
      new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$'),
      '$1',
    )

    // 设置cookie的过期时间为过去的日期，并附带原有的属性
    document.cookie =
      name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;' + cookieAttributes
  }
}

export const useThirdStateCode = () => {
  let code = localStorage.getItem('thirdStateCode')

  if (!code) {
    code = sampleSize('abcdefghijklmnopqrstuvwxyz', 16).reduce(
      (prev, cur) => `${prev}${cur}`,
    )
    localStorage.setItem('thirdStateCode', code)
  }

  return code
}

export function useTenantCode() {
  const { tenantCode } = useParams<{ tenantCode: string }>()

  let domainTenantCode = ''
  if (WEBSITE_DOMAIN_NAME) {
    const hostname = window.location.hostname
    if (hostname.endsWith(WEBSITE_DOMAIN_NAME)) {
      const domainFirst = hostname.replace(WEBSITE_DOMAIN_NAME, '')
      if (domainFirst) {
        domainTenantCode = domainFirst.replace('.', '')
      }
    }
  }

  return TENANT_CODE || domainTenantCode || tenantCode
}
