"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useControlled = void 0;
const react_1 = require("react");
const useConstantCallback_1 = require("./useConstantCallback");
function useControlled(args) {
    const { value: valueProp, onChange: onChangeProp, initialValue, name = 'unknown', } = args;
    const { current: isControlled } = (0, react_1.useRef)(valueProp !== undefined);
    const [valueState, setValueState] = (0, react_1.useState)(initialValue);
    const value = isControlled ? valueProp : valueState;
    if (process.env['NODE_ENV'] !== 'production') {
        (0, react_1.useEffect)(() => {
            if (isControlled !== (valueProp !== undefined)) {
                console.error([
                    `A component is changing the ${isControlled ? '' : 'un'}controlled state of ${name} to be ${isControlled ? 'un' : ''}controlled.`,
                    'Elements should not switch from uncontrolled to controlled (or vice versa).',
                    `Decide between using a controlled or uncontrolled ${name} ` +
                        'element for the lifetime of the component.',
                    "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.",
                    'More info: https://fb.me/react-controlled-components',
                ].join('\n'));
            }
        }, [isControlled, name, valueProp]);
        const { current: defaultValue } = (0, react_1.useRef)(initialValue);
        (0, react_1.useEffect)(() => {
            if (!isControlled && defaultValue !== initialValue) {
                console.error([
                    `A component is changing the default state of an uncontrolled ${name} after being initialized. ` +
                        `To suppress this warning opt to use a controlled ${name}.`,
                ].join('\n'));
            }
        }, [defaultValue, initialValue, isControlled, name]);
    }
    const onChange = (0, useConstantCallback_1.useConstantCallback)((nextValue) => {
        if (!isControlled) {
            setValueState(nextValue);
        }
        onChangeProp === null || onChangeProp === void 0 ? void 0 : onChangeProp(nextValue);
    });
    return [value, onChange];
}
exports.useControlled = useControlled;
