import { Form, Title } from '@frontend/mui'
import { LoadingButton } from '@mui/lab'
import { Link, Stack, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { useMe } from 'src/context/me'
import {
  LoginMutation,
  loginByPhoneNumberMutation,
  registerByPhoneNumberAllTenantMutation,
  loginByPhoneNumberAllTenantMutation,
  useLoginByEmailMutation,
  useLoginMutation,
  wecomAppLoginMutation,
} from 'src/generated/apollo'
import { isWxWorkScene, wxWorkState } from 'src/pages/wxwork/useWxWork'
import { useTenantCode } from 'src/utils/chaos'
import { useOAuth2 } from 'src/utils/oauth2'
import { LoginType } from '.'
import { AccountFields } from '../login/fields/account'
import { EmailFields } from '../login/fields/email'
import { PhoneFields } from '../login/fields/phone'
import { LoginInput } from '../login/form/Login'
import { useLocation, useNavigate } from 'react-router-dom'

export function LoginForm(props: PropsWithChildren<{ loginType: LoginType }>) {
  const { loginType, children } = props
  const { login } = useMe()
  const [loginMutation] = useLoginMutation()
  const [loginByEmailMutation] = useLoginByEmailMutation()
  const { maybeGrantAuthorization } = useOAuth2()
  const { tenantId } = useRecoilValue(wxWorkState)
  const tenantCode = useTenantCode()

  const { pathname } = useLocation()
  const isRegister = pathname.startsWith('/register')

  const navigate = useNavigate()

  return (
    <Form<LoginInput>
      mode="onBlur"
      onSubmit={async (values) => {
        let res: LoginMutation['login'] | undefined = undefined

        if (!tenantCode) {
          if (isRegister) {
            const { data } = await registerByPhoneNumberAllTenantMutation({
              variables: {
                input: values,
              },
            })
            res = data?.registerByPhoneNumberAllTenant
          } else {
            const { data } = await loginByPhoneNumberAllTenantMutation({
              variables: {
                input: values,
              },
            })
            res = data?.loginByPhoneNumberAllTenant
          }
        } else if (loginType === LoginType.Account) {
          if (isWxWorkScene()) {
            const { data } = await wecomAppLoginMutation({
              variables: {
                input: {
                  ...values,
                  tenantId: tenantId!,
                },
              },
            })
            res = data?.wecomAppLogin
          } else {
            const { data } = await loginMutation({
              variables: {
                input: {
                  ...values,
                  tenantCode: tenantCode!,
                },
              },
            })
            res = data?.login
          }
        } else if (loginType === LoginType.Email) {
          const { data } = await loginByEmailMutation({
            variables: {
              input: {
                ...values,
                tenantCode,
              },
            },
          })
          res = data?.loginByEmail
        } else if (loginType === LoginType.Phone) {
          const result = await loginByPhoneNumberMutation({
            variables: {
              input: {
                ...values,
                tenantCode: tenantCode!,
              },
            },
          })
          res = result.data?.loginByPhoneNumber
        }

        if (!res) return
        maybeGrantAuthorization(res.token)
        login(res.token)
      }}
    >
      {({ formState }) => (
        <>
          {!tenantCode && (
            <>
              <Title
                variant="h6"
                color={(theme) => theme.palette.primary.main}
                pl={3}
                mb={4}
                sx={{
                  '> .MuiBox-root': {
                    borderRadius: '3px',
                  },
                }}
              >
                {isRegister ? '注册' : '登录'}
              </Title>
              <Typography variant="subtitle1" marginBottom="16px">
                {isRegister ? (
                  <>
                    已有账号？
                    <Link
                      onClick={() => {
                        navigate('/login')
                      }}
                      underline="hover"
                      sx={(theme) => ({
                        ...theme.typography.subtitle1,
                      })}
                    >
                      登录现有账号
                    </Link>
                  </>
                ) : (
                  <>
                    还没有账号？
                    <Link
                      onClick={() => {
                        navigate('/register')
                      }}
                      underline="hover"
                      sx={(theme) => ({
                        ...theme.typography.subtitle1,
                      })}
                    >
                      立即注册
                    </Link>
                  </>
                )}
              </Typography>
            </>
          )}
          <Stack
            display="grid"
            gap="12px"
            gridTemplateRows="repeat(3, 72px)"
            height={tenantCode ? 284 : 292}
          >
            {loginType === LoginType.Account ? (
              <AccountFields />
            ) : loginType === LoginType.Email ? (
              <EmailFields />
            ) : loginType === LoginType.Phone ? (
              <PhoneFields />
            ) : null}
            {children}
          </Stack>
          <LoadingButton
            type="submit"
            loading={formState.isSubmitting}
            fullWidth
          >
            {tenantCode ? '登录' : isRegister ? '注册' : '登录'}
          </LoadingButton>
        </>
      )}
    </Form>
  )
}
