import { Box } from '@mui/material'

export function FakeField() {
  return (
    <Box sx={{ height: 0 }} overflow="hidden" position="absolute">
      <input type="text"></input>
      <input type="password"></input>
    </Box>
  )
}
