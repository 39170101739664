"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diff = void 0;
const lodash_1 = require("lodash");
function diff(object, prevObject) {
    if ((0, lodash_1.isNil)(object) || (0, lodash_1.isNil)(prevObject)) {
        return object;
    }
    const result = Object.entries(object).reduce((result, [key, value]) => {
        const prevValue = prevObject[key];
        if ((0, lodash_1.isEqual)(value, prevValue)) {
            return result;
        }
        if ((0, lodash_1.isArray)(value) || (0, lodash_1.isArray)(prevValue)) {
            return (0, lodash_1.extend)(result, {
                [key]: value,
            });
        }
        if ((0, lodash_1.isObject)(value) || (0, lodash_1.isObject)(prevValue)) {
            return (0, lodash_1.extend)(result, {
                [key]: diff(value, prevValue),
            });
        }
        result[key] = value;
        return result;
    }, {});
    return Object.keys(result).length === 0 ? null : result;
}
exports.diff = diff;
