import { AvatarProps, Box, Typography } from '@mui/material'
import { ReactElement, cloneElement } from 'react'

export function AltImage(props: {
  src?: AvatarProps['src']
  icon?: ReactElement
  alt: string
  onClick: () => void
  size?: number
}) {
  const { src, alt, size = 40, onClick, icon } = props

  return (
    <Box position="relative">
      <Box
        position="absolute"
        width={size}
        height={size}
        display="grid"
        onClick={() => onClick()}
        sx={{
          top: 0,
          zIndex: 1,
          placeItems: 'center',
          borderRadius: '100%',
          ':hover': {
            bgcolor: 'rgba(0,0,0,0.5)',
            '.MuiTypography-root': {
              display: 'block',
            },
          },
        }}
      >
        <Typography
          variant="overline"
          color="white"
          display="none"
          sx={{ cursor: 'pointer' }}
        >
          {alt}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '20px',
          border: '1px solid #E3E5EF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon ? (
          cloneElement(icon, {
            sx: {
              ...icon.props.sx,
              width: '20px',
              height: '20px',
              color: '#527BF2',
            },
          })
        ) : (
          <img
            src={src}
            alt={alt}
            style={{
              cursor: 'pointer',
              width: '20px',
              height: '20px',
            }}
          />
        )}
      </Box>
    </Box>
  )
}
