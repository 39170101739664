export const {
  LOGO_URL,
  TELETRAAN_LOGO_URL,
  TELETRAAN_LOGO_PRIMARY_URL,
  TELETRAAN_LOGO_MOBILE_PRIMARY_URL,
  LOGIN_BG_URL,
  LOGIN_BG_VIDEO_URL,
  LOGIN_BG_VIDEO_PC_URL,
  CARD_BG_URL,
  TITLE,
  COPY_RIGHT,
  ICP_NUMBER,
  ICP_LINK,
  SUPPORT,
  TENANT_CODE,
  PUBLIC_URL,
  ROUTE_URL,
  WXWORK_APP_ID,
  LOGIN_BG_URLS,
  LOGIN_WELCOME_DESC,
  WEBSITE_DOMAIN_NAME,
  TELETRAAN_WEBSITE_LOGO_URL,
} = window.__env

export const VIEWPORT_HEIGHT = window.innerHeight + 'px'
