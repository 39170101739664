import { Status } from '@frontend/mui'
import { Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useMyAppList } from 'src/context/appList'
import { isMobile } from 'src/utils/chaos'

export function EmptyPage() {
  const navigate = useNavigate()
  const { appList } = useMyAppList()

  return !appList ? null : (
    <Stack width="100%" height="calc(100vh - 52px)">
      {isMobile() && (
        <Stack p="0 4px">
          <Button
            variant="text"
            sx={{ width: 'fit-content' }}
            onClick={() => navigate('/dashboard')}
          >
            返回工作台
          </Button>
        </Stack>
      )}
      <Stack flex={1} justifyContent="center" alignItems="center">
        <Stack spacing={4}>
          <Status variant="error" />
          <Typography variant="subtitle2" color="#8E939B">
            未找到资源页面，请联系管理员
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
