import microApp from '@micro-zoe/micro-app'
import { initDingH5RemoteDebug } from 'dingtalk-h5-remote-debug'
import React from 'react'
import ReactDOM from 'react-dom'
import 'text-security/text-security.css'
import App from './App'
import './index.css'
const origin = window.location.origin
initDingH5RemoteDebug()
microApp.start({
  preFetchApps: [
    { name: 'navigate', url: `${origin}/app/navigate` },
    { name: 'workbench', url: `${origin}/app/workbench` },
  ],
})

const container = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container,
)
