"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsArrayEqual = exports.isArrayEqual = exports.isEqual = exports.isIdEqual = void 0;
const lodash_1 = require("lodash");
function isIdEqual(a, b) {
    return (a === null || a === void 0 ? void 0 : a.id) === (b === null || b === void 0 ? void 0 : b.id);
}
exports.isIdEqual = isIdEqual;
function isEqual(a, b) {
    if (a === b) {
        return true;
    }
    if ((0, lodash_1.isNil)(a) || (0, lodash_1.isNil)(b)) {
        return false;
    }
    if (!(0, lodash_1.isNil)(a.id) &&
        !(0, lodash_1.isNil)(b.id)) {
        return isIdEqual(a, b);
    }
    return (0, lodash_1.isEqual)(a, b);
}
exports.isEqual = isEqual;
function isArrayEqual(a, b, comparator = isEqual) {
    if (Array.isArray(a) && Array.isArray(b)) {
        return (a.length === b.length &&
            a.every((value, index) => comparator(value, b[index])));
    }
    return comparator(a, b);
}
exports.isArrayEqual = isArrayEqual;
function getIsArrayEqual(isEqual) {
    return (a, b) => isArrayEqual(a, b, isEqual);
}
exports.getIsArrayEqual = getIsArrayEqual;
