import { AsyncStatus, useConfirm } from '@frontend/mui'
import { PriorityHigh } from '@mui/icons-material'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useEffectOnce } from 'react-use'
import { useRecoilState } from 'recoil'
import { logout } from 'src/context/apollo'
import {
  bindWechatStaffMutation,
  unbindWechatStaffMutation,
} from 'src/generated/apollo'
import { accessTokenStorage, ssoStorage, wxMPState } from './useWxMP'

export function WxMPBindFailedPage() {
  const confirm = useConfirm()
  const [wxMP, setWxMP] = useRecoilState(wxMPState)
  const accessToken = accessTokenStorage.get()
  const { bindStatus } = wxMP

  const bind = useCallback(async () => {
    if (!accessToken) {
      setWxMP((prev) => ({
        ...prev,
        bindStatus: 'invalid',
      }))
      return
    }

    const { data } = await bindWechatStaffMutation({
      variables: { accessToken },
    })

    if (data?.bindWechatStaff) {
      ssoStorage.remove()
      setWxMP((prev) => ({
        ...prev,
        bindStatus: 'success',
        isBindSuccess: true,
      }))
    } else {
      setWxMP((prev) => ({
        ...prev,
        bindStatus: 'invalid',
      }))
    }
  }, [accessToken, setWxMP])

  const unbind = useCallback(async () => {
    const { data } = await unbindWechatStaffMutation()

    if (!data?.unbindWechatStaff) {
      await confirm({ title: '取消绑定失败' })
    }
    setWxMP({
      isBindSuccess: false,
      isAuthorized: false,
      bindStatus: null,
    })
    logout()
  }, [confirm, setWxMP])

  const onClickButton = useCallback(async () => {
    if (bindStatus === 'success') {
      const confirmed = await confirm({
        title: '确定取消绑定吗？',
        cancelLabel: '返回',
        confirmLabel: '确定取消',
      })
      if (confirmed) {
        unbind()
      }
    } else {
      unbind()
    }
  }, [bindStatus, confirm, unbind])

  useEffectOnce(() => {
    if (!bindStatus) {
      bind()
    }
  })

  return (
    <AsyncStatus loading={!bindStatus}>
      <Stack sx={{ height: window.innerHeight }}>
        <Stack
          rowGap="10px"
          alignItems="center"
          justifyContent="center"
          height={700}
        >
          <Grid
            sx={{
              display: 'grid',
              width: '80px',
              height: '80px',
              borderRadius: '100%',
              background: '#f4664a',
              placeItems: 'center',
              color: 'white',
              fontSize: '48px',
              marginBottom: '14px',
            }}
          >
            <PriorityHigh fontSize="inherit" />
          </Grid>
          <Typography variant="h4">绑定失败</Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            whiteSpace="pre-wrap"
          >
            请重新绑定
          </Typography>
        </Stack>
        <Button onClick={() => onClickButton()} variant="text">
          重新绑定
        </Button>
      </Stack>
    </AsyncStatus>
  )
}
