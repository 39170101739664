import { Form, TextField } from '@frontend/mui'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { PropsWithChildren, useEffect } from 'react'
import profile from 'src/assets/profile.svg'
import profileBG from 'src/assets/profile_bg.svg'
import { isMobile } from 'src/utils/chaos'

export function Container(props: PropsWithChildren<{}>) {
  return isMobile() ? (
    <Stack
      sx={{
        width: '100vw',
        height: '100vh',
        background: `url(${profileBG})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {props.children}
    </Stack>
  ) : (
    <Paper>
      <Stack
        sx={{
          width: 500,
          height: 560,
          background: `url(${profileBG})`,
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {props.children}
      </Stack>
    </Paper>
  )
}

export function AddProfilePage() {
  useEffect(() => {
    const title = document.title
    setTimeout(() => (document.title = '欢迎使用'))
    return () => {
      document.title = title
    }
  }, [])

  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      overflow="auto"
      bgcolor="white"
    >
      <Form
        onSubmit={(values) => {
          fetch('/api/v1/wecom/post_profile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          }).then(() => (window.location.pathname = '/'))
        }}
      >
        <Container>
          <Stack height="100%" spacing={6} p="48px 36px">
            <Stack spacing={3}>
              <img
                src={profile}
                width={isMobile() ? 140 : 210}
                height={isMobile() ? 36 : 54}
                alt="welcome"
              />
              <Stack spacing={1}>
                <Typography variant="h4">欢迎来到 设备资产管理</Typography>
                <Typography variant="body2" color="text.secondary">
                  请补充资料，以便后续使用
                </Typography>
              </Stack>
            </Stack>
            <Stack flex={1} justifyContent="space-between" alignItems="center">
              <TextField
                name="name"
                label="姓名"
                placeholder="请输入真实姓名"
                required
              />
              <Button type="submit" sx={{ width: isMobile() ? '100%' : 240 }}>
                确定
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Form>
    </Stack>
  )
}
