import { Maybe } from '@frontend/type'

export function emailValidator(value: Maybe<string>) {
  if (!value) {
    return
  }
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
  ) {
    return '邮箱格式错误，请重新输入'
  }
}

export function phoneNumberValidator(value: Maybe<string>) {
  if (!value) {
    return
  }
  if (!/^[\d]{11}$/.test(value)) {
    return '手机号格式错误，请重新输入'
  }
}

export function nicknameValidator(value: Maybe<string>) {
  if (!value) {
    return
  }
  if (!/^(\w|\p{Unified_Ideograph}){0,32}$/u.test(value)) {
    return '昵称超过32个字符，请重新输入'
  }
}
