"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tube = exports.sleep = exports.isNotNil = void 0;
function isNotNil(value) {
    return value !== undefined && value !== null;
}
exports.isNotNil = isNotNil;
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.sleep = sleep;
function tube(raw) {
    return raw;
}
exports.tube = tube;
