import { TextField, useAlert } from '@frontend/mui'
import { Box, ButtonProps, FormLabel, Stack } from '@mui/material'
import debounce from 'debounce-promise'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  isEmailExistsQuery,
  sendIdentityVerifyCodeToEmailMutation,
} from 'src/generated/apollo'
import { emailValidator } from 'src/utils/validators'
import { GetCaptcha } from '../../../components/GetCaptcha'
import { FakeField } from './fake'

const debounceCheckEmail = debounce(isEmailExistsQuery, 500)

export function EmailFields({
  getCaptchaSize,
}: {
  getCaptchaSize?: ButtonProps['size']
}) {
  const alert = useAlert()
  const { getValues } = useFormContext()
  const [getCaptchaDisabled, setGetCaptchaDisabled] = useState<boolean>(true)
  const { watch } = useFormContext()

  useEffect(() => {
    if (!watch('email')) {
      setGetCaptchaDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('email')])

  const focusRef = useRef<HTMLInputElement | null>(null)

  return (
    <>
      <FakeField />
      <TextField
        name="email"
        label="邮箱"
        required
        validate={async (value: string) => {
          setGetCaptchaDisabled(true)

          if (!value) {
            return '请填写该必填项'
          }
          const validMsg = emailValidator(value)
          if (validMsg) {
            return validMsg
          }
          const { data: existQuery } = await debounceCheckEmail({
            variables: {
              email: value,
            },
          })
          if (!existQuery.isEmailExists) {
            return '该邮箱不存在'
          }

          setGetCaptchaDisabled(false)
        }}
      />
      <Stack direction="row">
        <TextField
          sx={{ marginRight: '16px' }}
          name="verifyCode"
          label="邮箱验证码"
          required
          inputProps={{
            ref: focusRef,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormLabel
            sx={{
              visibility: 'hidden',
            }}
          >
            GetCaptcha
          </FormLabel>
          <GetCaptcha
            disabled={getCaptchaDisabled}
            onClickGetCaptcha={async () => {
              const result = await sendIdentityVerifyCodeToEmailMutation({
                variables: {
                  email: getValues('email'),
                },
              })

              if (result.data?.sendIdentityVerifyCodeToEmail) {
                alert.show({
                  severity: 'success',
                  children: '验证码已发送至邮箱，请注意查收。',
                })
              }
            }}
            size={getCaptchaSize}
            focusRef={focusRef}
          />
        </Box>
      </Stack>
    </>
  )
}
