"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inheritTreeState = exports.initFlatTree = exports.initFlatTreeState = exports.updateMatched = exports.updateSelected = exports.updateFocused = exports.updateExpanded = exports.updateCollapsed = exports.flatTreeFromTree = void 0;
const lodash_1 = require("lodash");
function flatTreeFromTree(tree, options = {}) {
    const { defaultExpanded = false, flatTree = [], depth = 0, pathIndexes = [], parentIndex, cascader, } = options;
    return tree.reduce((result, current) => {
        const { nodeId, label, value, children } = current;
        const index = result.length;
        const isLeaf = !(children === null || children === void 0 ? void 0 : children.length);
        const isRoot = parentIndex === undefined;
        const expanded = cascader ? false : defaultExpanded;
        if (!isRoot) {
            result[parentIndex].childrenIndexes.push(index);
        }
        result.push({
            nodeId,
            label,
            value,
            index,
            pathIndexes,
            parentIndex,
            childrenIndexes: !isLeaf ? [] : undefined,
            depth,
            isLeaf,
            isRoot,
            expanded,
            focused: false,
            selected: false,
            indeterminate: false,
            collapsed: expanded ? false : isRoot ? false : true,
            matched: true,
        });
        if (children === null || children === void 0 ? void 0 : children.length) {
            return flatTreeFromTree(children, {
                defaultExpanded,
                flatTree: result,
                depth: depth + 1,
                pathIndexes: pathIndexes.concat(index),
                parentIndex: index,
                cascader,
            });
        }
        return result;
    }, flatTree);
}
exports.flatTreeFromTree = flatTreeFromTree;
function updateCollapsed(args) {
    const { flatTree, index } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    if (!node.isRoot) {
        node.collapsed = !node.pathIndexes
            .map((index) => flatTree[index])
            .every((parent) => parent.expanded);
    }
    if (!node.isLeaf) {
        node.childrenIndexes.forEach((index) => {
            updateCollapsed({
                flatTree,
                index,
            });
        });
    }
}
exports.updateCollapsed = updateCollapsed;
function updateExpanded(args) {
    const { flatTree, index, value, cascader } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    const nextExpanded = value !== null && value !== void 0 ? value : !node.expanded;
    if (nextExpanded === node.expanded) {
        return;
    }
    if (cascader && nextExpanded) {
        const currentExpanded = flatTree
            .filter((item) => item.depth === node.depth)
            .find((item) => item.expanded);
        if (currentExpanded) {
            currentExpanded.expanded = false;
            updateCollapsed({
                flatTree,
                index: currentExpanded.index,
            });
        }
    }
    node.expanded = nextExpanded;
    updateCollapsed({
        flatTree,
        index,
    });
}
exports.updateExpanded = updateExpanded;
function updateFocused(args) {
    const { flatTree, index } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    const focused = flatTree.find((node) => node.focused);
    if ((focused === null || focused === void 0 ? void 0 : focused.index) === index) {
        return;
    }
    if (focused) {
        focused.focused = false;
    }
    node.focused = true;
}
exports.updateFocused = updateFocused;
function updateSelectedParents(args) {
    const { flatTree, index } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    const children = node.childrenIndexes.map((index) => flatTree[index]);
    const allSelected = children.every((node) => node.selected);
    const allUnSelected = children.every((node) => node.selected === false && node.indeterminate === false);
    node.selected = allSelected;
    node.indeterminate = !allSelected && !allUnSelected;
    if (!node.isRoot) {
        updateSelectedParents({
            flatTree,
            index: node.parentIndex,
        });
    }
}
function updateSelectedChildren(args) {
    var _a;
    const { flatTree, index, value } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    const nextSelected = value !== null && value !== void 0 ? value : node.selected;
    (_a = node.childrenIndexes) === null || _a === void 0 ? void 0 : _a.forEach((index) => {
        const child = flatTree[index];
        child.selected = nextSelected;
        child.indeterminate = false;
        if (!child.isLeaf) {
            updateSelectedChildren({
                flatTree,
                index,
                value: nextSelected,
            });
        }
    });
}
function updateSelected(args) {
    const { flatTree, index, value, ignoreParents, isolated } = args;
    if ((0, lodash_1.isNil)(index) || index === -1) {
        return;
    }
    const node = flatTree[index];
    const nextSelected = value !== null && value !== void 0 ? value : !node.selected;
    if (nextSelected === node.selected && node.indeterminate === false) {
        return;
    }
    node.selected = nextSelected;
    node.indeterminate = false;
    if (isolated) {
        return;
    }
    if (!node.isLeaf) {
        updateSelectedChildren({
            flatTree,
            index,
            value: nextSelected,
        });
    }
    if (!ignoreParents && !node.isRoot) {
        updateSelectedParents({
            flatTree,
            index: node.parentIndex,
        });
    }
}
exports.updateSelected = updateSelected;
function updateMatched(args) {
    const { flatTree, value, cascader } = args;
    if (value) {
        flatTree.forEach((node) => {
            const matched = typeof value === 'function' ? value(node) : node.label.includes(value);
            node.matched = matched;
            if (cascader) {
                return;
            }
            node.expanded = matched;
            if (!matched) {
                return;
            }
            node.collapsed = false;
            node.pathIndexes
                .map((index) => flatTree[index])
                .forEach((node) => {
                node.matched = true;
                node.expanded = true;
                node.collapsed = false;
            });
        });
    }
    else {
        flatTree.forEach((node) => {
            node.matched = true;
            node.expanded = false;
            node.collapsed = !node.isRoot;
        });
    }
}
exports.updateMatched = updateMatched;
function initFlatTreeState(args) {
    const { flatTree, defaultExpanded, expanded, focused, selected, search, isolated, } = args;
    if (expanded !== undefined) {
        flatTree.forEach((node) => {
            node.expanded = defaultExpanded || false;
            node.collapsed = defaultExpanded ? false : node.isRoot ? false : true;
        });
    }
    if (expanded === null || expanded === void 0 ? void 0 : expanded.length) {
        expanded.forEach((expanded) => {
            const index = flatTree.findIndex((node) => node.nodeId === expanded.nodeId);
            updateExpanded({
                flatTree,
                index,
                value: true,
            });
        });
    }
    if (focused !== undefined) {
        flatTree.forEach((node) => {
            node.focused = false;
        });
    }
    if (focused) {
        const index = flatTree.findIndex((node) => node.nodeId === focused.nodeId);
        updateFocused({
            flatTree,
            index,
            value: true,
        });
    }
    if (selected !== undefined) {
        flatTree.forEach((node) => {
            node.selected = false;
            node.indeterminate = false;
        });
    }
    if (selected === null || selected === void 0 ? void 0 : selected.length) {
        selected.forEach((selected) => {
            const index = flatTree.findIndex((node) => node.nodeId === selected.nodeId);
            updateSelected({
                flatTree,
                index,
                value: true,
                isolated,
            });
        });
    }
    if (search) {
        updateMatched({
            flatTree,
            value: search,
        });
    }
}
exports.initFlatTreeState = initFlatTreeState;
function initFlatTree(args) {
    const { nodes, isEqual, defaultExpanded, expanded: expandedArg, focused: focusedArg, selected: selectedArg, search, cascader, } = args;
    const flatTree = flatTreeFromTree(nodes, {
        defaultExpanded,
        cascader,
    });
    const expanded = expandedArg
        ? flatTree.filter((item) => expandedArg.find((innerItem) => isEqual(item.value, innerItem)))
        : undefined;
    const focused = flatTree.find((item) => isEqual(item.value, focusedArg));
    const selected = selectedArg
        ? flatTree.filter((item) => selectedArg.find((innerItem) => isEqual(item.value, innerItem)))
        : undefined;
    initFlatTreeState({
        flatTree,
        expanded,
        focused,
        selected,
        search,
    });
    return flatTree;
}
exports.initFlatTree = initFlatTree;
function inheritTreeState(args) {
    const { flatTree, prevFlatTree, defaultExpanded } = args;
    const focused = prevFlatTree.find((node) => node.focused);
    const expanded = prevFlatTree.filter((node) => node.expanded);
    const selected = prevFlatTree.filter((node) => node.selected);
    initFlatTreeState({
        flatTree,
        defaultExpanded,
        expanded,
        focused,
        selected,
    });
}
exports.inheritTreeState = inheritTreeState;
