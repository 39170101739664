import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider as RawApolloProvider,
  from,
  makeVar,
  split,
} from '@apollo/client'
import { GraphQLErrors } from '@apollo/client/errors'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { storage } from '@frontend/helpers'
import { useAlert, useDialog } from '@frontend/mui'
import { omitAllVariables, omitVariables } from '@frontend/operation'
import microApp from '@micro-zoe/micro-app'
import { Button } from '@mui/material'
import { createUploadLink } from 'apollo-upload-client'
import {
  getMainDefinition,
  getOperationDefinitionOrDie,
} from 'apollo-utilities'
import { FieldNode, buildSchema } from 'graphql'
import raw from 'raw.macro'
import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { isDingDingScene } from 'src/pages/dingding/useDingTalk'
import { isWxWorkScene } from 'src/pages/wxwork/useWxWork'
import { clearCookie, isMobile, resolveWebSocketURL } from 'src/utils/chaos'
import { qsToObj } from 'src/utils/query'

const alertVar = makeVar<ReturnType<typeof useAlert>>(
  null as unknown as ReturnType<typeof useAlert>,
)

export const authTokenStorage = storage<string>('AUTH_TOKEN')

export function logout() {
  const alert = alertVar()
  authTokenStorage.remove()
  const qsObj = qsToObj(window.location.search)
  const isOAuth2LoginScene =
    /login/.test(window.location.pathname) && qsObj.authorize_url
  if (isWxWorkScene()) {
    clearCookie()
    // window.location.pathname = '/'
    window.close()
  } else if (isDingDingScene()) {
    window.location.href = `/`
  } else if (!isOAuth2LoginScene) {
    window.location.href = '/'
  }
}

export const schema = buildSchema(raw('../generated/schema.graphql'))

const GRAPHQL_SERVER = '/graphql/'

const authTokenLink = setContext((_, context) => {
  const authToken = authTokenStorage.get()

  if (!authToken) {
    return
  }
  return {
    headers: {
      ...context.headers,
      Authorization: `Token ${authToken}`,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const alert = alertVar()

  if (operation.getContext().ignoreError) {
    return
  }
  microApp.setGlobalData({
    errors: graphQLErrors,
  })

  if (networkError) {
    alert.show({
      severity: 'error',
      children: '请刷新重试',
    })
  }
})

const variablesLink = new ApolloLink((operation, forward) => {
  const definition = getOperationDefinitionOrDie(operation.query)
  const fields = definition.selectionSet.selections.map(
    (item) => (item as FieldNode).name.value,
  )
  const multi = fields.length > 1
  const initialVariables = operation.getContext()['initialVariables']

  operation.variables = multi
    ? omitAllVariables({
        schema,
        operationType: definition.operation,
        variables: operation.variables,
        initialVariables,
      })
    : omitVariables({
        schema,
        operationType: definition.operation,
        operationName: fields[0],
        variables: operation.variables,
        initialVariables,
      })

  return forward(operation)
})

const urlLink = setContext((operation) => {
  return {
    uri: `${GRAPHQL_SERVER}?${operation.operationName}`,
  }
})

const subscriptionLink = new WebSocketLink({
  uri: resolveWebSocketURL(process.env.REACT_APP_WS || '/ws'),
  options: {
    connectionParams: () => {
      const authToken = authTokenStorage.get()

      if (!authToken) {
        return
      }
      return {
        authToken,
      }
    },
    lazy: true,
    reconnect: true,
  },
})

const uploadLink = createUploadLink({
  uri: GRAPHQL_SERVER,
})

const networkLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  subscriptionLink,
  uploadLink,
)

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authTokenLink, errorLink, variablesLink, urlLink, networkLink]),
  defaultOptions: {
    mutate: {
      errorPolicy: 'none',
      fetchPolicy: 'no-cache',
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  },
})

const AuthErrorDict: Record<string, string> = {
  TENANT_STATE_CHANGED: '该企业已被禁用，无法登录系统！',
  ACCOUNT_AUTHORIZATION_CHANGED:
    '当前账号的权限发生了变更，为不影响正常操作，请重新登录！',
  ACCOUNT_INACTIVE: '当前账号已被禁止登录，无法进行任何操作！',
  ACCOUNT_STATE_CHANGED: '当前账号身份认证信息被更新，请重新登录！！',
}

function useErrorThrottle() {
  const set = new Set<string>()
  return (message: string, callback: Function) => {
    if (set.has(message)) return
    else {
      set.add(message)
      setTimeout(() => set.delete(message), 1000)
      callback()
    }
  }
}

export function ApolloProvider(props: React.PropsWithChildren<{}>) {
  const alert = useAlert()
  const dialog = useDialog()
  const check = useErrorThrottle()

  useEffect(() => {
    alertVar(alert)
  }, [alert])

  useEffectOnce(() => {
    let toLogout = true
    microApp.addGlobalDataListener(async (data: { errors: GraphQLErrors }) => {
      const path = window.location.pathname
      const code = data.errors[0].extensions?.code as string
      // 登录页没有登出逻辑
      if (toLogout && !/^\/login/.test(path)) {
        if (
          code === 'AUTHENTICATION_ERROR' ||
          code === 'ACCOUNT_NOT_EXISTS' ||
          // 即将废弃
          code === 'ACCOUNT_EXPIRED' ||
          code === 'ROLE_CHANGED_SCOPE' ||
          code === 'ACCOUNT_ORGANIZATION_CHANGED'
        ) {
          toLogout = false
          logout()
          return
        }
        if (AuthErrorDict[code]) {
          dialog.show({
            hideCloseIcon: true,
            title: '提示',
            content: AuthErrorDict[code],
            maxWidth: 'md',
            actions: ({ setOpen }) => (
              <Button
                sx={isMobile() ? { width: 'calc(200% + 16px)' } : undefined}
                onClick={() => {
                  setOpen(false)
                  toLogout = false
                  logout()
                }}
              >
                我知道了
              </Button>
            ),
          })
          return
        }
      }
      // 功能包过期不提示
      if (code !== 'EXPIRED_ERROR') {
        data.errors.forEach((error) => {
          check(error.message, () => {
            alert.show({
              severity: 'error',
              children:
                error.extensions.code === 'SUBREQUEST_HTTP_ERROR'
                  ? '请刷新重试'
                  : error.message,
            })
          })
        })
      }
    })
  })

  return (
    <RawApolloProvider client={apolloClient}>
      {props.children}
    </RawApolloProvider>
  )
}
