import microApp, { getActiveApps } from '@micro-zoe/micro-app'
import { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useMyAppList } from 'src/context/appList'
import React from 'react'
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event'
/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import { AsyncStatus } from '@frontend/mui'
import { Box } from '@mui/system'
import { useLocation } from 'react-use'
import { useWaterMark } from 'src/components/Watermark'
import { useMe } from 'src/context/me'
import { EmptyPage } from './empty'

function LazyMicroApp(props: { name: string; url: string }) {
  const { refetch } = useMe()
  const { name, url } = props
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Box hidden={visible} sx={{ height: 'calc(100vh - 48px)' }}>
        <AsyncStatus loading={true} />
      </Box>
      <Box hidden={!visible}>
        <micro-app
          name={name}
          url={url}
          onCreated={() => setVisible(false)}
          onUnmount={() => {
            setVisible(false)
          }}
          onMounted={() => {
            // 挂载子应用刷新水印
            setVisible(true)
            refetch()
          }}
        ></micro-app>
      </Box>
    </>
  )
}

export function MicroApp() {
  const { appList } = useMyAppList()
  const origin = window.location.origin
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { refetch } = useWaterMark()
  const pushState = useCallback(
    (
      appCode: string,
      path: string,
      fromAppCode?: string,
      toAppCode?: string,
    ) => {
      const currentApps = getActiveApps()
      if (!currentApps.includes(appCode)) {
        // 跳转到未挂载的子应用
        const currentPath = path === '/' ? '/dashboard' : path
        navigate(currentPath)
      } else {
        // 由于菜单栏一直处于挂载状态
        // 为了正确展示页面，菜单栏app内部会进行一次跳转
        // 菜单栏内部的navigate操作不会引起当前子应用的卸载（不卸载会导致某些问题）
        // 以下操作是为了触发子应用的卸载
        if (appCode === 'navigate') {
          navigate(path, { replace: true })
          refetch()
          return
        }
        // 跳转到当前已经挂载的子应用的某个路由
        // 如管理中心账号管理页面通过菜单栏的最近访问跳转到人员管理页面
        let childPath = path.replace(/^\/?subapp\/[^/]+/, '')
        if (!childPath) return
        microApp.setData(appCode, {
          path: childPath,
          fromAppCode: fromAppCode,
          toAppCode: toAppCode,
          currentApps: currentApps,
        })
  
      }
    },
    [navigate, refetch],
  )

  useEffect(() => {
    const currentApps = getActiveApps()
    microApp.setData('navigate', {
      pushState,
      path: pathname,
      currentApps: currentApps,
    })
    // 子应用跳转到其他自应用需要通过基座
    microApp.setData('workbench', { pushState })
  }, [appList, pathname, pushState])

  return (
    <div>
      <Routes>
        <Route
          path="/dashboard/*"
          element={
            <LazyMicroApp
              name="workbench"
              url={`${origin}/app/workbench`}
            ></LazyMicroApp>
          }
        />
        {appList?.map((app) => (
          <Route
            key={app.code}
            path={`/subapp/${app.code}/*`}
            element={
              <LazyMicroApp
                name={app.code}
                url={`${origin}/app/${app.code}`}
              ></LazyMicroApp>
            }
          />
        ))}
        {/* 忽略菜单栏下的路由 */}
        <Route path="app" element={<></>} />
        <Route path="profile" element={<></>} />
        {/* 404匹配兜底 */}
        <Route path="*" element={<EmptyPage />} />
      </Routes>
    </div>
  )
}
