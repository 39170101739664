import { TextField, useAlert } from '@frontend/mui'
import { Box, ButtonProps, FormLabel, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { sendIdentityVerifyCodeToPhoneNumberMutation } from 'src/generated/apollo'
import { isDingDingScene } from 'src/pages/dingding/useDingTalk'
import { phoneNumberValidator } from 'src/utils/validators'
import { GetCaptcha } from '../../../components/GetCaptcha'
import { FakeField } from './fake'

export function PhoneFields({
  getCaptchaSize,
}: {
  getCaptchaSize?: ButtonProps['size']
}) {
  const alert = useAlert()
  const { getValues } = useFormContext()
  const [getCaptchaDisabled, setGetCaptchaDisabled] = useState<boolean>(true)
  const { watch } = useFormContext()
  const validPhone = async (value?: string) => {
    setGetCaptchaDisabled(true)

    if (!value) {
      return '请填写该必填项'
    }

    const validFormatResult = phoneNumberValidator(value)
    if (validFormatResult) {
      return validFormatResult
    }

    setGetCaptchaDisabled(false)
  }

  useEffect(() => {
    if (!watch('phoneNumber')) {
      setGetCaptchaDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('phoneNumber')])

  const focusRef = useRef<HTMLInputElement | null>(null)
  const isDingDing = isDingDingScene()
  return (
    <>
      <FakeField />
      <TextField
        name="phoneNumber"
        label="手机号"
        validate={validPhone}
        required
        sx={{}}
      />
      <Stack
        direction="row"
    
      >
        <TextField
          sx={{ marginRight: '16px' }}
          name="verifyCode"
          label="短信验证码"
          required
          inputProps={{
            ref: focusRef,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormLabel
            sx={{
              visibility: 'hidden',
            }}
          >
            GetCaptcha
          </FormLabel>
          <GetCaptcha
            disabled={getCaptchaDisabled}
            onClickGetCaptcha={async () => {
              const result = await sendIdentityVerifyCodeToPhoneNumberMutation({
                variables: {
                  phoneNumber: getValues('phoneNumber'),
                },
              })
              if (result.data?.sendIdentityVerifyCodeToPhoneNumber) {
                alert.show({
                  severity: 'success',
                  children: '验证码已发送至手机，请注意查收。',
                })
              }
            }}
            size={getCaptchaSize}
            focusRef={focusRef}
          />
        </Box>
      </Stack>
    </>
  )
}
