import { useLocation } from 'react-router-dom'
import { authTokenStorage } from 'src/context/apollo'
import { myAppListQuery } from 'src/generated/apollo'
import { qsToObj } from 'src/utils/query'

export function useOAuth2() {
  const location = useLocation()
  const qsObj = qsToObj(location.search)
  const isOAuth2LoginScene =
    /login/.test(location.pathname) && qsObj.authorize_url

  // 第三方授权场景, 在登录成功或已登录时(用户在平台持有token了), 通知后端以302跳转回调地址带授权码(code)
  const maybeGrantAuthorization = async (authToken: string) => {
    if (isOAuth2LoginScene) {
      // 第三方接口无法判断token是否过期，只能先调用一下平台接口
      const { data, errors } = await myAppListQuery()
      if (!data || errors) {
        authTokenStorage.remove()
        window.location.reload()
        return
      }
      await fetch(qsObj.authorize_url, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }).then((res) => {
        if (res.redirected) {
          window.location.href = res.url
        }
      })
    }
  }

  return { isOAuth2LoginScene, maybeGrantAuthorization }
}
